import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable, of } from "rxjs";
import { delay, map } from "rxjs/operators";
import { EPerfilDoitt } from "src/app/shared/services/PerfilDoitt.service";
import { StateService } from "src/app/shared/services/stateService";
import { environment } from "src/environments/environment";
import { CategoriaIndicacao } from "../models/categorias.model";
import Indicacao from "../models/indicacao.model";
import { CategoriasParceiros, Parceiros, TabelasComissao } from "../models/parceiros/parceiros.model";
import PessoaDoitt from "../models/pessoaDoitt.model";
import { TiposDeIndicacao } from "../models/tipos-indicacao.model";
import { TiposDeSeguro } from "../models/tipos-seguros.model";
import { EDoittTipoDeRankingDoitt, ETiposDeEvolucaoDeGanhos, IDoittDashboard, IEvolucaoDeGanhos, IParceiroDoitt, IRanking, IResumoCashback, IResumoValoresCashback, IUltimaAtualizacao } from "./DTO/response/IDoittDashboard";


@Injectable({
  providedIn: "root",
})
export class CashBackService {
  private urlApiHost = environment.apidoittCashback.host;
  private methods = environment.apidoittCashback.endpoints.methods;

  constructor(private http: HttpClient, private stateService: StateService) {}

  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + this.stateService.getDoittToken()
    });
  }

  private getIdsPayload(){
    if(this.stateService.possuiPermissaoPerfil(103)){
      return {
        idPessoa: 0,
        idParceiro: this.getIdParceiro()
      }
    }else if(this.stateService.possuiPermissaoPerfil(102)){
      return {
        idPessoa: this.stateService.getPessoaLogada().Id,
        idParceiro: 0
      }
    }else{
      return {
        idPessoa: 0,
        idParceiro: 0
      }
    }
  }

  getResumo(dataInicial: string, dataFinal: string, parceiro: number) {
    const url = this.urlApiHost + this.methods.indicacao.post.dashboardResume;
    let payload = {
      dataInicial,
      dataFinal,
      idPessoa: this.getIdsPayload().idPessoa,
      idParceiro: this.getIdsPayload().idParceiro
    };

    // if(this.stateService.possuiPermissaoPerfil(103)){
    //   payload = {
    //     dataInicial,
    //     dataFinal,
    //     idPessoa: 0,
    //     idParceiro: this.getIdParceiro()
    //   }
    // }else if(this.stateService.possuiPermissaoPerfil(102)){
    //   payload = {
    //     dataInicial,
    //     dataFinal,
    //     idPessoa: this.stateService.getPessoaLogada().Id,
    //     idParceiro: 0
    //   }
    // }else{
    //   payload = {

    //     dataInicial,
    //     dataFinal,
    //     idPessoa: 0,
    //     idParceiro: 0
    //   }
    // }
    // const payload = {
    //   dataInicial,
    //   dataFinal,
    //   idPessoa: parceiro !== null? 0 :this.stateService.getPessoaLogada().Id,
    //   idParceiro: parceiro !== null? parceiro : 0,
    // };

    return this.http.post<IResumoCashback>(url, payload, { headers: this.getHeader() });
  }

  
  getResumoValores(dataInicial: string, dataFinal: string, parceiro: number) {
    const url = this.urlApiHost + this.methods.indicacao.post.dashboardValores;

    // const payload = {
    //   dataInicial,
    //   dataFinal,
    //   idPessoa: this.stateService.getPessoaLogada().Id,
    //   idParceiro: parceiro !== null? parceiro : 0,
    // };


    let payload = {
      dataInicial,
      dataFinal,
      idPessoa: this.getIdsPayload().idPessoa,
      idParceiro: this.getIdsPayload().idParceiro
    }
    // if(this.stateService.possuiPermissaoPerfil(103)){
    //   payload = {
    //     dataInicial,
    //     dataFinal,
    //     idPessoa: 0,
    //     idParceiro: this.getIdParceiro()
    //   }
    // }else if(this.stateService.possuiPermissaoPerfil(102)){
    //   payload = {
    //     dataInicial,
    //     dataFinal,
    //     idPessoa: this.stateService.getPessoaLogada().Id,
    //     idParceiro: 0
    //   }
    // }else{
    //   payload = {

    //     dataInicial,
    //     dataFinal,
    //     idPessoa: 0,
    //     idParceiro: 0
    //   }
    // }

    return this.http.post<IResumoValoresCashback>(url, payload, { headers: this.getHeader() });
  }

  getIdParceiro(){
    let id: number;
    let obj : PessoaDoitt = JSON.parse(sessionStorage.getItem('PessoaDoitt'));
    console.log('PessoaDoitt:',obj);
    if(this.stateService.possuiPermissaoPerfil(103) && obj.parceiros.length !== 0) {
      id = obj.parceiros[0].idParceiro;
    } else if (!this.stateService.possuiPermissaoPerfil(103) && obj.parceiros.length === 0) {
      id = 0;
    } else {
      id = -1;
    }
    
    // console.log(id);
    return id;
    // return obj.idPessoa;
    
  }

  getDadosDoGrafico(meses: number, parceiro: string) {
    const url = this.urlApiHost + this.methods.indicacao.post.dashboardEvolucao;

    let payload = {
      'meses': meses,
      idPessoa: this.getIdsPayload().idPessoa,
      idParceiro: this.getIdsPayload().idParceiro
    }
    // if(this.stateService.possuiPermissaoPerfil(103)){
    //   payload = {
    //     'meses': meses,
    //     idPessoa: 0,
    //     idParceiro: this.getIdParceiro()
    //   }
    // }else if(this.stateService.possuiPermissaoPerfil(102)){
    //   payload = {
    //     'meses': meses,
    //     idPessoa: this.stateService.getPessoaLogada().Id,
    //     idParceiro: 0
    //   }
    // }else{
    //   payload = {

    //     'meses': meses,
    //     idPessoa: 0,
    //     idParceiro: 0
    //   }
    // }
    // const payload = {
    //   'meses': meses,
    //   "idPessoa": parceiro !== null ? 0 : this.stateService.getPessoaLogada().Id,
    //   idParceiro: parceiro !== null ? parseInt(parceiro): 0 ,
    // };

    return this.http.post<IEvolucaoDeGanhos[]>(url, payload, { headers: this.getHeader() }).pipe(map(data => {
      data.forEach(grafico => {
        const total = grafico.ganhosPorMes.reduce((p, c) => p + c);

        if(total <= 0)
          grafico.ganhosPorMes = [];
      });

      return data;
    }));
  }

  deleteParceiro(idParceiro: number) {
    const url = this.urlApiHost +  this.methods.parceiro.del.parceiro + idParceiro;

    return this.http.delete<string>(url, { headers: this.getHeader(), responseType: 'text' as any });
  }

  getUltimasAtualizacoes(dataInicial: string, dataFinal: string, status: string, idCategoria: number, parceiro?: number) {
    const url = this.urlApiHost + this.methods.indicacao.post.dashboardUltimasAtualizacoes;
    let payload = {
      idPessoa: this.getIdsPayload().idPessoa,
      idParceiro: this.getIdsPayload().idParceiro
    }
    // if(this.stateService.possuiPermissaoPerfil(103)){
    //   payload = {
    //     idPessoa: 0,
    //     idParceiro: this.getIdParceiro()
    //   }
    // }else if(this.stateService.possuiPermissaoPerfil(102)){
    //   payload = {
    //     idPessoa: this.stateService.getPessoaLogada().Id,
    //     idParceiro: 0
    //   }
    // }else{
    //   payload = {
    //     idPessoa: 0,
    //     idParceiro: 0
    //   }
    // }
    // let payload = {
    //   // idPessoa: this.stateService.getPessoaLogada().Id,
    //   idPessoa: this.stateService.possuiPermissaoPerfil(103) ? 0 : this.getIdParceiro(),
    //   // idParceiro: parceiro !== null ? this.getIdParceiro() : 0,
    //   idParceiro: this.stateService.possuiPermissaoPerfil(103) ? this.getIdParceiro() : 0,
    // }

    if(dataInicial)
      payload['dataInicial'] = dataInicial;

    if(dataFinal)
      payload['dataFinal'] = dataFinal;

    if(status)
      payload['status'] = status;

    if(idCategoria)
      payload['idCategoria'] = idCategoria;

    return this.http.post<any>(url, payload, { headers: this.getHeader() });
  }

  getParceiros(id?: number, filtro: "ATIVOS" | "INATIVOS" | "TODOS" = "ATIVOS") {
    let url = this.urlApiHost + environment.apidoittCashback.endpoints.methods.parceiro.get.parceirosXcategorias + (id ? id.toString() : '0');

    if(filtro !== "TODOS")
      url += '/' + filtro;

    return this.http.get<IParceiroDoitt[]>(url, { headers: this.getHeader() })
      .pipe(map(res => this.adicionarNomeDoParceiroAbreviado(res, 24)));
  }

  getRanking(dataInicial: string, dataFinal: string, tipo: EDoittTipoDeRankingDoitt) {
    const url = this.urlApiHost + this.methods.indicacao.post.dashboardRanking;

    const payload = {
      dataInicial,
      dataFinal,
      tipoRanking: tipo,
    };

    return this.http.post<IRanking[]>(url, payload, { headers: this.getHeader() })
      .pipe(map(res => this.adicionarNomeDoParceiroAbreviado(res, 13)));
  }

  getTiposIndicacao(): Array<TiposDeIndicacao> {
    const indicacao: Array<TiposDeIndicacao> = [
      {
        icon: "fas fa-key",
        title: "Locação",
      },
      {
        icon: "fa fa-home",
        title: "Compra & Venda",
      },
      {
        icon: "fas fa-hand-holding-heart",
        title: "Seguros",
      },
      {
        icon: "fas fa-plug",
        title: "Energia",
      },
      {
        icon: "fa-solid fa-faucet",
        title: "Periódicos",
      },
    ];

    return indicacao;
  }

  getTiposSeguro(): Array<TiposDeSeguro> {
    return [
      {
        titulo: "Conteúdo",
        id: "seg01",
      },
      {
        titulo: "Condolar",
        id: "seg02",
      },
      {
        titulo: "Fiança",
        id: "seg03",
      },
      {
        titulo: "Garantia (obras)",
        id: "seg04",
      },
      {
        titulo: "Incêndio",
        id: "seg05",
      },
      {
        titulo: "Prestamista",
        id: "seg06",
      },
      {
        titulo: "Previdência privada",
        id: "seg07",
      },
      {
        titulo: "Resposabilidade civil Obras",
        id: "seg08",
      },
      {
        titulo: "Riscos de Engenharia",
        id: "seg09",
      },
      {
        titulo: "Saúde",
        id: "seg10",
      },
      {
        titulo: "Seguro Pet",
        id: "seg11",
      },
      {
        titulo: "Título de Capitalização",
        id: "seg12",
      },
      {
        titulo: "Veículos",
        id: "seg13",
      },
      {
        titulo: "Vida",
        id: "seg14",
      },
      {
        titulo: "Vida & Funeral",
        id: "seg15",
      },
    ];
  }

  getCategorias():Observable<Array<CategoriaIndicacao>>{
    const headers = this.getHeader();
    
    return this.http.get<Array<CategoriaIndicacao>>(this.urlApiHost + this.methods.categorias.get.categorias, { headers })
  }

  getDashboardDataDoitter(): Observable<IDoittDashboard> {
    return of({
      visaoGeral: {
        valorTotalGanho: "5.740,00",
        totalDeIndicacoes: 80,
        indicacoesRealizadas: 40,
        indicacoesEmAndamento: 23,
        indicacoesFechadadas: 14,
        indicacoesRecusadas: 3,
        cashbackDoitt: "20.740,00",
        negociosFechados: 4890,
      },
      evolucaoDeGanhos: [
        {
          label: "valor total",
          meses: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio"],
          ganhosPorMes: [0, 3000, 1000, 6000, 7500],
          tipo: ETiposDeEvolucaoDeGanhos.VALOR_TOTAL,
        },
      ],
      parceiros: [
        {
          image: "assets/mock/wohpag parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 12,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/apsa parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 530,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/acende parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 239,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/imunimaster parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 192,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/wohpag parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 5,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/apsa parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 1,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/acende parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 87,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/imunimaster parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 92,
          valorTotal: 2235.58,
        },
      ],
      ultimasAtualizacoes: [
        {
          codigo: "#123456",
          data: "dd/mm/yyyy",
          servico: "Compra & venda",
          parceiro: "img",
          status: "Realizadas",
          idStatus: 999,
          valor: "----",
          cashback: "-----",
        },
      ],
    });
  }

  getDashboardDataDoitt(): Observable<IDoittDashboard> {
    return of({
      visaoGeral: {
        valorTotalGanho: "5.740,00",
        totalDeIndicacoes: 80,
        indicacoesRealizadas: 40,
        indicacoesEmAndamento: 23,
        indicacoesFechadadas: 14,
        indicacoesRecusadas: 3,
        cashbackDoitt: "20.740,00",
        negociosFechados: 4890,
      },
      evolucaoDeGanhos: [
        {
          label: "negócios fechados",
          meses: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio"],
          ganhosPorMes: [0, 1500, 2000, 4500, 6000],
          tipo: ETiposDeEvolucaoDeGanhos.NEGOCIOS_FECHADOS,
        },
        {
          label: "valor total",
          meses: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio"],
          ganhosPorMes: [0, 3000, 1000, 6000, 7500],
          tipo: ETiposDeEvolucaoDeGanhos.VALOR_TOTAL,
        },
      ],
      parceiros: [
        {
          image: "assets/mock/wohpag parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 12,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/apsa parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 530,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/acende parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 239,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/imunimaster parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 192,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/wohpag parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 5,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/apsa parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 1,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/acende parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 87,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/imunimaster parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 92,
          valorTotal: 2235.58,
        },
      ],
      ultimasAtualizacoes: [
        {
          codigo: "#123456",
          data: "dd/mm/yyyy",
          servico: "Compra & venda",
          parceiro: "img",
          status: "Realizadas",
          idStatus: 999,
          valor: "----",
          cashback: "-----",
        },
      ],
    });
  }

  getDashboardDataParceiro(): Observable<IDoittDashboard> {
    return of({
      visaoGeral: {
        valorTotalGanho: "5.740,00",
        totalDeIndicacoes: 80,
        indicacoesRealizadas: 40,
        indicacoesEmAndamento: 23,
        indicacoesFechadadas: 14,
        indicacoesRecusadas: 3,
        cashbackDoitt: "20.740,00",
        negociosFechados: 4890,
      },
      evolucaoDeGanhos: [
        {
          label: "negócios fechados",
          meses: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio"],
          ganhosPorMes: [0, 1500, 2000, 4500, 6000],
          tipo: ETiposDeEvolucaoDeGanhos.NEGOCIOS_FECHADOS,
        },
        {
          label: "valor total",
          meses: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio"],
          ganhosPorMes: [0, 3000, 1000, 6000, 7500],
          tipo: ETiposDeEvolucaoDeGanhos.VALOR_TOTAL_CASHBACK,
        },
      ],
      parceiros: [
        {
          image: "assets/mock/wohpag parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 12,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/apsa parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 530,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/acende parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 239,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/imunimaster parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 192,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/wohpag parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 5,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/apsa parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 1,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/acende parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 87,
          valorTotal: 2235.58,
        },
        {
          image: "assets/mock/imunimaster parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 92,
          valorTotal: 2235.58,
        },
      ],
      ultimasAtualizacoes: [
        {
          codigo: "#123456",
          data: "dd/mm/yyyy",
          servico: "Compra & venda",
          parceiro: "img",
          status: "Realizadas",
          idStatus: 999,
          valor: "----",
          cashback: "-----",
        },
      ],
    });
  }

  getEmptyDashboardData(): Observable<IDoittDashboard> {
    return of({
      visaoGeral: {
        valorTotalGanho: "0",
        totalDeIndicacoes: 0,
        indicacoesRealizadas: 0,
        indicacoesEmAndamento: 0,
        indicacoesFechadadas: 0,
        indicacoesRecusadas: 0,
        cashbackDoitt: "0",
        negociosFechados: 0,
      },
      evolucaoDeGanhos: [
        {
          label: "teste",
          meses: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio"],
          ganhosPorMes: [],
          tipo: ETiposDeEvolucaoDeGanhos.VALOR_TOTAL,
        },
      ],
      parceiros: [
        {
          image: "assets/mock/wohpag parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 0,
          valorTotal: 0,
        },
        {
          image: "assets/mock/apsa parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 0,
          valorTotal: 0,
        },
        {
          image: "assets/mock/acende parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 0,
          valorTotal: 0,
        },
        {
          image: "assets/mock/imunimaster parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 0,
          valorTotal: 0,
        },
        {
          image: "assets/mock/wohpag parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 0,
          valorTotal: 0,
        },
        {
          image: "assets/mock/apsa parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 0,
          valorTotal: 0,
        },
        {
          image: "assets/mock/acende parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 0,
          valorTotal: 0,
        },
        {
          image: "assets/mock/imunimaster parceiro.png",
          url: "https://www.google.com",
          negociosFechados: 0,
          valorTotal: 0,
        },
      ],
      ultimasAtualizacoes: [],
    });
  }

  getListaParceiros(idCategoria: string, filtro: "ATIVOS" | "INATIVOS" | "TODOS" = "ATIVOS") {
    let url = this.urlApiHost + this.methods.parceiro.get.parceirosXcategorias + idCategoria;

    if(filtro !== "TODOS")
      url += '/' + filtro;
    const headers = this.getHeader();

    return this.http.get<any>(url, {headers:headers}).pipe(map(res => this.adicionarDescricaoParceiroAbreviado(res, 100)))
  }

  getParceiroByID(id: string): Observable<Parceiros> {
    // const parceiroByiD: Parceiros = listaParceiros.find((e) => {
    //   return e.idParceiro.toString() == id;
    // });
    const url = `${this.urlApiHost + this.methods.parceiro.get.parceiro+id}`
    return this.http.get<Parceiros>(url,{headers:this.getHeader()});
    // return parceiroByiD;
  }

  getListaCategoriasParceiros(): CategoriasParceiros[] {
    const lista = null;
    // const lista = listaCategoriasParceiros;
    return lista
  }


  setIndicacao(body){
    const url = this.urlApiHost + this.methods.indicacao.post.indicacao
        
    return this.http.post<any>(url,body, { headers: this.getHeader() })
  }

  getListaIndicacoes(body) {
    let url = this.urlApiHost + this.methods.indicacao.post.listarIndicacoes;

    return this.http.post<any>(url, body, { headers: this.getHeader() })
  }
  setParceiro(body: any){
    const url = this.urlApiHost + this.methods.parceiro.post.parceiro;
    const header = this.getHeader();
    
    return this.http.post<any>(url, body, {headers: header})
  }

  updateParceiro(body: Parceiros) : Observable<any>{
    const url = this.urlApiHost + this.methods.parceiro.put.parceiro
     return this.http.put<any>(url, body, {headers:this.getHeader()})
  }

  getIndicacao(id: number): Observable<Indicacao> {
    let url = this.urlApiHost + this.methods.indicacao.get.indicacao + `${id}` ;

    return this.http.get<Indicacao>(url, { headers: this.getHeader() }  );
  }

  updateIndicacao(updatedInfo: any): Observable<Indicacao> {
    let url = this.urlApiHost + this.methods.indicacao.put.alterarIndicacao;

    let body = {
      idIndicacao: Number(updatedInfo.idIndicacao),
      status: updatedInfo.status,
      idParceiro: Number(updatedInfo.idParceiro),
      valor: updatedInfo.valor,
      valorCashback: updatedInfo.valorCashback,
      motivoRecusa: updatedInfo.motivoRecusa,
      
    }

    return this.http.put<Indicacao>(url, body, {headers: this.getHeader()})
  }

  // getListaTabelasComissao(): TabelasComissao[] {
  getListaTabelasComissao(): Observable<Array<TabelasComissao>> {
    // const lista = listaTabelasComissao
    const url = this.urlApiHost + this.methods.tabelaComissao.post.listarTabelas
    const payload = {
      idParceiro: null,
      ativo: null,
      inicioVigencia: null,
      fimVigencia: null,
    };
    const headers = this.getHeader()
    return this.http.post<Array<TabelasComissao>>(url,payload, {headers: headers})
  }

  private adicionarNomeDoParceiroAbreviado(parceiros: IParceiroDoitt[] | IRanking[], amount: number): any[] {
    parceiros.forEach(parceiro => {
      parceiro['nomeParceiroAbreviado'] = parceiro.nomeParceiro.slice(0, amount) + '...';
      parceiro['temNomeLongo'] = parceiro.nomeParceiro.length > amount;
    });

    return parceiros;
  }

  private adicionarDescricaoParceiroAbreviado(parceiros: IParceiroDoitt[], amount: number): any[] {
    parceiros.forEach(parceiro => {
      parceiro['descricaoParceiroAbreviado'] = parceiro.descricaoParceiro.slice(0, amount) + '...';
      parceiro['temDescricaoLongo'] = parceiro.descricaoParceiro.length > amount;
    });

   return parceiros;
  }
}
