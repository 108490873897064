import { BehaviorSubject, Subscription } from 'rxjs';
import { IonSlides } from '@ionic/angular';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-slider-select-mobile',
  templateUrl: './slider-select-mobile.component.html',
  styleUrls: ['./slider-select-mobile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderSelectMobileComponent implements OnInit {
  @ViewChild('slider') slider: IonSlides;
  @Input() listaPeriodoDisponivel;
  @Output() onMonthSelection = new EventEmitter();

  public monthSelected = new BehaviorSubject(null);
  private subscription: Subscription;
  public sliderOptions = {
    slidesPerView: 1,
    allowTouchMove: false,
  };

  constructor() { }

  ngOnInit() {}

  ngAfterViewInit() {
    this.subscription = this.monthSelected.subscribe(month => {
      if (month !== null) {
        this.slideTo(month);
        this.onMonthSelection.emit([this.listaPeriodoDisponivel[month]]);
      }
    });

    this.monthSelected.next(this.listaPeriodoDisponivel.length - 1);
  }

  ngOnDestroy() {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  nextMonth() {
    const currentMonth = this.monthSelected.getValue();

    if (currentMonth < (this.listaPeriodoDisponivel.length - 1))
      this.monthSelected.next(this.monthSelected.getValue() + 1);
  }

  previousMonth() {
    const currentMonth = this.monthSelected.getValue();

    if (currentMonth > 0)
      this.monthSelected.next(this.monthSelected.getValue() - 1);
  }

  slideTo(index: number) {
    this.slider.slideTo(index);
  }

}
