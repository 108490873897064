import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ds-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() color: string = "Primary"; //Primary, Secondary, Negative, Success, Dark, Gray
  @Input() type: string = "Solid";
  @Input() full: boolean = false;
  @Input() popover: boolean = false;
  @Input() small: boolean = false;
  @Input() inactive: boolean = false;
  @Input() leftIcon: string;
  @Input() rightIcon: string;
  @Input() fullOnMobile: boolean = false;

  constructor() { }

  ngOnInit() {}

}
