import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StateService } from '../../services/stateService';
import { Router, ActivatedRoute } from '@angular/router';
import { AppShortcutsFacade } from '../../facades/app-shortcuts.facade';
import { Observable } from 'rxjs';
import CustomerService from '../../services/customer.service';
import { ConstantsService } from '../../../shared/services/constants.service';
import { LightBoxesService } from 'src/app/shared/components/lightboxes';

@Component({
  selector: 'app-lista-menu-mobile',
  templateUrl: './lista-menu-mobile.component.html',
  styleUrls: ['./lista-menu-mobile.component.scss'],
  encapsulation: ViewEncapsulation.None, 
})
export class ListaMenuMobileComponent implements OnInit {
  public isListDesign$: Observable<boolean>;
  public customer$ = this.customerService.getCustomerObservable();

  @Input() ehGestaoTotal: boolean;
  @Input() ehContaPool: boolean;
  @Input() possuiPerfilSindico: boolean;
  @Input() possuiPerfilLocatario: boolean;
  @Input() possuiPerfilProprietario: boolean;
  @Input() possuiPerfilGestorSindico: boolean;
  
  constructor(
    public appShortcuts: AppShortcutsFacade,
    private lightBoxesService: LightBoxesService,
    public stateService: StateService,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private customerService: CustomerService,
    public constantsService: ConstantsService,
    ) { 
      activedRoute.params.subscribe(val => {
        this.isListDesign$ = this.appShortcuts.isListDesignObservable();
        this.ngOnInit();
      });
    }
    
  ngOnInit() { 
    this.possuiPerfilSindico = this.stateService.possuiPerfilSindico();
    this.possuiPerfilLocatario = this.stateService.possuiPerfilLocatario();
    this.possuiPerfilProprietario = this.stateService.possuiPerfilProprietario();
    this.possuiPerfilGestorSindico = this.stateService.possuiPerfilGestor() || this.stateService.possuiPerfilSindico()
  }

  possuiPermissaoAcesso(idFuncionalidade: number) {
    return this.stateService.possuiAcessoFuncionalidade(idFuncionalidade);
  }

  ehCondominioPrime() : boolean{
    var condominioLogado = this.stateService.getCondominioLogado();
    if(!condominioLogado) return false;
    return condominioLogado.prime === 1
  }

  validaExibicaoContrato(){
    var gerencial = this.stateService.possuiAcessoCentralAtendimento();
    var condominio = this.stateService.getCondominioLogado();
    if(gerencial && condominio && !this.ehGestaoTotal) {
      if(condominio && (condominio.agencia == "19" || condominio.agencia == "20")) {
        return true;
      } else return false;
    } 
    else if(condominio && !this.ehGestaoTotal)
    {
      return (condominio.agencia == "19" || condominio.agencia == "20") && (this.stateService.possuiAcessoFuncionalidadeGeral(866) || this.stateService.possuiAcessoFuncionalidadeGeral(289));
    }
    else return this.stateService.possuiAcessoFuncionalidadeGeral(866) || this.stateService.possuiAcessoFuncionalidadeGeral(289);
  }

  possuiPermissaoAcessoValidacaoCentralAtendimento(idFuncionalidade: number){
    if(this.stateService.visaoCentralAtendimento())
      return this.stateService.possuiAcessoFuncionalidadeGeral(idFuncionalidade);
    else
      return this.stateService.possuiAcessoFuncionalidade(idFuncionalidade);
  }

  openModalAreaRestrita () {
    this.lightBoxesService.open(
      "custom-generico",
      {
        title: "Acesso Restrito",
        text: "Esta área não é disponivel para acessos de teste.",
      },
      5000
    );
  }

}
