import { Injectable } from "@angular/core";

import { Chooser, ChooserResult } from "@awesome-cordova-plugins/chooser/ngx";
import { Base64 } from "@ionic-native/base64/ngx";
import { FileMobile } from "../../services/DTO/response/FileMobile";
import { FilePath } from "@ionic-native/file-path/ngx";
import { IChooser } from "./IChooser";
import { File } from "@ionic-native/file/ngx";

@Injectable({ providedIn: "root" })
export class ChooserService implements IChooser {
  constructor(public chooser: Chooser, private base64: Base64, private filePath: FilePath) {}

  async getFileMobile(): Promise<FileMobile> {
    let fileDTO: FileMobile;

    const file: ChooserResult = await this.chooser.getFile();


    let filetype = this.getFileType(file.name);

    let path: string = null;
    let base: string = null;

    //encoder para imagens
    if (filetype === "image/jpeg" || filetype === "image/png") {
      path = await this.filePath.resolveNativePath(file.uri);
      base = await this.base64.encodeFile(path);
    }

    fileDTO = {
      data: file.data,
      dataURI: base !== null ? base : file.dataURI,
      // dataURI: file.dataURI,
      mediaType: file.mediaType,
      name: file.name,
      uri: file.uri,
      fileType: filetype,
    };

    return fileDTO;
  }

  getFileType(file: string) {
    if (file.toLowerCase().includes("pdf")) {
      return "pdf";
    } else if (file.toLowerCase().includes("xls")) {
      return "xls";
    } else if (file.toLowerCase().includes("xlsx")) {
      return "xlsx";
    } else if (file.toLowerCase().includes("jpeg") || file.toLowerCase().includes("jpg")) {
      return "image/jpeg";
    } else if (file.toLowerCase().includes("png")) {
      return "image/png";
    } else {
      return "alert";
    }
  }
}

