
<div class="Lightbox TextAreaWithConfirmationPopup" [ngClass]="{ '__is-open': showModal == true }">
  <div class="Popup_ComunicacaoGenerica">
        
    <div class="Popup_Content">
      
      <div class="modalConfirmacao" >
        <div class="modalHeader" [ngClass]="{'_sucesso': sucesso === true, '_falha': sucesso === false }">
          <div class="imgArea" *ngIf="!sucesso;else falha">
            <img src="../../../../../assets/svg/clock-prox-dia.svg" alt="Ícone de Relógio">
          </div>

          <div class="textArea">
            <h6>Falta pouco para concluir! </h6>
            <p id="subtitle">{{tituloHeader}}</p>
            <p>{{mensagemHeader}}</p>
          </div>
        </div>
        
        <ng-template #falha>
          <div class="imgArea check">
            <img src="../../../../../assets/svg/clock-regular.svg" alt="Ícone de Relógio">
          </div>
        </ng-template>
        
        <div class="modalBody">
          <div class="textBody">
            
            <!-- PARA TESTES -->
            <!-- <div class="textGroup" >
              <h6 class="title">Titulo</h6>
              <p class="text" >
                texto
              </p>
            </div>
            <div class="textGroup" >
              <h6 class="title">Titulo</h6>
              <p class="text" >
                texto
              </p>
            </div>
            <div class="textGroup" >
              <h6 class="title">Titulo</h6>
              <p class="text" >
                texto
              </p>
            </div>
            <div class="textGroup" >
              <h6 class="title">Titulo</h6>
              <p class="text" >
                texto
              </p>
            </div> -->


            <div class="textGroup" *ngFor="let item of dadosModal">
              <h6 class="title">{{item.titulo}}</h6>
              <ng-container class="text" *ngIf="item.titulo !== 'Anexos'; else anexo">
                <div [ngClass]="{'container_texto': !texto}" *ngFor="let texto of item.texto">
                  <i class="fas fa-exclamation-triangle fa-sm" style="color: #d33131;" *ngIf="!texto"></i>
                  <p [ngClass]="{'__Alert': !texto}">{{texto || 'Este campo ainda não foi definido.'}}<p>
                </div> 
              </ng-container>
      
              <ng-template  #anexo>
                <div *ngFor="let texto of item.texto">
                  <a href="{{texto.src}}">{{texto.Nome}}</a>
                </div>
              </ng-template>
            </div>

          </div>
        </div>
        
        <div class="checkBoxArea" *ngIf="form">
          <form [formGroup]="this.form" >
            <div class="checkbox-area" >
              <ion-checkbox formControlName = "aceiteTermo"
              color="primary" (click)="aceitarTermo()"></ion-checkbox>
        
              <!-- <p (click)="aceitarTermo()">Concordo com as informações registradas acima em relação ao vencimento, valor, dados bancários e histórico; além de declarar que li atentamente todas as informações fornecidas antes de enviar, garantindo que sejam verdadeiras.</p> -->
              <p >{{mensagemCheck}}</p>
            </div>

          </form>
          
        </div>

        <div class="buttonArea">
          <button class="Button Button_Outline Button_Solid " (click)="cancelarCadastro()">
            <div class="Button_Content">
              <div class="Button_Content_Label">Retomar</div>
            </div>
          </button>
          <button class="Button Button_Primary Button_Solid "
            [ngClass]="{ '__is-inactive': this.form.controls.aceiteTermo.value == false || this.campoVazio }" 
            [disabled]="this.form.controls.aceiteTermo.value === false || this.campoVazio"
            (click)="confirmarCadastro()">
            <div class="Button_Content">
              <div class="Button_Content_Label">Enviar</div>
            </div>
          </button>
        </div>
      </div>
    </div> 
  </div>
</div>