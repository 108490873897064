import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ImagemArquivoDTO } from 'src/app/shared/services/DTO/Request/ImagemArquivo.DTO';

@Component({
  selector: 'app-button-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  providers: [Camera]
})
export class FileComponent implements OnInit {

  @Input() text: string;
  @Input() icon: string;
  @Input() accept: string;
  @Input() type: number;
  @Input() platform: string;
  @Output() onFileChange: EventEmitter<any> = new EventEmitter();


  // public listaImagens: ImagemArquivoDTO[] = [];
  public reader = new FileReader();
  public id;

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(private camera: Camera) {

    this.id = Math.random().toString(36).substring(2)
  }

  ngOnInit() {
    // console.log(this.platform)
  }

  openCamera(type: number) {
    // console.log(type)
    const options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: (type == 1) ? this.camera.MediaType.PICTURE : this.camera.PictureSourceType.PHOTOLIBRARY,
      sourceType: (type == 1) ? 1 : 0,
      allowEdit: false,
      correctOrientation: true
    }
    // console.log("veio na imagem")
    this.camera.getPicture(options).then((imageData) => {
      // console.log("imageData", imageData);
      if (imageData) {
        // this.listaImagens.push(new ImagemArquivoDTO(imageData, "image/jpeg", ""));
        // this.formControls.imagens.setValue(this.listaImagens);
        this.onFileChange.emit(new ImagemArquivoDTO(imageData, "image/jpeg", ""))
      }
      let base64Image = 'data:image/jpeg;base64,' + imageData;
    }, (err) => {
      // console.log("veio no error", err)
    });
  }



}
