<div
  class="Tooltip __is-{{position}} __is-mobile-{{mobilePosition}}"
  [ngClass]="{ '__is-open': isOpen }"
>
  <div class="Tooltip_Content">
    <div *ngIf="title" class="Tooltip_Content_Title">
      {{ title }}
    </div>

    <p class="Tolltip_Content_Text">
      <ng-content></ng-content>
    </p>
  </div>
</div>
