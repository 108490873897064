import { Component, OnInit, SimpleChanges, ViewEncapsulation, Input, OnChanges } from '@angular/core';
import { StateService } from 'src/app/shared/services/stateService';
import { CondominioService } from 'src/app/shared/services/condominio.service';
import { GerenteContaDTO } from 'src/app/shared/services/DTO/response/GerenteContaDTO';
import { LightBoxesService } from 'src/app/shared/components/lightboxes';
import { Platform, PopoverController } from '@ionic/angular';
import CustomerService from '../../services/customer.service';

@Component({
  selector: 'app-consultor-mobile',
  templateUrl: './consultor.component.html',
  styleUrls: ['./consultor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConsultorMobileComponent implements OnInit, OnChanges {
  @Input() trocouCondominio;
  public gerenteConta: GerenteContaDTO;
  public isGT: boolean;
  public isCollapseOpen: boolean = true;
  public customer$ = this.customer.getCustomerObservable();

  constructor(
    public stateService: StateService,
    private condominioService: CondominioService,
    private lightBoxesService: LightBoxesService,
    private platform: Platform,
    private customer: CustomerService,
  ) {}

  ngOnInit() {
    this.getDadosConsultor();
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes.trocouCondominio.currentValue) this.getDadosConsultor();
  }

  toggle() {
    this.isCollapseOpen = !this.isCollapseOpen;
  }

  getDadosConsultor() {
    let condLogado = this.stateService.getCondominioLogado();
    if(condLogado) {
      this.isGT = condLogado.ehGestaoTotal;
      this.condominioService.getDadosGerenteConta(condLogado.contaEmpresa).subscribe(
        response => this.gerenteConta = response,
        err => this.gerenteConta = null,
      ) 
    }
  }

  copyMessage(val: string) {
    if(this.platform.is('mobileweb')) {
      this.lightBoxesService.open('custom-generico',
      {
        title: 'Por favor, selecione o e-mail abaixo e copie para a sua área de transferência.',
        text: val
      },
      25000
    );
      // return;
    }

    if (this.stateService.platformIsDevice()) {
      (cordova as any).plugins.clipboard.copy(val);
    }
    else {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }

    this.lightBoxesService.open('custom-generico',
      {
        title: 'O e-mail foi copiado para a área de transferência.',
        text: val,
      },
      6000
    );

    // setTimeout(() => {
    //   this.avaliacaoLoja.avaliacaoLoja();
    // }, 5000);
  }
}
