<div class="loading-screen-wrapper" *ngIf="loading">
  <p class="message" *ngIf="(message | async) !== null">
    {{ message | async }}
  </p>

  <div class="loading lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>  