import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ElementRef,
  OnDestroy
} from "@angular/core";


import { LightBoxesService } from "../lightboxes.services";

@Component({
  selector: 'yes-or-no-with-link',
  templateUrl: './yes-or-no-with-link.component.html',
  styleUrls: ['./yes-or-no-with-link.component.scss'],
})
export class YesOrNoWithLinkComponent implements OnInit {
  @Input() id: string;
  @Input() className: string;

  private element: any;
  private promisse: Promise<boolean>;
  private resolve;

  constructor(private service: LightBoxesService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("click", el => {
      if (el.target.className === "Lightbox") {
        this.recusar();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.service.add(this);
  }

  ngOnDestroy(): void {
    // TODO: Retirado para não bugar
    // this.service.remove(this.id);
    this.element.remove();
  }

  open(mensagem: { title: string, text: string, link: string }, time: number = 1000): Promise<boolean> {
    const n = this.className;
    const a = this.element;
    this.element.firstChild.classList.add(this.className);
    this.element.getElementsByClassName('Popup_Header_Title')[0].innerHTML = mensagem.title;
    this.element.getElementsByClassName('Popup_Content_MsgDefault')[0].innerHTML = mensagem.text;

    if(mensagem.link) {
      this.element.querySelector('.Popup_Link_Container').style.display = 'flex';
      this.element.querySelector('.Popup_Link_Container button').innerHTML = mensagem.link;
    }
    else {
      this.element.querySelector('.Popup_Link_Container').style.display = 'none';
    }
    
    //this.element.getElementsByClassName('Popup Popup_ComunicacaoGenerica')[0].classList.add(this.className);

    if (time === 0) return new Promise(resolve => { this.resolve = resolve; });

    return new Promise(resolve => {
      setTimeout(function () {
        a.firstChild.classList.remove(n);
        //this.element.getElementsByClassName('Popup Popup_ComunicacaoGenerica')[0].classList.remove(this.className);
        resolve(false);
      }, time);
    });
  }

  confirmar(): void {
    this.resolve('yes');
    this.element.firstChild.classList.remove(this.className);
  }

  recusar(): void {
    this.resolve('no');
    this.element.firstChild.classList.remove(this.className);
  }

  link(): void {
    this.resolve('link');
    this.element.firstChild.classList.remove(this.className);
  }
}
