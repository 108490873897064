import { Component, HostListener, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { Observable } from 'rxjs';
import CustomerService from '../../services/customer.service';

@Component({
  selector: 'modal-historico-mensagens',
  templateUrl: './modal-historico-mensagens.component.html',
  styleUrls: ['./modal-historico-mensagens.component.scss']
})
export class ModalHistoricoMensagensComponent implements OnInit {
  protected mensagens
  protected historico
  protected typeButton

  constructor(private modalService: ModalService,private customerService: CustomerService) { }

  get nomeAdministradora(): string {
   return this.customerService.getCustomer().webTitle.replace(" Condomínio Digital", '')
  }

  ngOnInit() {
    this.modalService.getHistoricoMensagens$().subscribe(v => {
      this.mensagens = v
      if(this.mensagens?.historico) {
        this.historico = [...this.mensagens.historico.slice(1)].reverse()
      } 
    })
  }

  closeModal() {
    this.modalService.setHistoricoMensagens(null)
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeModal()
    }
  }

}
