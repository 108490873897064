<div class="slider-select-component" [ngClass]="{'__one-selection-mode': oneSelectionMode}">
  <div class="prev-buttons">
    <button [disabled]="!canSlidePrevious()" (click)="slideToStart()" *ngIf="showFastBackwardArrow">
      <i class="fas fa-angle-double-left"></i>
    </button>

    <button [disabled]="!canSlidePrevious()" (click)="slidePrevious()">
      <i class="fas fa-chevron-left"></i>
    </button>
  </div>

  <ion-slides class="options-container" [options]="carouselConfig" #slider>
    <ion-slide *ngFor="let option of options; let i = index">
      <div
        class="option"
        [ngClass]="{'__is-active': isOptionSelected(option)}"
      >
        {{option}}
    </div>
    </ion-slide>
  </ion-slides>

  <div class="next-buttons">
    <button [disabled]="!canSlideNext()" (click)="slideNext()">
      <i class="fas fa-chevron-right"></i>
    </button>

    <button [disabled]="!canSlideNext()" (click)="slideToEnd()" *ngIf="showFastForwardArrow">
      <i class="fas fa-angle-double-right"></i>
    </button>
  </div>
</div>
