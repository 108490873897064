export const menuListFake = {
  "condominiosImoveis": {
    "imoveisLocatario": null,
    "imoveisProprietario": null,
    "condominios": [
      {
        "condominio": {
          "carteira": "51.07",
          "agencia": 51,
          "contaEmpresa": 1234,
          "conta": 12345,
          "nome": "MOCK RESIDENCE",
          "uf": "RJ",
          "cnpj": "123456789123",
          "prime": 0.0,
          "ehGestaoTotal": false,
          "ehContaPool": true,
          "exibeCartao": true,
          "nomeGerenteConta": "Roberta Bomfim",
          "optins": {
            "podeVisualizarRelatAcoes": false
          },
          "pdfase2": false,
          "id": 0,
          "uid": "00000000-0000-0000-0000-000000000000",
          "creationDate": "0001-01-01T00:00:00",
          "updateDate": "0001-01-01T00:00:00",
          "validationResult": null,
          "isValid": true
        },
        "perfis": [
          {
            "idPerfil": 19,
            "nomePerfil": "Courrier"
          },
          {
            "idPerfil": 5,
            "nomePerfil": "Condômino"
          },
          {
            "idPerfil": 3,
            "nomePerfil": "Síndico"
          }
        ],
        "funcionalidades": [
          {
            "idFuncionalidade": 100,
            "nomeFuncionalidade": "Seleção de Ambiente",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 101,
            "nomeFuncionalidade": "Minha página",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 102,
            "nomeFuncionalidade": "Dados de acesso à área do cliente",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 103,
            "nomeFuncionalidade": "Dados cadastrais",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 104,
            "nomeFuncionalidade": "Dados cadastrais Síndico/SubSindico e Conselheiro",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 105,
            "nomeFuncionalidade": "OS para alteração de telefone e e-mail de Síndico/SubSindico e Conselheiro",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 106,
            "nomeFuncionalidade": "OS para alteração de endereço de Síndico/SubSindico e Conselheiro",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 107,
            "nomeFuncionalidade": "Dados Cadastrais para Condômino",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 108,
            "nomeFuncionalidade": "OS para alteração de endereços de cobrança e correspondência do Condômino",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 115,
            "nomeFuncionalidade": "Serviços de email e sms",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 116,
            "nomeFuncionalidade": "Minhas solicitações",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 117,
            "nomeFuncionalidade": "Busca de Fornecedores",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 118,
            "nomeFuncionalidade": "Indicar Fornecedores",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 119,
            "nomeFuncionalidade": "Legislação",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 120,
            "nomeFuncionalidade": "Material de apoio",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 121,
            "nomeFuncionalidade": "Perguntas e Respostas",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 122,
            "nomeFuncionalidade": "Fale com a APSA",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 124,
            "nomeFuncionalidade": "Optin Condominio",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 130,
            "nomeFuncionalidade": "Optin Débito Fácil",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 131,
            "nomeFuncionalidade": "Débito Fácil",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 133,
            "nomeFuncionalidade": "Cadastro/Manutenção de contas",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 134,
            "nomeFuncionalidade": "Administração de Bloqueio de Envio de Boletos por e-mail",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 135,
            "nomeFuncionalidade": "Pasta do síndico: acesso remanescente",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 136,
            "nomeFuncionalidade": "Rede de Parcerias",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 200,
            "nomeFuncionalidade": "Página inicial do condomínio para sindicos",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 201,
            "nomeFuncionalidade": "Página inicial do condomínio para condôminos",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 203,
            "nomeFuncionalidade": "Convenções e Regulamentos",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 204,
            "nomeFuncionalidade": "Atas, circulares e convocações",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 205,
            "nomeFuncionalidade": "Dados cadastrais do condomínio",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 206,
            "nomeFuncionalidade": "Calendário de visítas - Malote",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 207,
            "nomeFuncionalidade": "Processos jurídicos",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 208,
            "nomeFuncionalidade": "Andamento de Processos Jurídicos",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 209,
            "nomeFuncionalidade": "Solicitações",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 210,
            "nomeFuncionalidade": "Solicitações Detalhe",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 211,
            "nomeFuncionalidade": "Condôminos",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 212,
            "nomeFuncionalidade": "Condôminos detalhe",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 213,
            "nomeFuncionalidade": "Minhas Unidades",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 214,
            "nomeFuncionalidade": "Acompanhamento orçamentário finaceiro",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 215,
            "nomeFuncionalidade": "Prestação de Contas Analítica",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 216,
            "nomeFuncionalidade": "Prestação de Contas Sintética",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 217,
            "nomeFuncionalidade": "Prestação de Contas Detalhada",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 218,
            "nomeFuncionalidade": "Extrato finaceiro",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 219,
            "nomeFuncionalidade": "Cotas Financeiro",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 220,
            "nomeFuncionalidade": "Cotas Financeiro Condômino",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 221,
            "nomeFuncionalidade": "2a via de Boleto de uma cota",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 222,
            "nomeFuncionalidade": "Autorização de Cobrança Judicial",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 223,
            "nomeFuncionalidade": "Posição das Cotas com vencimento no mês corrente",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 224,
            "nomeFuncionalidade": "Posição das Cotas pagas no mês fechado",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 225,
            "nomeFuncionalidade": "Cotas pagas ao Condomínio no mês fechado",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 226,
            "nomeFuncionalidade": "Receitas previstas finaceiro",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 227,
            "nomeFuncionalidade": "Funcionários - Listagem dos funcionário",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 228,
            "nomeFuncionalidade": "Funcionários - Dados do funcionário",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 229,
            "nomeFuncionalidade": "Funcionários - Dados do pessoais",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 230,
            "nomeFuncionalidade": "Funcionários - Último ContraCheque do Funcionário",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 231,
            "nomeFuncionalidade": "Funcionários - Histórico de  ContraCheque do Funcionário",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 233,
            "nomeFuncionalidade": "Funcionários - Emissão de vale para funcionários",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 234,
            "nomeFuncionalidade": "Funcionários - Pedido de Vale Transporte para funcionário",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 235,
            "nomeFuncionalidade": "Funcionários - Declarar que o funcionário não é beneficiário de Vale-Transporte",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 236,
            "nomeFuncionalidade": "Funcionários - Tipo de Pedido de Rescisão de Contrato de Trabalho",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 237,
            "nomeFuncionalidade": "Funcionários - Rescisão de contrato de trabalho com antecedência de 30 dias",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 238,
            "nomeFuncionalidade": "Funcionários - Rescisão de contrato de trabalho com dispensa de aviso prévio",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 239,
            "nomeFuncionalidade": "Funcionários - Rescisão de contrato de trabalho sem dispensa de aviso prévio",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 240,
            "nomeFuncionalidade": "Funcionários - Demissão do Funcionário",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 241,
            "nomeFuncionalidade": "Funcionários - Simulação de Férias",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 242,
            "nomeFuncionalidade": "Funcionários - Simulação de Rescisão",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 243,
            "nomeFuncionalidade": "Funcionários - Proventos e Descontos para o Funcionário",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 244,
            "nomeFuncionalidade": "Funcionários - Histórico de Proventos e Descontos",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 245,
            "nomeFuncionalidade": "Providências Individuais e em Grupo",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 246,
            "nomeFuncionalidade": "Providencias - Último ContraCheque do Funcionário",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 247,
            "nomeFuncionalidade": "Providencias - Histórico de  ContraCheque do Funcionário",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 248,
            "nomeFuncionalidade": "Providencias - Proventos e Descontos para o Funcionário",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 249,
            "nomeFuncionalidade": "Providencias - Histórico de Proventos e Descontos",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 250,
            "nomeFuncionalidade": "Providencias - Agendar férias de Funcionários",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 251,
            "nomeFuncionalidade": "Providencias - Emissão de vale para funcionários",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 252,
            "nomeFuncionalidade": "Providencias - Pedido de Vale Transporte para funcionário",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 253,
            "nomeFuncionalidade": "Providencias - Funcionário não é beneficiário de Vale-Transporte",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 254,
            "nomeFuncionalidade": "Providencias - Tipo de Pedido de Rescisão de Contrato de Trabalho",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 255,
            "nomeFuncionalidade": "Providencias - Pedido de rescisão de contrato de trabalho com antecedência de 30 dias",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 256,
            "nomeFuncionalidade": "Providencias - Pedido de rescisão de contrato de trabalho com dispensa de aviso prévio",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 257,
            "nomeFuncionalidade": "Providencias - Pedido de rescisão de contrato de trabalho sem dispensa de aviso prévio",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 258,
            "nomeFuncionalidade": "Providencias - Demissão do Funcionário",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 259,
            "nomeFuncionalidade": "Providencias - Simulação de Férias",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 260,
            "nomeFuncionalidade": "Providencias - Simulação de Rescisão",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 261,
            "nomeFuncionalidade": "Providencias - Imprimir ContraCheque de Funcionários em Grupo",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 262,
            "nomeFuncionalidade": "Providencias - Seleção de ContraCheques em Grupo para Impressão",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 263,
            "nomeFuncionalidade": "Serviços de Contratação de Funcionários",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 264,
            "nomeFuncionalidade": "Admissão de Funcionários",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 265,
            "nomeFuncionalidade": "Recrutamento de Funcionários",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 266,
            "nomeFuncionalidade": "Sindicância de Funcionários",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 274,
            "nomeFuncionalidade": "Alterar Dados cadastrais do condomínio",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 275,
            "nomeFuncionalidade": "Cotas Extras",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 276,
            "nomeFuncionalidade": "Funcionários - Jornada de trabalho - Quadro de horário",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 277,
            "nomeFuncionalidade": "Funcionários - Jornada de trabalho - Escala de revezamento",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 280,
            "nomeFuncionalidade": "Funcionários - Informe de Rendimentos do Funcionário",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 281,
            "nomeFuncionalidade": "Meu condomínio",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 283,
            "nomeFuncionalidade": "Prestação de contas - Pasta do síndico",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 284,
            "nomeFuncionalidade": "Relatórios Gerenciais",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 289,
            "nomeFuncionalidade": "Gestão Total - Contratos",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 290,
            "nomeFuncionalidade": "Gestão Total - Mensagens de courrier para os moradores",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 292,
            "nomeFuncionalidade": "Achados e perdidos",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 302,
            "nomeFuncionalidade": "Gestão Total - Relatório de courrier",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 305,
            "nomeFuncionalidade": "Gestão Total - Enviar mensagem de correspondência para o morador",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 306,
            "nomeFuncionalidade": "Envio de Documentos",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 806,
            "nomeFuncionalidade": "Gestão Total - Mensagens de Courrier",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 808,
            "nomeFuncionalidade": "Optin Adesão Boleto Digital",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 810,
            "nomeFuncionalidade": "Normas e Documentos",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 811,
            "nomeFuncionalidade": "Visualizar Enquetes",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 812,
            "nomeFuncionalidade": "Cadastrar e Editar Enquetes",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 813,
            "nomeFuncionalidade": "Encerrar Enquetes",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 814,
            "nomeFuncionalidade": "Visualizar Mural (Convencional)",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 815,
            "nomeFuncionalidade": "Cadastrar e Editar Mural (Convencional)",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 816,
            "nomeFuncionalidade": "Visualizar Ocorrências/Fale com Gestor (Convencional)",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 817,
            "nomeFuncionalidade": "Cadastrar e Editar Ocorrências/Fale com Gestor (Convencional)",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 820,
            "nomeFuncionalidade": "Espaços (Convencional) - Visualização",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 821,
            "nomeFuncionalidade": "Espaços (Convencional) - Exclusão",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 823,
            "nomeFuncionalidade": "Reservas (Convencional) - Listagem",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 824,
            "nomeFuncionalidade": "Reservas (Convencional) - Cancelamento",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 826,
            "nomeFuncionalidade": "Enquetes - Excluir",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 827,
            "nomeFuncionalidade": "Enquetes - Votar",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 828,
            "nomeFuncionalidade": "Benefícios para você - Visualização",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 831,
            "nomeFuncionalidade": "Exibição do ícone do WhatsApp",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 832,
            "nomeFuncionalidade": "Cotas - Pagamento por Cartão - Efetuar Pagamento",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 835,
            "nomeFuncionalidade": "Financeiro - Informe de Quitação",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 839,
            "nomeFuncionalidade": "Pasta prestação de contas - Visualizar comentários  e comentar",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 840,
            "nomeFuncionalidade": "Pasta prestação de contas - Aprovar pastas",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 841,
            "nomeFuncionalidade": "Mural Convencional - Excluir",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 842,
            "nomeFuncionalidade": "Entrega Expressa - Visualizar",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 843,
            "nomeFuncionalidade": "Entrega Expressa - Cancelar Agendamento",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 846,
            "nomeFuncionalidade": "Entrega Expressa - Excluir Agendamento",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 847,
            "nomeFuncionalidade": "Entrega Expressa - Cadastrar Agendamento",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 850,
            "nomeFuncionalidade": "Adesão do Boleto Digital - Condomínio Digital",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 852,
            "nomeFuncionalidade": "Kits de Boas Vindas",
            "permissaoLeitura": true,
            "permissaoModificacao": false
          },
          {
            "idFuncionalidade": 853,
            "nomeFuncionalidade": "Minhas Solicitações - Listagem",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 854,
            "nomeFuncionalidade": "Minhas Solicitações - Cadastro",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 855,
            "nomeFuncionalidade": "Minhas Solicitações - Reenvio",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 858,
            "nomeFuncionalidade": "Envio de Documentos - enviar documento",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 863,
            "nomeFuncionalidade": "Saldo em contas - Dashboard Cond. Digital",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 865,
            "nomeFuncionalidade": "Optin Assinatura Digital - Visualização",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 869,
            "nomeFuncionalidade": "Recorrência - Habilitar recorrência de cartão de crédito",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 870,
            "nomeFuncionalidade": "Recorrência - Cancelamento de recorrência",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 871,
            "nomeFuncionalidade": "Recorrência - Alteração de cartão de crédito de recorrência",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 872,
            "nomeFuncionalidade": "Recorrência - Visualização menu Recorrência Cartão",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 873,
            "nomeFuncionalidade": "Relatórios Prime - Ações",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 874,
            "nomeFuncionalidade": "Relatórios Prime - Financeiro",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 875,
            "nomeFuncionalidade": "Relatórios Prime - Visitas DP",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 876,
            "nomeFuncionalidade": "Visualizar gestores do condomínio",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 877,
            "nomeFuncionalidade": "Cotas com vencimentos",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 878,
            "nomeFuncionalidade": "Cotas devidas",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 879,
            "nomeFuncionalidade": "Saldo devedor",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 880,
            "nomeFuncionalidade": "Calendário de Visitas",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 881,
            "nomeFuncionalidade": "Pasta DP",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 882,
            "nomeFuncionalidade": "Cotas - Visualizar link de histórico de cobrança",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 883,
            "nomeFuncionalidade": "Cotas - Visualizar link redirecionamento processos jurídicos",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 884,
            "nomeFuncionalidade": "Cotas - Visualizar mensagem boleto bloqueado",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 885,
            "nomeFuncionalidade": "Relação de unidades Inadimplentes",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 887,
            "nomeFuncionalidade": "Dados do Gestor/Consultor - Visualização da foto e nome",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 888,
            "nomeFuncionalidade": "Dados do Gestor/Consultor - Visualização do e-mail",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          },
          {
            "idFuncionalidade": 889,
            "nomeFuncionalidade": "Dados do Gestor/Consultor - Visualização da WhatsApp",
            "permissaoLeitura": true,
            "permissaoModificacao": true
          }
        ],
        "descricaoTodosPerfis": null
      }
    ],
    "uFs": [
      "RJ"
    ],
    "possuiPerfilPrestadorServico": false,
    "possuiPerfilProcurador": false
  }
}
