import { NgModule, ErrorHandler } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './containers/layout/layout.component';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { Platform } from '@ionic/angular';
import { AuthGuardChildService } from './shared/interceptors/auth-guard-child';
import { AnalyticsGuardService } from './shared/interceptors/analytics-guard-child';
import { GlobalErrorHandler } from './shared/interceptors/GlobalErrorHandler';
import { EntregaExpressaDeactivator } from './modules/entrega-expressa/services/entrega-expressa.deactivator';
import { IsDoittGuardGuard } from './shared/interceptors/is-doitt-guard.guard';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./modules/login/login.module').then(m => m.LoginModule)
      },
      { path: '', redirectTo: 'login', pathMatch: 'full' }
    ],
    canActivate: [AnalyticsGuardService],
  },
  {
    path: 'home',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/home/home.module').then(m => m.HomeModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'condomino',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/condomino/condomino.module').then(m => m.CondominoModule)
      }
    ],
    canActivate: [AnalyticsGuardService],
  },
  {
    path: 'checklist',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/checklist/checklist.module').then(m => m.ChecklistModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'menu',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/condominio/condominio.module').then(m => m.CondominioModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'atalhos',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/atalhos/atalhos.module').then(m => m.AtalhosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'funcionario',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/funcionario/funcionario.module').then(m => m.FuncionarioModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'colaboradores',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/colaboradores/colaboradores.module').then(m => m.ColaboradoresModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'cotas',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/cotas/cotas.module').then(m => m.CotasModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'recorrencia-cartao-credito',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/recorrencia-cartao-credito/recorrencia-cartao-credito.module').then(m => m.RecorrenciaCartaoCreditoModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  // {
  //   path: 'funcionario',
  //   component: LayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('./modules/funcionario/funcionario.module').then(m => m.FuncionarioModule)
  //     }
  //   ],
  //   canActivate: [AuthGuardChildService, AnalyticsGuardService]
  // },
  {
    path: 'reserva',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/reserva/reserva.module').then(m => m.ReservaModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'reserva-conv',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/reserva-conv/reserva-conv.module').then(m => m.ReservaConvModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'reserva-configuracao',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/reserva-configuracao/reserva-configuracao.module').then(m => m.ReservaConfiguracaoModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'quitacao-anual',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/informes-quitacao/informes-quitacao.module').then(m => m.InformesQuitacaoModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'ocorrencia',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/ocorrencia/ocorrencia.module').then(m => m.OcorrenciaModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'ocorrenciaGT',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/ocorrencia-gt/ocorrencia-gt.module').then(m => m.OcorrenciaGTModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'ocorrenciaGT',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/ocorrencia-gt/ocorrencia-gt.module').then(m => m.OcorrenciaGTModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'envio-documento',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/envio-documento/envio-documento.module').then(m => m.EnvioDocumentoModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'envio-pagamentos',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/envio-pagamentos/envio-pagamentos.module').then(m => m.EnvioPagamentosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'gestao-pagamentos',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/gestao-pagamentos/gestao-pagamentos.module').then(m => m.GestaoPagamentosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'processamento-de-documentos',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/processamento-de-documentos/processamento-de-documentos.module').then(m => m.ProcessamentoDeDocumentosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'envio-documento-geral',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/envio-documento-geral/envio-documento-geral.module').then(m => m.EnvioDocumentoGeralModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'usuario',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/usuario/usuario.module').then(m => m.UsuarioModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'imovel',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/imovel/imovel.module').then(m => m.ImovelModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'extrato',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/extrato/extrato.module').then(m => m.ExtratoModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'mural',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/mural/mural.module').then(m => m.MuralModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'muralGT',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/mural-gt/mural-gt.module').then(m => m.MuralGTModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'documento',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/documentos/documentos.module').then(m => m.DocumentosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'pagamentos',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/pagamentos/pagamentos.module').then(m => m.PagamentosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'enquetes',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/enquetes/enquetes.module').then(m => m.EnquetesModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'fale-conosco',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/fale-com-a-apsa/fale-com-a-apsa.module').then(m => m.FaleComAApsaModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'seguros',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/seguros/seguros.module').then(m => m.SegurosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'acessibilidade',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/acessibilidade/acessibilidade.module').then(m => m.AcessibilidadeModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'disponibilidade',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/disponibilidade/disponibilidade.module').then(m => m.DisponibilidadeModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'centralatendimento',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/centralatendimento/centralatendimento.module').then(m => m.CentralatendimentoModule)
      }
    ],
    canActivate: [AnalyticsGuardService],
  },
  {
    path: 'rede-de-parcerias',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/rede-de-parcerias/rede-de-parcerias.module').then(m => m.RedeDeParceriasModule)
      }
    ],
    canActivate: [AnalyticsGuardService],
  },
  {
    path: 'dados-imovel',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dados-imovel/dados-imovel.module').then(m => m.DadosImovelModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'quero-me-cadastrar',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/quero-me-cadastrar/quero-me-cadastrar.module').then(m => m.QueroMeCadastrarModule)
      }
    ],
    canActivate: [AnalyticsGuardService],
  },
  {
    path: 'emissao-de-boleto',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/emissao-de-boleto/emissao-de-boleto.module').then(m => m.EmissaoDeBoletoModule)
      }
    ],
    canActivate: [AnalyticsGuardService],
  },
  {
    path: 'emissao-de-boleto-cond',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/emissao-de-boleto-cond/emissao-de-boleto-cond.module').then(m => m.EmissaoDeBoletoCondModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'segunda-via-boleto',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/segunda-via-boleto/segunda-via-boleto.module').then(m => m.SegundaViaBoletoModule)
      }
    ],
    canActivate: [AnalyticsGuardService],
  },
  {
    path: 'forbidden',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/forbidden/forbidden.module').then(m => m.ForbiddenModule)
      }
    ],
    canActivate: [AnalyticsGuardService],
  },
  {
    path: 'dados-condominio',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dados-condominio/dados-condominio.module').then(m => m.DadosCondominioModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'cadastro-de-espacos',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/cadastro-de-espacos/cadastro-de-espacos.module').then(m => m.CadastroDeEspacosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'cadastro-de-espacos-gt',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/cadastro-de-espacos-gt/cadastro-de-espacos-gt.module').then(m => m.CadastroDeEspacosGtModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'termo-adesao-condicoes-uso',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/termo-adesao-condicoes-uso/termo-adesao-condicoes-uso.module').then(m => m.TermoAdesaoCondicoesUsoModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'politica-privacidade',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/politica-privacidade/politica-privacidade.module').then(m => m.PoliticaPrivacidadeModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'termos-e-politicas',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/termos-e-politicas/termos-e-politicas.module').then(m => m.TermosEPoliticasModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'sobre',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/sobre/sobre.module').then(m => m.SobreModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'version-logs',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/version-logs/version-logs.module').then(m => m.VersionLogsModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'prestacao-conta-digital',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/prestacao-conta-digital/prestacao-conta-digital.module').then(m => m.PrestacaoContaDigitalModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'prestacao-contas',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/prestacao-contas/prestacao-contas.module').then(m => m.PrestacaoContasModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'descontos-parceiros',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/descontos-parceiros/descontos-parceiros.module').then(m => m.DescontosParceirosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'relatorio-pagamentos',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/relatorio-pagamentos/relatorio-pagamentos.module').then(m => m.RelatorioPagamentosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'clientes-contratacao',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/clientes-contratacao/clientes-contratacao.module').then(m => m.ClientesContratacaoModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'chame-moto',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/entrega-expressa/entrega-expressa.module').then(m => m.EntregaExpressaModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
    canDeactivate: [EntregaExpressaDeactivator],
  },
  {
    path: 'adesao-de-boleto-digital',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/adesao-de-boleto-digital/adesao-de-boleto-digital.module').then(m => m.AdesaoDeBoletoDigitalModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
    canDeactivate: [EntregaExpressaDeactivator],
  },
  {
    path: 'assinatura-digital',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/assinatura-digital/assinatura-digital.module').then(m => m.AssinaturaDigitalModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
    canDeactivate: [EntregaExpressaDeactivator],
  },
  {
    path: 'bloqueio-funcionalidade',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/bloqueio-funcionalidade/bloqueio-funcionalidade.module').then(m => m.BloqueioFuncionalidadeModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
    // canDeactivate: [EntregaExpressaDeactivator],
  },
  {
    path: 'relatorios-gerenciais',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/relatorios-gerenciais/relatorios-gerenciais.module').then(m => m.RelatoriosGerenciaisModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
    // canDeactivate: [EntregaExpressaDeactivator],
  },
  {
    path: 'informe-rendimento',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/informe-rendimento/informe-rendimento.module').then(m => m.InformeRendimentoModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'perguntas-respostas',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/perguntas-respostas/perguntas-respostas.module').then(m => m.PerguntasRespostasModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
    // canDeactivate: [EntregaExpressaDeactivator],
  },
  {
    path: 'redirect',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/redirect/redirect.module').then(m => m.RedirectModule)
      }
    ],
    canActivate: [AnalyticsGuardService],
  },
  {
    path: 'minhas-solicitacoes',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/minhas-solicitacoes/minhas-solicitacoes.module').then(m => m.MinhasSolicitacoesModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'simulacao-de-ferias',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/simulacao-de-ferias/simulacao-de-ferias.module').then(m => m.SimulacaoDeFeriasModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'simulacao-de-rescisao',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/simulacao-de-rescisao/simulacao-de-rescisao.module').then(m => m.SimulacaoDeRescisaoModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'processos-juridicos',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/processos-juridicos/processos-juridicos.module').then(m => m.ProcessosJuridicosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'quadro-horarios',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/quadro-horarios/quadro-horarios.module').then(m => m.QuadroHorariosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'acompanhamento-orcamentario',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/acompanhamento-orcamentario/acompanhamento-orcamentario.module').then(m => m.AcompanhamentoOrcamentarioModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'contratos',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/contratos/contratos.module').then(m => m.ContratosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'pasta-dp',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/pasta-dp/pasta-dp.module').then(m => m.PastaDpModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'courrier',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/courrier/courrier.module').then(m => m.CourrierModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'calendario-visitas',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/calendario-visitas/calendario-visitas.module').then(m => m.CalendarioVisitasModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'tutorial-desktop',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/tutorial-desktop/tutorial-desktop.module').then(m => m.TutorialDesktopModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'relatorio-ocorrencias',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/relatorio-ocorrencias/relatorio-ocorrencias.module').then(m => m.RelatorioOcorrenciasModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'achados-perdidos',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/achados-perdidos/achados-perdidos.module').then(m => m.AchadosPerdidosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'adesao-iptu',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/adesao-iptu/adesao-iptu.module').then(m => m.AdesaoIptuModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'ir-prestador-servico',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/ir-prestador-servico/ir-prestador-servico.module').then(m => m.IrPrestadorServicoModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'inventario',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/inventario/inventario.module').then(m => m.InventarioModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'cotas-inadimplentes',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/cotas-inadimplentes/cotas-inadimplentes.module').then(m => m.CotasInadimplentesModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'receitas-previstas',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/receitas-previstas/receitas-previstas.module').then(m => m.ReceitasPrevistasModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'relacao-de-devedores',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/relacao-de-devedores/relacao-de-devedores.module').then(m => m.RelacaoDeDevedoresModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'lancamento-proventos-descontos',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/lancamento-proventos-descontos/lancamento-proventos-descontos.module').then(m => m.LancamentoProventosDescontosModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'cashBack',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/cash-back/cash-back.module').then(m => m.CashBackModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
    canActivateChild: [IsDoittGuardGuard]
  },
  {
    path: 'biblioteca',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/biblioteca/biblioteca.module').then(m => m.BibliotecaModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService],
  },
  {
    path: 'tabela-comissao',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/tabela-comissao/tabela-comissao.module').then(m => m.TabelaComissaoModule)
      }
    ],
    canActivate: [AuthGuardChildService, AnalyticsGuardService]
  },
  {
    path: 'consulta-pat/:key',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/consulta-pat/consulta-pat.module').then(m => m.ConsultaPatModule)
      }
    ],
    canActivate: [AnalyticsGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    EntregaExpressaDeactivator,
  ]
})
export class AppRoutingModule {
  constructor(public platform: Platform) {
  }
}

