<button
  class="Button Button_{{color}} Button_{{type}}"
  [ngClass]="{
   '__is-full': full,
   '__is-full-on-mobile': fullOnMobile,
   '__is-inactive': inactive,
   '__is-small': small,
   'Button_Popover': popover
  }"
>
  <div class="Button_Content">
    <!-- Ícone à esquerda -->
    <div *ngIf="leftIcon" class="Button_Content_Icon">
      <i class="{{ leftIcon }}"></i>
    </div>

    <div class="Button_Content_Label">
      <ng-content></ng-content>
    </div>

    <!-- Ícone à direita -->
    <div *ngIf="rightIcon" class="Button_Content_Icon">
      <i class="{{ rightIcon }}"></i>
    </div>
  </div>
</button>