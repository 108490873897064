<div class="ListaMenuMobileComponent" *ngIf="!constantsService.ehPerfilTeste()">

  <ng-container *ngLet="isListDesign$ | async as isListDesign">
    <div *ngIf="!isListDesign" class="ListCards __is-floated">
      <div class="ListCards_Wrapper">

        <div *ngIf="possuiPermissaoAcesso(219) || possuiPermissaoAcesso(220)" class="ListCards_Wrapper_Card">
          <a routerLink="/cotas" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-cotas">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
              </span>
            </div>
            <div *ngIf="this.stateService.possuiApenasPerfilCondomino(); then segundaVia else cotas"></div>
            <ng-template #segundaVia>
              <div class="Wrapper_Card_Title">2ª via de boletos</div>
            </ng-template>
            <ng-template #cotas>
              <div class="Wrapper_Card_Title">Visualizar cotas</div>
            </ng-template>
          </a>
        </div>

        <ng-container  *ngIf="(customer$ | async).comodidadesParaVoce.exibir">
          <div *ngIf="this.stateService.getCondominioLogado() && this.stateService.possuiAcessoFuncionalidadeGeral(828)"
            class=" ListCards_Wrapper_Card __is-highlighted">
            <a routerLink="/descontos-parceiros" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                </span>
              </div>
  
              <div class="Wrapper_Card_Title">Comodidades para você!</div>
            </a>
          </div>
        </ng-container>

        <!-- <div *ngIf="stateService.possuiAcessoFuncionalidadeGeral(853)" class="ListCards_Wrapper_Card __is-highlighted">
          <a routerLink="/minhas-solicitacoes" routerLinkActive="active">
            <div class="Wrapper_Card_Icon __is-fake">
              <i class="fas fa-tasks"></i>
            </div>

            <div class="Wrapper_Card_Title">
              Minhas solicitações
            </div>
          </a>
        </div> -->

        <div *ngIf="this.possuiPerfilSindico" class="ListCards_Wrapper_Card  ">
          <a routerLink="/seguros" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="far fa-building" style="color: #fff; font-size: 24px"></i>

              <div class="overlayed-icon">
                <i class="fas fa-heart"></i>
              </div>
            </div>

            <div class="Wrapper_Card_Title">Seguros obrigatórios</div>
          </a>
        </div>

        <!-- <div *ngIf="this.possuiPerfilSindico" class="ListCards_Wrapper_Card  ">
          <a (click)="stateService.openExternalLink('https://imoveis.apsa.com.br/')">
            <div class="Wrapper_Card_Icon">
              <i class="far fa-building" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Aluguel<br>Compra e Venda</div>
          </a>
        </div> -->

        <div *ngIf="this.possuiPerfilSindico" class="ListCards_Wrapper_Card  ">
          <a (click)="stateService.openExternalLink('https://www.revistasindico.com.br/guia')">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-tools" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Fornecedores</div>
          </a>
        </div>

        <div class=" ListCards_Wrapper_Card" *ngIf="ehGestaoTotal && possuiPermissaoAcesso(292)">
          <a routerLink="/achados-perdidos" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
                <i class="fas fa-box-open" style="font-size: 24px;"></i>
              </span>
            </div>

            <div class="Wrapper_Card_Title">Achados e perdidos</div>
          </a>
        </div>

        <div
          *ngIf="this.stateService.permitirEntregaExpressa() != 0 && possuiPermissaoAcessoValidacaoCentralAtendimento(842)"
          class="ListCards_Wrapper_Card">
          <a routerLink="/chame-moto" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-motorcycle">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
                <span class="path9"></span>
                <span class="path10"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Chame a Moto</div>
          </a>
        </div>

        <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(287)" class=" ListCards_Wrapper_Card">
          <a routerLink="/checklist" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-check">
                <span class="path1"></span>
                <span class="path2"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Checklist</div>
          </a>
        </div>

        <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(293)" class="ListCards_Wrapper_Card">
          <a routerLink="/colaboradores" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-colaboradores">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Colaboradores</div>
          </a>
        </div>

        <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(305)" class="ListCards_Wrapper_Card">
          <a routerLink="/courrier" routerLinkActive="active">
            <div class="Wrapper_Card_Icon CourrierIcon">
              <i class="fas fa-business-time"></i>
              <i class="fas fa-clock"></i>
            </div>

            <div class="Wrapper_Card_Title">Courrier</div>
          </a>
        </div>

        <div *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(822)" class="ListCards_Wrapper_Card">
          <a routerLink="/reserva-configuracao" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-reservas1">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Configuração de Reservas</div>
          </a>
        </div>

        <div *ngIf="stateService.permitirEntregaExpressa() === 0 && stateService.possuiAcessoFuncionalidade(206)"
          class="ListCards_Wrapper_Card">
          <a routerLink="/calendario-visitas" routerLinkActive="active">
            <div class="Wrapper_Card_Icon calendarioVisitasIcon">
              <i class="far fa-calendar-check"></i>
            </div>
            <div class="Wrapper_Card_Title">Calendário de visitas</div>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(211)" class="ListCards_Wrapper_Card">
          <a routerLink="/condomino" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-condominio-digital">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
                <span class="path9"></span>
                <span class="path10"></span>
                <span class="path11"></span>
                <span class="path12"></span>
                <span class="path13"></span>
                <span class="path14"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Condôminos</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card" *ngIf="possuiPermissaoAcesso(214)">
          <a routerLink="/acompanhamento-orcamentario" routerLinkActive="active">
            <div class="Wrapper_Card_Icon __not-colorful">
              <i class="fas fa-database Card_Icon_SolidAsColorfull"></i>
            </div>

            <div class="Wrapper_Card_Title" style="word-wrap: initial;">Acompanhamento Orçamentário</div>
          </a>
        </div>



        <div class="ListCards_Wrapper_Card" *ngIf="possuiPermissaoAcesso(885)">
          <a routerLink="/cotas-inadimplentes" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-copy" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Relatório de Inadimplentes</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card" *ngIf="possuiPermissaoAcesso(226)">
          <a routerLink="/receitas-previstas" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-copy" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Receitas previstas</div>
          </a>
        </div>

        <div *ngIf="this.validaExibicaoContrato()" class="ListCards_Wrapper_Card">
          <a routerLink="/contratos" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-file-signature" style="color: #fff; font-size: 24px;"></i>
            </div>

            <div class="Wrapper_Card_Title">Contratos</div>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(205)" class="ListCards_Wrapper_Card __is-highlighted">
          <a routerLink="/dados-condominio" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-sobre-condominio-colorido">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
                <span class="path9"></span>
                <span class="path10"></span>
                <span class="path11"></span>
                <span class="path12"></span>
                <span class="path13"></span>
                <span class="path14"></span>
                <span class="path15"></span>
                <span class="path16"></span>
                <span class="path17"></span>
                <span class="path18"></span>
                <span class="path19"></span>
                <span class="path20"></span>
                <span class="path21"></span>
                <span class="path22"></span>
                <span class="path23"></span>
                <span class="path24"></span>
                <span class="path25"></span>
                <span class="path26"></span>
                <span class="path27"></span>
                <span class="path28"></span>
                <span class="path29"></span>
                <span class="path30"></span>
                <span class="path31"></span>
                <span class="path32"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Dados do Condomínio</div>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(811)" class="ListCards_Wrapper_Card">
          <a routerLink="/enquetes" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-enquete">
                <span class="path1"></span>
                <span class="path2"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Enquetes</div>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(306)" class="ListCards_Wrapper_Card">
          <a routerLink="/envio-documento" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-envio-documentos">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Envio de Documentos</div>
          </a>
        </div>

        <!-- <div
          *ngIf="stateService.pessoaPossuiAcessoFuncionalidade(856)"
          class="ListCards_Wrapper_Card"
        >
          <a routerLink="/processamento-de-documentos">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-envio-documentos">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
              </span>
            </div>

            <div class="Wrapper_Card_Title">
              Documentos não tratados
            </div>
          </a>
        </div> -->

        <div *ngIf="possuiPermissaoAcesso(218)" class="ListCards_Wrapper_Card __is-highlighted">
          <a routerLink="/extrato/sindico" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-extrato">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
                <span class="path9"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Extrato</div>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(306)" class="ListCards_Wrapper_Card EnvioDeDocumentos  ">
          <a routerLink="/envio-pagamentos" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <!-- <img src="../../../assets/img/envioDePagamentosAtalho.png" alt="icone envio de pagamentos"> -->
              <span class="doitt-icon-colorido-cash">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Envio de Pagamentos</div>
          </a>
        </div>

        <div *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(819)" class="ListCards_Wrapper_Card">
          <a routerLink="/cadastro-de-espacos" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-espacos-colorido">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Espaços</div>
          </a>
        </div>

        <div *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(816)" class="ListCards_Wrapper_Card">
          <a routerLink="/ocorrencia" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-fale-com-gestor"><span class="path1"></span><span
                  class="path2"></span><span class="path3"></span><span class="path4"></span><span
                  class="path5"></span><span class="path6"></span><span class="path7"></span></span>
            </div>
            <div *ngIf="possuiPerfilGestorSindico; else faleComOsindico" class="Wrapper_Card_Title">Ocorrências</div>
            <ng-template #faleComOsindico>
              <div class="Wrapper_Card_Title">Fale com o Síndico</div>
            </ng-template>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(227)" class="ListCards_Wrapper_Card">
          <a routerLink="/funcionario" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-funcionarios"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span></span>
            </div>
            <div class="Wrapper_Card_Title">Funcionários</div>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(243)" class="ListCards_Wrapper_Card">
          <a routerLink="/lancamento-proventos-descontos" routerLinkActive="active">
            <div class="Wrapper_Card_Icon __is-fake">
              <i class="fas fa-file-invoice-dollar"></i>
            </div>

            <div class="Wrapper_Card_Title">Lançamento de descontos e proventos</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card" *ngIf="possuiPermissaoAcesso(276)">
          <a routerLink="/quadro-horarios" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-business-time" style="color: #fff; font-size: 24px;"></i>
            </div>

            <div class="Wrapper_Card_Title">Jornada de Trabalho</div>
          </a>
        </div>

        <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(295)" class="ListCards_Wrapper_Card">
          <a routerLink="/disponibilidade" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-reservas1"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span></span>
            </div>
            <div class="Wrapper_Card_Title">Gerenciar Disponibilidade</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card" *ngIf="ehGestaoTotal && possuiPermissaoAcesso(291)">
          <a routerLink="/muralGT" routerLinkActive="active">
            <div class="Wrapper_Card_Icon __not-colorful">
              <i class="apsa-icon-solid-mural Card_Icon_SolidAsColorfull"></i>
            </div>
            <div class="Wrapper_Card_Title">Mural</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card" *ngIf="!ehGestaoTotal && possuiPermissaoAcesso(814)">
          <a routerLink="/mural" routerLinkActive="active">
            <div class="Wrapper_Card_Icon __not-colorful">
              <i class="apsa-icon-solid-mural Card_Icon_SolidAsColorfull"></i>
            </div>
            <div class="Wrapper_Card_Title">Mural</div>
          </a>
        </div>

        <div *ngIf="this.stateService.possuiPerfilFuncionario()" class="ListCards_Wrapper_Card">
          <a routerLink="/funcionario/meus-dados" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-funcionarios">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Meus Dados</div>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(810)" class="ListCards_Wrapper_Card __is-highlighted">
          <a routerLink="/documento" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-malotes">
                <span class="path1"></span>
                <span class="path2"></span>
              </span>
            </div>

            <div class="Wrapper_Card_Title">Normas e Documentos</div>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(283)" class="ListCards_Wrapper_Card">
          <a routerLink="/prestacao-conta-digital" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-financeiro">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
              </span>
            </div>

            <div class="Wrapper_Card_Title">Prestação de contas digital</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card __is-highlighted"
          *ngIf="possuiPermissaoAcesso(215) || possuiPermissaoAcesso(216) || possuiPermissaoAcesso(217)">
          <a routerLink="/prestacao-contas" routerLinkActive="active">
            <i class="fas fa-coins" style="color: #fff; font-size: 24px;"></i>
            <div class="Wrapper_Card_Title">Prestação de contas</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card"
          *ngIf="stateService.possuiPerfilCentralAtendimento() || stateService.possuiPerfilSindico() || possuiPermissaoAcesso(881)">
          <a routerLink="/pasta-dp" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-folder-open" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Documentos Fiscais</div>
          </a>
        </div>

        <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(207)" class="ListCards_Wrapper_Card">
          <a routerLink="/processos-juridicos" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-gavel" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Processos Jurídicos</div>
          </a>
        </div>

        <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(285)" class="ListCards_Wrapper_Card">
          <a routerLink="/ocorrenciaGT" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-fale-com-gestor">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
              </span>
            </div>
            <div *ngIf="possuiPerfilGestorSindico; else faleComOsindico" class="Wrapper_Card_Title">Ocorrências</div>
            <ng-template #faleComOsindico>
              <div class="Wrapper_Card_Title">Fale com o Síndico</div>
            </ng-template>
          </a>
        </div>

        <div *ngIf="this.ehContaPool && this.stateService.possuiAcessoFuncionalidadeGeral(835)"
          class="ListCards_Wrapper_Card">
          <a routerLink="/quitacao-anual" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-colorido-informe-quitacao">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Quitação Anual</div>
          </a>
        </div>

        <!-- <div *ngIf="this.stateService.condominioPodeExibirCartao() && possuiPermissaoAcessoValidacaoCentralAtendimento(833)" class="ListCards_Wrapper_Card">
          <a routerLink="/relatorio-pagamentos" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-cotas">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Relatório de pagamentos</div>
          </a>
        </div> -->

        <div
          *ngIf="this.ehGestaoTotal && (possuiPermissaoAcesso(286) ||possuiPermissaoAcesso(294)||possuiPermissaoAcesso(304))"
          class="ListCards_Wrapper_Card">
          <a routerLink="/reserva" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-reservas1">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Reservas</div>
          </a>
        </div>

        <div *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(823)" class="ListCards_Wrapper_Card">
          <a routerLink="/reserva-conv" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-reservas1">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Reservas</div>
          </a>
        </div>

        <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(299)" class="ListCards_Wrapper_Card">
          <a routerLink="/relatorio-ocorrencias" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-copy" style="color: #fff; font-size: 24px"></i>
            </div>
            <div class="Wrapper_Card_Title">Relatório de Ocorrências</div>
          </a>
        </div>

        <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(873) && ((ehCondominioPrime() && this.ehGestaoTotal) 
              || this.stateService.condominioPodeVisualizarRelatAcoes())" class="ListCards_Wrapper_Card">
          <a routerLink="/relatorios-gerenciais/acoes" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <div class="relatorios-prime-icon">
                <i class="fas fa-file-alt"></i>
              </div>
            </div>
            <div class="Wrapper_Card_Title">Relatório de Ações</div>
          </a>
        </div>
        <div *ngIf="ehCondominioPrime() && this.stateService.possuiAcessoFuncionalidadeGeral(874)"
          class="ListCards_Wrapper_Card">
          <a routerLink="/relatorios-gerenciais/financeiro" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <div class="relatorios-prime-icon">
                <i class="fas fa-file-alt"></i>
              </div>
            </div>
            <div class="Wrapper_Card_Title">Relatório Financeiro</div>
          </a>
        </div>
        <div *ngIf="ehCondominioPrime() && this.stateService.possuiAcessoFuncionalidadeGeral(875)"
          class="ListCards_Wrapper_Card">
          <a routerLink="/relatorios-gerenciais/visitas-dp" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <div class="relatorios-prime-icon">
                <i class="fas fa-file-alt"></i>
              </div>
            </div>
            <div class="Wrapper_Card_Title">Relatório Visitas DP</div>
          </a>
        </div>

        <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(303)" class="ListCards_Wrapper_Card">
          <a routerLink="/inventario" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-copy" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Relatório de Inventário</div>
          </a>
        </div>
      </div>

      <div class="ListCards_Wrapper" *ngIf="this.possuiPerfilLocatario">
        <ng-container *ngIf="(customer$ | async).comodidadesParaVoce.exibir">
          <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(828)"
            class=" ListCards_Wrapper_Card __is-highlighted">
            <a routerLink="/descontos-parceiros" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                </span>
              </div>
  
              <div class="Wrapper_Card_Title">Comodidades para você!</div>
            </a>
          </div>
        </ng-container>

        <div *ngIf="stateService.possuiAcessoFuncionalidadeGeral(853)" class="ListCards_Wrapper_Card __is-highlighted">
          <a routerLink="/minhas-solicitacoes" routerLinkActive="active">
            <div class="Wrapper_Card_Icon __is-fake">
              <i class="fas fa-tasks"></i>
            </div>

            <div class="Wrapper_Card_Title">
              Minhas solicitações
            </div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card">
          <a routerLink="/pagamentos" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-pagamento-locatario">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Pagamentos</div>
          </a>
        </div>
      </div>

      <div class="ListCards_Wrapper" *ngIf="this.possuiPerfilProprietario">
        <ng-container *ngIf="(customer$ | async).comodidadesParaVoce.exibir">
          <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(828)"
            class=" ListCards_Wrapper_Card __is-highlighted">
            <a routerLink="/descontos-parceiros" routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                </span>
              </div>
  
              <div class="Wrapper_Card_Title">Comodidades para você!</div>
            </a>
          </div>
        </ng-container>

        <div *ngIf="stateService.possuiAcessoFuncionalidadeGeral(853)" class="ListCards_Wrapper_Card __is-highlighted">
          <a routerLink="/minhas-solicitacoes" routerLinkActive="active">
            <div class="Wrapper_Card_Icon __is-fake">
              <i class="fas fa-tasks"></i>
            </div>

            <div class="Wrapper_Card_Title">
              Minhas solicitações
            </div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card">
          <a routerLink="/dados-imovel" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <div class="Item_Current_Icon">
                <span class="apsa-icon-colorido-dados-imovel">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                  <span class="path5"></span>
                </span>
              </div>
            </div>
            <div class="Wrapper_Card_Title">Dados do Imóvel</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card __is-highlighted">
          <a routerLink="/extrato/proprietario" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <div class="Item_Current_Icon">
                <span class="apsa-icon-colorido-extrato">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                  <span class="path5"></span>
                  <span class="path6"></span>
                  <span class="path7"></span>
                  <span class="path8"></span>
                  <span class="path9"></span>
                </span>
              </div>
            </div>
            <div class="Wrapper_Card_Title">Extrato</div>
          </a>
        </div>

        <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(606)" class="ListCards_Wrapper_Card">
          <a routerLink="/relacao-de-devedores" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-gavel" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Relação de Devedores</div>
          </a>
        </div>

        <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(604)" class="ListCards_Wrapper_Card">
          <a routerLink="/processos-juridicos" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-gavel" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Processos Jurídicos</div>
          </a>
        </div>
      </div>
    </div>

    <mobile-shortcuts-with-list-design *ngIf="isListDesign"></mobile-shortcuts-with-list-design>
  </ng-container>
</div>

<!--------Header Mockup------->
<div class="ListaMenuMobileComponent" *ngIf="constantsService.ehPerfilTeste()">

  <ng-container *ngLet="isListDesign$ | async as isListDesign">
    <div *ngIf="!isListDesign" class="ListCards __is-floated">
      <div class="ListCards_Wrapper">

        <div *ngIf="possuiPermissaoAcesso(219) || possuiPermissaoAcesso(220)" class="ListCards_Wrapper_Card">
          <a routerLink="/cotas" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-cotas">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
              </span>
            </div>
            <div *ngIf="this.stateService.possuiApenasPerfilCondomino(); then segundaVia else cotas"></div>
            <ng-template #segundaVia>
              <div class="Wrapper_Card_Title">2ª via de boletos</div>
            </ng-template>
            <ng-template #cotas>
              <div class="Wrapper_Card_Title">Visualizar cotas</div>
            </ng-template>
          </a>
        </div>

        <ng-container  *ngIf="(customer$ | async).comodidadesParaVoce.exibir">
          <div *ngIf="this.stateService.getCondominioLogado() && this.stateService.possuiAcessoFuncionalidadeGeral(828)"
            class=" ListCards_Wrapper_Card __is-highlighted">
            <a  routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                </span>
              </div>
  
              <div class="Wrapper_Card_Title">Comodidades para você!</div>
            </a>
          </div>
        </ng-container>

        <div *ngIf="stateService.possuiAcessoFuncionalidadeGeral(853)" class="ListCards_Wrapper_Card __is-highlighted">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon __is-fake">
              <i class="fas fa-tasks"></i>
            </div>

            <div class="Wrapper_Card_Title">
              Minhas solicitações
            </div>
          </a>
        </div>

        <!-- <div *ngIf="this.possuiPerfilSindico" class="ListCards_Wrapper_Card  ">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="far fa-building" style="color: #fff; font-size: 24px"></i>

              <div class="overlayed-icon">
                <i class="fas fa-heart"></i>
              </div>
            </div>

            <div class="Wrapper_Card_Title">Seguros obrigatórios</div>
          </a>
        </div> -->

        <!-- <div *ngIf="this.possuiPerfilSindico" class="ListCards_Wrapper_Card  ">
          <a>
            <div class="Wrapper_Card_Icon">
              <i class="far fa-building" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Aluguel<br>Compra e Venda</div>
          </a>
        </div> -->

        <!-- <div *ngIf="this.possuiPerfilSindico" class="ListCards_Wrapper_Card  ">
          <a>
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-tools" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Fornecedores</div>
          </a>
        </div> -->

        <!-- <div class=" ListCards_Wrapper_Card" *ngIf="ehGestaoTotal && possuiPermissaoAcesso(292)">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
                <i class="fas fa-box-open" style="font-size: 24px;"></i>
              </span>
            </div>

            <div class="Wrapper_Card_Title">Achados e perdidos</div>
          </a>
        </div> -->

        <div
          *ngIf="this.stateService.permitirEntregaExpressa() != 0 && possuiPermissaoAcessoValidacaoCentralAtendimento(842)"
          class="ListCards_Wrapper_Card">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-motorcycle">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
                <span class="path9"></span>
                <span class="path10"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Chame a Moto</div>
          </a>
        </div>

        <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(287)" class=" ListCards_Wrapper_Card">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-check">
                <span class="path1"></span>
                <span class="path2"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Checklist</div>
          </a>
        </div>

        <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(293)" class="ListCards_Wrapper_Card">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-colaboradores">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Colaboradores</div>
          </a>
        </div>

        <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(305)" class="ListCards_Wrapper_Card">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon CourrierIcon">
              <i class="fas fa-business-time"></i>
              <i class="fas fa-clock"></i>
            </div>

            <div class="Wrapper_Card_Title">Courrier</div>
          </a>
        </div>

        <div *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(822)" class="ListCards_Wrapper_Card">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-reservas1">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Configuração de Reservas</div>
          </a>
        </div>

        <div *ngIf="stateService.permitirEntregaExpressa() === 0 && stateService.possuiAcessoFuncionalidade(206)"
          class="ListCards_Wrapper_Card" (click)="openModalAreaRestrita()">
          <a routerLinkActive="active">
            <div class="Wrapper_Card_Icon calendarioVisitasIcon">
              <i class="far fa-calendar-check"></i>
            </div>
            <div class="Wrapper_Card_Title">Calendário de visitas</div>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(211)" class="ListCards_Wrapper_Card" (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-condominio-digital">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
                <span class="path9"></span>
                <span class="path10"></span>
                <span class="path11"></span>
                <span class="path12"></span>
                <span class="path13"></span>
                <span class="path14"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Condôminos</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card" *ngIf="possuiPermissaoAcesso(214)" (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon __not-colorful">
              <i class="fas fa-database Card_Icon_SolidAsColorfull"></i>
            </div>

            <div class="Wrapper_Card_Title">Acompanhamento Orçamentário</div>
          </a>
        </div>



        <div class="ListCards_Wrapper_Card" *ngIf="possuiPermissaoAcesso(885)" (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-copy" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Relatório de Inadimplentes</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card" *ngIf="possuiPermissaoAcesso(226)" (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-copy" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Receitas previstas</div>
          </a>
        </div>

        <div *ngIf="this.validaExibicaoContrato()" class="ListCards_Wrapper_Card">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-file-signature" style="color: #fff; font-size: 24px;"></i>
            </div>

            <div class="Wrapper_Card_Title">Contratos</div>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(205)" class="ListCards_Wrapper_Card __is-highlighted" (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-sobre-condominio-colorido">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
                <span class="path9"></span>
                <span class="path10"></span>
                <span class="path11"></span>
                <span class="path12"></span>
                <span class="path13"></span>
                <span class="path14"></span>
                <span class="path15"></span>
                <span class="path16"></span>
                <span class="path17"></span>
                <span class="path18"></span>
                <span class="path19"></span>
                <span class="path20"></span>
                <span class="path21"></span>
                <span class="path22"></span>
                <span class="path23"></span>
                <span class="path24"></span>
                <span class="path25"></span>
                <span class="path26"></span>
                <span class="path27"></span>
                <span class="path28"></span>
                <span class="path29"></span>
                <span class="path30"></span>
                <span class="path31"></span>
                <span class="path32"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Dados do Condomínio</div>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(811)" class="ListCards_Wrapper_Card" (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-enquete">
                <span class="path1"></span>
                <span class="path2"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Enquetes</div>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(306)" class="ListCards_Wrapper_Card">
          <a routerLink="/envio-documento" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-envio-documentos">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Envio de Documentos</div>
          </a>
        </div>

        <!-- <div
          *ngIf="stateService.pessoaPossuiAcessoFuncionalidade(856)"
          class="ListCards_Wrapper_Card"
        >
          <a routerLink="/processamento-de-documentos">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-envio-documentos">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
              </span>
            </div>

            <div class="Wrapper_Card_Title">
              Documentos não tratados
            </div>
          </a>
        </div> -->

        <div *ngIf="possuiPermissaoAcesso(218)" class="ListCards_Wrapper_Card __is-highlighted">
          <a routerLink="/extrato/sindico" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-extrato">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
                <span class="path9"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Extrato</div>
          </a>
        </div>

        <div *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(819)" class="ListCards_Wrapper_Card">
          <a routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-espacos-colorido">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Espaços</div>
          </a>
        </div>

        <div *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(816)" class="ListCards_Wrapper_Card" (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-fale-com-gestor"><span class="path1"></span><span
                  class="path2"></span><span class="path3"></span><span class="path4"></span><span
                  class="path5"></span><span class="path6"></span><span class="path7"></span></span>
            </div>
            <div *ngIf="possuiPerfilGestorSindico; else faleComOsindico" class="Wrapper_Card_Title">Ocorrências</div>
            <ng-template #faleComOsindico>
              <div class="Wrapper_Card_Title">Fale com o Síndico</div>
            </ng-template>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(227)" class="ListCards_Wrapper_Card">
          <a routerLink="/funcionario"  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-funcionarios"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span></span>
            </div>
            <div class="Wrapper_Card_Title">Funcionários</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card" (click)="openModalAreaRestrita()">
          <a routerLinkActive="active">
            <div class="Wrapper_Card_Icon __is-fake">
              <i class="fas fa-file-invoice-dollar"></i>
            </div>

            <div class="Wrapper_Card_Title">Lançamento de descontos e proventos</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card" *ngIf="possuiPermissaoAcesso(276)" (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-business-time" style="color: #fff; font-size: 24px;"></i>
            </div>

            <div class="Wrapper_Card_Title">Jornada de Trabalho</div>
          </a>
        </div>

        <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(295)" class="ListCards_Wrapper_Card">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-reservas1"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span></span>
            </div>
            <div class="Wrapper_Card_Title">Gerenciar Disponibilidade</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card" *ngIf="ehGestaoTotal && possuiPermissaoAcesso(291)" (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon __not-colorful">
              <i class="apsa-icon-solid-mural Card_Icon_SolidAsColorfull"></i>
            </div>
            <div class="Wrapper_Card_Title">Mural</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card" *ngIf="!ehGestaoTotal && possuiPermissaoAcesso(814)" (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon __not-colorful">
              <i class="apsa-icon-solid-mural Card_Icon_SolidAsColorfull"></i>
            </div>
            <div class="Wrapper_Card_Title">Mural</div>
          </a>
        </div>

        <div *ngIf="this.stateService.possuiPerfilFuncionario()" class="ListCards_Wrapper_Card">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-funcionarios">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Meus Dados</div>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(810)" class="ListCards_Wrapper_Card __is-highlighted" (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-malotes">
                <span class="path1"></span>
                <span class="path2"></span>
              </span>
            </div>

            <div class="Wrapper_Card_Title">Normas e Documentos</div>
          </a>
        </div>

        <div *ngIf="possuiPermissaoAcesso(283)" class="ListCards_Wrapper_Card">
          <a routerLink="/prestacao-conta-digital" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-financeiro">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
              </span>
            </div>

            <div class="Wrapper_Card_Title">Prestação de contas digital</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card __is-highlighted"
          *ngIf="possuiPermissaoAcesso(215) || possuiPermissaoAcesso(216) || possuiPermissaoAcesso(217)">
          <a routerLink="/prestacao-contas" routerLinkActive="active">
            <i class="fas fa-coins" style="color: #fff; font-size: 24px;"></i>
            <div class="Wrapper_Card_Title">Prestação de contas</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card"
          *ngIf="stateService.possuiPerfilCentralAtendimento() || stateService.possuiPerfilSindico() || possuiPermissaoAcesso(881)" 
          (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-folder-open" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Documentos Fiscais</div>
          </a>
        </div>

        <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(207)" class="ListCards_Wrapper_Card" 
        (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-gavel" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Processos Jurídicos</div>
          </a>
        </div>

        <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(285)" class="ListCards_Wrapper_Card" (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-fale-com-gestor">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
              </span>
            </div>
            <div *ngIf="possuiPerfilGestorSindico; else faleComOsindico" class="Wrapper_Card_Title">Ocorrências</div>
            <ng-template #faleComOsindico>
              <div class="Wrapper_Card_Title">Fale com o Síndico</div>
            </ng-template>
          </a>
        </div>

        <div *ngIf="this.ehContaPool && this.stateService.possuiAcessoFuncionalidadeGeral(835) "
          class="ListCards_Wrapper_Card" (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-colorido-informe-quitacao">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Quitação Anual</div>
          </a>
        </div>

        <!-- <div *ngIf="this.stateService.condominioPodeExibirCartao() && possuiPermissaoAcessoValidacaoCentralAtendimento(833)" class="ListCards_Wrapper_Card">
          <a routerLink="/relatorio-pagamentos" routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-cotas">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Relatório de pagamentos</div>
          </a>
        </div> -->

        <div
          *ngIf="this.ehGestaoTotal && (possuiPermissaoAcesso(286) ||possuiPermissaoAcesso(294)||possuiPermissaoAcesso(304))"
          class="ListCards_Wrapper_Card" (click)="openModalAreaRestrita()">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-reservas1">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Reservas</div>
          </a>
        </div>

        <div *ngIf="!this.ehGestaoTotal && possuiPermissaoAcesso(823)" class="ListCards_Wrapper_Card">
          <a  routerLinkActive="active" (click)="openModalAreaRestrita()">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-reservas1">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Reservas</div>
          </a>
        </div>

        <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(299)" class="ListCards_Wrapper_Card">
          <a  routerLinkActive="active" (click)="openModalAreaRestrita()">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-copy" style="color: #fff; font-size: 24px"></i>
            </div>
            <div class="Wrapper_Card_Title">Relatório de Ocorrências</div>
          </a>
        </div>

        <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(873) && ((ehCondominioPrime() && this.ehGestaoTotal) 
              || this.stateService.condominioPodeVisualizarRelatAcoes())" class="ListCards_Wrapper_Card">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <div class="relatorios-prime-icon">
                <i class="fas fa-file-alt"></i>
              </div>
            </div>
            <div class="Wrapper_Card_Title">Relatório de Ações</div>
          </a>
        </div>
        <div *ngIf="ehCondominioPrime() && this.stateService.possuiAcessoFuncionalidadeGeral(874)"
          class="ListCards_Wrapper_Card">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <div class="relatorios-prime-icon">
                <i class="fas fa-file-alt"></i>
              </div>
            </div>
            <div class="Wrapper_Card_Title">Relatório Financeiro</div>
          </a>
        </div>
        <div *ngIf="ehCondominioPrime() && this.stateService.possuiAcessoFuncionalidadeGeral(875)"
          class="ListCards_Wrapper_Card">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <div class="relatorios-prime-icon">
                <i class="fas fa-file-alt"></i>
              </div>
            </div>
            <div class="Wrapper_Card_Title">Relatório Visitas DP</div>
          </a>
        </div>

        <div *ngIf="this.ehGestaoTotal && possuiPermissaoAcesso(303)" class="ListCards_Wrapper_Card">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-copy" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Relatório de Inventário</div>
          </a>
        </div>
      </div>

      <div class="ListCards_Wrapper" *ngIf="this.possuiPerfilLocatario">
        <ng-container *ngIf="(customer$ | async).comodidadesParaVoce.exibir">
          <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(828)"
            class=" ListCards_Wrapper_Card __is-highlighted">
            <a  routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                </span>
              </div>
  
              <div class="Wrapper_Card_Title">Comodidades para você!</div>
            </a>
          </div>
        </ng-container>

        <div *ngIf="stateService.possuiAcessoFuncionalidadeGeral(853)" class="ListCards_Wrapper_Card __is-highlighted">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon __is-fake">
              <i class="fas fa-tasks"></i>
            </div>

            <div class="Wrapper_Card_Title">
              Minhas solicitações
            </div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card">
          <a  routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <span class="apsa-icon-colorido-pagamento-locatario">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
              </span>
            </div>
            <div class="Wrapper_Card_Title">Pagamentos</div>
          </a>
        </div>
      </div>

      <div class="ListCards_Wrapper" *ngIf="this.possuiPerfilProprietario">
        <ng-container *ngIf="(customer$ | async).comodidadesParaVoce.exibir">
          <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(828)"
            class=" ListCards_Wrapper_Card __is-highlighted">
            <a  routerLinkActive="active">
              <div class="Wrapper_Card_Icon">
                <span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                </span>
              </div>
  
              <div class="Wrapper_Card_Title">Comodidades para você!</div>
            </a>
          </div>
        </ng-container>

        <div *ngIf="stateService.possuiAcessoFuncionalidadeGeral(853)" class="ListCards_Wrapper_Card __is-highlighted">
          <a routerLinkActive="active">
            <div class="Wrapper_Card_Icon __is-fake">
              <i class="fas fa-tasks"></i>
            </div>

            <div class="Wrapper_Card_Title">
              Minhas solicitações
            </div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card">
          <a routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <div class="Item_Current_Icon">
                <span class="apsa-icon-colorido-dados-imovel">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                  <span class="path5"></span>
                </span>
              </div>
            </div>
            <div class="Wrapper_Card_Title">Dados do Imóvel</div>
          </a>
        </div>

        <div class="ListCards_Wrapper_Card __is-highlighted">
          <a routerLinkActive="active" (click)="openModalAreaRestrita()">
            <div class="Wrapper_Card_Icon">
              <div class="Item_Current_Icon">
                <span class="apsa-icon-colorido-extrato">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                  <span class="path5"></span>
                  <span class="path6"></span>
                  <span class="path7"></span>
                  <span class="path8"></span>
                  <span class="path9"></span>
                </span>
              </div>
            </div>
            <div class="Wrapper_Card_Title">Extrato</div>
          </a>
        </div>

        <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(606)" class="ListCards_Wrapper_Card">
          <a routerLinkActive="active">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-gavel" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Relação de Devedores</div>
          </a>
        </div>

        <div *ngIf="this.stateService.possuiAcessoFuncionalidadeGeral(604)" class="ListCards_Wrapper_Card">
          <a routerLinkActive="active" (click)="openModalAreaRestrita()">
            <div class="Wrapper_Card_Icon">
              <i class="fas fa-gavel" style="color: #fff; font-size: 24px"></i>
            </div>

            <div class="Wrapper_Card_Title">Processos Jurídicos</div>
          </a>
        </div>
      </div>
    </div>

    <mobile-shortcuts-with-list-design *ngIf="isListDesign"></mobile-shortcuts-with-list-design>
  </ng-container>
</div>