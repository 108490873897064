export class ImagemArquivoMaloteDTO {
    Id: string;
    Conteudo: string;
    Nome: string;
    Tamanho: number;
    Tipo: string;
    ExtensaoArquivo: string;
    src: string;
    UrlDownload: string;
    arquivoBack?:string;

    constructor(public file: string, type: string, name: string, src?: string, tamanho?: number, url?: string) {
        this.Conteudo = file;
        this.Tipo = type;
        this.Nome = name;
        this.src = src;
        this.Tamanho = tamanho;
        this.UrlDownload = url;
    }
}
