<div
  *ngIf="hasFeatures"
  class="MostUsedFeaturesComponent"
  [ngClass]="{'__is-compact': design === 'compact'}"
>
  <ds-light-collapse p-title="Mais acessados" p-is-open="true">
    <ion-slides pager [options]="slideOpts">
      <ion-slide>
        <app-quick-card
          [id]="1"
          title="Cotas"
          link="/cotas"
          icon="apsa-icon-colorido-cotas"
          [compact]="design === 'compact'"
        ></app-quick-card>
      </ion-slide>

      <ng-container *ngFor="let feature of mostUsedFeatures" >
        <ion-slide *ngIf="feature.id !== 1" >
          <app-quick-card
            [id]="feature.id"
            [title]="returnNome(feature)"
            [link]="feature.rota"
            [icon]="feature.icon"
            [compact]="design === 'compact'"
          ></app-quick-card>
        </ion-slide>
      </ng-container>
    </ion-slides>
  </ds-light-collapse>
</div>
