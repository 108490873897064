import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { BrMaskDirective, BrMaskModel } from 'br-mask';

@Component({
  selector: 'ds-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None, 
})

export class SearchInputComponent implements OnInit {
  public form: UntypedFormGroup;

  @Output() onSubmit = new EventEmitter<string>();
  @Output() onClear = new EventEmitter();
  @Input() isFiltered: boolean;
  @Input('p-placeholder') placeholder: string;
  @Input() onlyNumber:boolean = false;
  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.createForm();
  }
  
  createForm() {
    this.form = this.fb.group({
      searchTerm: ['', [Validators.required]],
    });
  }


  bindFormListener(){
    if(this.onlyNumber){
      this.form.get('searchTerm').valueChanges.subscribe(val => {
        if(val){
          //console.log('val', val)
          this.form.get('searchTerm').setValue(
            this.form.get('searchTerm').value.replace(/[^\d]+/g, '')
          )
        }
      })
    }
  }

  onKey(){
    this.form.get('searchTerm').setValue(
      this.form.get('searchTerm').value.replace(/[^\d]+/g, '')
    )

    let ehCpf = this.form.get('searchTerm').value.length <= 11;
    //console.log('ehCPF', ehCpf)
    ehCpf 
    ?this.formatarCPF()
    :this.formatarCNPJ()
  }

  formatarCPF(){
    this.form.get('searchTerm').setValue(
      this.form.get('searchTerm').value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4")
    )
  }

  formatarCNPJ(){
    //console.log('tamanho', this.form.get('searchTerm').value.length)
    if(!(this.form.get('searchTerm').value.length > 14)){
      this.form.get('searchTerm').setValue(
        this.form.get('searchTerm').value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5")
      )
    }
  }

  submit() {
    if(this.form.valid) 
      this.onSubmit.emit(this.form.get('searchTerm').value.toString().trim());
  }

  clear() {
    if(this.isFiltered) {
      this.form.get('searchTerm').setValue('');
      this.onClear.emit();
    }
  }

  selecinarMascara(value:string){
    //console.log('value', value)
    let ehCpf = value.length <= 11;
    return{
      mask: ehCpf ? '000.000.000-00' : '00.000.000/0000-00',
      len: value.length
    }
  }
}
