<div class="Modal Modal_AvaliarApp" [ngClass]="{ '__is-open': open }">
  <div class="Modal_Close" (click)="close()">
    <i class="fa fa-times"></i>
  </div>

  <div class="Modal_Header">
    <div class="Modal_Header_Title">
       Avalie o APP
    </div>
  </div>

  <div class="Modal_Content">
    <div class="Block">
      <div class="Block_Container">
        <p class="Paragraph">
          Gostaríamos de entender um pouco melhor onde você acha
          que podemos melhorar. Por favor, descreva abaixo sua experiência
          utilizando nosso aplicativo:
        </p>

        <form [formGroup]="form">
          <div class="FormGroup" [ngClass]="getFieldError('descricao')">
            <div class="FormGroup_Input">
              <textarea placeholder="" formControlName="descricao"></textarea>
            </div>

            <ng-container *ngIf="descricaoValue.errors">
              <div class="FormGroup_Erro" *ngIf="descricaoValue.errors.minlength">
                Pode nos informar mais detalhes? (Mínimo de 20 caracteres)
              </div>
      
              <div class="FormGroup_Erro" *ngIf="descricaoValue.errors.required">
                Este campo é obrigatório.
              </div>
            </ng-container>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="Modal_Footer">
    <div class="Modal_Footer_ListActions">
      <div class="Footer_ListActions_Item __is-full-width">
        <ds-button (click)="enviar()" [full]="true" [inactive]="form.invalid">
          Enviar
        </ds-button>
      </div>
    </div>
  </div>
</div>