import { ImoveisDTO } from './DTO/response/Imoveis.DTO';
import { ListaCondominiosUnidadesDTO } from './DTO/response/UnidadesCondominio.DTO';
import { PessoaFoto } from './../../modules/login/services/DTO/response/TokenResult.DTO';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { TokenDTO } from '../services/DTO/Request/Token.DTO';
import { LoginResultDTO } from '../../modules/login/services/DTO/response/LoginResult.DTO';
import { UsuarioResultDTO } from '../../modules/login/services/DTO/response/UsuarioResult.DTO';
import { LoginDTO } from 'src/app/modules/login/services/DTO/request/Login.DTO';
import { Router } from '@angular/router';
import { PessoaLogadaDTO } from './DTO/Request/PessoaLogada.DTO';
import { ResultEspacoListDTO } from 'src/app/modules/ocorrencia/services/DTO/response/ResultEspacoList.DTO';
import { EspacoDTO } from './DTO/Request/Espaco.DTO';
import { ResultTiposChecklistDTO } from 'src/app/modules/ocorrencia/services/DTO/response/ResultTiposChecklist.DTO';
import { TipoChecklistDTO } from './DTO/Request/TipoChecklist.DTO';
import { DomSanitizer } from '@angular/platform-browser';

import {
  TokenResultDTO,
  NotificacaoVersaoAPP,
} from 'src/app/modules/login/services/DTO/response/TokenResult.DTO';
import { CondominioDTO } from './DTO/response/Condominio.DTO';
import { ignoreElements } from 'rxjs/operators';
import { LightBoxesService } from '../components/lightboxes';
import { ConstantsService } from '../services/constants.service';
import { ResultCondominosListDTO } from 'src/app/modules/condomino/services/DTO/response/ResultCondominosList.DTO';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Browser } from '@capacitor/browser';
import { AccessibilityLevelSwitcher } from './accessibility.service';
import SimpleCrypto from "simple-crypto-js"
import { BehaviorSubject, Subject } from 'rxjs';
import { IImovelLogado } from './DTO/response/IImovelLogado';
import PessoaDoitt from 'src/app/modules/cash-back/models/pessoaDoitt.model';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private urlApiHost = environment;
  private methods = environment;
  private acessoExpirado: boolean = false;
  private hostURL: string;
  private triggerExibirTutorial = new Subject<void>();
  private envioPag = new BehaviorSubject(false)

  constructor(
    private platform: Platform,
    private http: HttpClient,
    private router: Router,
    private lightBoxesService: LightBoxesService,
    private constant: ConstantsService,
    private statusBar: StatusBar,
    public pxSwitcher: AccessibilityLevelSwitcher,
    private sanitizer: DomSanitizer
  ) {
    //console.info("Passou state service")
  }

  getVeioMenuLateral() {
    return this.envioPag.asObservable()
  }
  setVeioMenuLateral(v: boolean) {
    this.envioPag.next(v)
  }

  public obterNomeCondominio(): string {
    var condominio = this.getCondominioLogado();
    if (condominio && condominio.nome) {
      return condominio.nome.toUpperCase();
    }

    return null;
  }

  public obterNomeCondominioImovel(): string {
    var condominio = this.getCondominioLogado();
    if (condominio && condominio.nome) {
      return condominio.nome.toUpperCase();
    }

    var imovelLogado = this.getImovelLogado();
    if (imovelLogado && imovelLogado.endereco)
      return imovelLogado.endereco.toUpperCase();
    else if (imovelLogado && imovelLogado.enderecoimovel)
      return imovelLogado.enderecoimovel.toUpperCase();

    return null;
  }

  public obterNomePerfil(): string {
    if (this.possuiPerfilGestor()) return 'Gestor do condomínio';
    if (this.possuiPerfilEquipe()) return 'Equipe do condomínio';
    if (this.possuiPerfilMoradorGT()) return 'Morador do condomínio';
    if (this.possuiPerfilCondomino()) return 'Condômino do condomínio';
    if (this.possuiPerfilGerencial()) return 'Perfil Gerencial';
    if (this.possuiPerfilLocatario()) return 'Locatário do imóvel';
    if (this.possuiPerfilProprietario()) return 'Proprietário do imóvel';

    return null;
  }

  public buscarUnidadesCondomino() {
    var pessoaLogada = this.getPessoaLogada();
    const header = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: this.getTokenApiCondominioDigital(),
    });

    var parametros = new HttpParams()
      .set('contaCondominio', this.getCondominioLogado().Conta)
      .set('contaEmpresa', this.getCondominioLogado().contaEmpresa)
      .set('cpfCnpj', pessoaLogada ? pessoaLogada.CpfCnpj : null)
      .set('paginaAtual', '1')
      .set('tamanhoPagina', '20');

    return this.http.get<ResultCondominosListDTO>(
      `${
        this.urlApiHost.apiCondominioDigital.host +
        this.methods.apiCondominioDigital.endpoints.methods.condomino
          .listarPorCondominio
      }`,
      { params: parametros, headers: header }
    );
  }

  public condominioPodeExibirCartao(): boolean {
    if (sessionStorage.getItem('condominioLogado')) {
      const obj = JSON.parse(sessionStorage.getItem('condominioLogado'));
      return obj.exibeCartao == true;
    }
  }

  public condominioPodeVisualizarRelatAcoes(): boolean {
    //referente ao chamado #3672
    if (sessionStorage.getItem('condominioLogado')) {
      const obj = JSON.parse(sessionStorage.getItem('condominioLogado'));
      if(!obj.optins) return false; // campo nulo
      return obj.optins.podeVisualizarRelatAcoes;
    }
  }

  public getCondominioLogado(): CondominioDTO {
    if (sessionStorage.getItem('condominioLogado')) {
      const obj = JSON.parse(sessionStorage.getItem('condominioLogado'));
      if (obj == 'undefined' || obj == undefined) {
        sessionStorage.clear();
        // console.log("VEIO LOGIN EXPIRADO 5")
        this.router.navigate(['/'], {
          queryParams: {
            showMsg: true,
            title: 'Acesso expirado',
            mensagem: 'Faça o login novamente',
          },
        });
        return;
      }
      const condominio = new CondominioDTO(
        obj.nome,
        obj.agencia,
        obj.conta,
        obj.contaEmpresa,
        obj.cnpj,
        obj.prime,
        obj.ehGestaoTotal,
        obj.ehContaPool,
        obj.exibeCartao,
        obj.perfis,
        obj.funcionalidades,
        obj.carteira,
        obj.uf,
        obj.optins
      );

      return condominio;
    }
  }

  public setExibicaoTutorial(valor) {
    sessionStorage.setItem('exibeTutorial', valor);
  }

  public exibeOpcaoMenuAtalhoHeader(): boolean{
    return this.constant.condominioImovelAtivo && !this.possuiPerfilVisaoAdm();
  }

  public exibeOpcaoMenuAtalhoFooter(): boolean{
    return this.constant.condominioImovelAtivo && !this.possuiPerfilVisaoAdm() && this.router.url !== '/home'
  }

  public exibeOpcaoMenuAtalhoJornadasFooter(): boolean{
    return this.constant.condominioImovelAtivo
      && !this.possuiPerfilVisaoAdm()
      && this.router.url !== '/home'
      && this.deveExibirATelaDeJornadasDeAtalho();
  }

  public deveExibirATelaDeJornadasDeAtalho() {
    return this.possuiAcessoFuncionalidadeGeral(863)
      || this.possuiAcessoFuncionalidade(887)
      || this.possuiAcessoFuncionalidade(283)
      || (this.possuiAcessoFuncionalidade(219) && this.possuiAcessoFuncionalidade(220));
  }

  public getExibicaoTutorial() {
    let jsonString = sessionStorage.getItem('exibeTutorial');
    if (jsonString == null || jsonString == undefined) return false;
    return JSON.parse(jsonString);
  }

  public getImovelLogado() {
    var jsonString = sessionStorage.getItem('imovelLogado');
    if (jsonString == null || jsonString == undefined) return null;
    return JSON.parse(jsonString);
  }

  public getListaCondominiosImoveis() {
    var jsonString = sessionStorage.getItem('listaCondominiosImoveis');
    if (jsonString == null || jsonString == undefined) return null;
    return JSON.parse(jsonString);
  }

  public getUserCondominiums(): CondominioDTO[] {
    var jsonString = sessionStorage.getItem('listaCondominiosImoveis');
    if (jsonString == null || jsonString == undefined) return null;
    const condominios = JSON.parse(jsonString);

    return condominios.condominios?.map((c: any) => c.condominio);
  }

  public getUserCondominiumsWithFeature(condominuimFeatureID: number): CondominioDTO[] {
    const busca = JSON.parse(sessionStorage.getItem('listaCondominiosImoveis'));

    if(busca === null)
      return [];

    busca.condominios.forEach((element) => {
      let funcionalidadesIds = [];

      element.funcionalidades.forEach((item) => {
        funcionalidadesIds.push(item.idFuncionalidade);
      });

      element.funcionalidades = funcionalidadesIds;
    });

    const condominums = busca.condominios.filter(cond => {
      return cond.funcionalidades.includes(condominuimFeatureID);
    });

    return condominums.map((c: any) => c.condominio);
  }

  public temListaCondominiosImoveis() {
    return JSON.parse(sessionStorage.getItem('listaCondominiosImoveis')) !== null;
  }

  public visaoCentralAtendimentoPessoa(): boolean {
    var jsonString = sessionStorage.getItem('pessoaLogadaCA');
    if (jsonString == null || jsonString == undefined) return false;
    return true;
  }

  public visaoCentralAtendimento(): boolean {
    var jsonString = sessionStorage.getItem('visaoCentralAtendimento');
    if (jsonString == null || jsonString == undefined) return null;
    return jsonString === 'true' ? true : false;
  }

  public getListaCondominios(): Array<CondominioDTO> {
    if (sessionStorage.getItem('listaCondominiosUsuario')) {
      const obj = JSON.parse(sessionStorage.getItem('listaCondominiosUsuario'));
      if (obj == 'undefined' || obj == undefined) {
        sessionStorage.clear();
        // console.log("VEIO LOGIN EXPIRADO 6")
        this.router.navigate(['/'], {
          queryParams: {
            showMsg: true,
            title: 'Acesso expirado',
            mensagem: 'Faça o login novamente',
          },
        });
        return;
      }

      var listaCondominios = new Array<CondominioDTO>();
      obj.forEach((item) => {
        var cond = item.condominio;
        listaCondominios.push(cond);
      });
      return listaCondominios;
    }
  }

  getPessoaLogada(considerarCentralAtendimento: boolean = true) {
    if (this.acessoExpirado) return null;

    if (
      considerarCentralAtendimento &&
      sessionStorage.getItem('pessoaLogadaCA')
    ) {
      var obj = JSON.parse(sessionStorage.getItem('pessoaLogadaCA'));
    } else {
      var obj = JSON.parse(sessionStorage.getItem('pessoaLogada'));
    }

    if (obj == 'undefined' || obj == undefined) {
      sessionStorage.clear();
      this.acessoExpirado = true;
      this.router.navigate(['/login'], {
        queryParams: {
          showMsg: true,
          title: 'Acesso expirado',
          mensagem: 'Faça o login novamente',
        },
      });
      this.acessoExpirado = false;
      
      return;
    }
    const pessoa = new PessoaLogadaDTO();
    pessoa.Id = obj.id;
    pessoa.IdAcesso = obj.idAcesso;
    pessoa.DataUltimoAcesso = obj.dataUltimoAcesso;
    pessoa.EmailConfirmado = obj.emailConfirmado;
    pessoa.Login = obj.Login;
    pessoa.Nome = obj.nome;
    pessoa.CpfCnpj = obj.cpfCnpj;
    pessoa.Email = obj.Email;
    pessoa.AcessoGerencial = obj.acessoGerencial;
    pessoa.CentralAtendimento = obj.centralAtendimento;
    pessoa.perfisColaborador = obj.perfisColaborador;
    pessoa.funcionalidadesColaborador = obj.funcionalidadesColaborador;
    pessoa.perfis = obj.perfis
    return pessoa;
  }

  public getTokenApiGT(): string {
    return sessionStorage.getItem('tokenApiGT');
  }

  public getTokenApiCondominioDigital(): string {
    return sessionStorage.getItem('tokenApiCondominioDigital');
  }

  public getTokenApiServicos(): string {
    return sessionStorage.getItem('tokenApiServicos');
  }

  public getTokenApiMaloteVirtual(): string {
    return sessionStorage.getItem('tokenApiMaloteVirtual');
  }

  public hasCondominioSelecionado() {
    var condominio = this.getCondominioLogado();
    return !(condominio === undefined || condominio == null);
  }

  public isCondominioGT(): boolean {
    return sessionStorage.getItem('ehGestaoTotal') == 'true';
  }

  public permitirEntregaExpressa(): number {
    return sessionStorage.getItem('allowEntrExpr')
      ? parseInt(sessionStorage.getItem('allowEntrExpr'))
      : 0;
  }

  public validacaoGT(rotaCorreta: string, validador: string) {
    if (sessionStorage.getItem('ehGestaoTotal') == validador) {
      this.router.navigate([rotaCorreta]);
    } else {
      return;
    }
  }

  public createTokenApiGT() {
    const data = new HttpParams()
      .set('grant_type', 'password')
      .set('username', 'areaexclusiva@apsa')
      .set('password', '@re@exclus!v@');

    return this.http
      .post<TokenDTO>(
        `${this.urlApiHost.apiGT.host + this.methods.apiGT.endpoints.token}`,
        data
      )
      .subscribe(
        (item) => {
          // console.log("token return", item);

          sessionStorage.setItem(
            'tokenApiGT',
            item.token_type.charAt(0).toUpperCase() +
              item.token_type.slice(1) +
              ' ' +
              item.access_token
          );
          return item;
        },
        (error) => {
          // console.log("token return err", error);
        }
      );
  }

  public createTokenApiServicos(obj: LoginDTO) {
    sessionStorage.clear();
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }),
    };

    const data = new HttpParams()
      .set('UsuarioWebservice', obj.usuarioAPI)
      .set('SenhaWebService', obj.senhaAPI)
      .set('Usuario', obj.loginUsuario)
      .set('Senha', obj.senhaUsuario);

    return this.http.post<LoginResultDTO>(
      `${
        this.urlApiHost.apiServicos.host +
        this.methods.apiServicos.endpoints.token
      }`,
      data,
      options
    );
  }

  public createTokenApiCondominioDigital(
    login: string,
    senha: string,
    idOrigemSite?: number,
    limpa?: boolean
  ) {
    // método de login
    if (!limpa) sessionStorage.clear();
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }),
    };

    let data = new HttpParams().set('login', login).set('senha', senha);

    if(idOrigemSite) data = data.append('idOrigemSite', idOrigemSite)

    return this.http.post<TokenResultDTO>(
      `${
        this.urlApiHost.apiCondominioDigital.host +
        this.methods.apiCondominioDigital.endpoints.methods.login.realizarLogin
      }`,
      data,
      options
    );
  }

  public redefinirSenha(
    login: string,
    senhaAnterior: string,
    senhaAtual: string,
    idOrigemSite?: number
  ) {
    // método de login
    sessionStorage.clear();
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }),
    };

    let data = new HttpParams()
      .set('login', login)
      .set('senhaAnterior', senhaAnterior)
      .set('senhAtual', senhaAtual);

    if(idOrigemSite) data = data.append('idOrigemSite', idOrigemSite)
    
    return this.http.put<any>(
      `${
        this.urlApiHost.apiCondominioDigital.host +
        this.methods.apiCondominioDigital.endpoints.methods.login.redefinirSenha
      }`,
      data,
      options
    );
  }

  public createTokenApiMaloteVirtual() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }),
    };
    const data = new HttpParams()
      .set('grant_type', 'password')
      .set('username', 'areaexclusiva@apsa')
      .set('password', '873892d59100506ea6f61b17f78e94a0');

    return this.http.post<TokenDTO>(
      `${
        this.urlApiHost.apiMaloteVirtual.host +
        this.methods.apiMaloteVirtual.endpoints.token
      }`,
      data,
      options
    );
  }

  public getDadosUsuarios() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        Authorization: sessionStorage.getItem('tokenApiServicos'),
      }),
    };
    const data = new HttpParams().set(
      'TokenAutorizacao',
      sessionStorage.getItem('tokenApiServicos')
    );

    return this.http.post<UsuarioResultDTO>(
      `${
        this.urlApiHost.apiServicos.host +
        this.methods.apiServicos.endpoints.dadosUsuario
      }`,
      data,
      options
    );
  }

  // public buscarCondominiosUsuario() {
  //   let options = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  //       'Authorization': this.getTokenApiServicos()
  //     })
  //   };
  //   var data = new HttpParams().set('TokenAutorizacao', this.getTokenApiServicos());
  //   return this.http.post<ListCondominiosResult[]>(`${this.urlApiHost.apiServicos.host + this.methods.apiServicos.endpoints.condominios.listarCondominios}`, data, options);
  // }

  // logarCondominio(item: CondominioDTO) {
  //   this.createTokenApiGT().add(() => {
  //     if (item !== undefined) {
  //       const obj = {
  //         carteira: item.carteira,
  //         agencia: item.agencia,
  //         conta: item.conta,
  //         contaEmpresa: item.contaEmpresa,
  //         nome: item.nome,
  //         cnpj: item.cnpj,
  //         prime: item.prime,
  //         ehGestaoTotal: item.ehGestaoTotal,
  //         ehContaPool: item.ehContaPool,
  //         exibeCartao: item.exibeCartao,
  //       };
  //       sessionStorage.setItem('condominioLogado', JSON.stringify(obj));
  //       this.router.navigate(['/menu']);
  //     } else {
  //       // TODO - colocar mensagem de ERRO
  //     }
  //   });
  // }

  ocultarFooterHeaderForModal() {
    var container = document.querySelectorAll('.Container');
    window.setTimeout(function () {
      for (var i = 0; i < container.length; i++) {
        container[i].classList.add('__has-modal');
      }
    }, 290);
  }

  exibirFooterHeaderForModal() {
    var container = document.querySelectorAll('.Container');
    for (var i = 0; i < container.length; i++) {
      container[i].classList.remove('__has-modal');
    }
  }

  closeAllComponentsHeader(input) {
    if (input != '.Header_Atalho_Perfil')
      this.closeComponent('.Header_Atalho_Perfil');

    if (input != '.Header_Atalho_Acessibilidade')
      this.closeComponent('.Header_Atalho_Acessibilidade');

    if (input != '.Header_Atalho_Duvidas')
      this.closeComponent('.Header_Atalho_Duvidas');

    if (input != '.Header_Atalho_CentralAtendimento')
      this.closeComponent('.Header_Atalho_CentralAtendimento');

    if (input != '.Header_Atalho_Notificacoes')
      this.closeComponent('.Header_Atalho_Notificacoes');

    if (input != '.Header_Atalho_Seguros')
      this.closeComponent('.Header_Atalho_Seguros');

    if (input != '.Header_Shortcut') this.closeComponent('.Header_Shortcut');
  }

  openCloseComponent(input) {
    this.closeAllComponentsHeader(input);
    var container = document.querySelectorAll(input);

    for (var i = 0; i < container.length; i++) {
      if (container[i].classList.contains('__is-open')) {
        container[i].classList.remove('__is-open');
      } else if (!container[i].classList.contains('__is-open')) {
        container[i].classList.add('__is-open');
      }
    }
  }

  closeComponent(input) {
    var container = document.querySelectorAll(input);
    for (var i = 0; i < container.length; i++) {
      container[i].classList.remove('__is-open');
    }
  }

  openCloseMenuMobile() {
    this.constant.statusMenu = !this.constant.statusMenu;
    // var container = document.querySelectorAll('.Sidebar');
    // for (var i = 0; i < container.length; i++) {
    //   if (container[i].classList.contains('__is-openMobile')) {
    //     container[i].classList.remove('__is-openMobile')
    //     container[i].classList.add('__is-close')
    //     this.constant.statusMenu = true;
    //   }
    //   else if (!container[i].classList.contains('__is-openMobile')) {
    //     container[i].classList.remove('__is-close')
    //     container[i].classList.add('__is-openMobile')
    //     this.constant.statusMenu = false;
    //   }
    // }
  }

  exibirInfoEmailNaoConfirmado(): boolean {
    return (
      !this.visaoCentralAtendimentoPessoa() &&
      this.getPessoaLogada() &&
      !this.getPessoaLogada().EmailConfirmado
    );
  }

  cadastraFuncionalidade(idFuncionalidade: number) {
    if (!sessionStorage.getItem('idUltimaFuncionalidade')) {
      return true;
    }

    var ultimaFunc = parseInt(sessionStorage.getItem('idUltimaFuncionalidade'));
    if (idFuncionalidade != ultimaFunc) return true;
    else return false;
  }

  // métodos compartilhados das funcionalidades/módulos
  getNotificacaoVersaoApp() {
    var obj = JSON.parse(sessionStorage.getItem('notificacaoVersaoApp'));

    if (obj == 'undefined' || obj == undefined) return null;

    if (obj.exibirAlerta === false) return null;

    const retorno = new NotificacaoVersaoAPP();
    retorno.numeroVersao = obj.numeroVersao;
    retorno.mensagem = obj.mensagem;
    retorno.exibirAlerta = obj.exibirAlerta;
    retorno.rota = obj.rota;
    return retorno;
  }

  possuiNovaVersao() {
    let versãoApp = this.constant.appVersion;
    let notificacao = this.getNotificacaoVersaoApp();
    let ultimanOtificacao = localStorage.getItem('ultimaNotificacaoVersaoApp');

    if (!notificacao) return false;

    if (ultimanOtificacao && ultimanOtificacao === notificacao.numeroVersao)
      return false;

    if (versãoApp === notificacao.numeroVersao) return true;
    else return false;
  }

  exibeOptinsAdesaoBoletoDigital(veioLayout?:boolean) {
    let obj = JSON.parse(sessionStorage.getItem('optins'));
    let propAtualizada = JSON.parse(sessionStorage.getItem('exibirModalAdesaoBoletoDigital'));
    
    // if (veioLayout) return veioLayout;
    if (obj == 'undefined' || obj == undefined) return null;
    if (obj.exibirModalAdesaoBoletoDigital === false) return null;
    
    return obj.exibirModalAdesaoBoletoDigital;
  }

  cadastroAcessoPagina(idFuncionalidade: number, idUnidade?: string): void {
    if (this.cadastraFuncionalidade(idFuncionalidade)) {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: this.getTokenApiCondominioDigital(),
        }),
      };

      const condominio = this.getCondominioLogado();
      const pessoa = this.getPessoaLogada();

      let obj = {
        idAcesso: pessoa.IdAcesso,
        idFuncionalidade: idFuncionalidade.toString(),
        idAmbiente: '',
        contaEmpresa:
          condominio && condominio.contaEmpresa
            ? condominio.contaEmpresa
            : null,
        contaCondominio:
          condominio && condominio.conta ? condominio.conta : null,
        idUnidade: idUnidade ? idUnidade : null,
      };

      this.http
        .post<any>(
          `${
            this.urlApiHost.apiCondominioDigital.host +
            this.methods.apiCondominioDigital.endpoints.methods.login
              .cadastraAcessoPagina
          }`,
          obj,
          options
        )
        .subscribe(
          (item) => {
            sessionStorage.setItem(
              'idUltimaFuncionalidade',
              idFuncionalidade.toString()
            );
            //return true;
          },
          (err) => {
            //return false;
          }
        );
      // alert(condominio.conta)
    }
    //return true;
  }

    

  buscarEspacoPorQRCode(id: string) {
    const condLogado = this.getCondominioLogado();
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.getTokenApiGT(),
    });
    return this.http.get<ResultEspacoListDTO>(
      `${
        this.urlApiHost.apiGT.host +
        this.methods.apiGT.endpoints.checklistreports.get.buscarEspacoPorQRCode
      }/${id}?agency=${condLogado.Agencia}&account=${condLogado.ContaEmpresa}`,
      { headers: header }
    );
  }

  buscarEspacoPorQRCodeConv(id: string) {
    const condominio = this.getCondominioLogado();
    const parametros = new HttpParams()
      .set('contaEmpresa', condominio.contaEmpresa)
      .set('qr', id);
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.getTokenApiCondominioDigital(),
    });

    return this.http.get<ResultEspacoListDTO>(
      `${
        this.urlApiHost.apiCondominioDigital.host +
        this.methods.apiCondominioDigital.endpoints.methods.cadastroDeEspacos
          .getEspacoPorQR
      }`,
      { headers: header, params: parametros }
    );
  }

  obterStatusOcorrencia(tipo) {
    if ((tipo = '1')) {
      return 'Aberta';
    } else if ((tipo = '2')) {
      return 'Em andamento';
    } else if ((tipo = '3')) {
      return 'Concluída';
    } else if ((tipo = '4')) {
      return 'Não aprovada';
    }
  }

  async listarEspacosCondominio() {
    if (sessionStorage.getItem('listaEspacosCondominio')) {
      const obj = JSON.parse(sessionStorage.getItem('listaEspacosCondominio'));
      var listaEspacos: EspacoDTO[] = [];
      obj.forEach((item) => {
        listaEspacos.push(
          new EspacoDTO(
            item.Uid,
            item.Name,
            item.Complement,
            item.Description,
            item.GuestListRequired,
            item.Images,
            item.Reserveable,
            item.totalImages
          )
        );
      });
      return listaEspacos;
    } else {
      const condominio = this.getCondominioLogado();
      const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.getTokenApiGT(),
      });
      return this.http
        .get<{ Data: EspacoDTO[] }>(
          `${
            this.urlApiHost.apiGT.host +
            this.methods.apiGT.endpoints.checklistreports.get
              .buscarEspacosCondominio
          }`
            .replace('{0}', condominio.Agencia)
            .replace('{1}', condominio.ContaEmpresa),
          { headers: header }
        )
        .toPromise()
        .then((item) => {
          if (item && item.Data) {
            sessionStorage.setItem(
              'listaEspacosCondominio',
              JSON.stringify(item.Data)
            ); // salvo a lista na sessão
            return item.Data;
          }
        });
    }
  }

  async listarTiposChecklist() {
    if (sessionStorage.getItem('listaTiposChecklist')) {
      const obj = JSON.parse(sessionStorage.getItem('listaTiposChecklist'));
      var lista: TipoChecklistDTO[] = [];
      obj.forEach((item) => {
        lista.push(new TipoChecklistDTO(item.Uid, item.Name));
      });
      return lista;
    } else {
      const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.getTokenApiGT(),
      });
      return this.http
        .get<ResultTiposChecklistDTO>(
          `${
            this.urlApiHost.apiGT.host +
            this.methods.apiGT.endpoints.checklistreports.get
              .listarTiposChecklist
          }`,
          { headers: header }
        )
        .toPromise()
        .then((item) => {
          if (item && item.Data) {
            sessionStorage.setItem(
              'listaTiposChecklist',
              JSON.stringify(item.Data)
            ); // salvo a lista na sessão
            return item.Data;
          }
        });
    }
  }

  obterPerfisEmTodosOsCondominiosEImoveis(): {idPerfil: number, nomePerfil: string}[] {
    const listaCondominiosImoveis = sessionStorage.getItem('listaCondominiosImoveis');

    if(listaCondominiosImoveis === null || listaCondominiosImoveis === 'null')
      return [];

    const { condominios } = JSON.parse(listaCondominiosImoveis);
    const { imoveisLocatario } = JSON.parse(listaCondominiosImoveis);
    const { imoveisProprietario } = JSON.parse(listaCondominiosImoveis);
    
    let todosPerfis: {idPerfil: number, nomePerfil: string}[] = [];

    if(condominios && condominios instanceof Array) {
      condominios.forEach((condominio: any) => {
        if(condominio && condominio.perfis && condominio.perfis.length > 0) {
          todosPerfis = todosPerfis.concat(condominio.perfis);
        }
      });
    }

    if(imoveisLocatario && imoveisLocatario instanceof Array) {
      imoveisLocatario.forEach((imovel: any) => {
        if(imovel && imovel.perfis && imovel.perfis.length > 0) {
          todosPerfis = todosPerfis.concat(imovel.perfis);
        }
      });
    }

    if(imoveisProprietario && imoveisProprietario instanceof Array) {
      imoveisProprietario.forEach((imovel: any) => {
        if(imovel && imovel.perfis && imovel.perfis.length > 0) {
          todosPerfis = todosPerfis.concat(imovel.perfis);
        }
      });
    }

    return todosPerfis;
  }

  // validação por perfis
  possuiAcessoCentralAtendimento() {
    var pessoa = this.getPessoaLogada();
    if (!pessoa) {
      return false;
    }

    return pessoa.CentralAtendimento;
  }

  possuiPerfilPrestadorServico() : boolean {
    var busca = JSON.parse(sessionStorage.getItem('listaCondominiosImoveis'));
    if(busca) return busca.possuiPerfilPrestadorServico === true;
    else return false;
  }

  possuiPerfilProcuradorParaEmissaoBoleto() : boolean {
    var busca = JSON.parse(sessionStorage.getItem('listaCondominiosImoveis'));
    if(busca) return busca.possuiPerfilProcurador === true;
    else return false;
  } 

  possuiAcessoCadastroEnvioDocumentos(funcionalidadeId) {
    let pessoa = this.getPessoaLogada();
    let condominio = this.getCondominioLogado();
    
    if (pessoa.CentralAtendimento) {
      return pessoa.funcionalidadesColaborador.some(
        (funcionalidade) =>
          funcionalidade.idFuncionalidade === funcionalidadeId
      );
    } else {
      return condominio.funcionalidadesIds.includes(funcionalidadeId)
    }
  }

  algumCondominioPossuiAcessoCadastroEnvioDocumentos(idFuncionalidade) {
    let condominios = JSON.parse(sessionStorage.getItem('listaCondominiosImoveis')).condominios
    let idFuncionalidades = condominios
      .map(c => c.funcionalidades)
      .flat()
      .map(func => func.idFuncionalidade)
    
    return idFuncionalidades.includes(idFuncionalidade)
  }

  possuiAcessoFuncionalidade(funcionalidadeId: number) {
    var pessoa = this.getPessoaLogada();
    if (!pessoa) {
      return false;
    } 
    else if (pessoa && pessoa.AcessoGerencial && !this.visaoCentralAtendimentoPessoa()) {
      return true;
    } 
    else {
      var condominio = this.getCondominioLogado();
      if (condominio && condominio.funcionalidadesIds) {
        return condominio.funcionalidadesIds.includes(funcionalidadeId);
      }
    }
  }

  pessoaPossuiAcessoFuncionalidade(numeroDaFuncionalidade: number): boolean {
    const usuarioLogado = this.getPessoaLogada();

    if (!usuarioLogado) return false;

    const funcionalidades = usuarioLogado.funcionalidadesColaborador || [];

    return funcionalidades.some(
      (funcionalidade) =>
        funcionalidade.idFuncionalidade === numeroDaFuncionalidade
    );
  }

  possuiAcessoFuncionalidadeGeral(funcionalidadeId: number) {
    // else if (pessoa && pessoa.AcessoGerencial) { return true; }
    var pessoa = this.getPessoaLogada();
    if (!pessoa) {
      return false;
    } else {
      var funcionalidadesIds: number[] = [];

      var busca = JSON.parse(sessionStorage.getItem('listaCondominiosImoveis'));
      if (busca) {
        if (busca.condominios) {
          busca.condominios.forEach((element) => {
            element.funcionalidades.forEach((item) => {
              funcionalidadesIds.push(item.idFuncionalidade);
            });
          });
        }

        if (busca.imoveisLocatario) {
          busca.imoveisLocatario.forEach((element) => {
            element.funcionalidades.forEach((item) => {
              funcionalidadesIds.push(item.idFuncionalidade);
            });
          });
        }

        if (busca.imoveisProprietario) {
          busca.imoveisProprietario.forEach((element) => {
            element.funcionalidades.forEach((item) => {
              funcionalidadesIds.push(item.idFuncionalidade);
            });
          });
        }
      }

      if (pessoa.funcionalidadesColaborador) {
        pessoa.funcionalidadesColaborador.forEach((item) => {
          funcionalidadesIds.push(item.idFuncionalidade);
        });
      }

      if (funcionalidadesIds.includes(funcionalidadeId)) return true;
      else return false;
    }
  }

  possuiPerfilGerencial(): boolean {
    var pessoa = this.getPessoaLogada();
    if (!pessoa) {
      return false;
    }
    return pessoa.AcessoGerencial;
  }

  possuiPerfilCondomino(): boolean {
    var condominio = this.getCondominioLogado();
    if (condominio && condominio.perfisIds) {
      return condominio.perfisIds.includes(5);
    }
  }

  possuiApenasPerfilMoradorGT(): boolean {
    var condominio = this.getCondominioLogado();
    if (
      condominio &&
      this.possuiPerfilMoradorGT() && 
      !this.possuiPerfilGestor() && 
      !this.possuiPerfilEquipe()
    ) {
      return true;
    }
  }

  possuiApenasPerfilCondomino(): boolean {
    var condominio = this.getCondominioLogado();
    if (
      condominio &&
      condominio.perfisIds.length == 1 &&
      this.possuiPerfilCondomino()
    ) {
      return true;
    }
  }

  possuiPerfilProcurador(): boolean {
    var condominio = this.getCondominioLogado();
    if (condominio && condominio.perfisIds) {
      return condominio.perfisIds.includes(9);
    }
  }

  possuiPerfilEquipe(): boolean {
    var condominio = this.getCondominioLogado();
    if (condominio && condominio.perfisIds) {
      return condominio.perfisIds.includes(17);
    }
  }

  possuiPerfilFuncionario(): boolean {
    var condominio = this.getCondominioLogado();
    if (condominio && condominio.perfisIds) {
      return (
        condominio.perfisIds.length === 1 && condominio.perfisIds.includes(4)
      );
    }
  }

  possuiPerfilCentralAtendimento(): boolean {
    let pessoa = this.getPessoaLogada();    
    return pessoa.CentralAtendimento;
  }

  possuiPerfilGestor(): boolean {
    var condominio = this.getCondominioLogado();
    if (condominio && condominio.perfisIds) {
      return condominio.perfisIds.includes(16);
    }
  }

  possuiPerfilVisaoAdm(): boolean {
    return (
      this.possuiPerfilColaboradorEntregaExpressa() ||
      this.possuiPerfilColaboradorFinanceiroPagamento()
    );
  }

  possuiPerfilColaboradorEntregaExpressa(): boolean {
    var pessoa = this.getPessoaLogada();
    if (pessoa && pessoa.perfisColaborador) {
      return pessoa.perfisColaborador.includes(88);
    }
    return false;
  }

  possuiPerfilColaboradorFinanceiroPagamento(): boolean {
    var pessoa = this.getPessoaLogada();
    if (pessoa && pessoa.perfisColaborador) {
      return pessoa.perfisColaborador.includes(89);
    }
    return false;
  }

  possuiPerfilSindico(): boolean {
    var condominio = this.getCondominioLogado();
    if (condominio && condominio.perfisIds) {
      return condominio.perfisIds.includes(3) || condominio.perfisIds.includes(75);
    }
  }

  possuiPerfilSubSindico(): boolean {
    var condominio = this.getCondominioLogado();
    if (condominio && condominio.perfisIds) {
      return condominio.perfisIds.includes(6);
    }
  }

  possuiPerfilConselheiro(): boolean {
    var condominio = this.getCondominioLogado();
    if (condominio && condominio.perfisIds) {
      return condominio.perfisIds.includes(7);
    }
  }

  possuiPerfilLocatario() {
    const imovelLogado = this.getImovelLogado();
    if (!imovelLogado) return false;

    return imovelLogado.tipoPerfil === 3;
  }

  public tipoPerfilLocatario(): boolean {
    let jsonString = JSON.parse(sessionStorage.getItem('listaCondominiosImoveis'));
    if(jsonString.imoveisLocatario !== null)
    for(let item of jsonString.imoveisLocatario)
      return item.imovel.tipoPerfil == 3
  }

  public getCpfCnpjLocatario(): string {
    let jsonString = JSON.parse(sessionStorage.getItem('listaCondominiosImoveis'));
    if(jsonString.imoveisLocatario !== null)
    for(let item of jsonString.imoveisLocatario)
      return item.imovel.cpfCnpjLocatario
  }

  public getPerfisUnidades(): boolean {
    let jsonString = JSON.parse(sessionStorage.getItem('listaCondominiosImoveis'));
    if(jsonString.condominios !== null) {
      for(let item of jsonString.condominios) {
        for(let i of item.perfis) 
          if(i.idPerfil === 5 || i.idPerfil === 9) return true
      }
    }
  }

  // retorna perfil síndico antes de logar no condomínio
  public possuiPerfilSindico_(): boolean {
    let isSindico: boolean = false;
    let jsonString = JSON.parse(sessionStorage.getItem('listaCondominiosImoveis'));
    if(jsonString && jsonString.condominios) {
      jsonString.condominios.map(c => c.perfis.filter(p => {
        if(p.idPerfil === 3) 
          isSindico = true;
      }))
    } else {
      isSindico = this.possuiPerfilSindico();
    }
    return isSindico;
  }

  possuiPerfilProprietarioEmAlgumImovel(): boolean {
    return this.possuiAlgumImovelComPerfil(2);
  }

  possuiPerfilProprietario() {
    const imovelLogado = this.getImovelLogado();
    if (!imovelLogado) return false;
    // console.log("YYYEE", imovelLogado)
    return imovelLogado.tipoPerfil === 2;
  }

  possuiPerfilMoradorGT(): boolean {
    var condominio = this.getCondominioLogado();
    if (condominio && condominio.perfisIds) {
      return condominio.perfisIds.includes(18);
    }
  }

  possuiPerfilCourrier(): boolean {
    var condominio = this.getCondominioLogado();
    if (condominio && condominio.perfisIds) {
      return condominio.perfisIds.includes(19);
    }
  }

  redirecionarPrestadorServico() {
    this.router.navigate(['/ir-prestador-servico']);
  }

  redirecionarEmissaoBoletoProcurador() {
    this.router.navigate(['/emissao-de-boleto-cond']);
  }

  redirecionarCentralAtendimento() {
    sessionStorage.removeItem('condominioLogado');
    sessionStorage.removeItem('imovelLogado');

    if (sessionStorage.getItem('listaCondominiosImoveisCA'))
      sessionStorage.setItem(
        'listaCondominiosImoveis',
        sessionStorage.getItem('listaCondominiosImoveisCA')
      );
    if (sessionStorage.getItem('pessoaLogadaCA'))
      sessionStorage.setItem(
        'pessoaLogada',
        sessionStorage.getItem('pessoaLogadaCA')
      );

    sessionStorage.removeItem('listaCondominiosImoveisCA');
    sessionStorage.removeItem('pessoaLogadaCA');

    this.router.navigate(['/centralatendimento']);
  }

  redirecionarRedeDeParcerias() {
    const userCpfCnpj = this.getPessoaLogada().CpfCnpj;
    // this.router.navigate([`/rede-de-parcerias/desktop/${userCpfCnpj}`]);
    if(window.innerWidth <= 800) {
      this.router.navigate([`/rede-de-parcerias/${userCpfCnpj}`]);
    }
    else {
      this.router.navigate([`/rede-de-parcerias/desktop/${userCpfCnpj}`]);
    }
    this.logoutCondominium();
    this.changeThemeToDefault();
  }

  logoutCondominium() {
    sessionStorage.removeItem('tokenApiGT');
    sessionStorage.removeItem('condominioLogado');
    sessionStorage.removeItem('ehGestaoTotal');
    sessionStorage.removeItem('contaEntrExpr');
    sessionStorage.removeItem('allowEntrExpr');
    sessionStorage.removeItem('assuntos');
    sessionStorage.removeItem('dadosGerente');
    sessionStorage.removeItem('tiposSeguro');
  }

  getCaminhoImagemExibicao(typeImage: string, caminhoImagem?: string) {
    var src = '';
    if (typeImage.includes('image')) {
      src = 'data:' + typeImage + ';base64,' + caminhoImagem;
    } else if (typeImage.includes('pdf')) {
      src = 'https://areaexclusiva.doitt.com.br/digital/assets/img/pdf_icon.png';
    } else if (typeImage.includes('word')) {
      src = 'https://areaexclusiva.doitt.com.br/digital/assets/img/doc_icon.png';
    } else if (
      typeImage.includes('xls') ||
      typeImage.includes('xlsx') ||
      typeImage.includes('spreadsheetml')
    ) {
      src = 'https://areaexclusiva.doitt.com.br/digital/assets/img/excel_icon.png';
    }

    return src;
  }

  openExternalLink(url: string) {
    if (this.platformIsDeviceMobile()) Browser.open({ url });
    else window.open(url, '_blank');
  }

  exibirBannerCovid(): boolean {
    return (
      sessionStorage.getItem('exibirBanner') &&
      sessionStorage.getItem('exibirBanner').includes('true')
    );
  }

  possuiAlgumImovelComPerfil(idPerfil: number): boolean {
    const imoveisDoUsuario: any[] = JSON.parse(sessionStorage.getItem('listaCondominiosImoveis')).imoveisProprietario || [];

    if(imoveisDoUsuario.some(imovel => imovel.imovel.tipoPerfil === idPerfil))
      return true;

    return false;
  }

  // ==================  ACESSIBILIDADE =========================================
  public themeList: any[] = ['Theme_Default', 'Theme_Grayed', 'Theme_Dark', 'tema01', 'tema02', 'tema03', 'tema04', 'tema05', 'tema06', 'tema07', 'tema08', 'tema09', 'tema10', 'tema11', 'tema12' ];
  //public fontList: any[] = ['Acces_Nv1', 'Acces_Nv2', 'Acces_Nv3']

  public cadastraAcessibilidade() {
    // método de login
    var pessoaLogada = this.getPessoaLogada();
    if (pessoaLogada) {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: this.getTokenApiCondominioDigital(),
        }),
      };

      const data = new HttpParams()
        .set('idPessoa', pessoaLogada.Id)
        .set('idFonte', this.getNumberFonteAcessibilidade().toString())
        .set('idTema', this.getNumberTemaAcessibilidade().toString());

      return this.http.post<TokenResultDTO>(
        `${
          this.urlApiHost.apiCondominioDigital.host +
          this.methods.apiCondominioDigital.endpoints.methods.login
            .cadastraAcessibilidade
        }`,
        data,
        options
      );
    }
  }

  getAcessibilidade(): any {
    if (
      sessionStorage.getItem('acessibilidade') &&
      sessionStorage.getItem('acessibilidade') != 'undefined'
    )
      return JSON.parse(sessionStorage.getItem('acessibilidade'));
    else return null;
  }

  getFonteAcessibilidade() {
    var acessibilidade = this.getAcessibilidade();
    return acessibilidade !== null
      ? acessibilidade.fonte
      : this.pxSwitcher.getAccessibilityLevelNameByIndex(0);
  }

  getTemaAcessibilidade() {
    var acessibilidade = this.getAcessibilidade();
    return !(acessibilidade == null) ? acessibilidade.tema : this.themeList[0];
  }

  getNumberFonteAcessibilidade(): number {
    var fonte = this.getFonteAcessibilidade();
    return !(fonte === undefined || fonte == null)
      ? this.pxSwitcher.getAccessibilityLevelNumberByIndex(fonte) + 1
      : 0;
  }

  getNumberFonteAcessibilidade2(): number {
    var fonte = this.getFonteAcessibilidade();
    return !(fonte === undefined || fonte == null)
      ? this.pxSwitcher.getAccessibilityLevelNumberByIndex(fonte)
      : 0;
  }

  getNumberTemaAcessibilidade() {
    var fonte = this.getTemaAcessibilidade();
    return !(fonte === undefined || fonte == null)
      ? this.themeList.indexOf(fonte) + 1
      : 1;
  }

  usuarioPossuiAcessoARedeDeParcerias(): boolean {
    const redeDeParcerias = JSON.parse(sessionStorage.getItem('redeDeParcerias')) || false;

    return redeDeParcerias;
  }

  validarTemaUsuario() {
    const body = document.getElementsByTagName('BODY')[0];
    if (body) {
      body.setAttribute(
        'class',
        this.getFonteAcessibilidade() + ' ' + this.getTemaAcessibilidade()
      );

      document.documentElement.setAttribute('theme', this.getTemaAcessibilidade());

      //alterar fonte
      this.pxSwitcher.setAccessibilityLevelByIndex(
        this.getNumberFonteAcessibilidade2()
      );

      // alteração do statusBar
      let colorHex = '';
      var numberTema = this.getNumberTemaAcessibilidade();
      if (numberTema === 1) colorHex = '#002A5E';
      else if (numberTema === 2) colorHex = '#f5f5f5';
      else if (numberTema === 3) colorHex = '#1a1a1a';

      this.statusBar.backgroundColorByHexString(colorHex);

      if (numberTema === 2) this.statusBar.styleDefault();
      // text color black
      else this.statusBar.styleLightContent(); // text color white
    }
  }

  changeThemeToDefault() {
    const body = document.getElementsByTagName('BODY')[0];
    document.documentElement.setAttribute('theme', 'Theme_Default');

    if (body) {
      body.setAttribute('class', 'Acces_Nv1 Theme_Default');
    }
    this.statusBar.styleLightContent();
  }

  // ==================================  validação da plataforma  ============================================

  openPlatformStore() {
    if(this.platformIsAndroid())
      this.openExternalLink(environment.playStoreURL);
    else if(this.platformIsIos())
      this.openExternalLink(environment.appStoreURL);
  }

  platformIsDeviceMobile() {
    return !this.platform.is('mobileweb') &&
      (this.platform.is('android') ||
        this.platform.is('iphone') ||
        this.platform.is('ios')
      );
  }

  platformIsDevice() {
    return (
      this.platform.is('android') ||
      this.platform.is('iphone') ||
      this.platform.is('ios')
    );
  }

  platformIsDesktopOrMobileWeb() {
    return this.platform.is('mobileweb') || this.platform.is('desktop');
  }

  platformIsDesktop() {
    return this.platform.is('desktop');
  }

  platformIsMobileWeb() {
    return this.platform.is('desktop');
  }

  platformIsAndroid() {
    return this.platform.is('android');
  }

  platformIsIos() {
    return this.platform.is('iphone') || this.platform.is('ios');
  }

  getOrigem() {
    if(!this.platformIsDeviceMobile()) {
      return 1
    } else if(this.platformIsDesktopOrMobileWeb()) {
      return 2
    } else if(this.platformIsAndroid()) {
      return 3
    } else if(this.platformIsIos() ) {
      return 4
    }
  }

  public getTokenApiContratacao(): string {
    const token = sessionStorage.getItem('tokenApiContratacao');

    if (token) {
      return token;
    } else {
      this.createTokenApiContratacao().subscribe((response) => {
        if (response && response.acessToken) {
          const bearerToken = `Bearer ${response.acessToken}`;

          sessionStorage.setItem('tokenApiContratacao', bearerToken);

          return bearerToken;
        }
      });
    }
  }

  public createTokenApiContratacao() {
    const host = environment.apiContratacao.host;
    const token = environment.apiContratacao.endpoints.token;
    const { login, senha } = environment.apiContratacao.acesso;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = host + token;
    const body = { login, senha };
    const options = { headers };

    return this.http.post<any>(url, body, options);
  }

  // ==================================== registrar reclamação ====================================================

  getOrigemReclamacao(): string {
    if(this.platformIsDesktop() || this.platformIsMobileWeb()) {
      return "1"
    } else {
      return "2"
    }
  }

  registrarReclamacao(mensagem: string, idOrigemDoitt: number) {
    const condominio = this.getCondominioLogado();
    const pessoaLogada = this.getPessoaLogada();
    const device = this.getOrigemReclamacao();

    let body = {
      idPessoa: pessoaLogada.Id,
      cpf: pessoaLogada.CpfCnpj,
      nome: pessoaLogada.Nome,
      agencia: condominio.agencia,
      contaEmpresa: condominio.contaEmpresa,
      perfis: condominio.perfisIds.toString(),
      mensagem: mensagem,
      origem: device,
      idOrigemDoitt
    };

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.getTokenApiCondominioDigital(),
      }),
    };

    return this.http.post<string>(
      `${
        this.urlApiHost.apiCondominioDigital.host +
        this.methods.apiCondominioDigital.endpoints.methods.registrarReclamacaoDoitt
      }`,
      body,
      options
    );
  }

  // ==================================  validação do tamanho do upload  ============================================
  
  verificarTamanhoUpload(uploadSize: number, limiteTamanho: number = 20000000) {
    if (uploadSize < limiteTamanho) {
      return true;
    } else {
      this.lightBoxesService
      .open(
        'custom-generico',
        {
          title: '',
          text:
          `O arquivo anexado é muito grande. O limite é e ${(limiteTamanho/1000000)}MB. Por favor, tente novamente.`,
        },
        5000
        )
        .then((event) => {
          return false;
        });
    }
  }

  // ============================  validação de carateres do tipo number em inputs do tipo text =======================

  somenteNumeros(event) {
    return (event.charCode >= 48 && event.charCode <= 57)
  }

  somenteLetras(event) {
    if(this.somenteNumeros(event)) {
      return false;
    }
  }

  // ============================  Método integração listagem modal adesão boleto digital =======================

  public listarCondominiosUnidades() {
    const pessoaLogada = this.getPessoaLogada();
    const header = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: this.getTokenApiCondominioDigital(),
    });

    const parametros = new HttpParams().set('cpfCnpj', pessoaLogada.CpfCnpj);
    
    

    return this.http.get<ListaCondominiosUnidadesDTO[]>(
      `${
        this.urlApiHost.apiCondominioDigital.host +
        this.methods.apiCondominioDigital.endpoints.methods.optinsUsuario
          .listarCondominiosUnidades
      }`,
      { params: parametros, headers: header }
    );
  }

  public listarImoveis() {
    const cpfCnpjLocatario = this.getCpfCnpjLocatario().replace(/[^\d]+/g, '')
    const header = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: this.getTokenApiCondominioDigital(),
    });
  
    const parametros = new HttpParams().set('cpfCnpj', cpfCnpjLocatario);
  
    return this.http.get<ImoveisDTO[]>(
      `${
        this.urlApiHost.apiCondominioDigital.host +
        this.methods.apiCondominioDigital.endpoints.methods.optinsUsuario
          .listarImoveisLocatario
      }`,
      { params: parametros, headers: header }
    );
  }

  public generateImgSrcForBase64(base64Str: string, filetype: string) {
    return `data:${filetype};base64,${base64Str}`
  }

  public getUserImageSrcAttribute() {
    if(this.keyExistsInSessionStorage('pessoaFoto')) {
      const pessoaFoto: PessoaFoto = this.getFromSessionStorageAsObject('pessoaFoto');

      return this.sanitizer.bypassSecurityTrustUrl(this.generateImgSrcForBase64(pessoaFoto.arquivo, pessoaFoto.tipo));
    }
    else {
      return 'https://areaexclusiva.doitt.com.br/digital/assets/img/user-no-image.png';
    }
  }

  private getFromSessionStorageAsObject(key: string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  private keyExistsInSessionStorage(key: string): boolean {
    return sessionStorage.getItem(key) !== null;
  }

  //   public cadastrarEditarAdesaoUnidade(condominios) {
  //     var pessoaLogada = this.getPessoaLogada();

  //   const options = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       Authorization: this.getTokenApiCondominioDigital(),
  //     }),
  //   };

  //   let body = {
  //     idPessoa: pessoaLogada.Id,
  //     cpfCnpj: pessoaLogada.CpfCnpj,
  //     condominios: condominios
  //   };

  //   return this.http.post<CadastroAdesaoUnidades>(
  //     `${
  //       this.urlApiHost.apiCondominioDigital.host +
  //       this.methods.apiCondominioDigital.endpoints.methods.optinsUsuario
  //         .cadastrarEditarAdesaoUnidade
  //     }`,
  //     body,
  //     options
  //   );
  // }
  // ================================================ ||  ================================================ \\
  
  // Mètodos de criptografia
  encryptValue(value) {
    let simpleCrypto = new SimpleCrypto("YET9$BRZq3Ks%RWs$1@J&QRxtAWL$u4Y02WRVEn6HUaG6viW%m");
    return simpleCrypto.encrypt(value);
  }
  
  decryptValue(value) {
    let simpleCrypto = new SimpleCrypto("YET9$BRZq3Ks%RWs$1@J&QRxtAWL$u4Y02WRVEn6HUaG6viW%m");
    return simpleCrypto.decrypt(value)
  }  

  showChatBotButton() {
    if(!this.platform.is('desktop')) {
      let chatbot = document.querySelector<HTMLElement>('.znv-float-button')
      let webChat = document.querySelector<HTMLElement>('.znv-opt-in')
      if(chatbot && webChat) {
        chatbot.style.visibility = 'hidden'
        webChat.style.visibility = 'hidden'
      };
    }
  }

  removeAcentos(str) {
    let com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    let sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    let novastr = "";
    for (let i = 0; i < str.length; i++) {
      let troca = false;
      for (let a = 0; a < com_acento.length; a++) {
        if (str.substr(i, 1) == com_acento.substr(a, 1)) {
          novastr += sem_acento.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca == false) {
        novastr += str.substr(i, 1);
      }
    }
    return novastr;
  } 

  public setHostURL(url: string) {
    this.hostURL = url;

    // console.log("host: ", url);
  }

  public getHostURL() {
    return this.hostURL;
  }
  removeCaracteres(str: string){
    return str.replace(/[^a-zA-Z0-9]/g, '');
  }

  formatCnpjCpf(value) {
    const cnpjCpf = value.replace(/\D/g, '');

    if (cnpjCpf.length === 11) {
      return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
    }
    return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
  }

  createDoittToken(params:any){
    const host = environment.apidoittCashback.host;
    const endpoint = environment.apidoittCashback.endpoints.methods.token.get.gerarToken
  
    let parametros = new HttpParams()
      .set('IdPessoa', params.IdPessoa)
      // .set('IdPessoa', '70859')
      .set('Nome', params.Nome)
      .set('Perfil', params.Perfil)
      .set('Email',params.Email)

    return this.http.get(`${host+endpoint}`,{params:parametros, responseType: 'text'})
  }

  getPessoaDoitt(){
    const host = environment.apidoittCashback.host;
    const endpoint = environment.apidoittCashback.endpoints.methods.parceiro.get.pessoa
    let url = host + endpoint
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + this.getDoittToken()
    })
    return this.http.get<PessoaDoitt>(url, {headers:header});
  }

  getDoittToken(){
    return sessionStorage.getItem('tokenDoitt')
  }

  possuiPermissaoPerfil(idFuncionalidade): boolean {
    const pessoa: PessoaLogadaDTO = this.getPessoaLogada();
    return pessoa.perfis.includes(idFuncionalidade);
  }

}
 
