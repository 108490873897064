<div class="LightboxSubcategorias" [ngClass]="{ '__is-open': isOpen }">
  <div class="Popup">
    <div class="Popup_Header">
      <div class="Popup_Header_Title">{{title}}</div>

      <div class="Popup_Close" (click)="close()">
        <i class="fas fa-times"></i>
      </div>
    </div>

    <div class="Popup_Content">
      <div class="Popup_Content_MsgDefault">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
