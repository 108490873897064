import {Injectable} from '@angular/core'
import { Router } from '@angular/router';
import { AdesaoDeBoletoDigitalService } from 'src/app/shared/services/adesao-de-boleto-digital.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { StateService } from 'src/app/shared/services/stateService';
import { LightBoxesService } from '../../lightboxes';

@Injectable({ providedIn: 'root' })
export class AdesaoBoletoButton {
    
  constructor(
      public stateService: StateService,
      private adesaoService: AdesaoDeBoletoDigitalService,
      private lightBoxesService: LightBoxesService,
      private router: Router,
      private modalService: ModalService
  ){}

  cadastraEdita(condominios, imoveis, origem) {
    const pessoaLogada = this.stateService.getPessoaLogada();
    const body = {
      idPessoa: pessoaLogada.Id,
      cpfCnpj: pessoaLogada.CpfCnpj,
      condominios: condominios,
      imoveis: imoveis
    }
    this.adesaoService.cadastrarEditarAdesao(body).subscribe(item => {
      // console.log('item: ', item);
      // if(item) {
        sessionStorage.setItem('listaCondominiosAdesaoBoleto', JSON.stringify(condominios));
        sessionStorage.setItem('listaImoveisAdesaoBoleto', JSON.stringify(imoveis));

        this.lightBoxesService.open(
          'custom-generico', {
            title: 'Sucesso!',
            text: 'Sua alteração na forma de recebimento dos boletos foi realizada.'
          }, 5000)
        // }
        this.close()
    },
    err => {
      this.lightBoxesService.open(
        'custom-generico', {
          title: 'Atenção',
          text: err.error.message
        }, 5000)
    })
  }
    
  close() {
    sessionStorage.setItem('exibirModalAdesaoBoletoDigital', JSON.stringify(false));
    // if (this.stateService.platformIsDesktop()) 
    this.router.navigate(['/home']);
  }
}