import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { StateService } from '../../services/stateService';
import { LightBoxesService } from '../lightboxes';
import CustomerService from '../../services/customer.service';

@Component({
  selector: 'modal-avaliar-app',
  templateUrl: './modal-avaliar-app.component.html',
  styleUrls: ['./modal-avaliar-app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalAvaliarApp implements OnInit {

  public form: UntypedFormGroup;
  public isSubmitted: boolean = false;
  private customer = this.customerService.getCustomer()

  @Input() open: boolean;
  @Output() onClose = new EventEmitter();

  constructor(
    private modalService: ModalService,
    private fb: UntypedFormBuilder,
    public stateService: StateService,
    private lightBoxesService: LightBoxesService,
    private customerService: CustomerService,
  ) {

  }

  ngOnInit() {
    this.createForms();
  }

  createForms() {
    this.form = this.fb.group({
      descricao: ['', [Validators.required, Validators.minLength(20)]],
    });
  }

  registrarReclamacao(item: string){
    const idOrigemDoitt = +this.customer.id

    this.stateService.registrarReclamacao(item, idOrigemDoitt).subscribe(item => {
      this.lightBoxesService.open(
        'custom-generico', {
        title: '',
        text: item
      }, 5000
      )
    }, err => {
      this.lightBoxesService.open(
        'custom-generico', {
        title: 'Atenção',
        text: err.error.message
      }, 5000
      )
    })
  }

  close() {
    this.modalService.close('avaliar-app');
    this.resetState();
  }

  enviar() {
    if(this.form.valid) {
      const descricao = this.form.get('descricao').value;

      this.registrarReclamacao(descricao);
      this.close();
    }
    else {
      this.isSubmitted = true;
    }
  }

  getFieldError(name: string) {
    const invalid = this.form.get(name).invalid;

    return {
      '__is-error': invalid && this.isSubmitted
    }
  }

  get descricaoValue() {
    return this.form.get('descricao');
  }

  private resetState() {
    this.form.reset();
    this.isSubmitted = false;
  }
}
