import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StateService } from '../../services/stateService';
import { ConstantsService } from '../../services/constants.service';
import { CondominioService } from '../../services/condominio.service';
import { AlertaService } from '../../services/alerta.service';
import { Platform } from '@ionic/angular';
import { PerfilDoitt } from '../../services/PerfilDoitt.service';

@Component({
  selector: 'app-nav-tab',
  templateUrl: './nav-tab.component.html',
  styleUrls: ['./nav-tab.component.scss'],
})
export class NavTabComponent implements OnInit {
  public centralAtendimento = (this.stateService.getPessoaLogada()) ? this.stateService.getPessoaLogada().CentralAtendimento : false;
  public rotaAtual = this.router.url;
  public hasNewNotification: boolean = false;
  public ehGestaoTotal: boolean = this.stateService.getCondominioLogado() ? this.stateService.getCondominioLogado().ehGestaoTotal : null
  public totalAlertas;

  @Output() eventOpenNotificationModal = new EventEmitter();

  constructor(
    private router: Router,
    public stateService: StateService,
    public constant: ConstantsService,
    public alertaService: AlertaService,
    private platform: Platform,
    private activatedRoute: ActivatedRoute,
    public perfilDoitt: PerfilDoitt
  ) {
    this.activatedRoute.params.subscribe(() => {
      this.getTotalNotificacoes();     
    });
  }
  
  ngOnInit() {} 

  ngClassRouterLink(rota:string){
    this.rotaAtual = rota
  }

  async getTotalNotificacoes() {
   
    const cpfPessoaLogada = (this.stateService.getPessoaLogada()) ? this.stateService.getPessoaLogada().CpfCnpj : null;
    const profileCode = "1";
    const condominioAgencia = this.stateService.getCondominioLogado() ? this.stateService.getCondominioLogado().Agencia : null;
    const condominioConta = this.stateService.getCondominioLogado() ? this.stateService.getCondominioLogado().ContaEmpresa : null;

    if (this.ehGestaoTotal) {
      let origem: string;
      if(this.platform.is('desktop'))
        origem = '1';
      if(this.platform.is('mobile'))
        origem = '2';   

      // const numberOfnewNotifications: number = await this.alertaService.getTotalAlertas(
      //   origem,
      //   cpfPessoaLogada,
      //   profileCode,
      //   condominioAgencia,
      //   condominioConta
      // ).toPromise();

      // this.hasNewNotification = numberOfnewNotifications > 0;

      this.alertaService.getTotalAlertas(origem, cpfPessoaLogada, profileCode, condominioAgencia, condominioConta).subscribe((response) => {
        if (response) {
          this.totalAlertas = response;
          this.hasNewNotification = response > 0;
        }
        else
          this.totalAlertas = null;
      });
    }

    //busca notificação de nova versão
    this.hasNewNotification = (this.hasNewNotification || this.stateService.possuiNovaVersao());
  }

  openMenuMobile() {
    this.stateService.openCloseMenuMobile();
  }

  openNotificationModal() {
    this.eventOpenNotificationModal.emit();
  }

  redirectHome() {
    this.router.navigate(['/home']);
  }
}
