import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute
} from '@angular/router';
import { Observable } from 'rxjs';
// import { AnalyticsService } from '../services/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardChildService implements CanActivate {
  private isAuthenticated: boolean = true;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    window.scroll(0, 0);

    // this.routerService.setInfo(route.data.imgSidebar, route.data.text);

    // se os tokens das APIs GT ou de Serviços forem inválidos, redireciona para o login.
    // TODO: Comentado

    if (!sessionStorage.tokenApiCondominioDigital) {
      //para a navegação mas não redireciona para a login
      //this.isAuthenticated = false;

      sessionStorage.clear(); // TODO: INSERIR MODAL COM MENSAGEM
      this.router.navigate(['/']);
      return false;

      //pra usar o UrlTree tem que mudar o tipo do retorno de Observable<boolean> para UrlTree
      //return this.router.createUrlTree(['']);

    } else {
      return this.isAuthenticated;

    }

  }

}
