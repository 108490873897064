<div class="Modal Modal_CadastroBiometria" [ngClass]="{ '__is-open': (isOpen$ | async) }">
  <div class="Modal_Header">
    <div class="Modal_Header_Title">
      Impressão digital {{ (customer$ |  async).nomeApp }}
    </div>
  </div>

  <div class="Modal_Content">
    <div class="Block">
      <div class="Block_Container">
        <div class="Message" *ngIf="!isIOS">
          Olá {{nome_usuario}}, seu app acabou de ficar mais seguro!
          Agora você pode acessar sua conta utilizando sua digital.
        </div>

        <div class="Message" *ngIf="isIOS">
          Olá {{nome_usuario}}, seu app acabou de ficar mais seguro!
          Agora você pode acessar sua conta utilizando sua digital
          ou Face ID.
        </div>

        <div *ngIf="!isIOS" class="IconOuterContainer">
          <div class="Icon">
            <div class="IconContainer">
              <i class="fas fa-fingerprint"></i>
            </div>
          </div>
        </div>

        <div class="IconOuterContainer" *ngIf="isIOS">
          <div class="Icon __is-ios">
            <img src="/assets/img/face-id-icon.png">
          </div>
        </div>

        <div class="SmallMessageAndCheckboxContainer">
          <div class="SmallMessage">
            Essa funcionalidade pode ser desativada sempre que desejar no menu do usuário, posicionado ao lado da logo
            da {{ (customer$ |  async).nomeApp }}.
          </div>

          <div class="FormGroupCheckBox">
            <div class="FormGroupCheckBox_Input">
              <ion-checkbox 
                id="confirmation-checkbox"
                [formControl]="marcouBiometria"
                [checked]="marcouBiometria.value"
              ></ion-checkbox>
            </div>

            <label
              class="FormGroupCheckBox_Label"
              for="confirmation-checkbox"
              (click)="marcouBiometria.setValue(!marcouBiometria.value)"
            >
              Acessar usando minha digital
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="Modal_Footer">
    <div class="Modal_Footer_ListActions">
      <div class="Footer_ListActions_Item __is-full-width">
        <ds-button full="true" color="Gray" *ngIf="!marcouBiometria.value" (click)="close()">
          Continuar sem a digital
        </ds-button>

        <ds-button class="GrayButton" *ngIf="marcouBiometria.value" full="true" (click)="register()">
          Continuar com a digital
        </ds-button>
      </div>
    </div>
  </div>
</div>