<ion-header *ngIf="isMobileHeader; else headerAndroidWeb">
  <ion-toolbar>
    <ng-container *ngTemplateOutlet="header"></ng-container>
  </ion-toolbar>
</ion-header>

<ng-template #headerAndroidWeb>
  <ng-container *ngTemplateOutlet="header"></ng-container>
</ng-template>

<ng-template #header>
  <header class="Header">
    <div class="Header_Voltar" (click)="backButton()">
      <i class="apsa-icon-solid apsa-icon-solid-arrow-back"></i>
    </div>
  
    <div class="Header_Logo">
      <a [routerLink]="ehPerfilDoitter.length > 0 ? '/cashBack/dashboard' : '/home'" routerLinkActive="active">
        <img
          [src]="caminhoLogoHeader"
          [alt]="(customer$ | async).nomeApp"
          class="Header_Logo_Mobile"
        >
      </a>
    </div>
  
    <div
      class="Header_Atalho Header_Atalho_Perfil"
      *ngIf="habilitarIconeModalPessoa()"
    >
      <div class="Header_Atalho_Action " (click)="abrirModalPessoa()">
        <div class="Atalho_Configuracoes_Icon">
          <div class="EmailAdvice" *ngIf="this.stateService.exibirInfoEmailNaoConfirmado()">
            <i class="fas fa-exclamation"></i>
          </div>
  
          <img
            class="user-image"
            [src]="stateService.getUserImageSrcAttribute()"
          >
  
          <!-- <i class="apsa-icon-solid apsa-icon-solid-user"></i> -->
        </div>
  
        <div class="Atalho_Configuracoes_Name">
          {{nomePessoa}}
        </div>
  
        <div class="Atalho_Configuracoes_Arrow">
          <i class="fas fa-chevron-down"></i>
        </div>
      </div>
  
      <div class="Header_Atalho_Popup __is-right">
        <div class="Atalho_Popup_Close" (click)="abrirModalPessoa()">
          <i class="fas fa-times"></i>
        </div>
  
        <div class="Atalho_Popup_Header">
          <div class="Popup_Header_Title">Perfil</div>
  
          <div class="Popup_Header_Subtitle">
            Acesse os principais dados da sua conta
          </div>
        </div>
  
        <app-lista-dados-conta
          (onOpenModalAvaliacao)="abrirModalAvaliacao()"
          [trocaDeCondominio]="trocaDeCondominio"
        >
        </app-lista-dados-conta>
      </div>
    </div>
  
    <!-- <ds-button
      class="tutorial-button"
      *ngIf="rotaAtual === '/home' && stateService.permitirEntregaExpressa() === 0 "
      routerLink="/tutorial-desktop"
    >
      Ver tutorial
    </ds-button> -->
  
  <div 
    class="Header_Atalho Header_Tutorial line-from-center"
    *ngIf="rotaAtual === '/home' && stateService.permitirEntregaExpressa() === 0 " 
    routerLink="/tutorial-desktop">
    <i class="fas fa-chalkboard-teacher"></i>
    <div class="Header_Atalho_Popup_Hover_Labels_Tutorial" [ngClass]="{'custom-left': !stateService.getCondominioLogado()}" >
      <div class="Just_Label">
        Tutorial
      </div>
    </div>
  </div>
  
    <div
      class="Header_Atalho Header_Shortcut Header_PGestao"
      *ngIf="stateService.usuarioPossuiAcessoARedeDeParcerias() && (perfilLogadoEhGestorOuSindico || (perfilDoitt.isDoitter$ | async))"
    >
      <div
        class="Header_Atalho_Action line-from-center"
        
        (click)="this.stateService.redirecionarRedeDeParcerias()"
      >
        <div class="Header_Shortcut_Icon">
          <img src="../../../../assets/svg/dashboard-parcerias-header.svg" />
        </div>
      </div>
      <div
          class="Header_Atalho_Popup_Hover_Labels_PGestão"
          [ngClass]="{'custom-left': !stateService.getCondominioLogado()}"
        >
          <div class="Just_Label">Painel de gestão</div>
        </div>
    </div>
  
    <div
      class="Header_Atalho Header_Shortcut Header_Atalhos"
      *ngIf="this.stateService.exibeOpcaoMenuAtalhoHeader()"
    >
      <div
        class="Header_Atalho_Action line-from-center"
        
        (click)="abrirAtalho();validaIsOpen()"
      >
        <div class="Header_Shortcut_Icon">
          <i class="apsa-icon-solid-menu-funcionalidades"></i>
        </div>
      </div>
  
      <div class="Header_Atalho_Popup __is-left Header_Shortcut_Popup">
        <div
          class="Atalho_Popup_Close"
          (click)="stateService.openCloseComponent('.Header_Shortcut_Popup'); IconIsOpen(false)"
        >
          <i class="fas fa-times"></i>
        </div>
  
        <div class="Atalho_Popup_Header">
          <div class="Popup_Header_Title">Atalhos</div>
        </div>
  
        <div class="Atalho_Popup_Content Shortcut_Popup_Content">
          <app-lista-menu-mobile
            [possuiPerfilGestorSindico]="possuiPerfilGestorSindico"
            [possuiPerfilProprietario]="possuiPerfilProprietario"
            [ehGestaoTotal]="ehGestaoTotal"
            [possuiPerfilLocatario]="possuiPerfilLocatario"
            [possuiPerfilSindico]="this.possuiPerfilSindico"
            class="Popup_Content_Menu"
          >
          </app-lista-menu-mobile>
        </div>
      </div>
      <div
          class="Header_Atalho_Popup_Hover_Labels_Atalhos"
          *ngIf="this.isOpen === false"
          [ngClass]="{'custom-left': !stateService.getCondominioLogado()}"
        >
          <div class="Just_Label">Atalhos</div>
        </div>
    </div>
  
    <div class="Header_Atalho Header_Atalho_Acessibilidade">
      <div
        class="Header_Atalho_Action line-from-center"
        (click)="stateService.openCloseComponent('.Header_Atalho_Acessibilidade');validaIsOpen()"
        
      >
        <i class="fas fa-palette"></i>
      </div>
  
      <div class="Header_Atalho_Popup __is-left">
        <div
          class="Atalho_Popup_Close"
          (click)="stateService.openCloseComponent('.Header_Atalho_Acessibilidade'); IconIsOpen(false)"
        >
          <i class="fas fa-times"></i>
        </div>
  
        <div class="Atalho_Popup_Header">
          <div class="Popup_Header_Title">
            Personalizar
          </div>
  
          <div class="Popup_Header_Subtitle">
            Ajustes as definições de visualização do sistema
          </div>
        </div>
  
        <div class="Atalho_Popup_Content __has-padding">
          <app-acessibilidade></app-acessibilidade>
        </div>
      </div>
      <div
          class="Header_Atalho_Popup_Hover_Labels_Acessibilidade"
          *ngIf="this.isOpen === false"
          [ngClass]="{'custom-left': !stateService.getCondominioLogado()}"
        >
          <div class="Just_Label">Acessibilidade</div>
        </div>
    </div>
  
    <ng-container *ngIf="customer$">
      <div
        class="Header_Atalho Header_Atalho_Solicitacoes"
        *ngIf="constant.condominioImovelAtivo && possuiPerfilDoitt(3)"
      >
        <a routerLink="/minhas-solicitacoes" routerLinkActive="active">
          <div
            class="Header_Atalho_Action line-from-center"
            
          >
            <i
              class="fas fa-tasks"
            >
            </i>
          </div>
          <div
          class="Header_Atalho_Popup_Hover_Labels_Solicitacao"
          [ngClass]="{'custom-left': !stateService.getCondominioLogado()}"
        >
          <div class="Just_Label">Minhas Solicitações</div>
        </div>
        </a>
      </div>
    </ng-container>
  
    <div class="Header_Atalho Header_Atalho_PerguntasRespostas">
      <a routerLink="/perguntas-respostas" routerLinkActive="active">
        <div
          class="Header_Atalho_Action line-from-center"      
          
        >
        <i class="far fa-question-circle" style="font-size: 24px;"></i>
        </div>
      </a>
      <div class="Header_Atalho_Popup_Hover_Labels_Perguntas" routerLink="/perguntas-respostas" [ngClass]="{'custom-left': !stateService.getCondominioLogado()}" >
  
        <div class="Just_Label">
          Perguntas e Respostas
        </div>
  
      </div>
  
      <div class="Header_Atalho_Popup __is-left">
        <div class="Atalho_Popup_Header">
          <div class="Popup_Header_Title">
            Perguntas e Respostas
          </div>        
        </div>
      </div>
    </div>
  
    <ng-container *ngIf="customer$ && (customer$ | async).exibirFaleComASeguradora">
      <div class="Header_Atalho Header_Atalho_Seguros">
        <div
          class="Header_Atalho_Action line-from-center"
          
          (click)="stateService.openCloseComponent('.Header_Atalho_Seguros')"
        >
          <i
            class="far fa-building"
            (click)="faleComSeguradora"
          >
    
            <div class="overlayed-icon">
              <i class="fas fa-heart"></i>
            </div>
          </i>
        </div>
    
        <div 
          class="Header_Atalho_Popup __is-left"
          #faleComSeguradora 
        >
          <div
            class="Atalho_Popup_Close"
            (click)="stateService.openCloseComponent('.Header_Atalho_Seguros')"
          >
            <i class="fas fa-times"></i>
          </div>
    
          <div class="Atalho_Popup_Content" >
            <app-fale-com-a-seguradora></app-fale-com-a-seguradora>
          </div>
        </div>
      </div>
    </ng-container>
  
    <ng-container *ngIf="customer$">
      <div
        class="Header_Atalho Header_Atalho_CentralAtendimento"
        *ngIf="constant.condominioImovelAtivo"
      >
        <div
          class="Header_Atalho_Action line-from-center"
          (click)="abrirCentralAtendimento(); validaIsOpen()"
          
        >
          <i
            class="apsa-icon-outline apsa-icon-outline-mail"
            (click)="faleComApsa.ngOnInit()"
          >
          </i>
        </div>
    
        <div class="Header_Atalho_Popup __is-left"
        [ngClass]="{ '__is-sindico': possuiPerfilSindico }">
          <div
            class="Atalho_Popup_Close"
            (click)="stateService.openCloseComponent('.Header_Atalho_CentralAtendimento');IconIsOpen(false)"
          >
            <i class="fas fa-times"></i>
          </div>
    
          <div class="Atalho_Popup_Header">
            <div class="Popup_Header_Title">Fale com a {{ (customer$ | async).nomeApp }}</div>
          </div>
    
          <div class="Atalho_Popup_Content">
            <app-fale-com-a-apsa
              #faleComApsa
              [load]="this.isCentralAtendimentoLoading"
            >
            </app-fale-com-a-apsa>
          </div>
        </div>
        <div
          class="Header_Atalho_Popup_Hover_Labels_FaleApsa"
          *ngIf="this.isOpen === false"
          [ngClass]="{'custom-left': !stateService.getCondominioLogado()}"
        >
          <div class="Just_Label">Fale com a {{ (customer$ | async).nomeApp }}</div>
        </div>
      </div>
    </ng-container>
  
    <div
      class="Header_Atalho Header_Atalho_Notificacoes"
      *ngIf="this.stateService.getCondominioLogado()"
    >
      <div
        class="Header_Atalho_Action line-from-center"
        (click)="carregarAlertas(); validaIsOpen()"
        
      >
        <span class="Header_Notificacoes_Contador" *ngIf="totalAlertas > 0">
          {{totalAlertas}}
        </span>
        
        <i class="apsa-icon-outline apsa-icon-outline-bell"></i>
      </div>
  
      <div class="Header_Atalho_Popup __is-left">
        <div
          class="Atalho_Popup_Close"
          (click)="stateService.openCloseComponent('.Header_Atalho_Notificacoes'); IconIsOpen(false)"
        >
          <i class="fas fa-times"></i>
        </div>
  
        <div class="Atalho_Popup_Header">
          <div class="Popup_Header_Title">Alertas</div>
        </div>
  
        <div class="Atalho_Popup_Content">
          <app-notificacoes [listNotifications]="listNotifications"></app-notificacoes>
        </div>
      </div>
      <div
          class="Header_Atalho_Popup_Hover_Labels_Alertas"
          *ngIf="this.isOpen === false"
          [ngClass]="{'custom-left': !stateService.getCondominioLogado()}"
        >
          <div class="Just_Label">Alertas</div>
        </div>
    </div>
  
    <div class="Header_Exit Header_Atalho" (click)="sairSimOuNao()">
      <div class="Header_Atalho_Action line-from-center Header_Exit_Icon">
        <i class="apsa-icon-outline-logout"></i>
      </div>
      <div
        class="Header_Atalho_Popup_Hover_Labels_Exit"
        [ngClass]="{'custom-left': !stateService.getCondominioLogado()}"
      >
        <div class="Just_Label">Sair</div>
      </div>
    </div>
  </header>
</ng-template>