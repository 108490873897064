import { Injectable } from '@angular/core';
import { CondominioDTO } from '../services/DTO/response/Condominio.DTO';
import { StateService } from '../services/stateService';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { resolve } from 'url';
import { Observable, of, Subject } from 'rxjs';
import { GerenteContaDTO } from './DTO/response/GerenteContaDTO';
import { PerfilDTO } from './DTO/response/Perfil.DTO';
import { FuncionalidadeDTO } from './DTO/response/Funcionalidade.DTO';
import { EspacoDTO } from './DTO/Request/Espaco.DTO';
import { BlocoGtDTO } from './DTO/Request/BlocoGt.DTO';
import { UnidadeDTO } from './DTO/Request/Unidade.DTO';
import { Guid } from 'guid-typescript';
import { ItemDTO } from './DTO/Request/Item.DTO';
import { EntregaExpressaService } from 'src/app/modules/entrega-expressa/services/entrega-expressa.service';
import { ConstantsService } from './constants.service';
import { ICotasMensais } from './DTO/response/ICotasMensais';
import { ICalendarioDeVisitasProximaVisita } from './DTO/response/ICalendarioDeVisitasProximaVisita';
import { IGetTiposSegurosResponse } from './DTO/response/IGetTiposSeguroResponse';
import { ICadastrarMensagemCorretoraRequest } from './DTO/Request/ICadastrarMensagemCorretoraRequest';
import { IBannerRota } from './DTO/response/IBannerRota';
import { IImovelLogado } from './DTO/response/IImovelLogado';
import CustomerService from './customer.service';


@Injectable({
  providedIn: 'root'
})
export class CondominioService {
  //API Condominio Digital
  public static trocaCondominio: Subject<CondominioDTO> = new Subject<CondominioDTO>();
  private urlApiHost: string;
  private methods = environment.apiCondominioDigital.endpoints.methods;
  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.stateService.getTokenApiCondominioDigital()
    });
  }

  //API GEstão Total
  private urlApiHostGT = environment.apiGT.host;
  private methodsGT = environment.apiGT.endpoints;
  private getHeaderGT() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.stateService.getTokenApiGT()
    });
  }
  private customer = this.customerService.getCustomer();

  //METODOS CONDOMINIO DIGITAL
  constructor(private http: HttpClient,
    public stateService: StateService,
    private entregaExpressaService: EntregaExpressaService,
    private constant: ConstantsService,
    private customerService: CustomerService,
  ) {
    this.urlApiHost = environment.apiCondominioDigital.host;
    
    
  }

  public getDadosGerenteConta(contaEmpresa: string) {
    var endpoint = this.urlApiHost + this.methods.condominio.getDadosGerenteConta;
    var parametros = new HttpParams().set("contaEmpresa", contaEmpresa);
    return this.http.get<GerenteContaDTO>(`${endpoint}`, { headers: this.getHeader(), params: parametros });
  }
  
  public getCotasDevidas() {
    const condominio = this.stateService.getCondominioLogado()
    var endpoint = this.urlApiHost + this.methods.cotas.getCotasDevidas;
    var parametros = new HttpParams().set('contaCondominio', condominio.Conta);

    if(this.stateService.possuiPerfilCondomino() && !this.stateService.possuiPerfilSindico()){
      const pessoa = this.stateService.getPessoaLogada(false);
      parametros = parametros.append('cpfCondomino', pessoa.CpfCnpj)
    }

    return this.http.get<any>(`${endpoint}`, { headers: this.getHeader(), params: parametros });
  }

  public getExibicaoWhatsApp() {
    var endpoint = this.urlApiHost + this.methods.condominio.validarExibicaoWpp;
    return this.http.get<string>(`${endpoint}`, { headers: this.getHeader() });
  }

  public listarCondominiosImoveis(cpfCnpj: string) {
    var cache = sessionStorage.getItem("listaCondominiosImoveis");
    if (cache != null && cache != undefined) {
      return Promise.resolve(JSON.parse(cache));
    }

    var pessoa = this.stateService.getPessoaLogada();
    var endpoint = this.urlApiHost + this.methods.login.getCondominiosImoveis;
    var parametros = new HttpParams().set("cpf", cpfCnpj).set("acessoGerencial", pessoa.AcessoGerencial.toString());
    return this.http.get<any>(`${endpoint}`, { headers: this.getHeader(), params: parametros }).toPromise().then(obj => {
      sessionStorage.setItem("listaCondominiosImoveis", JSON.stringify(obj));
      return obj;
    });
  }

  public verificaAdesaoCondominio(){
    const cond = this.stateService.getCondominioLogado();
    if(!cond) return;

    if((sessionStorage.getItem('contaEntrExpr') != cond.contaEmpresa) || !sessionStorage.getItem('allowEntrExpr')) {
      this.entregaExpressaService.verificaAdesaoCondominio(cond.contaEmpresa, cond.conta).subscribe(item =>{
        sessionStorage.setItem('contaEntrExpr', cond.contaEmpresa);
        sessionStorage.setItem('allowEntrExpr',item);
      }
      // , error =>{
      //   sessionStorage.setItem('allowEntrExpr','0');
      // }
      );
    }
  }

  public logarCondominio(item: CondominioDTO, perfis: Array<PerfilDTO>, funcionalidades: Array<FuncionalidadeDTO>) {
    return this.stateService.createTokenApiGT().add(() => {
      let perfisIds: number[] = [];
      let nomePerfis: string[] = [];
      if (perfis && perfis.length > 0) {
        perfis.forEach(item => {
          perfisIds.push(item.idPerfil);
          nomePerfis.push(item.nomePerfil);
        })
      }
      let funcionalidadesIds: number[] = [];
      if (perfis && perfis.length > 0) {
        funcionalidades.forEach(item => {
          funcionalidadesIds.push(item.idFuncionalidade);
        })
      }
      if (item !== undefined) {
        const obj = {
          carteira: item.carteira,
          agencia: item.agencia,
          conta: item.conta,
          contaEmpresa: item.contaEmpresa,
          nome: item.nome,
          cnpj: item.cnpj,
          prime: item.prime,
          ehGestaoTotal: item.ehGestaoTotal,
          ehContaPool: item.ehContaPool,
          perfis: perfisIds,
          nomePerfil: nomePerfis,
          funcionalidades: funcionalidadesIds,
          exibeCartao: item.exibeCartao,
          uf: item.uf,
          optins: item.optins
        };
        sessionStorage.setItem('condominioLogado', JSON.stringify(obj));
        sessionStorage.removeItem('imovelLogado');
        sessionStorage.setItem('ehGestaoTotal', item.ehGestaoTotal.toString());     
      }
    });
  }

  public logarImovel(item) {
    sessionStorage.setItem('imovelLogado', JSON.stringify(item));
    sessionStorage.removeItem('condominioLogado');
  }
//METODOS GESTAO TOTAL

  public listarEspacosReservaveis() {
    const condominio = this.stateService.getCondominioLogado();
    return this.http.get<{ Data: EspacoDTO[] }>(
      `${this.urlApiHostGT +
        this.methodsGT.checklistreports.get.buscarEspacosReservaveis}`
        .replace('{0}', condominio.Agencia)
        .replace('{1}', condominio.ContaEmpresa),
      { headers: this.getHeaderGT() }
    );
  }

  public listarBlocosGT() {
    const condominio = this.stateService.getCondominioLogado();
    const parametros = new HttpParams()
      .set('showAllBlocks', 'True')
      .set('c', condominio.contaEmpresa);
      
    return this.http.get<{ Data: BlocoGtDTO[]}>(`${this.urlApiHostGT + 
      this.methodsGT.checklistreports.get.buscarBlocosGT}`,
      { headers: this.getHeaderGT(), params: parametros }
      )
  }

  public espacosDetalhe(idRecebida) {
    const parametros = new HttpParams()
      .set('id', idRecebida);

    return this.http.get(`${this.urlApiHostGT + this.methodsGT.checklistreports.get.espacosSemImagem}`,
     { headers: this.getHeaderGT(), params: parametros})
  }

  buscarConfiguracoesReservaEspaco(uidEspaco: Guid) {
    return this.http.get<UnidadeDTO[]>(
      `${this.urlApiHostGT +
      this.methodsGT.checklistreports.get
        .buscarConfiguracoesReservaEspaco}/${uidEspaco}`,
      { headers: this.getHeaderGT() }
    );
  }

  getCotasMensal() {
    const url = this.urlApiHost + this.methods.condominio.getCotasMensal;
    const queryParams = {
      FiltroContaCondominio: this.stateService.getCondominioLogado().conta,
    }

    return this.http.get<ICotasMensais>(url, { params: queryParams, headers: this.getHeader() });
  }

  getProximaVisita() {
    const url = this.urlApiHost + this.methods.condominio.getProximaVisita;
    const queryParams = {
      pAgencia: this.stateService.getCondominioLogado().agencia,
      pContas: this.stateService.getCondominioLogado().contaEmpresa,
      UF: this.stateService.getCondominioLogado().uf,
    }

    return this.http.get<ICalendarioDeVisitasProximaVisita>(url, { params: queryParams, headers: this.getHeader() });
  }

  getTiposSeguro() {
    const url = this.urlApiHost + this.methods.condominio.getTiposSeguro;

    return this.http.get<IGetTiposSegurosResponse[]>(url, { headers: this.getHeader() });
  }

  cadastrarMensagemCorretora(idSeguro: string, mensagem: string) {    
    const url = this.urlApiHost + this.methods.condominio.cadastrarMensagemCorretora;

    const pessoaLogada = this.stateService.getPessoaLogada();
    const condominioLogado = this.stateService.getCondominioLogado();
    const imovelLogado: IImovelLogado | null = this.stateService.getImovelLogado();
    
    const payload: ICadastrarMensagemCorretoraRequest = {
      idPessoa: pessoaLogada.Id,
      idTipoSeguro: idSeguro,
      mensagem: mensagem,
    };

    if(this.constant.condominioImovelAtivo) {
      if(condominioLogado)
        payload['contaEmpresa'] = condominioLogado.contaEmpresa;
      else
        payload['codigoImovel'] = imovelLogado.codigoImovel;
    }
    
    return this.http.post<unknown>(url, payload, { headers: this.getHeader()})
  }

    
  getBannerRota(rota: string) {
    const route = this.urlApiHost + this.methods.condominio.getBannerRota;
    const idPerfis = this.stateService.obterPerfisEmTodosOsCondominiosEImoveis().map(p => p.idPerfil) || [];
    const idPerfisCommaSeparated = idPerfis.length > 0 ? idPerfis.join(',') : '';
    const idOrigemSite = this.customer.id;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.stateService.getTokenApiCondominioDigital()
    });

    const queryParams = new HttpParams()
      .set('rota', idOrigemSite + '_' + rota)
      .set('idsPerfis', idPerfisCommaSeparated);

    const options = { headers, params: queryParams }

    return this.http.get<IBannerRota>(route, options);
  }
}
