import { ModalService } from './../../services/modal.service';
import { StateService } from 'src/app/shared/services/stateService';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { AdesaoDeBoletoDigitalService } from '../../services/adesao-de-boleto-digital.service';
import { LightBoxesService } from '../lightboxes';
import { Router } from '@angular/router';

@Component({
  selector: 'adesao-de-boleto-digital-selecao-de-unidades',
  templateUrl: './adesao-de-boleto-digital-selecao-de-unidades.component.html',
  styleUrls: ['./adesao-de-boleto-digital-selecao-de-unidades.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdesaoDeBoletoDigitalSelecaoDeUnidadesComponent implements OnInit, OnChanges {

  @Input() listaCondominios
  @Input() listaImoveis
  @Output() closeModal = new EventEmitter();

  public condominioSelecionado = [];
  public todasAsUnidadesAceitas = false;

  constructor(
    public stateService: StateService,
    private adesaoService: AdesaoDeBoletoDigitalService,
    private lightBoxesService: LightBoxesService,
    private router: Router,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    // console.log(this.listaCondominios)
    // console.log(this.listaImoveis)
  }

  ngOnChanges() {
    if (this.listaCondominios) {
      this.listaCondominios.forEach(element => {

      });
    }
  }

  toggleCollapse(id: string) {
    const el = document.getElementById(id);

    el.classList.toggle('__is-open');

    // console.log(el);
  }

  ////////////////// TOGGLE DOS CHECKBOXS//////////////////////
  aderirDesaderirCondominio(contaCondominio) {
    // console.log('foi condo', contaCondominio)
    for (let condominio of this.listaCondominios) {
      if (contaCondominio == condominio.contaCondominio) {
        let naoAparece = !this.condominioSelecionado.includes(contaCondominio);

        for (let unidade of condominio.unidades) {
          if (naoAparece) unidade.unidadeAderida = true;
          else unidade.unidadeAderida = false;
          // console.log(unidade.unidadeAderida)
        };

        this.toggleTodasUnidadeCondominio(contaCondominio, naoAparece);
      }
    }

  }

  toggleTodasUnidadeCondominio(conta, refBoleano) {
    if (refBoleano) {
      this.condominioSelecionado.push(conta);
    } else if (!refBoleano) {
      const index = this.condominioSelecionado.indexOf(conta);
      this.condominioSelecionado.splice(index, 1);
    }
    // console.log(this.condominioSelecionado)
  }

  aderirDesaderirUnidade(contaCondominio, idUnidade, idSubdivisao) {
    // console.log('AQUIIIIIII', contaCondominio, idUnidade, idSubdivisao, this.listaCondominios);

    for (let condominio of this.listaCondominios) {
      if (contaCondominio == condominio.contaCondominio) {
        for (let unidade of condominio.unidades) {
          if (idUnidade == unidade.idUnidade)
            unidade.unidadeAderida = !unidade.unidadeAderida;
          // console.log(this.listaCondominios);
        }
      }
    }

  }
  
  aderirDesaderirImovel(contratoLocacao) {

    for (let imovel of this.listaImoveis) {
      if (contratoLocacao == imovel.contrato_Locacao) {
          imovel.aderido = !imovel.aderido;
          // console.log(this.listaImoveis);
      }
    }
  }


  aderirDesaderirTodos() {
    for (let condominio of this.listaCondominios) {
      for (let unidade of condominio.unidades) {
        unidade.unidadeAderida = !this.todasAsUnidadesAceitas;
      }
    }
    this.toggleTodasAsUnidades();
    // console.log(this.listaCondominios);
  }

  toggleTodasAsUnidades() {
    this.todasAsUnidadesAceitas = !this.todasAsUnidadesAceitas
  }

  checkCondominioAderido(conta) {
    let condominioDesmarcado = false;
    for (let condominio of this.listaCondominios) {
      if (conta == condominio.contaCondominio) {
        for (let unidade of condominio.unidades) {
          if (!unidade.unidadeAderida) condominioDesmarcado = true;
        }
      }
    }

    return !condominioDesmarcado;
  }
  /////////////////////////////////////////////////////////////



}
