import { ImagemArquivoMaloteDTO } from "src/app/modules/envio-documento/services/DTO/request/ImagemArquivoMalote.DTO"

export class MinhasSolicitacoesDTO {
  data: Array<Solicitacao>
  paginacao: Paginacao
  concluidos:string 
  emAnalise:string 
  enviadas:string 
  pendentes:string 
  reenviadas:string 
  totalMXM: string
  aguardandoAnalise: string
  destinatarios: any[]
}

export class Solicitacao {
  dataCadastro: string
  descricaoFuncionalidade: string
  descricaoStatus: string
  idPatParceiro: number
  idStatus: number
  instrucaoPreenchimento: string
  naturezaFuncionalidade: number
  nomeCondominio: string;
  contaCondominio: number;
  idFuncionalidade: number;
  carteiraCondominio: string;
  motivoPendencia: string;

  // TODO: Adicionar no back Novos dados inseridos de acordo com o Figma
  solicitante?: string
  assunto?:string
  ultimaAtualizacao?: string
  origemSindico?: boolean
}

export class Paginacao {
  numeroPagina: number
  tamanhoPagina: number
  totalRegistros: number
}

export interface ISolicitacaoDetalhe {
	idSolicitacao: number
	idStatus: number
  descricaoFuncionalidade: string
  idFuncionalidade?:number
	assunto: string
	solicitante: string
	dataCadastro: string
	ultimaAtualizacao: string
	anexos?: ImagemArquivoMaloteDTO[] | null
	historico: IHistoricoDeAcoes[]
  urlPlanilha: string
  formulario?: string
}

export interface IHistoricoDeAcoes {
	dataAbertura: string
  dataConclusao: string
	mensagemStatus: string
	mensagemAdm?: string | null
  mensagemSolicitante?: string | null
  formEnviado?: any | null,
  possuiPendencia?: boolean
}

// TODO: Substitui 'ISolicitacaoDetalhe' na pagina de detalhe após integração com back
export interface IDetalhePAT {
  idSolicitacao:           number;
  idStatus:                number;
  descricaoFuncionalidade: string;
  assunto:                 string;
  solicitante:             string;
  dataCadastro:            string;
  ultimaAtualizacao:       string;
  formulario:              string;
  anexos:                  IAnexo[] | any;
  historico:               IHistorico[];
  origemSindico?: boolean
}

export interface IAnexo {
  // nome:    string;
  // arquivo: string;
  
  // TODO: Mudar para esse formato:
  file:     string;
  Conteudo: string;
  Tipo:     string;
  Nome:     string;
  src:      string;
}

export interface IHistorico {
  data:                string;
  mensagemStatus:      string;
  mensagemAdm:         string;
  mensagemSolicitante: string;
  ehMensagemRetaguarda: boolean;
}

export interface IConstrucaoPayload {
  camposFormulario: any, 
  idFuncionalidade?: any,
  idPatParceiro?: any, 
  condominioSelecionado?: any,
  idStatus?:number
}