import { Component, Input, OnInit } from '@angular/core';
import { StateService } from 'src/app/shared/services/stateService';

@Component({
  selector: 'ds-tooltip-box',
  templateUrl: './tooltip-box.component.html',
  styleUrls: ['./tooltip-box.component.scss'],
})
export class TooltipBoxComponent implements OnInit {
  @Input('p-title') title: string;
  @Input() position: string = "left";
  @Input() mobilePosition: string = "left-bottom";
  @Input() isOpen: boolean;

  constructor(
    public stateService: StateService,
  ) { }

  ngOnInit() {}

  handleMouseOver() {
    if(this.stateService.platformIsDesktop()) {
      this.isOpen = true;
    }
  }

  handleMouseOut() {
    this.isOpen = false;
  }

  handleClick() {
    this.isOpen = !this.isOpen;
  }
}
