<div class="Modal ModalDadosConta MenuDoUsuario" [ngClass]="{ '__is-open': open }">
  <div class="Modal_Close" (click)="close()">
    <i class="fa fa-times"></i>
  </div>

  <div class="Modal_Header">
    Acesse os dados da sua conta
  </div>

  <div class="Modal_Content">
    <div class="User">
      <div class="UserImage">
        <img [src]="stateService.getUserImageSrcAttribute()">
      </div>

      <div class="UserInfo">
        <div class="UserName">
          {{ nomeDoUsuario }}
        </div>

        <div class="UserEmail">
          {{ emailDoUsuario }}
        </div>

        <div class="UserAddPhoto">
          <button (click)="popupChangeUserImage.show()">
            {{
              profilePictureFacade.exists()
               ? 'Alterar foto'
               : 'Adicionar foto'
            }}
          </button>

          <button
            *ngIf="profilePictureFacade.exists()"
            (click)="profilePictureFacade.remove()"
            class="__is-last"
          >
            Remover foto
          </button>
        </div>
      </div>
    </div>

    <app-lista-dados-conta 
      (abreModalAdesao)='this.toggleAdesao.emit()'
      (abreModalAdesaoIPTU)='this.toggleAdesaoIPTU.emit()'
    >
    </app-lista-dados-conta>
  </div>
</div>

<popup-change-user-image></popup-change-user-image>