import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PerfilDoitt } from '../services/PerfilDoitt.service';


@Injectable({
  providedIn: 'root'
})
export class IsDoittGuardGuard implements CanActivateChild  {
  private perfilDoitt =  inject(PerfilDoitt)
  private router = inject(Router)

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const ehPerfilDoitt = this.perfilDoitt.isDoitt$.value 
        || this.perfilDoitt.isDoitter$.value 
        || this.perfilDoitt.isParceiro$.value
      
      if(!ehPerfilDoitt) {
        this.router.navigate(['/home'])
        return false  
      } else {
        return true
      }
  }
  
}
