import { IonSlides, Platform } from '@ionic/angular';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';
import { ImagemArquivoDTO } from 'src/app/shared/services/DTO/Request/ImagemArquivo.DTO';
import { EspacoDTO } from 'src/app/shared/services/DTO/Request/Espaco.DTO';
import { StateService } from '../../services/stateService';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-modal-espaco',
  templateUrl: './modal-espaco.component.html',
  styleUrls: ['./modal-espaco.component.scss'],
  encapsulation: ViewEncapsulation.None, 
})
export class ModalEspacoComponent implements OnInit, OnDestroy {
  @Input() open: boolean;
  @Input() espaco: EspacoDTO;
  @Output() eventClose = new EventEmitter();
  @ViewChild(IonSlides) slides: IonSlides;
  // public nomeEspaco: string;
  // public descricaoEspaco: string;
  // public listaImagens: Array<ImagemArquivoDTO>

  constructor(
    public stateService: StateService,
    private sanitizer: DomSanitizer,
    private modalService: ModalService,
    public platform: Platform,
  ) { }

  ngOnDestroy() {
    this.modalService.removeHasModalClassFromContainerDiv();
  }

  ngOnInit() {
    // console.log('espaco', this.espaco)
    window.scrollTo(0,0)
  }

  sanitizedUrl(src){
    // console.log(src)
    return this.sanitizer.bypassSecurityTrustUrl(src)
  }

  close() {    
    this.eventClose.emit(false);
    this.stateService.exibirFooterHeaderForModal();
  }

  nextSlide() {
    this.slides.slideNext();
  }

  prevSlide() {
    this.slides.slidePrev();
  }

}
