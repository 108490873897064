import { Inject, Injectable } from "@angular/core";
import { Device } from "@capacitor/device";
import { Platform } from "@ionic/angular";
import { BiometryPlugin, BiometryPluginOptions, BiometryPluginToken, EBiometryPluginResult } from "../plugins/Biometry/BiometryPlugin";
import { StateService } from "./stateService";
import { LoginService } from "src/app/modules/login/services/login.service";
import { BehaviorSubject } from "rxjs";
import { HomeService } from "src/app/modules/home/services/home.service";
import { LightBoxesService } from "../components/lightboxes";

@Injectable({ providedIn: 'root'})
export class BiometryService {
  private USER_DENIED_BIOMETRY_STORAGE_KEY = 'hasUserDeniedBiometry';
  private isBiometryModalOpen = new BehaviorSubject<boolean>(false);
  private isBiometryEnabledByUser = new BehaviorSubject<boolean>(false);

  constructor(
    private platform: Platform,
    @Inject(BiometryPluginToken) private biometryPlugin: BiometryPlugin,
    private stateService: StateService,
    private homeService: HomeService,
    private loginService: LoginService,
    private lightboxesService: LightBoxesService,
  ) {}

  public isBiometryModalOpenAsObservable() {
    return this.isBiometryModalOpen.asObservable();
  }

  public isBiometryEnabledByUserAsObservable() {
    return this.isBiometryEnabledByUser.asObservable();
  }

  public async authenticateBiometryToGetPasswordHash(userLogin: string): Promise<string | null> {
    if((await this.isBiometryAvaiable())) {
      try {
        const deviceUID = (await Device.getId()).identifier;
        const hash = await this.loginService.verificaDigitalParaLogin(userLogin, deviceUID).toPromise();

        if(typeof hash === 'string' && hash.length > 0) {
          const options = this.getBiometryPluginOptions();
          const biometryResult = await this.biometryPlugin.authenticate(options);

          if(biometryResult === EBiometryPluginResult.SUCCESS) {
            localStorage.setItem('loginDigital', userLogin);
            return hash;
          }
        }

        return null;
      }
      catch(err) {
        return null;
      }
    }
  }

  async registerFingerprintBiometry() {
    let deviceInfo = await Device.getInfo();
    let idPessoa = this.stateService.getPessoaLogada().Id;
    let uid_autenticacao = (await Device.getId()).identifier;
    let device_model = deviceInfo.model;
    let device_platform = deviceInfo.platform;

    this.homeService.cadastrarDigital(idPessoa, uid_autenticacao, device_model, device_platform).subscribe({
      next: _ => {
        this.lightboxesService.openOkPopup(
          'Digital cadastrada com sucesso!',
          'Sua digital será solicitada a partir do seu próximo login'
        );

        this.isBiometryEnabledByUser.next(true);
      },
      error: error => {
        this.lightboxesService.openOkPopup('Atenção!', error.error.message);
      },
      complete: () => {
        if(this.isBiometryModalOpen.value === true)
          this.isBiometryModalOpen.next(false);
      },
    });
  }

  async removeFingerprintBiometry() {
    const id_pessoa = this.stateService.getPessoaLogada().Id;

    const result = await this.lightboxesService.openYesOrNoPopup(
      'Desabilitar',
      'Tem certeza que deseja remover a sua digital e logar apenas com a sua senha?',
    );

    if(result) {
      this.homeService.deletarDigital(id_pessoa).subscribe({
        next: message => {
          this.lightboxesService.openOkPopup(
            'Autenticação com digital desativada.',
            message,
          );

          this.isBiometryEnabledByUser.next(false);
        },
        error: error => {
          this.lightboxesService.openOkPopup(
            'Atenção',
            error.error.message,
          );
        }
      });
    }
  }

  public denyBiometry() {
    this.isBiometryModalOpen.next(false);
    localStorage.setItem(this.USER_DENIED_BIOMETRY_STORAGE_KEY, 'true');
  }

  public async openBiometryModalIfAvaiable() {
    if(await this.shouldOpenBiometryModal()) {
      this.isBiometryModalOpen.next(true);
    }
  }

  public async shouldOpenBiometryModal(): Promise<boolean> {
    try {
      const login = this.stateService.getPessoaLogada().Login;
  
      return (
        this.isBiometryEnabledByUser.value === false
        && (await this.isBiometryAvaiable())
        && (await this.checkIfBiometryWasntAlreadyRegisteredForThisDeviceInAPI(login))
        && this.checkIfUserHasntDeniedTheBiometryFeature()
      );
    }
    catch(err) {
      return false;
    }
  }

  public async isBiometryAvaiable(): Promise<boolean> {
    return (
      (await this.checkIfPlatformSupportsBiometry())
      && (await this.biometryPlugin.isAvaiable())
    );
  }

  private async checkIfPlatformSupportsBiometry(): Promise<boolean> {
    if(this.platform.is('mobileweb'))
      return false;

    let deviceInfo = await Device.getInfo();

    if (this.platform.is('android')) {
      let model: string = deviceInfo.model;
      return model != 'LG-H870I' && model != 'SM-A105M';
    }

    return this.platform.is('ios') || this.platform.is('iphone');
  }

  private async checkIfBiometryWasntAlreadyRegisteredForThisDeviceInAPI(userLogin: string): Promise<boolean> {
    if(!userLogin || typeof userLogin !== 'string')
      return false;

    if(userLogin.trim().length === 0)
      return false;

    try {
      const deviceUID = (await Device.getId()).identifier
      const APIResponse = await this.loginService.verificaDigitalParaLogin(userLogin, deviceUID).toPromise();
  
      const alreadyRegistered: boolean = typeof APIResponse === 'string' && APIResponse.length > 0;
  
      this.isBiometryEnabledByUser.next(alreadyRegistered);
  
      return !alreadyRegistered;
    }
    catch(err) {
      return false;
    }
  }

  private checkIfUserHasntDeniedTheBiometryFeature() {
    const value = localStorage.getItem(this.USER_DENIED_BIOMETRY_STORAGE_KEY);

    return value !== 'true';
  }

  private getBiometryPluginOptions(): BiometryPluginOptions {
    if(this.platform.is('android')) {
      return {
        title: 'Olá',
        subtitle: 'Autenticação por digital',
        description: 'Por favor, insira seu dedo sobre o leitor de digital para realizar a autenticação',
        maxAttempts: 3,
        negativeButtonText: 'Cancelar',
      };
    }
    else {
      return {
        title: 'Olá',
        subtitle: 'Autenticação por biometria',
        description: 'Por favor, autentique-se',
        maxAttempts: 3,
        negativeButtonText: 'Cancelar',
      }
    }
  }
}