import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, EventEmitter, Output, AfterViewChecked, OnDestroy, Input } from '@angular/core';
import { Location } from "@angular/common";
import { StateService } from '../../services/stateService';
import { AlertaService } from '../../services/alerta.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AlertaDTO } from '../../services/DTO/response/AlertaDTO';
import { CondominioDTO } from '../../services/DTO/response/Condominio.DTO';
import { CondominioService } from '../../services/condominio.service';
import { GerenteContaDTO } from '../../services/DTO/response/GerenteContaDTO';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Texts } from './texts';
import { SessionStorageService } from '../../services/session-storage.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { Validations } from '../../utils/validations';
import { LightBoxesService } from '../lightboxes';
import { ConstantsService } from '../../services/constants.service';
import { LoginService } from 'src/app/modules/login/services/login.service';

import CustomerService from '../../services/customer.service';
import { Subject, Subscription } from 'rxjs';
import { PessoaLogadaDTO } from '../../services/DTO/Request/PessoaLogada.DTO';
import { PerfilDoitt } from '../../services/PerfilDoitt.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public nomePessoa: string;
  public caminhoLogoHeader: string;
  // public totalAlertas: number;
  public alertas: AlertaDTO[];
  public mostrarAlerta: boolean = false;
  public mostrarCentralAtendimento: boolean;
  public mostrarAtalhosCondominio: boolean;
  public condominioLogado: CondominioDTO;
  public gerenteConta: GerenteContaDTO;
  public isCentralAtendimentoLoading: boolean;
  public ehGestaoTotal: boolean = (sessionStorage.getItem('ehGestaoTotal') == "true");
  public customer = this.customerService.getCustomer();
  public customer$ = this.customerService.getCustomerObservable();
  public rotaAtual = this.router.url;
  public isOpen = false;
  public isMobileHeader: boolean = false;

  public possuiPerfilLocatario = this.stateService.possuiPerfilLocatario();
  public possuiPerfilSindico: boolean = false;
  public possuiPerfilProprietario = this.stateService.possuiPerfilProprietario();
  public possuiPerfilGestorSindico = this.stateService.possuiPerfilGestor() || this.stateService.possuiPerfilSindico()
  public getTemaAcessibilidade = this.stateService.getTemaAcessibilidade();

  public formFaleComApsa: UntypedFormGroup;
  public textsCustom = Texts;
  public isSubmitted: boolean = false;
  public subscriptions: Subscription[] = [];
  public listAssuntos = [];
  public perfilLogadoEhGestorOuSindico = this.stateService.possuiPerfilGestor() || this.stateService.possuiPerfilSindico()
  
  public ehPerfilDoitter = this.stateService.getPessoaLogada().perfis.filter(perfil => perfil == 102 || perfil == 103 || perfil == 104)

  public ehAdmDoitt = this.stateService.getPessoaLogada().perfis.includes(102);
  public ehSindicoConvencional = this.stateService.getPessoaLogada().perfis.includes(3);
  public admLogada


  private admLogada$ = this.customerService.getCustomerObservable()
  private unsubscribe$ = new Subject<boolean>()

  

  @Output() eventAbriModalConta = new EventEmitter();
  @Output() onOpenModalAvaliacao = new EventEmitter();
  @Input() totalAlertas;
  @Input() listNotifications;
  @Input() trocaDeCondominio;

  constructor(private location: Location,
    private loginService: LoginService,
    public stateService: StateService,
    private router: Router,
    public platform: Platform,
    private alertaService: AlertaService,
    private condominioService: CondominioService,
    private fb: UntypedFormBuilder,
    private sessionStorageService: SessionStorageService,
    private headerService: HeaderService,
    private lightBoxesService: LightBoxesService,
    public constant: ConstantsService,
    private activedRoute: ActivatedRoute,
    private customerService: CustomerService,
    public perfilDoitt: PerfilDoitt
  ) {
    activedRoute.params.subscribe(val => {
      this.ngOnInit();
      if (this.ehGestaoTotal) {
          this.carregarQuantidadeAlertas();
          // this.bindEventAlerta();
      }
    });
    this.alertas = [];
    // this.mostrarAlerta = false;
    this.mostrarCentralAtendimento = (this.constant.condominioImovelAtivo) ? true : false;
    this.isCentralAtendimentoLoading = false;
    this.mostrarAtalhosCondominio = (this.constant.condominioImovelAtivo) ? true : false;
  }

  ngOnInit() {
    this.isMobileHeader = this.stateService.platformIsDeviceMobile() && this.stateService.platformIsIos() && this.minHeightIos();
    this.setHeaderLogo();

    const subscription = this.customer$.subscribe(customer => {
      // console.log('aqui');
      this.setHeaderLogo(customer);
    });

    this.subscriptions.push(subscription);
    this.possuiPerfilSindico = this.stateService.possuiPerfilSindico_();
    this.popularNomeUsuario();
    this.bindEventCentralDeAtendimento();
    this.bindEventAtalhosFuncionalidades();
    this.inicializarForm();
    this.validarOpenModalDadosMobile();
    this.stateService.closeAllComponentsHeader('close-all');

    this.admLogada$.pipe(takeUntil(this.unsubscribe$)).subscribe(adm => {
      this.admLogada = adm
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe);
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }


  validarOpenModalDadosMobile() {
    if (this.router.url === '/menu')
      this.eventAbriModalConta.emit(0);
  }

  ngAfterViewChecked() {
    const CONDOMINIO_LOGADO = this.stateService.getCondominioLogado();

    if (CONDOMINIO_LOGADO)
      this.ehGestaoTotal = CONDOMINIO_LOGADO.ehGestaoTotal;
  }

  abrirModalPessoa() {
    this.trocaDeCondominio++
    if (window.innerWidth < 800) {
      this.eventAbriModalConta.emit(1);
    }
    else {
      this.stateService.openCloseComponent('.Header_Atalho_Perfil');
    }
  }


  // fecharModalPessoa() {
  //   console.log("veioo");
  //   this.eventAbriModalConta.emit(0);
  // }

  IconIsOpen(value){
    this.isOpen = value;
  }
  validaIsOpen(){
    this.isOpen = !this.isOpen;
  }

  abrirAtalho() {
    // console.log("rrr", this.stateService.possuiPerfilSindico(), this.stateService.possuiPerfilLocatario(), this.stateService.possuiPerfilProprietario());
    this.possuiPerfilSindico = this.stateService.possuiPerfilSindico();
    this.possuiPerfilLocatario = this.stateService.possuiPerfilLocatario();
    this.possuiPerfilProprietario = this.stateService.possuiPerfilProprietario();
    this.possuiPerfilGestorSindico = this.stateService.possuiPerfilGestor() || this.stateService.possuiPerfilSindico()
    this.stateService.openCloseComponent('.Header_Shortcut');
  }

  abrirCentralAtendimento() {
    this.stateService.openCloseComponent('.Header_Atalho_CentralAtendimento');
    this.isCentralAtendimentoLoading = true;
  }

  habilitarIconeModalPessoa() {
    if (!this.stateService.platformIsDeviceMobile()) return true; // nunca deve desabilitar no desktop

    if (this.router.url === '/fale-conosco' || this.router.url === '/usuario/edicao-dados') return false;
    else return true;
  }

  handleCentralAtendimentoLoaded() {
    this.isCentralAtendimentoLoading = false;
  }

  backButton() {
    this.location.back();
  }

  bindEventAlerta() {
    CondominioService.trocaCondominio.subscribe(condominio => {
      this.mostrarAlerta = (condominio != null);
      this.carregarQuantidadeAlertas();
    })
  }

  bindEventCentralDeAtendimento() {
    CondominioService.trocaCondominio.subscribe(condominio => {
      //this.mostrarCentralAtendimento = (condominio != null);
    });
  }

  bindEventAtalhosFuncionalidades() {
    CondominioService.trocaCondominio.subscribe(condominio => {
      this.mostrarAtalhosCondominio = (condominio != null);
    });
  }

  carregarAlertas() {
    this.stateService.openCloseComponent('.Header_Atalho_Notificacoes');
    this.mostrarAlerta = true;
  }

  carregarQuantidadeAlertas() {
    this.mostrarAlerta = false;
    this.condominioLogado = this.stateService.getCondominioLogado();
    if (this.condominioLogado == null) return;
    if (this.condominioLogado.ehGestaoTotal == false) return;

    this.totalAlertas = null;
    var cpfPessoaLogada = (this.stateService.getPessoaLogada()) ? this.stateService.getPessoaLogada().CpfCnpj : null;
    var profileCode = "1"; //TODO: Buscar essa informação do CondominioLogado.Profiles[0].profileCodeGT (ainda não existe)
    var condominioAgencia = this.condominioLogado.Agencia;
    var condominioConta = this.condominioLogado.ContaEmpresa;

    let origem: string;
      if(this.platform.is('desktop'))
        origem = '1';
      if(this.platform.is('mobile'))
        origem = '2';      

    this.alertaService.getTotalAlertas(origem, cpfPessoaLogada, profileCode, condominioAgencia, condominioConta).subscribe((response) => {
      this.totalAlertas = response;
      this.mostrarAlerta = true;
    });
  }

  newAlerts() {
    return this.alertas.filter(item => item.NewAlert);
  }

  oldAlerts() {
    return this.alertas.filter(item => !item.NewAlert);
  }

  inicializarForm() {
    // if (this.sessionStorageService.get('condominioLogado')) {
    // }

    this.formFaleComApsa = this.fb.group({
      tipoMensagem: ['1', Validators.required],
      mensagem: ['', Validators.required],
      idAssunto: ['', Validators.required],
      contaEmpresa: this.sessionStorageService.get('condominioLogado') ? [JSON.parse(this.sessionStorageService.get('condominioLogado')).contaEmpresa] : undefined,
      idPessoa: [JSON.parse(this.sessionStorageService.get('pessoaLogada')).id],
    });
  }

  createFormProprietario() {

  }

  enviarMensagem() {
    this.isSubmitted = true;

    this.formFaleComApsa.value.contaEmpresa = (this.sessionStorageService.get('condominioLogado')) ? JSON.parse(this.sessionStorageService.get('condominioLogado')).contaEmpresa : null;

    if (this.formFaleComApsa.invalid) {
      Validations.markFormGroupTouched(this.formFaleComApsa);
      return;
    }
    const payload = {
      ...this.formFaleComApsa.value,
      idOrigemDoitt: +this.admLogada?.id
    }
    this.headerService.enviarMensagem(payload).subscribe(result => {

      this.stateService.openCloseComponent('.Header_Atalho_CentralAtendimento')

      this.lightBoxesService.open(
        'custom-generico',
        { title: '', text: "Enviado com sucesso!" },
        5000
      );
    },
      err => {

        this.lightBoxesService.open(
          'custom-generico',
          { title: '', text: "Não foi possível enviar a mensagem" },
          5000
        );
      });

  }

  popularNomeUsuario() {
    var obj = JSON.parse(sessionStorage.getItem('pessoaLogada'));
    if (obj != 'undefined' && obj != undefined) {
      this.nomePessoa = obj.nome;
      return;
    }

    sessionStorage.clear();
    // console.log("VEIO LOGIN EXPIRADO 3")
    this.router.navigate(['/'], { queryParams: { showMsg: true, title: 'Acesso expirado', mensagem: 'Faça o login novamente' } });
  }

  redirecionarPara(pagina: string) {
    this.router.navigate([pagina]);
    return;
    //window.location.href = pagina;
  }

  setHeaderLogo(customer?: any) {
    if (this.router.url === '/home') {
      this.setThemedLogoHeaderImage(customer);
      return;
    }

    const condLogadado = this.stateService.getCondominioLogado();

    if(condLogadado && condLogadado.prime === 1)
      this.setThemedLogoHeaderPrimeImage(customer);
    else
      this.setThemedLogoHeaderImage(customer);
  }

  sairSimOuNao() {
    this.lightBoxesService.open(
      'custom-mural-cadastrado',
      { title: 'Sair', text: "Tem certeza que quer sair?" },
      0
    ).then(event => {
      if (event) {
        this.sair();
      }
    });
  }

  sair() {
    sessionStorage.clear();
    this.customerService.forceDoittConfigIfMobile();

    this.constant.condominioImovelAtivo = null;
    this.router.navigate(['/login', { 'exitFromLogout': true }]);
    return;
  }

  get formControls() {
    return this.formFaleComApsa.controls;
  }

  setClassError(input) {
    // return {
    //   '__is-error': this.isSubmitted && this.formControls[input].invalid
    // };
  }

  getErrorMessage(input) {
    if (this.formControls[input].errors) {
      return this.textsCustom.form.error[input][
        Object.keys(this.formControls[input].errors)[0]
      ];
    }
  }

  enviarEmailConfirmacao(){
    this.loginService.enviarEmailConfirmacao().subscribe(item =>{
      this.lightBoxesService.open(
        'custom-generico',
        { title: 'E-mail enviado', text: item },
        50000
      );
    }, err=>{
      this.lightBoxesService.open(
        'custom-generico',
        { title: 'E-mail enviado', text: err.error.message },
        50000
      );
    });
  }

  possuiPerfilDoitt(idFuncionalidade): boolean {
    const pessoa: PessoaLogadaDTO = this.stateService.getPessoaLogada();
    return pessoa.perfis.includes(idFuncionalidade);
  }

  private setThemedLogoHeaderImage(customer?: any) {
    if(this.stateService.getTemaAcessibilidade() != 'Theme_Dark')
      this.caminhoLogoHeader = customer ? customer.logos.logoPequena : this.customer.logos.logoPequena;
    else
      this.caminhoLogoHeader = customer ? customer.logos.logoPequena : this.customer.logos.logoPequenaContraste;
  }

  private setThemedLogoHeaderPrimeImage(customer?: any) {
    if(this.stateService.getTemaAcessibilidade() != 'Theme_Dark')
      this.caminhoLogoHeader = customer ? customer.logos.logoPequenaPrime : this.customer.logos.logoPequenaPrime;
    else
      this.caminhoLogoHeader = customer ? customer.logos.logoPequenaPrime : this.customer.logos.logoPequenaPrimeContraste;
  }

  abrirModalAvaliacao() {
   this.onOpenModalAvaliacao.emit()
  }
 
  minHeightIos(): boolean {
    return window.innerHeight > 813
  }
}
