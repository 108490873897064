export const Texts = {
  form: {
    error: {
      tipoMensagem: {
        required: 'Campo obrigatório'
      },
      mensagem: {
        required: 'Campo obrigatório'
      },
      idAssunto: {
        required: 'Campo obrigatório'
      },
      contaEmpresa: {
        required: 'Campo obrigatório'
      },
      idPessoa: {
        required: 'Campo obrigatório'
      },

    }
  }
};