import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import CustomerService from '../../services/customer.service';


import { StateService } from '../../services/stateService';

@Component({
  selector: 'adesao-de-boleto-digital',
  templateUrl: './adesao-de-boleto-digital.component.html',
  styleUrls: ['./adesao-de-boleto-digital.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdesaoDeBoletoDigitalComponent implements OnInit {

  @Input() listaCondominios;
  @Input() listaImoveis;
  @Output() close = new EventEmitter;
  public customer$ = this.customerService.getCustomerObservable();

  constructor(
    public stateService: StateService,
    private customerService: CustomerService,
  ) {}

  ngOnInit() {
    // console.log('veio adesao');
    
    //this.fetchData();
  }

  // fetchData() {
  //   this.fetchCondominios();
  // }

  // fetchCondominios() {
  //   this.service.getCondominios().subscribe(data => {
  //     this.condominios = data;
  //   });
  // }

  get nome(): string {
    var consulta = this.stateService.getPessoaLogada();
    if (consulta) {
      return consulta.Nome;
    }
  }

  closeModal() {
    this.close.emit();
  }
}
