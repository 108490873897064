import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public modals: Object = {};
  private modalDataSubject: BehaviorSubject<any> = new BehaviorSubject(null)
  public modalData$ = this.modalDataSubject.asObservable()
  private enviarFormSubject = new Subject<boolean>()
  public enviarForm$: Observable<boolean> = this.enviarFormSubject.asObservable()
  private modalHistoricoMensagens = new BehaviorSubject(null)

  constructor() { }

  add(modalName: string, isOpen: boolean = false) {
    this.modals[modalName] = isOpen;
  }

  open(modalName: string) {
    if(!this.modals[modalName]) {
      this.modals[modalName] = true;
    }
  }

  close(modalName: string) {
    if(this.modals[modalName]) {
      this.modals[modalName] = false;
    }
  }

  isOpen(modalName: string) {
    return this.modals[modalName];
  }

  removeHasModalClassFromContainerDiv() {
    const containers = document.querySelectorAll('.Container');

    containers.forEach(container => container.classList.remove('__has-modal'));
  }

  openModal(data: any) {
    this.modalDataSubject.next(data)
  }

  closeModal() {
    this.modalDataSubject.next(null)
  }

  enviarForm() {
    this.enviarFormSubject.next(true)
  }

  setHistoricoMensagens(mensagens: any) {
    this.modalHistoricoMensagens.next(mensagens);
  }

  getHistoricoMensagens$() {
    return this.modalHistoricoMensagens.asObservable();
  }
}
