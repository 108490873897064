import { PerfilDTO } from './Perfil.DTO';
import { FuncionalidadeDTO } from './Funcionalidade.DTO';

export class OptinsCondominio {
    podeVisualizarRelatAcoes: boolean;
}

export class CondominioDTO {
    carteira: string;
    agencia: string;
    contaEmpresa: string;
    conta: string;
    nome: string;
    cnpj: string;
    prime: number;
    ehGestaoTotal: boolean;
    ehContaPool: boolean;
    exibeCartao: boolean;
    nomeGerenteConta: string;
    perfis: Array<PerfilDTO>;
    perfisIds: number[];
    funcionalidades: Array<FuncionalidadeDTO>;
    funcionalidadesIds: number[];
    uf:string;
    optins: OptinsCondominio;

    //Mantendo compatibilidade com o objeto de CondominioLogadoDTO usado no GT
    get Carteira() { return this.carteira };
    set Carteira(value: string) { this.carteira = value; }
    
    get Agencia() { return this.agencia };
    set Agencia(value: string) { this.agencia = value; }

    get ContaEmpresa() { return this.contaEmpresa };
    set ContaEmpresa(value: string) { this.contaEmpresa = value };

    get Conta() { return this.conta };
    set Conta(value: string) { this.conta = value; };

    get Cnpj() { return this.cnpj };
    set Cnpj(value: string) { this.cnpj = value; };

    get Nome() { return this.nome };
    set Nome(value: string) { this.nome = value; };

    get Prime(): number { return (this.prime === 1) ? 1 : 0 };
    set Prime(value: number) { this.prime = value; };

    get Perfis(): Array<PerfilDTO> { return this.perfis };
    set Perfis(value: Array<PerfilDTO>) { this.perfis = value; };

    constructor(nome: string, agencia: string, conta: string, contaEmpresa: string, cnpj: string, prime: number, gestaoTotal: boolean, contaPool: boolean, exibeCartao: boolean, perfis: number[], funcionalidades: number[], carteira: string, uf: string, optins: OptinsCondominio) {
        this.nome = nome;
        this.agencia = agencia;
        this.conta = conta;
        this.contaEmpresa = contaEmpresa;
        this.cnpj = cnpj;
        this.prime = prime;
        this.ehGestaoTotal = gestaoTotal;
        this.exibeCartao = exibeCartao;
        this.ehContaPool = contaPool;
        this.perfisIds = perfis;
        this.funcionalidadesIds = funcionalidades;
        this.carteira = carteira;
        this.uf = uf
        this.optins = optins;
    }
}
