import { AfterContentInit, AfterViewInit, Component, DoCheck, OnDestroy, OnInit } from '@angular/core';


import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';

//service
import { OnsignalService } from './shared/services/onsignal.service';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from './shared/services/analytics.service';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { StateService } from './shared/services/stateService';
import { LightBoxesService } from './shared/components/lightboxes';
import { Title } from '@angular/platform-browser';
import CustomerService from './shared/services/customer.service';
import { CUSTOMERS } from 'src/customers';
import { Observable, Subscription } from 'rxjs';
import { PlatformService } from './shared/services/platform.service';
import { Meta } from '@angular/platform-browser';
import { log } from 'console';


declare function ZenviaChat(id: string): void;
type Customer = typeof CUSTOMERS.Apsa;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  
})
export class AppComponent implements OnDestroy, OnInit {
  public customer$ = new Observable<Customer>();
  public subscriptions: Subscription[] = [];
  public environment = environment;
  public isLocalhostDoitt!: boolean;

  constructor(
    private lightboxService: LightBoxesService,
    public platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private onsignalService: OnsignalService,
    private oneSignal: OneSignal,
    private analytics: AnalyticsService,
    private acessibilidade: MobileAccessibility,
    public stateService: StateService,
    private titleService: Title,
    private customerService: CustomerService,
    private platformService: PlatformService,
    private meta: Meta,
  ) {
    this.setDoittCustomer();
    this.initializeApp();
    this.setAndroidBackButtonBehavior();
    this.platformService.init();
  }
  ngOnInit(): void {
    this.isLocalhostDoitt = window.location.hostname === 'doitt.hmg.marlin.com.br' || window.location.hostname === 'localhost';
  };
  
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private setAndroidBackButtonBehavior(): void {
    if (this.platform.is('android')) {
      this.platform.backButton.subscribe(() => {
        if (window.location.pathname == "/login" || window.location.pathname == "/")
          this.lightboxService.open(
            'custom-mural-cadastrado', {
            title: 'Sair',
            text: 'Deseja sair do aplicativo?'
          }, 0
          ).then(event => {
            if (event) {
              navigator['app'].exitApp()
            }
          })

        else
          navigator['app'].backButton();
      });
    }
  }

  initializeApp() {
    this.platform.ready().then((data) => {
      this.configureMobileStatusBar();

      this.splashScreen.hide();

      if (environment.production) {
        if(this.stateService.platformIsDevice()) {
          this.acessibilidade.usePreferredTextZoom(false);
          this.onsignalService.initMobile();
        }
        else {
          this.onsignalService.init(); // desktop
        }
      };

      // if (this.platform.is('desktop')) {
      //   if (environment.production) {
      //     this.onsignalService.init();
      //   }
      // } else {
      //   if (environment.production) {
      //   this.onsignalService.initMobile()
      //   };

        // console.log("TESTEEE", window['plugins'])
        // if (this.platform.is('ios') && window['plugins'] && window['plugins'].OneSignal) {
        //   window['plugins'].OneSignal.getPermissionSubscriptionState(function (status) {
        //     if (status.permissionStatus.hasPrompted == false) {
        //       this.oneSignal.addTrigger("prompt_ios", "true");
        //       window['plugins'].OneSignal.addTrigger("prompt_ios", "true");
        //     }
        //   });
        // }

        // if (this.stateService.platformIsDeviceMobile())
        // this.acessibilidade.usePreferredTextZoom(false);

        // this.ga.startTrackerWithId(environment.config.googleStartTrackeId)
        //   .then((teste) => {

        //     console.log(teste)

        //     this.ga.trackView('Vamos nessa', 'teste');

        //   }).catch(e => console.log('Error starting GoogleAnalytics == ' + e));

        //this.analytics.init();
        // this.analytics.sendPageview('aplicativo', 'leo');
      //}


    });
  }

  // TODO: Retirar e colocar em um serviço
  // setupPush() {
  //   // I recommend to put these into your environment.ts
  //   this.oneSignal.startInit(environment.config.onesignalId, environment.config.googleProjectId);

  //   this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
  //   // this.oneSignal.setExternalUserId('3240f2f4-6805-4107-a1b4-42fae3edd768');
  //   this.oneSignal.sendTags({ user_id: 'ee5d148a-26e9-4beb-8d4c-b4a8d7ff56d8', device: this.platform.is('ios') ? 'ios' : (this.platform.is('android')) ? 'android' : 'desktop' })

  //   // Notifcation was received in general
  //   this.oneSignal.handleNotificationReceived().subscribe(data => {
  //     console.log(data)
  //   });

  //   // Notification was really clicked/opened
  //   this.oneSignal.handleNotificationOpened().subscribe(data => {
  //     // Just a note that the data is a different place here!
  //     let additionalData = data.notification.payload.additionalData;

  //   });

  //   this.oneSignal.endInit();
  // }

  startZenviaWebChatOnDesktop(customer: Customer) {
    let isMobile: boolean;
    
    if (screen.width < 640 || screen.height < 480) isMobile = true
    if (screen.width < 1024 || screen.height < 768) isMobile = true

    if(!isMobile && customer.webchat.exibir) {
      new ZenviaChat(customer.webchat.zenviaID)
        .embedded(customer.webchat.embedded)
        .message(customer.webchat.message)
        .build();
    }
  }

  private setDoittCustomer() {
    this.customerService.initialize();
    this.customer$ = this.customerService.getCustomerObservable();

    const subscriptionWebchat = this.customer$.subscribe(customer => {
      if(customer.webchat.exibir) {
        this.removeZenviaWebChat();
        this.startZenviaWebChatOnDesktop(customer);
      }
      else
        this.removeZenviaWebChat();
    });

    this.subscriptions.push(subscriptionWebchat);
    
  }

  private removeZenviaWebChat() {
    const chatElement = document.querySelectorAll('.znv-chat') || [];

    if(chatElement.length > 0)
      chatElement.forEach(d => d.remove());
  }

  private configureMobileStatusBar() {
    const subscription = this.customer$.subscribe(customer => {
      if(customer.appStatusBar.blackText)
          this.statusBar.styleDefault();
        else
          this.statusBar.styleLightContent();
  
        this.statusBar.backgroundColorByHexString(customer.appStatusBar.backgroundColor);
    });

    this.subscriptions.push(subscription);
  }
}
