<div class="Coitainer__modal__resumo " *ngIf="true">
	<header *ngIf="!resumoFormulario.formEnviado; else headerFormularioEnviado">
		<div class="Content__Header">
			<i class="icon-list-check-solid"></i>
			
			<p class="Content__Title">
        Revise as informações fornecidas e confirme seu envio.
      </p>
		</div>
	</header>
	<main>
		<div class="Content__Info">
				<h2>
					Receita a cadastrar
				</h2>
				<p>{{resumoFormulario.nomeSolicitacao}}</p>
			</div>
		
		<div class="Content__Info">
				<h2>
					Nomenclatura da receita
				</h2>
				<p>{{resumoFormulario.receita.nomenclatura}}</p>
			</div>
		
		<div class="Content__Info">
			<h2>
				Subconta para o cadastro 
			</h2>
			<p>{{resumoFormulario.subConta.Descricao}}</p>
		</div>

		<div class="Conteiner__Valor">
			<div class="Content__Info">
				<h2>Valor total da arrecadação</h2>
				<p>R$ {{resumoFormulario.arrecadacao}}</p>
			</div>
				<div class="Content__Info">
				<h2>No. de parcelas</h2>
				<p>{{resumoFormulario.parcelas}}</p>
			</div>
				<div class="Content__Info">
				<h2>Início</h2>
				<p>{{resumoFormulario.inicioParcela | date: "MMMM 'de' yyyy"}}</p>
			</div>
			<div class="Content__Info">
				<h2>Fim</h2>
				<p>{{resumoFormulario.fimParcela}}</p>
			</div>
		</div>
		
		<div class="Content__Info">
			<h2>Forma de pagamento</h2>
			<p>{{resumoFormulario.formaDePagamento}}</p>
		</div>
		
		<div class="Content__Info">
			<h2>Vencimento original</h2>
			<p>{{resumoFormulario.vencimentoOriginal | date: "dd/MM/yyyy"}}</p>
		</div>
		
		<div class="Content__Info" *ngIf="resumoFormulario.detalhes">
			<h2>Detalhes sobre o cadastro</h2>
			<p>{{resumoFormulario.detalhes}}</p>
		</div>
		
		<div class="Content__Info">
			<h2>Envio automático de circular</h2>
			<p>{{resumoFormulario.envioCircularAutomatico ? 'ativado' : 'desativado'}}</p>
		</div>
		
		<div class="Content__Info" *ngIf="resumoFormulario?.anexos?.length">
			<h2>Anexos</h2>
			<ng-container *ngFor="let anexo of resumoFormulario.anexos">
				<a (click)="service.downloadFile(anexo.Conteudo, anexo.Nome)">
					<i class="fas fa-file-pdf"></i>
					{{anexo.Nome}}
				</a>
			</ng-container>
				<!-- <a href="#">
					<i class="fas fa-file-pdf"></i>
				Template_circular.pdf
			</a> -->
		</div>
		
		<div class="Alert__info">
			<h2>Atenção!</h2>
			<p>Após a confirmação do envio, valores e informações referentes a este cadastro serão processados e analisados por nossa equipe e enviados a você posteriormente. Fique atento às suas notificações!</p>
		</div>
		
		<div class="Actions">
			<ng-container *ngIf="resumoFormulario.formEnviado; then fechar else acoesBtn">
			</ng-container>
			<ng-template #acoesBtn>
				<ds-button
					type="Outline"
					(click)="closeModal()"
					[full]="true"
				>
					Cancelar
				</ds-button>
				<ds-button
					rightIcon="fas fa-chevron-right"
					[full]="true"
					(click)="enviar()"
				>
					Enviar
				</ds-button>
			</ng-template>

			<ng-template #fechar>
				<ds-button
					type="Outline"
					(click)="closeModal()"
					[full]="true"
					class="Action__Fechar"
				>
					Fechar
				</ds-button>
			</ng-template>
			
		</div>
	</main>
</div>

<div class="Overlay" (click)="closeModal()"></div>

<ng-template #headerFormularioEnviado>
	<header>
		<div class="Content__Header Resumo FormEnviado">
			<div class="Content__Header__Title">
				<i class="icon-list-check-solid"></i>
				<div class="Title">
					<p>Detalhes da solicitação</p>
					<h2>{{resumoFormulario.nomeSolicitacao}}</h2>
				</div>
			</div>
			
			<p class="Content__Header__Id">
        ID #{{resumoFormulario.idSolicitacao}}
      </p>
		</div>
	</header>
</ng-template>