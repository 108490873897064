import { Component, OnInit } from '@angular/core';
import { Base64 } from '@ionic-native/base64/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ProfilePictureFacade } from '../../facades/profile-picture.facade';
import { StateService } from '../../services/stateService';

@Component({
  selector: 'popup-change-user-image',
  templateUrl: './popup-change-user-image.component.html',
  styleUrls: ['./popup-change-user-image.component.scss'],
})
export class PopupChangeUserImageComponent {

  public isOpen = false;

  constructor(
    private camera: Camera,
    public stateService: StateService,
    private base64: Base64,
    private profilePictureFacade: ProfilePictureFacade,

  ) { }

  show() {
    this.isOpen = true;
  }

  hide() {
    this.isOpen = false;
  }

  async takePhoto() {
    await this.openCamera(1);
    this.hide();
  }

  async uploadPhotoFromDeviceGalery() {
    await this.openCamera(2);
    this.hide();
  }

  private openCamera(type: number): Promise<void> {
    return new Promise((resolve, _) => {
      const options: CameraOptions = {
        quality: 50,
        cameraDirection: 1,
        destinationType: this.stateService.platformIsAndroid() && type == 1 ? this.camera.DestinationType.FILE_URI : this.camera.DestinationType.DATA_URL,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: (type == 1) ? this.camera.MediaType.PICTURE : this.camera.PictureSourceType.PHOTOLIBRARY,
        sourceType: (type == 1) ? 1 : 0,
        allowEdit: true,
      }
  
      this.camera.getPicture(options).then((imageData) => {
        if (this.stateService.platformIsIos() || (imageData && this.stateService.platformIsAndroid() && type == 2)) {
          this.profilePictureFacade.change('gallery.jpeg', imageData);
          resolve();
        }
  
        if (((this.stateService.platformIsAndroid() || !this.stateService.platformIsIos()) && type == 1 && imageData)) {
          this.base64.encodeFile(imageData).then((base64File) => {
            this.profilePictureFacade.change('camera.jpeg', base64File.toString().split(',')[1]);
            resolve();
          });
        }
      });
    });
  }
}
