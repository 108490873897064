export interface IDoittDashboard {
  visaoGeral: IVisaoGeral;
  evolucaoDeGanhos: IEvolucaoDeGanhos[];
  parceiros: IParceiro[];
  ultimasAtualizacoes: IIndicacaoDoitt[];
}

export interface IVisaoGeral {
  valorTotalGanho?: string;
  cashbackDoitt?: string;
  totalDeIndicacoes: number;
  indicacoesRealizadas: number;
  indicacoesEmAndamento: number;
  indicacoesFechadadas: number;
  indicacoesRecusadas: number;
  negociosFechados?: number;
}

export interface IResumoCashback {
  totalIndicacoes: number;
  totalRealizadas: number;
  totalEmAndamento: number;
  totalFechadas: number;
  totalRecusadas: number;
}

export interface IResumoValoresCashback {
  valorTotalNegociosFechados: number;
  qtdTotalNegociosFechados: number;
  valorTotalCashback: number;
}

export interface IEvolucaoDeGanhos {
  meses: string[];
  ganhosPorMes: number[];
  label: string;
  tipo: 'NEGOCIOS_FECHADOS' | 'VALOR_TOTAL' | 'VALOR_TOTAL_CASHBACK';
}

export enum ETiposDeEvolucaoDeGanhos {
  NEGOCIOS_FECHADOS = 'NEGOCIOS_FECHADOS',
  VALOR_TOTAL = 'VALOR_TOTAL',
  VALOR_TOTAL_CASHBACK = 'VALOR_TOTAL_CASHBACK',
}

export interface IIndicacaoDoitt {
  codigo: string;
  data: string;
  servico: string;
  parceiro: string;
  status: string;
  idStatus: number;
  valor: string;
  cashback: string;
}

export interface IParceiro {
  image: string;
  url: string;
  negociosFechados: number;
  valorTotal: number;
}

export interface IParceiroDoitt {
  idParceiro: number;
  cnpj: number;
  razaoSocial: string;
  nomeParceiro: string;
  nomeParceiroAbreviado?: string; // adicionado pelo front
  temNomeLongo?: boolean; // Adicionado pelo front
  descricaoParceiro: string;
  emailParceiro: string;
  telefoneParceiro: string;
  enderecoParceiro: string;
  imgParceiro: ImgParceiro;
  ativo: boolean;
  urlParceiro: string;
  categorias: number[];
  detalhesCategorias: DetalhesCategoria[];
}

export interface ImgParceiro {
  nomeArquivo: string;
}

export interface DetalhesCategoria {
  idCategoria: number;
  descricao: string;
  cores?: any;
  templateFormulario?: any;
}

export interface Categoria {
  idCategoria: number;
  descricao: string;
  cores?: any;
  templateFormulario?: any;
}

export interface Parceiro {
  idParceiro: number;
  cnpj: number;
  razaoSocial?: any;
  nomeParceiro: string;
  descricaoParceiro: string;
  emailParceiro?: any;
  telefoneParceiro?: any;
  enderecoParceiro?: any;
  imgParceiro?: any;
  ativo: boolean;
  urlParceiro?: any;
  detalhesCategorias: any[];
}

export interface Historico {
  idPessoa: number;
  dataAlteracao: Date;
  status: string;
}

export interface IUltimaAtualizacao {
  idIndicacao: number;
  idPessoa: number;
  dataIndicacao: Date;
  categoria: Categoria;
  parceiro: Parceiro;
  valor?: any;
  percentualCashback?: any;
  status: string;
  historico: Historico[];
}

export enum EDoittTipoDeRankingDoitt {
  NEGOCIOS_FECHADOS = 'NEGOCIOS_FECHADOS',
  VALOR_TOTAL = 'VALOR_TOTAL',
}

export enum ETipoIndicacaoDoitt {
  TODAS = 0,
  LOCACAO = 1,
  COMPRA_E_VENDA = 2,
  SEGUROS = 3,
  ENERGIA = 4,
  PERIODICOS = 5,
}
export interface IRanking {
  idParceiro: number;
  cnpj: number;
  razaoSocial: string;
  nomeParceiro: string;
  nomeParceiroAbreviado?: string; // Adicionado pelo front
  temNomeLongo?: boolean; // Adicionado pelo front
  descricaoParceiro: string;
  emailParceiro?: any;
  telefoneParceiro?: any;
  enderecoParceiro?: any;
  imgParceiro?: any;
  ativo: boolean;
  urlParceiro?: any;
  negociosFechados: number;
  valorTotal: number;
  categorias: number[];
  detalhesCategorias: DetalhesCategoria[];
}


export interface DetalhesCategoria {
  idCategoria: number;
  descricao: string;
  cores?: any;
  templateFormulario?: any;
}