import { Router } from '@angular/router';
import { Component, OnInit, Input, ElementRef, ViewEncapsulation } from '@angular/core';
import { LightBoxesService } from "../lightboxes.services";
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-image-with-link-button',
  templateUrl: './image-with-link-button.component.html',
  styleUrls: ['./image-with-link-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageWithLinkButtonComponent implements OnInit {
  @Input() id: string;
  @Input() className: string;
  
  private element: any;
  private resolve: any;
  private userId: string;
  private url: string;

  constructor(
    private el: ElementRef,
    private router: Router,
    private platform: Platform,
    private service: LightBoxesService, 
    ) { this.element = el.nativeElement; }

  ngOnInit(): void {
    
    if (!this.id) return;    

    document.body.appendChild(this.element);

    this.element.addEventListener("click", el => {
      if (el.target.className === "Lightbox") 
        this.close();      
    });

    this.service.add(this);
  }

  ngOnDestroy(): void {
    this.element.remove();
  }

  open(data: { path: string, userId: string, url?: string }, time: number = 1000): Promise<boolean> {
    this.userId = data.userId
    this.element.firstChild.classList.add(this.className);
    this.element.getElementsByClassName('LightboxImage')[0].setAttribute('src', data.path);
    this.url = data.url;

    if (time === 0) return new Promise(resolve => { this.resolve = resolve; });

    return new Promise(resolve => {
      this.resolve = resolve;

      setTimeout(function () {
        this.element.firstChild.classList.remove(this.className);
        resolve(false);
      }, time);
    });
  }

  close(): void {
    this.resolve(false);
    this.element.firstChild.classList.remove(this.className);
    sessionStorage.setItem('exibeBannerFaleComAApsa', JSON.stringify(false));
  }

  dontShowItAgain() {
    let exibeModalLocal = JSON.parse(localStorage.getItem('exibeBannerFaleComAApsa'));    
    
    exibeModalLocal.find(item => {
      if(item.id === this.userId) {
        item.exibeModal = false;
      }
    });
    
    localStorage.setItem('exibeBannerFaleComAApsa', JSON.stringify(exibeModalLocal));
    this.close();
  }

  redirectFalaComApsa() {    
    if(this.platform.is('desktop')) {    
      let container = document.querySelector('.Header_Atalho_CentralAtendimento');      
      
      if (!container.classList.contains('__is-open')) {
        container.classList.add('__is-open');
      }
      this.close();
    } 

    if(this.platform.is('mobile')) {
      this.router.navigate(['/fale-com-a-apsa']);
      this.close();
      }
  }

  abrirLink() {
    if (this.url) {
      window.open(this.url, '_blank')
    }
  }
}
