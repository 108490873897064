import { Injectable } from '@angular/core';
import { StateService } from 'src/app/shared/services/stateService';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  subject = new BehaviorSubject<boolean>(null);
  perfilEhGestorOuSindico: boolean;

  constructor(
    private http: HttpClient,
    private routerService: StateService,
    public stateService: StateService
  ) { }

  verificarPerfilLogado() {
    let boolObservable = of(this.stateService.possuiPerfilGestor() || this.stateService.possuiPerfilSindico());

    boolObservable.subscribe(item => {
      this.perfilEhGestorOuSindico = item;
      this.getPerfilLogado();
    })
    return this.subject.asObservable();
  }

  getPerfilLogado() {
    this.subject.next(this.perfilEhGestorOuSindico);
  }

  getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8'
    });
  }

  cadastrarDigital(id_pessoa, uid_autenticacao, device_model, device_platform) {
    let urlApiHost = environment.apiCondominioDigital.host;
    let methods = environment.apiCondominioDigital.endpoints.methods;

    var endpoint = methods.autenticacaoDigital.create;
    var url = urlApiHost + endpoint;

    var body = {
      "id_pessoa": id_pessoa,
      "uid_autenticacao": uid_autenticacao,
      "device_model": device_model,
      "device_platform": device_platform
    }

    return this.http.post<any>(url, body, { headers: this.getHeader() });
  }

  deletarDigital(id_pessoa) {
    let urlApiHost = environment.apiCondominioDigital.host;
    let methods = environment.apiCondominioDigital.endpoints.methods;

    var endpoint = methods.autenticacaoDigital.delete;
    var url = urlApiHost + endpoint;

    let params = new HttpParams().set('id_pessoa', id_pessoa);

    return this.http.delete<any>(url, { params, headers: this.getHeader() });
  }
}
