import { CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA,NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { BrMaskerModule } from 'br-mask';
import { Camera } from '@ionic-native/camera/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';


// Components
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { NavTabComponent } from './components/nav-tab/nav-tab.component';
import { MostUsedFeaturesComponent } from './components/most-used-features/most-used-features.component';

import {
  SucessoComponent,
  ErroComponent,
  DefaultComponent,
  
} from './components/popups/index';
import { FileComponent } from './components/buttons/index';

import {
  DefaultLightBoxesComponent,
  MuralCadastradoComponent,
  TextInputWithConfirmationComponent,
  ImageWithToggleAndOkButton,
  ContratacaoDeClienteComponent,
  TextAreaWithConfirmationComponent,
  YesOrNoWithLinkComponent,
  ModalCancelamentoAdesaoRecorrenciaComponent,
  ImageWithLinkButtonComponent,
  ImageWithTitleAndToggleButton
  
} from './components/lightboxes/index';

import { HeaderComponent } from './components/header/header.component';
import { DadosContaComponent } from './components/dados-conta/dados-conta.component';
import { ListaDadosContaComponent } from './components/lista-dados-conta/lista-dados-conta.component';
import { ListaMenuMobileComponent } from './components/lista-menu-mobile/lista-menu-mobile.component';
import { AcessibilidadeComponent } from './components/acessibilidade/acessibilidade.component';
import { BlocosComponent } from './components/blocos/blocos.component';
import { UnidadesComponent } from './components/unidades/unidades.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { FaleComAApsaComponent } from './components/fale-com-a-apsa/fale-com-a-apsa.component';
import { NotificacoesComponent } from './components/notificacoes/notificacoes.component';
import { NotificacoesModalComponent } from './components/notificacoes-modal/notificacoes-modal.component';
import { TermoAdesaoCondicoesUsoTextComponent } from './components/termo-adesao-condicoes-uso-text/termo-adesao-condicoes-uso-text.component';
import { PoliticaPrivacidadeTextComponent } from './components/politica-privacidade-text/politica-privacidade-text.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { ModalBiometriaComponent } from './components/modal-biometria/modal-biometria.component';
import { ModalAvaliarApp } from './components/modal-avaliar-app/modal-avaliar-app.component';
import { QuickCardComponent} from './components/quick-card/quick-card.component';
import { AdesaoDeBoletoDigitalComponent } from './components/adesao-de-boleto-digital/adesao-de-boleto-digital.component';
import { ModalAdesaoDeBoletoDigitalComponent } from './components/modal-adesao-de-boleto-digital/modal-adesao-de-boleto-digital.component';
import { ModalAssinaturaDigitalComponent } from './components/modal-assinatura-digital/modal-assinatura-digital.component';
import { PopupChangeUserImageComponent } from './components/popup-change-user-image/popup-change-user-image.component';
import { ListEnvioDocumentoComponent } from './components/list-envio-documento/list-envio-documento.component';
import { MobileShortcutsWithListDesignComponent } from './components/mobile-shortcuts-with-list-design/mobile-shortcuts-with-list-design.component';
import { DebugDoittCustomerComponent } from './components/debug-doitt-customer/debug-doitt-customer.component';

// Services
import { LoadingScreenService } from './components/loading-screen/loading-screen.service';
import { UserService } from './services/user.service';

// Interceptors
import { LoadingScreenInterceptor } from './components/loading-screen/loading-screen.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor401 } from './interceptors/interceptor401';

//Pipes
import { MyFilterPipe } from './pipes/filterCondominio';
import { FilterMenuPipe } from './pipes/filterMenu';
import { UploadHtml5Component } from './components/buttons/upload-html5/upload-html5.component';
import { ModalEspacoComponent } from './components/modal-espaco/modal-espaco.component';
import { ModalTermosComponent } from './components/modal-termos/modal-termos.component';
// import { InputFileMobile } from './components/buttons/input-file-mobile/input-file-mobile.component';
import { DesignSystemModule } from '../design-system/design-system.module';
import { PasswordToggleDirective } from './directives/password-toggle.directive';
import { LetDirective } from './directives/ng-let.directive';
import { AvaliacaoLojaComponent } from './components/avaliacao-loja-app/avaliacao.component';
import { CotasDevidasComponent } from './components/cotas-devidas/cotas-devidas.component';
import { AdesaoDeBoletoDigitalSelecaoDeUnidadesComponent } from './components/adesao-de-boleto-digital-selecao-de-unidades/adesao-de-boleto-digital-selecao-de-unidades.component';
import { ProfilePictureFacade } from './facades/profile-picture.facade';
import { CondominiumFacade } from './facades/condominium.facade';
import { SaldoEmContasDesktopComponent } from './components/saldo-em-contas-desktop/saldo-em-contas-desktop.component';
import { CotasComVencimentoNoMesComponent } from './components/cotas-com-vencimento-no-mes/cotas-com-vencimento-no-mes.component';
import { SaldoDevedorComponent } from './components/saldo-devedor/saldo-devedor.component';
import { SliderSelectComponent } from './components/slider-select/slider-select.component';
import { SliderSelectMobileComponent } from './components/slider-select-mobile/slider-select-mobile.component';
import { ModalAdesaoIptuComponent } from './components/modal-adesao-iptu/modal-adesao-iptu.component';
import { FaleComASeguradoraComponent } from './components/fale-com-a-seguradora/fale-com-a-seguradora.component';
import { AnnouncementMessageComponent } from './components/announcement-message/announcement-message.component';
import { ModalWithTextFieldComponent } from './components/modal-with-text-field/modal-with-text-field.component';
import { ConsultorMobileComponent } from './components/consultor-mobile/consultor.component';
import { ModalSubcategoriasComponent } from './components/modal-subcategorias/modal-subcategorias.component';
import { ModalConfirmacaoInformacoesComponent } from './components/modal-confirmacao-informacoes/modal-confirmacao-informacoes.component';
import { ModalDetalheConsultorComponent } from '../modules/envio-pagamentos/components/modal-detalhe-consultor/modal-detalhe-consultor.component';
import { ResponsiveDirective } from './directives/responsive.directive';
import { DeselectRadioDirective } from './directives/deselect-radio.directive';
// import { AdesaoBoletoButton } from './components/buttons/adesao-boleto-digital/adesao-boleto-button.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ModalResumoSolicitacaoComponent } from './components/modal-resumo-solicitacao/modal-resumo-solicitacao.component';
import { ModalHistoricoMensagensComponent } from './components/modal-historico-mensagens/modal-historico-mensagens.component';

const popupsComponents = [SucessoComponent, ErroComponent, DefaultComponent];
const lightBoxesComponents = [
  DefaultLightBoxesComponent,
  MuralCadastradoComponent,
  ImageWithToggleAndOkButton,
  ContratacaoDeClienteComponent,
  TextAreaWithConfirmationComponent,
  ModalCancelamentoAdesaoRecorrenciaComponent,
  ImageWithLinkButtonComponent,
  ImageWithTitleAndToggleButton,
];
const buttons = [FileComponent]

@NgModule({
  declarations: [
    LoadingScreenComponent,
    ...popupsComponents,
    ...lightBoxesComponents,
    ...buttons,
    NavTabComponent,
    HeaderComponent,
    AcessibilidadeComponent,
    MyFilterPipe,
    DadosContaComponent,
    ListaDadosContaComponent,
    ListaMenuMobileComponent,
    FilterMenuPipe,
    BlocosComponent,
    UnidadesComponent,
    UploadHtml5Component,
    SearchInputComponent,
    FaleComAApsaComponent,
    NotificacoesComponent,
    NotificacoesModalComponent,
    ModalEspacoComponent,
    TermoAdesaoCondicoesUsoTextComponent,
    PoliticaPrivacidadeTextComponent,
    ModalTermosComponent,
    TextInputWithConfirmationComponent,
    UploadImageComponent,
    ModalBiometriaComponent,
    MostUsedFeaturesComponent,
    PasswordToggleDirective,
    LetDirective,
    QuickCardComponent,
    CotasDevidasComponent,
    ModalAvaliarApp,
    YesOrNoWithLinkComponent,
    AdesaoDeBoletoDigitalComponent,
    ModalAdesaoDeBoletoDigitalComponent,
    ModalAssinaturaDigitalComponent,
    AdesaoDeBoletoDigitalSelecaoDeUnidadesComponent,
    PopupChangeUserImageComponent,
    ListEnvioDocumentoComponent,
    // AdesaoBoletoButton,
    YesOrNoWithLinkComponent,
    MobileShortcutsWithListDesignComponent,
    // AvaliacaoLojaComponent,
    DebugDoittCustomerComponent,
    SaldoEmContasDesktopComponent,
    CotasComVencimentoNoMesComponent,
    SaldoDevedorComponent,
    SliderSelectComponent,
    SliderSelectMobileComponent,
    ModalAdesaoIptuComponent,
    FaleComASeguradoraComponent,
    AnnouncementMessageComponent,
    ModalWithTextFieldComponent,
    ConsultorMobileComponent,
    ModalSubcategoriasComponent,
    // AvaliacaoLojaComponent,
    ModalConfirmacaoInformacoesComponent,
    ResponsiveDirective,
    DeselectRadioDirective,
    ModalResumoSolicitacaoComponent,
    ModalHistoricoMensagensComponent,
  ],
  imports: [RouterModule, CommonModule, ReactiveFormsModule, IonicModule, FormsModule, BrMaskerModule, DesignSystemModule, FileUploadModule],
  exports: [
    ReactiveFormsModule,
    RouterModule,
    BrMaskerModule,
    LoadingScreenComponent,
    ...popupsComponents,
    ...lightBoxesComponents,
    ...buttons,
    NavTabComponent,
    HeaderComponent,
    AcessibilidadeComponent,
    DadosContaComponent,
    ListaDadosContaComponent,
    ListaMenuMobileComponent,
    FilterMenuPipe,
    BlocosComponent,
    UnidadesComponent,
    UploadHtml5Component,
    SearchInputComponent,
    FaleComAApsaComponent,
    NotificacoesComponent,
    NotificacoesModalComponent,
    ModalEspacoComponent,
    TermoAdesaoCondicoesUsoTextComponent,
    PoliticaPrivacidadeTextComponent,
    ModalTermosComponent,
    TextInputWithConfirmationComponent,
    UploadImageComponent,
    ModalBiometriaComponent,
    MostUsedFeaturesComponent,
    PasswordToggleDirective,
    LetDirective,
    QuickCardComponent,
    CotasDevidasComponent,
    ModalAvaliarApp,
    YesOrNoWithLinkComponent,
    AdesaoDeBoletoDigitalComponent,
    ModalAdesaoDeBoletoDigitalComponent,
    ModalAssinaturaDigitalComponent,
    AdesaoDeBoletoDigitalSelecaoDeUnidadesComponent,
    PopupChangeUserImageComponent,
    ListEnvioDocumentoComponent,
    // AdesaoBoletoButton,
    YesOrNoWithLinkComponent,
    MobileShortcutsWithListDesignComponent,
    // AvaliacaoLojaComponent,
    DebugDoittCustomerComponent,
    SaldoEmContasDesktopComponent,
    CotasComVencimentoNoMesComponent,
    SaldoDevedorComponent,
    SliderSelectComponent,
    SliderSelectMobileComponent,
    ModalAdesaoIptuComponent,
    FaleComASeguradoraComponent,
    AnnouncementMessageComponent,
    // AvaliacaoLojaComponent
    ModalWithTextFieldComponent,
    ConsultorMobileComponent,
    ModalSubcategoriasComponent,
    ModalConfirmacaoInformacoesComponent,
    ModalResumoSolicitacaoComponent,
    ModalHistoricoMensagensComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor401,
      multi: true
    },
    Camera,
    Clipboard,
    AvaliacaoLojaComponent,
    UserService,
    ProfilePictureFacade,
    CondominiumFacade,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule { }
