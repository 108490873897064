import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ConstantsService {

  public statusMenuSubject = new BehaviorSubject(false);
  public condominioImovelAtivo: string;
  public condominioImovelMostrado: string;
  public trocaRotaMenu: Subject<any> = new Subject<any>()
  public appVersion: string = "1.0.11";
  
  constructor() { }
  
  ehPerfilTeste() {
    const perfil = {
      perfis: JSON.parse(sessionStorage.getItem('pessoaLogada')).perfis,
      condominios: JSON.parse(sessionStorage.getItem('listaCondominiosImoveis')).condominios || [],
    }

    const perfilTeste = perfil.perfis?.length === 1 && perfil.perfis[0] === 102;

    return perfilTeste && perfil.condominios.length < 1;
  }

  get statusMenu() {
    return this.statusMenuSubject.getValue();
  }

  set statusMenu(newValue: boolean) {
    this.statusMenuSubject.next(newValue);
  }

  getStatusMenu() {
    return this.statusMenuSubject.asObservable()
  }
}
