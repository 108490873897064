export enum CUSTOMERS_ID {
  APSA = '1',
  DOITT = '2',
  TESTE = '3',
  BRJ = '5',
  CONFIARE = '6',
  EDK = '7',
  G9 = '9',
  IMOVEISGARCIA = '8',
  ADELINA = '10',
  TRUSTEE = '11',
  SELANO = '12',
  ALDM = '13',
  LOPESCONDOMINIAL = '14',
  FLAVIOAGUIAR= '15',
  BELOSNEGOCIOS = '16',
  TEMA01 = '17',
  TEMA02 = '18',
  TEMA03 = '19',
  TEMA04 = '20',
  TEMA05 = '21',
  TEMA06 = '22',
  TEMA07 = '23',
  TEMA08 = '24',
  TEMA09 = '25',
  TEMA10 = '26',
  TEMA11 = '27',
  TEMA12 = '28',
  SA_GESTAO_CONDOMINIAL = '29',
  FARZO_CONSULTORIA = '30',
  homeHunters = '31',
  // PROTEST = '4',
};

export const CUSTOMERS = {
  "Doitt": {
    "id": CUSTOMERS_ID.DOITT,
    "hosts": ["doitt.hmg.marlin.com.br", "app.doitt.com.br"],
    "nomeApp": "Doitt",
    "webTitle": "Doitt Condomínio Digital",
    "favicon": "/assets/icon/favicon-doitt.ico",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/default/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#35948a",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "loremipsum@loremipsum.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "https://areaexclusiva.doitt.com.br/digital/images/banner_fale_apsa_mobile.png",
      "imagemDesktop": "https://areaexclusiva.doitt.com.br/digital/images/banner_fale_apsa_desktop.png",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack":true,
    "exibirMenuDashboard":true,
    "exibirParceirosEstrategicosDoitt": true,
    "logos": {
      "logo": "/assets/img/doitt.png",
      "logoContraste": "/assets/img/doitt.png",
      "logoPequena": "/assets/img/doitt-pequena.png",
      "logoPequenaContraste": "/assets/img/doitt-pequena.png",
      "logoPequenaPrime": "/assets/img/doitt-pequena.png",
      "logoPequenaPrimeContraste": "/assets/img/doitt-pequena.png",
      "logoSplashScreen": "/assets/img/doitt.png",
      "logoSplashScreenContraste": "/assets/img/doitt.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [
        "CRECI J1116",
        "CRECI J253",
        "CRECI J7995",
        "CRECI J0011"
      ],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "assets/img/doitt-login-banner.png",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_1.svg",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_2.svg",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_3.svg",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_4.svg",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a Doitt, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": true,
        "links": {
          "minhasSolicitacoes": {
            "exibir": true,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Apsa": {
    "id": CUSTOMERS_ID.APSA,
    "hosts": ["localhost", "127.0.0.1", "areaexclusiva.doitt.com.br", "condominiodigital.hmg.marlin.com.br"],
    "nomeApp": "Apsa",
    "webTitle": "APSA Condomínio Digital",
    "favicon": "/assets/icon/favicon.png",
    "linkExterno": "https://apsa.com.br",
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/apsa/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/apsa/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/apsa/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/apsa/bg-login-desktop.png",
      "cityBackground": "/assets/customers/apsa/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#003974",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "atendimento@apsa.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=com.apsa&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/apsa/id1171170928",
    "podeTrocarTemaDeCores": true,
    "modalDeAtendimento": {
      "exibir": true,
      "imagemMobile": "https://areaexclusiva.doitt.com.br/digital/images/banner_fale_apsa_mobile.png",
      "imagemDesktop": "https://areaexclusiva.doitt.com.br/digital/images/banner_fale_apsa_desktop.png",
    },
    "formasDePagamento": {
      "cartaoDeCredito": true,
      "boletoDigital": true,
    },
    "atendimentoViaWhatsApp": {
      "exibir": true,
      "url": "https://wa.me/5521987862462",
    },
    "exibirFaleComASeguradora": true,
    "exibirMenuCashBack":true,
    "exibirMenuDashboard":true,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "https://areaexclusiva.doitt.com.br/digital/assets/logo.png",
      "logoContraste": "https://areaexclusiva.doitt.com.br/digital/images/logo_branco_centralatendimento.png",
      "logoPequena": "assets/img/logo_header.png",
      "logoPequenaContraste": "assets/img/logo_branco_header.png",
      "logoPequenaPrime": "assets/img/logo_header_prime.png",
      "logoPequenaPrimeContraste": "assets/img/logo_branco_header_prime.png",
      "logoSplashScreen": "assets/img/logo_splashscreen_azul.png",
      "logoSplashScreenContraste": "assets/img/logo_splashscreen_branco.png",
    },
    "crecis": {
      "exibir": true,
      "lista": [
        "CRECI J1116",
        "CRECI J253",
        "CRECI J7995",
        "CRECI J0011"
      ],
    },
    "cotas": {
      "bannerModal": {
        "exibir": true,
        "desktop": "https://areaexclusiva.doitt.com.br/digital/images/Comunicado_fraude.jpg",
        "mobile": "https://areaexclusiva.doitt.com.br/digital/images/comunicado_fraude_mobile.png",
      },
    },
    "comodidadesParaVoce": {
      "exibir": true,
    },
    "webchat": {
      "exibir": true,
      "zenviaID": "d3495d0443326378a60b4f3141783be2",
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "https://areaexclusiva.doitt.com.br/digital/images/AlugueRapido.jpg",
            "link": "https://www.apsa.com.br/aluguel"
          },
          {
            "image": "https://areaexclusiva.doitt.com.br/digital/imagesBoletoDigital.jpg",
            "link": "https://www.youtube.com/watch?v=s7I7sr0rMg8"
          },
          {
            "image": "https://areaexclusiva.doitt.com.br/digital/images/GPU.jpg",
            "link": "https://www.apsa.com.br/gpu"
          }
        ]
      },
      "footer": {
        "exibir": true,
        "slidesDesktop": [
          {
            "texto": "<b>Busque seu imóvel!</b> Experiência, confiança e dedicação para você viver bem!",
            "textoLink": "Acesse",
            "url": "https://apsa.com.br/"
          }
        ],
        "slidesMobile": [
          {
            "texto": "Busque seu imóvel!",
            "textoLink": "Acesse",
            "url": "https://apsa.com.br/"
          }
        ]
      }
    },
    "tutorial": {
      "step1": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_1.svg",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_2.svg",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_3.svg",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_4.svg",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a Apsa, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": true,
        "links": {
          "minhasSolicitacoes": {
            "exibir": true,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": true,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "https://apsa.com.br/",
          },
        },
      },
    },
  },
  "teste": {
    "id": CUSTOMERS_ID.TESTE,
    "hosts": ["app-piloto.doitt.com.br","doitt-piloto.hmg.marlin.com.br"],
    "nomeApp": "teste",
    "webTitle": "Teste Condomínio Digital",
    "favicon": "/assets/icon/favicon-teste.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/default/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#823400",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "loremipsum@loremipsum.com.br",
    "linkLojaAndroid": "https://play.google.com/store/search?q=doitt&c=apps&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "https://areaexclusiva.doitt.com.br/digital/images/banner_fale_apsa_mobile.png",
      "imagemDesktop": "https://areaexclusiva.doitt.com.br/digital/images/banner_fale_apsa_desktop.png",
    },
    "formasDePagamento": {
      "cartaoDeCredito": true,
      "boletoDigital": true,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "https://wa.me/5521987862462",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack":false,
    "exibirMenuDashboard":false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "assets/img/lorem-logo.png",
      "logoContraste": "assets/img/lorem-logo.png",
      "logoPequena": "assets/img/lorem-logo-header.png",
      "logoPequenaContraste": "assets/img/lorem-logo-header.png",
      "logoPequenaPrime": "assets/img/lorem-logo-header.png",
      "logoPequenaPrimeContraste": "assets/img/lorem-logo-header.png",
      "logoSplashScreen": "assets/img/lorem-logo.png",
      "logoSplashScreenContraste": "assets/img/lorem-logo.png",
    },
    "crecis": {
      "exibir": true,
      "lista": [
        "CRECI J1116",
        "CRECI J253",
        "CRECI J7995",
        "CRECI J0011"
      ],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": true,
      "zenviaID": "d3495d0443326378a60b4f3141783be2",
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "https://areaexclusiva.doitt.com.br/digital/images/AlugueRapido.jpg",
            "link": "https://www.apsa.com.br/aluguel"
          },
          {
            "image": "https://areaexclusiva.doitt.com.br/digital/imagesBoletoDigital.jpg",
            "link": "https://www.youtube.com/watch?v=s7I7sr0rMg8"
          },
          {
            "image": "https://areaexclusiva.doitt.com.br/digital/images/GPU.jpg",
            "link": "https://www.apsa.com.br/gpu"
          }
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [
          {
            "texto": "<b>Busque seu imóvel!</b> Experiência, confiança e dedicação para você viver bem!",
            "textoLink": "Acesse",
            "url": ""
          }
        ],
        "slidesMobile": [
          {
            "texto": "Busque seu imóvel!",
            "textoLink": "Acesse",
            "url": ""
          }
        ]
      }
    },
    "tutorial": {
      "step1": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_1.svg",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_2.svg",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_3.svg",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_4.svg",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a teste, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": true,
        "links": {
          "minhasSolicitacoes": {
            "exibir": true,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "BRJ": {
    "id": CUSTOMERS_ID.BRJ,
    "hosts": ["doitt-brj.hmg.marlin.com.br"],
    "nomeApp": "BRJ",
    "webTitle": "BRJ Condomínio Digital",
    "favicon": "/assets/customers/brj/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/default/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#B7344D",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "loremipsum@loremipsum.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack":false,
    "exibirMenuDashboard":false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/brj/logo.png",
      "logoContraste": "/assets/customers/brj/logo.png",
      "logoPequena": "/assets/customers/brj/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/brj/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/brj/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/brj/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/brj/logo.png",
      "logoSplashScreenContraste": "/assets/customers/brj/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/brj/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/brj/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/brj/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/brj/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a BRJ, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": true,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": true,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "https://www.brjimoveis.com.br/search?acao=s1",
          },
        },
      },
    },
  },
  "Confiare": {
    "id": CUSTOMERS_ID.CONFIARE,
    "hosts": ["doitt-confiare.hmg.marlin.com.br"],
    "nomeApp": "Confiare",
    "webTitle": "Confiare Condomínio Digital",
    "favicon": "/assets/customers/confiare/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/confiare/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#0E3560",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "loremipsum@loremipsum.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack":false,
    "exibirMenuDashboard":false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/confiare/logo.png",
      "logoContraste": "/assets/customers/confiare/logo.png",
      "logoPequena": "/assets/customers/confiare/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/confiare/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/confiare/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/confiare/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/confiare/logo.png",
      "logoSplashScreenContraste": "/assets/customers/confiare/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/confiare/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/confiare/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/confiare/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/confiare/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a Confiare, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "EDK": {
    "id": CUSTOMERS_ID.EDK,
    "hosts": ["doitt-edk.hmg.marlin.com.br"],
    "nomeApp": "EDK",
    "webTitle": "EDK Condomínio Digital",
    "favicon": "/assets/customers/edk/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/edk/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#0E3560",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "loremipsum@loremipsum.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/edk/logo.png",
      "logoContraste": "/assets/customers/edk/logo.png",
      "logoPequena": "/assets/customers/edk/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/edk/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/edk/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/edk/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/edk/logo.png",
      "logoSplashScreenContraste": "/assets/customers/edk/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/edk/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/edk/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/edk/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/edk/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a EDK, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "G9": {
    "id": CUSTOMERS_ID.G9,
    "hosts": ["doitt-g9.hmg.marlin.com.br"],
    "nomeApp": "G9",
    "webTitle": "G9 Condomínio Digital",
    "favicon": "/assets/customers/g9/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/g9/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#0E3560",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "loremipsum@loremipsum.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/g9/logo.png",
      "logoContraste": "/assets/customers/g9/logo.png",
      "logoPequena": "/assets/customers/g9/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/g9/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/g9/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/g9/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/g9/logo.png",
      "logoSplashScreenContraste": "/assets/customers/g9/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/g9/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/g9/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/g9/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/g9/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a G9, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "ImoveisGarcia": {
    "id": CUSTOMERS_ID.IMOVEISGARCIA,
    "hosts": ["doitt-imoveisgarcia.hmg.marlin.com.br"],
    "nomeApp": "ImoveisGarcia",
    "webTitle": "Imoveis Garcia Condomínio Digital",
    "favicon": "/assets/customers/imoveisGarcia/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/imoveisGarcia/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#515155",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "loremipsum@loremipsum.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/imoveisGarcia/logo.png",
      "logoContraste": "/assets/customers/imoveisGarcia/logo.png",
      "logoPequena": "/assets/customers/imoveisGarcia/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/imoveisGarcia/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/imoveisGarcia/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/imoveisGarcia/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/imoveisGarcia/logo.png",
      "logoSplashScreenContraste": "/assets/customers/imoveisGarcia/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/imoveisGarcia/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/imoveisGarcia/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/imoveisGarcia/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/imoveisGarcia/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a Imóveis Garcia, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Adelina": {
    "id": CUSTOMERS_ID.ADELINA,
    "hosts": ["doitt-adelina.hmg.marlin.com.br"],
    "nomeApp": "Adelina",
    "webTitle": "Adelina Condomínio Digital",
    "favicon": "/assets/customers/adelina/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/adelina/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "loremipsum@loremipsum.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/adelina/logo.png",
      "logoContraste": "/assets/customers/adelina/logo.png",
      "logoPequena": "/assets/customers/adelina/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/adelina/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/adelina/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/adelina/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/adelina/logo.png",
      "logoSplashScreenContraste": "/assets/customers/adelina/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/adelina/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/adelina/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/adelina/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/adelina/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a Adelina, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Trustee": {
    "id": CUSTOMERS_ID.TRUSTEE,
    "hosts": ["doitt-trustee.hmg.marlin.com.br"],
    "nomeApp": "Trustee",
    "webTitle": "Trustee Condomínio Digital",
    "favicon": "/assets/customers/trustee/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/trustee/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "loremipsum@loremipsum.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/trustee/logo.png",
      "logoContraste": "/assets/customers/trustee/logo.png",
      "logoPequena": "/assets/customers/trustee/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/trustee/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/trustee/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/trustee/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/trustee/logo.png",
      "logoSplashScreenContraste": "/assets/customers/trustee/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/trustee/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/trustee/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/trustee/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/trustee/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a Trustee, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Selano": {
    "id": CUSTOMERS_ID.SELANO,
    "hosts": ["doitt-selano.hmg.marlin.com.br"],
    "nomeApp": "Selano",
    "webTitle": "Selano Condomínio Digital",
    "favicon": "/assets/customers/selano/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/selano/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#C9AB3E",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "loremipsum@loremipsum.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/selano/logo.png",
      "logoContraste": "/assets/customers/selano/logo.png",
      "logoPequena": "/assets/customers/selano/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/selano/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/selano/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/selano/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/selano/logo.png",
      "logoSplashScreenContraste": "/assets/customers/selano/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/selano/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/selano/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/selano/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/selano/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a Selano, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "ALDM": {
    "id": CUSTOMERS_ID.ALDM,
    "hosts": ["doitt-aldm.hmg.marlin.com.br"],
    "nomeApp": "ALDM",
    "webTitle": "ALDM Condomínio Digital",
    "favicon": "/assets/customers/aldm/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/aldm/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#C9AB3E",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "loremipsum@loremipsum.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/aldm/logo.png",
      "logoContraste": "/assets/customers/aldm/logo.png",
      "logoPequena": "/assets/customers/aldm/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/aldm/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/aldm/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/aldm/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/aldm/logo.png",
      "logoSplashScreenContraste": "/assets/customers/aldm/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2",
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/aldm/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/aldm/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/aldm/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/aldm/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a ALDM, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "LopesCondominial": {
    "id": CUSTOMERS_ID.LOPESCONDOMINIAL,
    "hosts": ["doitt-lopescondominial.hmg.marlin.com.br"],
    "nomeApp": "LopesCondominial",
    "webTitle": "Lopes Condominial Condomínio Digital",
    "favicon": "/assets/customers/lopesCondominial/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/lopesCondominial/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#C9AB3E",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "loremipsum@loremipsum.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/lopesCondominial/logo.png",
      "logoContraste": "/assets/customers/lopesCondominial/logo.png",
      "logoPequena": "/assets/customers/lopesCondominial/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/lopesCondominial/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/lopesCondominial/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/lopesCondominial/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/lopesCondominial/logo.png",
      "logoSplashScreenContraste": "/assets/customers/lopesCondominial/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2",
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/lopesCondominial/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/lopesCondominial/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/lopesCondominial/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/lopesCondominial/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a Lopes Condominial, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "FlavioAguiar": {
    "id": CUSTOMERS_ID.FLAVIOAGUIAR,
    "hosts": ["doitt-flavioaguiar.hmg.marlin.com.br"],
    "nomeApp": "FlavioAguiar",
    "webTitle": "Flavio Aguiar Condomínio Digital",
    "favicon": "/assets/customers/flavioAguiar/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/flavioAguiar/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#C9AB3E",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "loremipsum@loremipsum.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/flavioAguiar/logo.png",
      "logoContraste": "/assets/customers/flavioAguiar/logo.png",
      "logoPequena": "/assets/customers/flavioAguiar/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/flavioAguiar/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/flavioAguiar/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/flavioAguiar/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/flavioAguiar/logo.png",
      "logoSplashScreenContraste": "/assets/customers/flavioAguiar/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2",
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/flavioAguiar/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/flavioAguiar/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/flavioAguiar/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/flavioAguiar/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a Flavio Aguiar, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "BelosNegocios": {
    "id": CUSTOMERS_ID.BELOSNEGOCIOS,
    "hosts": ["doitt-belosnegocios.hmg.marlin.com.br"],
    "nomeApp": "BelosNegocios",
    "webTitle": "Belo's Negócios Condomínio Digital",
    "favicon": "/assets/customers/belosNegocios/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/belosNegocios/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "imoveis@belosnegocios.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/belosNegocios/logo.png",
      "logoContraste": "/assets/customers/belosNegocios/logo.png",
      "logoPequena": "/assets/customers/belosNegocios/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/belosNegocios/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/belosNegocios/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/belosNegocios/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/belosNegocios/logo.png",
      "logoSplashScreenContraste": "/assets/customers/belosNegocios/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/belosNegocios/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/belosNegocios/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/belosNegocios/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/belosNegocios/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a Belo's Negócios, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Tema01": {
    "id": CUSTOMERS_ID.TEMA01,
    "hosts": ["tema01.doitt.hmg.marlin.com.br"],
    "nomeApp": "Tema01",
    "webTitle": "Tema 01 Doitt Condomínio Digital",
    "favicon": "/assets/customers/tema01/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/tema01/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "imoveis@belosnegocios.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/tema01/logo.png",
      "logoContraste": "/assets/customers/tema01/logo.png",
      "logoPequena": "/assets/customers/tema01/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/tema01/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/tema01/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/tema01/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/tema01/logo.png",
      "logoSplashScreenContraste": "/assets/customers/tema01/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/tema01/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/tema01/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/tema01/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/tema01/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato conosco, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Tema02": {
    "id": CUSTOMERS_ID.TEMA02,
    "hosts": ["tema02.doitt.hmg.marlin.com.br"],
    "nomeApp": "Tema02",
    "webTitle": "Tema 02 Doitt Condomínio Digital",
    "favicon": "/assets/customers/tema02/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/tema02/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "imoveis@belosnegocios.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/tema02/logo.png",
      "logoContraste": "/assets/customers/tema02/logo.png",
      "logoPequena": "/assets/customers/tema02/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/tema02/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/tema02/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/tema02/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/tema02/logo.png",
      "logoSplashScreenContraste": "/assets/customers/tema02/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/tema02/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/tema02/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/tema02/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/tema02/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato conosco, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Tema03": {
    "id": CUSTOMERS_ID.TEMA03,
    "hosts": ["tema03.doitt.hmg.marlin.com.br"],
    "nomeApp": "Tema03",
    "webTitle": "Tema 03 Doitt Condomínio Digital",
    "favicon": "/assets/customers/tema03/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/tema03/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "imoveis@belosnegocios.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/tema03/logo.png",
      "logoContraste": "/assets/customers/tema03/logo.png",
      "logoPequena": "/assets/customers/tema03/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/tema03/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/tema03/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/tema03/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/tema03/logo.png",
      "logoSplashScreenContraste": "/assets/customers/tema03/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/tema03/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/tema03/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/tema03/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/tema03/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato conosco, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Tema04": {
    "id": CUSTOMERS_ID.TEMA04,
    "hosts": ["tema04.doitt.hmg.marlin.com.br"],
    "nomeApp": "Tema04",
    "webTitle": "Tema 04 Doitt Condomínio Digital",
    "favicon": "/assets/customers/tema04/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/tema04/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "imoveis@belosnegocios.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/tema04/logo.png",
      "logoContraste": "/assets/customers/tema04/logo.png",
      "logoPequena": "/assets/customers/tema04/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/tema04/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/tema04/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/tema04/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/tema04/logo.png",
      "logoSplashScreenContraste": "/assets/customers/tema04/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/tema04/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/tema04/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/tema04/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/tema04/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato conosco, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Tema05": {
    "id": CUSTOMERS_ID.TEMA05,
    "hosts": ["tema05.doitt.hmg.marlin.com.br"],
    "nomeApp": "Tema05",
    "webTitle": "Tema 05 Doitt Condomínio Digital",
    "favicon": "/assets/customers/tema05/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/tema05/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "imoveis@belosnegocios.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/tema05/logo.png",
      "logoContraste": "/assets/customers/tema05/logo.png",
      "logoPequena": "/assets/customers/tema05/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/tema05/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/tema05/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/tema05/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/tema05/logo.png",
      "logoSplashScreenContraste": "/assets/customers/tema05/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/tema05/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/tema05/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/tema05/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/tema05/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato conosco, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Tema06": {
    "id": CUSTOMERS_ID.TEMA06,
    "hosts": ["tema06.doitt.hmg.marlin.com.br"],
    "nomeApp": "Tema06",
    "webTitle": "Tema 06 Doitt Condomínio Digital",
    "favicon": "/assets/customers/tema06/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/tema06/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "imoveis@belosnegocios.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/tema06/logo.png",
      "logoContraste": "/assets/customers/tema06/logo.png",
      "logoPequena": "/assets/customers/tema06/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/tema06/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/tema06/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/tema06/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/tema06/logo.png",
      "logoSplashScreenContraste": "/assets/customers/tema06/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/tema06/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/tema06/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/tema06/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/tema06/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato conosco, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Tema07": {
    "id": CUSTOMERS_ID.TEMA07,
    "hosts": ["tema07.doitt.hmg.marlin.com.br"],
    "nomeApp": "Tema07",
    "webTitle": "Tema 07 Doitt Condomínio Digital",
    "favicon": "/assets/customers/tema07/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/tema07/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "imoveis@belosnegocios.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/tema07/logo.png",
      "logoContraste": "/assets/customers/tema07/logo.png",
      "logoPequena": "/assets/customers/tema07/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/tema07/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/tema07/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/tema07/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/tema07/logo.png",
      "logoSplashScreenContraste": "/assets/customers/tema07/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/tema07/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/tema07/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/tema07/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/tema07/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato conosco, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Tema08": {
    "id": CUSTOMERS_ID.TEMA08,
    "hosts": ["tema08.doitt.hmg.marlin.com.br"],
    "nomeApp": "Tema08",
    "webTitle": "Tema 08 Doitt Condomínio Digital",
    "favicon": "/assets/customers/tema08/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/tema08/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "imoveis@belosnegocios.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/tema08/logo.png",
      "logoContraste": "/assets/customers/tema08/logo.png",
      "logoPequena": "/assets/customers/tema08/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/tema08/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/tema08/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/tema08/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/tema08/logo.png",
      "logoSplashScreenContraste": "/assets/customers/tema08/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/tema08/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/tema08/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/tema08/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/tema08/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato conosco, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Tema09": {
    "id": CUSTOMERS_ID.TEMA09,
    "hosts": ["tema09.doitt.hmg.marlin.com.br"],
    "nomeApp": "Tema09",
    "webTitle": "Tema 09 Doitt Condomínio Digital",
    "favicon": "/assets/customers/tema09/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/tema09/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "imoveis@belosnegocios.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/tema09/logo.png",
      "logoContraste": "/assets/customers/tema09/logo.png",
      "logoPequena": "/assets/customers/tema09/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/tema09/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/tema09/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/tema09/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/tema09/logo.png",
      "logoSplashScreenContraste": "/assets/customers/tema09/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/tema09/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/tema09/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/tema09/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/tema09/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato conosco, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Tema10": {
    "id": CUSTOMERS_ID.TEMA10,
    "hosts": ["tema10.doitt.hmg.marlin.com.br"],
    "nomeApp": "Tema10",
    "webTitle": "Tema 10 Doitt Condomínio Digital",
    "favicon": "/assets/customers/tema10/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/tema10/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "imoveis@belosnegocios.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/tema10/logo.png",
      "logoContraste": "/assets/customers/tema10/logo.png",
      "logoPequena": "/assets/customers/tema10/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/tema10/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/tema10/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/tema10/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/tema10/logo.png",
      "logoSplashScreenContraste": "/assets/customers/tema10/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/tema10/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/tema10/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/tema10/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/tema10/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato conosco, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Tema11": {
    "id": CUSTOMERS_ID.TEMA11,
    "hosts": ["tema11.doitt.hmg.marlin.com.br"],
    "nomeApp": "Tema11",
    "webTitle": "Tema 11 Doitt Condomínio Digital",
    "favicon": "/assets/customers/tema11/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/tema11/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "imoveis@belosnegocios.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/tema11/logo.png",
      "logoContraste": "/assets/customers/tema11/logo.png",
      "logoPequena": "/assets/customers/tema11/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/tema11/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/tema11/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/tema11/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/tema11/logo.png",
      "logoSplashScreenContraste": "/assets/customers/tema11/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/tema11/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/tema11/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/tema11/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/tema11/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato conosco, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Tema12": {
    "id": CUSTOMERS_ID.TEMA12,
    "hosts": ["tema12.doitt.hmg.marlin.com.br"],
    "nomeApp": "Tema12",
    "webTitle": "Tema 12 Doitt Condomínio Digital",
    "favicon": "/assets/customers/tema12/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/tema12/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "imoveis@belosnegocios.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/tema12/logo.png",
      "logoContraste": "/assets/customers/tema12/logo.png",
      "logoPequena": "/assets/customers/tema12/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/tema12/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/tema12/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/tema12/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/tema12/logo.png",
      "logoSplashScreenContraste": "/assets/customers/tema12/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/tema12/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/tema12/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/tema12/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/tema12/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato conosco, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "SA Gestão Condominial": {
    "id": CUSTOMERS_ID.SA_GESTAO_CONDOMINIAL,
    "hosts": ["sag-adm.doitt.com.br"],
    "nomeApp": "SA Gestão Condominial",
    "webTitle": "SA Gestão Condominial",
    "favicon": "/assets/icon/favicon-doitt.ico",
    "linkExterno": 'https://www.sag-adm.com.br/',
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/default/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#35948a",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": ["(11) 5242-3041"]
    },
    "emailAtendimento": "loremipsum@loremipsum.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "https://areaexclusiva.doitt.com.br/digital/images/banner_fale_apsa_mobile.png",
      "imagemDesktop": "https://areaexclusiva.doitt.com.br/digital/images/banner_fale_apsa_desktop.png",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack":true,
    "exibirMenuDashboard":true,
    "exibirParceirosEstrategicosDoitt": true,
    "logos": {
      "logo": "/assets/customers/SAGestaoCondominial/logo.png",
      "logoContraste": "/assets/customers/SAGestaoCondominial/logo.png",
      "logoPequena": "/assets/customers/SAGestaoCondominial/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/SAGestaoCondominial/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/SAGestaoCondominial/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/SAGestaoCondominial/logo-pequena.png",
      "logoSplashScreen": "/assets/img/logo.png",
      "logoSplashScreenContraste": "/assets/img/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [
        "CRECI J1116",
        "CRECI J253",
        "CRECI J7995",
        "CRECI J0011"
      ],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "/assets/customers/SAGestaoCondominial/banner-desktop.png",
            "link": "https://www.sag-adm.com.br/"
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_1.svg",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_2.svg",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_3.svg",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "https://areaexclusiva.doitt.com.br/digital/assets/svg/tutorial_4.svg",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato com a SA Gestão Condominial, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": true,
        "links": {
          "minhasSolicitacoes": {
            "exibir": true,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "Farzo Consultoria Condomínios": {
    "id": CUSTOMERS_ID.FARZO_CONSULTORIA,
    "hosts": ["doitt-farzo.hmg.marlin.com.br","farzo.doitt.com.br"],
    "nomeApp": "Farzo Consultoria Condomínios",
    "webTitle": "Farzo Consultoria Condomínios",
    "favicon": "/assets/customers/farzoConsultoria/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/farzoConsultoria/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "farzo.consultoria@gmail.com",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/farzoConsultoria/logo.png",
      "logoContraste": "/assets/customers/farzoConsultoria/logo.png",
      "logoPequena": "/assets/customers/farzoConsultoria/logo-pequena.png",
      "logoPequenaContraste": "/assets/customers/farzoConsultoria/logo-pequena.png",
      "logoPequenaPrime": "/assets/customers/farzoConsultoria/logo-pequena.png",
      "logoPequenaPrimeContraste": "/assets/customers/farzoConsultoria/logo-pequena.png",
      "logoSplashScreen": "/assets/customers/farzoConsultoria/logo.png",
      "logoSplashScreenContraste": "/assets/customers/farzoConsultoria/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/farzoConsultoria/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/farzoConsultoria/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/farzoConsultoria/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/farzoConsultoria/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato conosco, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
  "homehunters by Apsa": {
    "id": CUSTOMERS_ID.homeHunters,
    "hosts": ["doitt-home-hunters.hmg.marlin.com.br", "hhcondominios.doitt.com.br"],
    "nomeApp": "homehunters by Apsa",
    "webTitle": "homehunters by Apsa Doitt Condomínio Digital",
    "favicon": "/assets/customers/homeHunters/favicon.png",
    "linkExterno": null,
    "assets": {
      "ilustracaoRecorrencia": "/assets/customers/default/recorrencia.png",
      "ilustracaoCadastro": "/assets/customers/default/registered.png",
      "ilustracaoPagamentoPorCartao": "/assets/customers/default/undraw_credit_card_payment.png",
      "loginBackground": "/assets/customers/default/bg-login-desktop.png",
      "cityBackground": "/assets/customers/homeHunters/bg-centralatendimento-desktop.png",
    },
    "appStatusBar": {
      "backgroundColor": "#287CC7",
      "blackText": false,
    },
    "informacoesAdicionais": {
      "contatos": []
    },
    "emailAtendimento": "consultor@hhcondominios.com.br",
    "linkLojaAndroid": "https://play.google.com/store/apps/details?id=br.com.doitt.app&hl=pt_BR",
    "linkLojaIOs": "https://apps.apple.com/br/app/doitt/id6443421679",
    "podeTrocarTemaDeCores": false,
    "modalDeAtendimento": {
      "exibir": false,
      "imagemMobile": "",
      "imagemDesktop": "",
    },
    "formasDePagamento": {
      "cartaoDeCredito": false,
      "boletoDigital": false,
    },
    "atendimentoViaWhatsApp": {
      "exibir": false,
      "url": "",
    },
    "exibirFaleComASeguradora": false,
    "exibirMenuCashBack": false,
    "exibirMenuDashboard": false,
    "exibirParceirosEstrategicosDoitt": false,
    "logos": {
      "logo": "/assets/customers/homeHunters/logo.png",
      "logoContraste": "/assets/customers/homeHunters/logo.png",
      "logoPequena": "/assets/customers/homeHunters/logo.png",
      "logoPequenaContraste": "/assets/customers/homeHunters/logo.png",
      "logoPequenaPrime": "/assets/customers/homeHunters/logo.png",
      "logoPequenaPrimeContraste": "/assets/customers/homeHunters/logo.png",
      "logoSplashScreen": "/assets/customers/homeHunters/logo.png",
      "logoSplashScreenContraste": "/assets/customers/homeHunters/logo.png",
    },
    "crecis": {
      "exibir": false,
      "lista": [],
    },
    "cotas": {
      "bannerModal": {
        "exibir": false,
        "desktop": "",
        "mobile": "",
      },
    },
    "comodidadesParaVoce": {
      "exibir": false,
    },
    "webchat": {
      "exibir": false,
      "zenviaID": "d3495d0443326378a60b4f3141783be2", // APSA
      "embedded": "button",
      "message": "Olá! Clique aqui para consultar seu boleto de condomínio ou locação!"
    },
    "login": {
      "banersDesktop": {
        "exibir": true,
        "slides": [
          {
            "image": "",
            "link": ""
          },
        ]
      },
      "footer": {
        "exibir": false,
        "slidesDesktop": [],  
        "slidesMobile": [],
      },
    },
    "tutorial": {
      "step1": {
        "ilustracao": "./assets/customers/homeHunters/tutorial_1.png",
        "paragrafos": [
          "Vou te mostrar algumas atualizações da sua nova Área do Cliente.",
          "Agora tudo ficou mais fácil, para você resolver o que precisar de forma rápida e prática. Vamos lá!",
        ],
      },
      "step2": {
        "ilustracao": "./assets/customers/homeHunters/tutorial_2.png",
        "paragrafos": [
          "Agora todos os seus perfis e menu aparecem aqui, na lateral esquerda da tela.",
          "Caso tenha muitos perfis cadastrados, uma caixa de busca estará à sua disposição no canto esquerdo superior para encontrar rapidamente o que procura.",
        ],
      },
      "step3": {
        "ilustracao": "./assets/customers/homeHunters/tutorial_3.png",
        "paragrafos": [
          "Ao clicar no perfil ou imóvel desejado, as informações ficarão expostas no centro da tela para sua melhor visualização. De forma clara e intuitiva, você poderá realizar as ações que desejar em poucos cliques.",
        ],
      },
      "step4": {
        "ilustracao": "./assets/customers/homeHunters/tutorial_4.png",
        "paragrafos": [
          "Funciona como uma barra de atalhos, onde você poderá acessar seu perfil e dados, ver notificações, dúvidas frequentes e um canal exclusivo de contato conosco, além de outras facilidades.",
        ],
      },
    },
    "sidebar": {
      "footer": {
        "exibir": false,
        "links": {
          "minhasSolicitacoes": {
            "exibir": false,
            "icon": '<i class="fas fa-tasks"></i>',
            "text": "Minhas solicitações",
          },
          "comodidadesParaVoce": {
            "icon": `<span class="apsa-icon-colorido-colorido_beneficios_pra_voce">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>`,
            "text": "Comodidades para você!",
          },
          "busqueSeuImovel": {
            "exibir": false,
            "icon": `<span class="apsa-icon-colorido-visit-us-colorido">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>`,
            "text": "Busque seu imóvel",
            "url": "",
          },
        },
      },
    },
  },
};