import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CondominioService } from '../../services/condominio.service';
import { ConstantsService } from '../../services/constants.service';
import { IGetTiposSegurosResponse } from '../../services/DTO/response/IGetTiposSeguroResponse';
import { StateService } from '../../services/stateService';
import { ThemeService } from '../../services/theme.service';
import { LightBoxesService } from '../lightboxes';

@Component({
  selector: 'app-fale-com-a-seguradora',
  templateUrl: './fale-com-a-seguradora.component.html',
  styleUrls: ['./fale-com-a-seguradora.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FaleComASeguradoraComponent implements OnInit, AfterViewInit, OnDestroy {
  public form: UntypedFormGroup;
  public isSubmitted: boolean = false;
  public tipos: IGetTiposSegurosResponse[] | null = null;
  public isSubmitting: boolean = false;
  public subscription: Subscription;
  public nomeUsuario = this.stateService.getPessoaLogada() ? this.stateService.getPessoaLogada().Nome : null;

  constructor(
    private fb: UntypedFormBuilder,
    private condominioService: CondominioService,
    private lightboxesService: LightBoxesService,
    private constants: ConstantsService,
    public themeService: ThemeService,
    public activedRoute: ActivatedRoute,
    public stateService: StateService,
    private router: Router
  ) {
    activedRoute.params.subscribe(val => {
      this.ngOnInit();
    });
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.nomeUsuario = this.stateService.getPessoaLogada() ? this.stateService.getPessoaLogada().Nome : null;

    this.isSubmitted = false;
    this.form = this.fb.group({
      tipoSeguro: ['', Validators.required],
      mensagem: ['', Validators.required],
    });

    this.fetchData();
  }

  ngAfterViewInit(): void {
    this.subscription = CondominioService.trocaCondominio.subscribe(
      condominio => {
        if(condominio) {
          this.fetchData();
        }
      },
    )
  }
  
  fetchData() {
    const tiposSeguro = JSON.parse(sessionStorage.getItem('tiposSeguro'));

    if(!tiposSeguro) {
      this.condominioService.getTiposSeguro().subscribe(tipos => {
        this.tipos = tipos;
        sessionStorage.setItem('tiposSeguro', JSON.stringify(tipos));
      });
    }
    else {
      this.tipos = tiposSeguro;
    }
  }

  get formControls() {
    return this.form.controls;
  }

  handleEnviarMensagemClick() {
    this.isSubmitted = true;

    if(this.form.valid) {
      this.enviarMensagemParaCorretora();
    }
  }
  
  enviarMensagemParaCorretora() {
    const tipo = this.form.get('tipoSeguro').value;
    const mensagem = this.form.get('mensagem').value;
    this.isSubmitting = true;

    this.condominioService.cadastrarMensagemCorretora(tipo, mensagem).subscribe(
      res => {
        this.isSubmitting = false;
        this.lightboxesService.openOkPopup('Sucesso!', 'Mensagem enviada para a corretora!');
        if (window.innerWidth < 800)
          this.router.navigate(['/menu']);

        this.stateService.openCloseComponent('.Header_Atalho_Seguros');
      },
      err => {
        this.lightboxesService.openOkPopup('Atenção', err.error.message);
        this.isSubmitting = false;
      }
    );
  }

  setClassError(input: string) {
    return {
      '__is-error': this.isSubmitted && this.form.get(input).invalid
    };
  }
}
