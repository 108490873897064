<nav class="TabNav">
  <div class="TabNav_Banner">
    Banner fixo na navegação
  </div>

  <div class="TabNav_List">
    <!-- HOME -->
    <div class="TabNav_List_Item" [ngClass]="{ '__is-active': rotaAtual === '/home'}">
      <a (click)="redirectHome()">
        <div class="List_Item_Icon">
          <i class="apsa-icon-solid apsa-icon-solid-house"></i>
        </div>

        <div class="List_Item_Label">Home</div>
      </a>
    </div>

    <!-- isDoitter  -->
    <ng-container
      *ngIf="(perfilDoitt.isDoitter$ | async) && ( rotaAtual.startsWith('/cashBack')) ">
      <!-- INDICAR -->
      <div class="TabNav_List_Item" [ngClass]="{ '__is-active': (rotaAtual === '/cashBack/indicar') }">
        <a (click)="ngClassRouterLink('/cashBack/indicar')" routerLink="/cashBack/indicar">
          <div class="List_Item_Icon">
            <i class="fas fa-1x">&#Xf2b5;</i>
          </div>
          <div class="List_Item_Label">Indicar</div>
        </a>
      </div>

      <!-- DASHBOARD -->
      <div class="TabNav_List_Item" [ngClass]="{ '__is-active':(rotaAtual === '/cashBack/dashboard')}">
        <a (click)="ngClassRouterLink('/cashBack/dashboard')" routerLink="/cashBack/dashboard">
          <div class="List_Item_Icon">
            <!-- <i class="apsa-icon-solid apsa-icon-solid-menu-funcionalidades"></i> -->
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path [ngClass]="{ '__is-active':(rotaAtual === '/cashBack/dashboard')}" d="M10.5417 0.125V6.375H18.875V0.125H10.5417ZM10.5417 18.875H18.875V8.45833H10.5417V18.875ZM0.125 18.875H8.45833V12.625H0.125V18.875ZM0.125 10.5417H8.45833V0.125H0.125V10.5417Z"/>
            </svg>
              
          </div>
          <div class="List_Item_Label">Dashboard</div>
        </a>
      </div>

      <!-- CASHBACK -->
      <div class="TabNav_List_Item" [ngClass]="{ '__is-active': (rotaAtual === '/cashBack/status-cashback')}">
        <a (click)="ngClassRouterLink('/cashBack/status-cashback')" routerLink="/cashBack/status-cashback">
          <div class="List_Item_Icon">
            <i class="fas fa-1x">&#Xf51e;</i>
          </div>
          <div class="List_Item_Label">Cashback</div>
        </a>
      </div>
    </ng-container>

    <!-- isDoitt  -->  
    <ng-container
      *ngIf="(perfilDoitt.isDoitt$ | async)  && ( rotaAtual.startsWith('/cashBack') || rotaAtual.startsWith('/tabela-comissao'))">
      <!-- MAIS OPÇÕES -->
      <!-- <div class="TabNav_List_Item" [ngClass]="{ '__is-active': rotaAtual === '/cashBack/dashboard'}">
        <a (click)="ngClassRouterLink('/cashBack/dashboard')" routerLink="/cashBack/dashboard">
          <div class="List_Item_Icon">
            <i class="fas fa-1x "[ngClass]="{ '__is-active': rotaAtual === '/cashBack/dashboard'}">&#Xf65e;</i>
          </div>
          <div class="List_Item_Label">Mais opções</div>
        </a>
      </div> -->

      <!-- DASHBOARD -->
      <div class="TabNav_List_Item" [ngClass]="{ '__is-active': rotaAtual === '/cashBack/dashboard'}">
        <a (click)="ngClassRouterLink('/cashBack/dashboard')" routerLink="/cashBack/dashboard">
          <div class="List_Item_Icon">
            <!-- <i class="apsa-icon-solid apsa-icon-solid-menu-funcionalidades"></i> -->
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path [ngClass]="{ '__is-active':(rotaAtual === '/cashBack/dashboard')}" d="M10.5417 0.125V6.375H18.875V0.125H10.5417ZM10.5417 18.875H18.875V8.45833H10.5417V18.875ZM0.125 18.875H8.45833V12.625H0.125V18.875ZM0.125 10.5417H8.45833V0.125H0.125V10.5417Z"/>
            </svg>
          </div>
          <div class="List_Item_Label">Dashboard</div>
        </a>
      </div>

      <!-- INDICAÇÕES -->
      <div class="TabNav_List_Item" [ngClass]="{ '__is-active': rotaAtual === '/cashBack/status-cashback'}">
        <a (click)="ngClassRouterLink('/cashBack/status-cashback')" routerLink="/cashBack/status-cashback">
          <div class="List_Item_Icon">
            <i class="fas fa-1x">&#Xf51e;</i>
          </div>
          <div class="List_Item_Label">Indicações</div>
        </a>
      </div>
    </ng-container>

    <!-- isParceiro  -->
    <ng-container
      *ngIf="(perfilDoitt.isParceiro$ | async)  && (rotaAtual.startsWith('/cashBack'))">
      <!-- INDICAÇÕES -->
      <div class="TabNav_List_Item" [ngClass]="{ '__is-active': rotaAtual === '/cashBack/status-cashback'}">
        <a (click)="ngClassRouterLink('/cashBack/status-cashback')" routerLink="/cashBack/status-cashback">
          <div class="List_Item_Icon">
            <i class="fas fa-1x">&#Xf51e;</i>
          </div>
          <div class="List_Item_Label">Indicações</div>
        </a>
      </div>

      <!-- DASHBOARD -->
      <div class="TabNav_List_Item" [ngClass]="{ '__is-active': rotaAtual === '/cashBack/dashboard'}">
        <a (click)="ngClassRouterLink('/cashBack/dashboard')" routerLink="/cashBack/dashboard">
          <div class="List_Item_Icon">
            <!-- <i class="apsa-icon-solid apsa-icon-solid-menu-funcionalidades"></i> -->
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path [ngClass]="{ '__is-active':(rotaAtual === '/cashBack/dashboard')}" d="M10.5417 0.125V6.375H18.875V0.125H10.5417ZM10.5417 18.875H18.875V8.45833H10.5417V18.875ZM0.125 18.875H8.45833V12.625H0.125V18.875ZM0.125 10.5417H8.45833V0.125H0.125V10.5417Z"/>
            </svg>
          </div>
          <div class="List_Item_Label">Dashboard</div>
        </a>
      </div>

      <!-- NOTIFICAÇÕES -->
      <div class="TabNav_List_Item">
        <a (click)="openNotificationModal()">
          <div class="List_Item_Icon">
            <i class="demo-icon apsa-icon-solid-bell"></i>
            <div *ngIf="hasNewNotification" class="List_Item_NewNotificationIndicator">{{ totalAlertas }}</div>
          </div>

          <div class="List_Item_Label">Notificações</div>
        </a>
      </div>
    </ng-container>

    <!-- ATALHOS -->
    <div class="TabNav_List_Item" [ngClass]="{ '__is-active': rotaAtual === '/atalhos'}"
      *ngIf="this.stateService.exibeOpcaoMenuAtalhoJornadasFooter() && !(rotaAtual.startsWith('/cashBack') || rotaAtual.startsWith('/tabela-comissao')) ">
      <a routerLink="/atalhos">
        <div class="List_Item_Icon">
          <i class="fas fa-share"></i>
        </div>

        <div class="List_Item_Label">Atalhos</div>
      </a>
    </div>

    <!-- SERVIÇOS -->
    <div
      *ngIf="this.stateService.exibeOpcaoMenuAtalhoFooter() && !(rotaAtual.startsWith('/cashBack') || rotaAtual.startsWith('/tabela-comissao')) "
      class="TabNav_List_Item" [ngClass]="{ '__is-active': rotaAtual === '/menu'}">
      <a routerLink="/menu" routerLinkActive="active">
        <div class="List_Item_Icon">
          <i class="apsa-icon-solid apsa-icon-solid-menu-funcionalidades"></i>
        </div>

        <div class="List_Item_Label">Serviços</div>
      </a>
    </div>

    <!-- NOTIFICAÇÕES -->
    <div
      *ngIf="constant.condominioImovelAtivo && rotaAtual !== '/home' && !(rotaAtual.startsWith('/cashBack') || rotaAtual.startsWith('/tabela-comissao')) "
      class="TabNav_List_Item">
      <a (click)="openNotificationModal()">
        <div class="List_Item_Icon">
          <i class="demo-icon apsa-icon-solid-bell"></i>
          <div *ngIf="hasNewNotification" class="List_Item_NewNotificationIndicator">{{ totalAlertas }}</div>
        </div>

        <div class="List_Item_Label">Notificações</div>
      </a>
    </div>

    <!-- MENU -->
    <div class="TabNav_List_Item">
      <a (click)="openMenuMobile()">
        <div class="List_Item_Icon">
          <i class="fas fa-bars"></i>
        </div>

        <div class="List_Item_Label">Menu</div>
      </a>
    </div>
  </div>
</nav>