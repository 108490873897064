import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StateService } from '../../services/stateService';
import { CondominioService } from '../../services/condominio.service';

@Component({
  selector: 'app-modal-confirmacao-informacoes',
  templateUrl: './modal-confirmacao-informacoes.component.html',
  styleUrls: ['./modal-confirmacao-informacoes.component.scss'],
})
export class ModalConfirmacaoInformacoesComponent implements OnDestroy, OnInit {

  @Input() dadosModal:any;
  @Input() showModal:boolean;
  @Input() checkbox:boolean = false;
  @Input() sucesso:boolean;
  @Input() mensagemHeader:string;
  @Input() mensagemCheck:string;
  @Input() tituloHeader:boolean;
  @Input() form: UntypedFormGroup;
  @Output() confirmar = new EventEmitter()
  @Output() cancelar = new EventEmitter()

  public campoVazio:boolean = false;
  public testeCheck = true;


  constructor(private componentRef: ElementRef, public stateService: StateService, private formBuilder: UntypedFormBuilder) {
   
  }
 
  ngAfterViewInit(): void {
    document.body.appendChild(this.componentRef.nativeElement); 
  }
  ngOnInit(): void {

  }

  ngOnDestroy() {
    
    // document.body.removeChild(this.componentRef.nativeElement);
  }

  confirmarCadastro(){
    if(this.form.controls.aceiteTermo.value === true) {
      this.confirmar.emit(true);
    } else {
      this.confirmar.emit();
    }
  }

  aceitarTermo(){
    // this.testeCheck = !this.testeCheck
    // this.form.controls.aceiteTermo.setValue(!this.form.controls.aceiteTermo.value)
    this.tipoDeServiçoVazio();
    
  }

  cancelarCadastro(){

    this.form.controls.aceiteTermo.setValue(false)
    this.cancelar.emit()
    this.campoVazio = false;

  }
  tipoDeServiçoVazio(){

    for(let i = 0; i < this.dadosModal.length; i++) {
      if(this.dadosModal[i].texto[0] === undefined) {
        this.campoVazio = true;
      }
    }
  }

  goToExternalLink(url: string): void {
    this.stateService.openExternalLink(url);
  }

}
