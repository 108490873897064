import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    constructor(public platform: Platform) {
    }

    init() {
    }

    sendPageview(rota) {
        //this.ga.trackView(title, `/${rota}`);
        window['ga']('set', 'appVersion', '1.0.11');
        window['ga']('set', 'dimension3', "Condômino");
        window['ga']('send', 'pageview', `${(this.platform.is('android') || this.platform.is('ios')) ? '/app' : ''}${rota}`);
        
        // window['gtag']('config', 'UA-155545649-1', {
        //     'page_path': rota
        // });
    }
}
