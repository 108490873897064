import { Directive, HostListener, Input, inject } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[deselectRadio]'
})
export class DeselectRadioDirective {
  @Input('deselectRadio') valueInput: string
  private control = inject(NgControl)

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    const currentValue = this.control.value;

    if (currentValue === this.valueInput) {
      this.control.reset(''); 
    }
  }

}
