import { TipoSolicitacao } from './DTO/response/TipoSolicitacao';
import { environment } from './../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { StateService } from 'src/app/shared/services/stateService';
import { IConstrucaoPayload, IDetalhePAT, ISolicitacaoDetalhe, MinhasSolicitacoesDTO } from './DTO/response/Solicitacao';
import { map } from 'rxjs/operators';
import { INaturezasDoitt, Solicitacoes } from '../models/naturezas.interface';
import { PatGenericaDTO } from './DTO/PatGenerica.DTO';
import { LightBoxesService } from 'src/app/shared/components/lightboxes';
import { NavigationStart, Router } from '@angular/router';
import { IfluxoDeCaixa } from '../models/fluxoDeCAixa.interface';
import { PerfilDoitt } from 'src/app/shared/services/PerfilDoitt.service';
import { IPainelSolicitacoes } from './DTO/response/PainelSolicitacoes';

@Injectable({
  providedIn: 'root',
})
export class MinhasSolicitacoesService {
  private host = environment.apiCondominioDigital.host;
  private methods = environment.apiCondominioDigital.endpoints.methods.minhasSolicitacoes;
  private hostMalote = environment.apiMaloteVirtual.host
  private methodsMalote = environment.apiMaloteVirtual.endpoints.methods;

  private hostPat = environment.apiPat.hostPat;
  private methodsPat = environment.apiPat.endpoints.methods;

  private solicitacaoDataSubject: BehaviorSubject<any> = new BehaviorSubject(null)
  private tabCurrent: BehaviorSubject<number> = new BehaviorSubject(1)
  public visaoAdm =  this.perfilDoitt.isDoitter$.getValue()

  constructor(
    private http: HttpClient, 
    public stateService: StateService, 
    private lightboxesService: LightBoxesService, 
    private route: Router,
    private perfilDoitt: PerfilDoitt
  ) { }

  setTabCurrent(tab: number) {
    this.tabCurrent.next(tab)
  }

  // TODO: Remover após integração com o back da página de solicitações Adm (dolicitaoes-adm.component)
  getTaCurrent$() {
    return this.tabCurrent
  }

  getTabCurrent() {
    return this.tabCurrent.value
  }

  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization' : this.stateService.getTokenApiCondominioDigital()
    });
  }

  listarTiposSolicitacoes(): Observable<TipoSolicitacao[]> {
    const url = this.host + this.methods.getNaturezas;
    
    const options = { headers: this.getHeader() };
    return this.http.get<TipoSolicitacao[]>(url, options);
  }

  getNaturezasDoitt(): Observable<INaturezasDoitt> {
    const url = this.host + this.methods.getNaturezasDoitt
    const options = { headers: this.getHeader() };

     return this.http.get<INaturezasDoitt>(url, options).pipe(map(naturezas => this.agruparNaturezasPorTipo(naturezas)))
    // return of(NaturezasDoitt)
  }

  private agruparNaturezasPorTipo(naturezas) {
    const grupos: INaturezasDoitt = naturezas.reduce((acc, nat) => {
      const { natureza } = nat;
      if (!acc[natureza]) {
        acc[natureza] = [];
      }
      acc[natureza].push(nat);
      return acc;
    }, {} as INaturezasDoitt);
  
    return grupos;
    // return Object.values(grupos);
  }

  getMinhasSolicitacoes(filtro?, status?:string, solicitacoesCondominio = false){
    const pessoaLogada = this.stateService.getPessoaLogada();
    let parametros = new HttpParams()
      .set('idUsuario', pessoaLogada.Id)
      .set('tamanhoPagina', '10')
      .set('solicitacoesCondominio', solicitacoesCondominio)
      // .set('filtrarPorAdm',(!!filtro?.contaCondominio))

      // console.log('filtrarPorAdm', !(!!filtro?.contaCondominio))
     
    // parametros = parametros.append('filtrarPorAdm',this.visaoAdm)

    // if(this.visaoAdm && !filtro?.contaCondominio) {
    //   const condominios = this.stateService.getListaCondominiosImoveis()?.condominios.map(({ condominio }) => condominio.conta)
    //   parametros = parametros.append('contaCondominio', condominios.toString())
    // }

    // parametros = solicitacoesCondominio ? parametros.append('solicitacoesCondominio',true) : parametros.append('solicitacoesCondominio',false)
    
    if(this.visaoAdm) {
      const condominios = this.stateService.getListaCondominiosImoveis()?.condominios.map(({ condominio }) => condominio.conta)
      parametros = parametros.append('contaCondominio', condominios.toString())
    }

    if(filtro){
      filtro.dataInicial? parametros = parametros.append('dataInicial',filtro.dataInicial):null;
      filtro.dataFinal? parametros = parametros.append('dataFinal',filtro.dataFinal):null;
      filtro.idFuncionalidade? parametros = parametros.append('idFuncionalidades',filtro.idFuncionalidade):null;
      filtro.status ? parametros = parametros.append('status', filtro.status) : null
      filtro.contaCondominio ? parametros = parametros.append('condominioDestaque',filtro.contaCondominio) : null

    }

    parametros = parametros.append('paginaAtual', filtro?.paginaAtual ?? 0);

    if(filtro?.ordenarPorMaisrecente === undefined || filtro?.ordenarPorMaisrecente === null) {
      parametros = parametros.append('ordenarPorMaisrecente', true)
    } else {
      parametros = parametros.append('ordenarPorMaisrecente', filtro.ordenarPorMaisrecente)
    }

    if(status) {
      parametros = parametros.append('status', status)
    }

    // return of(solicitcoesMock) 
    return this.http.get<MinhasSolicitacoesDTO>(`${this.host + this.methods.minhasSolicitacoesDoitt}`,
      {headers:this.getHeader(), params:parametros})
  }

  getPainelAdministradora():Observable<IPainelSolicitacoes> {
    const pessoaLogada = this.stateService.getPessoaLogada();
    const contaEmpresaDosCondominios = this.stateService.getUserCondominiums()
      .map(({ contaEmpresa }) => contaEmpresa)
    
    let parametros = new HttpParams()
      .set('idUsuario', pessoaLogada.Id)
      .set('contas', contaEmpresaDosCondominios.toString())
   
    return this.http.get<IPainelSolicitacoes>(
      `${this.host + this.methods.getPainelAdministradora}`,
      {headers: this.getHeader(), params: parametros}
    )
  }

  getPainelDestinatarios(contasCondominios: number[]) {
    let params = new HttpParams()
      .set('contas', contasCondominios.toString())

    return this.http.get<any>(
      `${this.host + this.methods.getPainelDestinatarios}`,
      {headers: this.getHeader(), params}
    )
  }

  getPatDetalhe(idSolicitacao: number): Observable<IDetalhePAT> {
    // const resposta = solicitacaoMock.filter(item => item.idSolicitacao === idSolicitacao)
    // return of(resposta)
    const ehSindico = this.stateService.getPessoaLogada().perfis.includes(3)
    const url = this.host + this.methods.getPatDetalhe + '?ehSindico=' + ehSindico +'&idPat=' + idSolicitacao
    const options = { headers: this.getHeader() };

    return this.http.get<IDetalhePAT>(url, options)
  }


  getTipoDeSolicitacao(idFuncionalidade: number): Observable<Solicitacoes> {
    const url = this.host + this.methods.getNaturezasDoittDetahe + '?idFuncionalidade=' + idFuncionalidade
    const options = { headers: this.getHeader() };

    return this.http.get<Solicitacoes>(url, options)

    // const resposta = TiposSolicitacoesMock
    //   .filter(solicitacao => solicitacao.idFuncionalidade === id)
    //   .reduce((_, s) => {
    //     return s.tipo.find(tipoSolicitacao => tipoSolicitacao.idSolicitacao === tipo)
    //   }, {})
    
    // return of(resposta)
  }

  enviaSolicitacao(body){
    const header = new HttpHeaders({
      'Content-Type': 'application/json'})

    return this.http.post<number>(`${this.hostPat + this.methodsPat.patGenerica}`, body,
      {headers:header});
  }

  getUrlDoDocumentoModeloDeHorasExtras(contaEmpresaDoCondominio?: string) {
    const url = this.host + this.methods.getDocumentoModeloDeHorasExtras;
    let contaEmpresa: string;

    if(contaEmpresaDoCondominio !== null) {
      contaEmpresa = contaEmpresaDoCondominio;
    }
    else {
      if(!this.stateService.getCondominioLogado()) {
        const condominios = this.stateService.getUserCondominiumsWithFeature(854);
  
        contaEmpresa = condominios[0].contaEmpresa;
      }
      else
        contaEmpresa = this.stateService.getCondominioLogado().contaEmpresa;
    }

    return this.http.get<string>(url, { params: { contaEmpresa }, headers: this.getHeader() });
  }

  setSolicitacaoData(solicitacao: unknown) {
    this.solicitacaoDataSubject.next(solicitacao)
  }

  getSolicitacaoData() {
    return this.solicitacaoDataSubject.asObservable()
  }

  buildSolicitationPayload(dadosForm: IConstrucaoPayload,): PatGenericaDTO {
    const payload = {} as PatGenericaDTO;
    const pessoaLogada = JSON.parse(sessionStorage.getItem('pessoaLogada'));
    const { carteira, conta } = this.stateService.getCondominioLogado() || {};
    const [contaCondominio] = this.stateService.getListaCondominiosImoveis()?.condominios.map(({ condominio }) => condominio.conta)
    const anexos = Array.isArray(dadosForm?.camposFormulario?.anexos) && 
      dadosForm?.camposFormulario?.anexos?.map(v => ({Conteudo: v.Conteudo, Nome: v.Nome, Tipo: v.Tipo, src: v.src}))
    delete dadosForm?.camposFormulario?.anexos
    let formulario = ''

    if(Object.keys(dadosForm?.camposFormulario).length === 0) {
      dadosForm.camposFormulario = ""
    } else {
      formulario = JSON.stringify(dadosForm?.camposFormulario)
    }
    payload.origemSindico = pessoaLogada?.perfis.includes(3)//Perfil síndico
    payload.patAdministradora = this.visaoAdm ? Boolean(!dadosForm?.condominioSelecionado) : false
    payload.idAplicacao = 3 // esse ID representa a Doitt no back; APSA é 2
    payload.nomeUsuario = pessoaLogada.nome
    payload.idUsuario = pessoaLogada.id
    payload.idFuncionalidade = dadosForm?.idFuncionalidade || null
    payload.idPatParceiro = dadosForm?.idPatParceiro || null
    payload.camposFormulario = dadosForm?.camposFormulario.mensagem ? 
      dadosForm?.camposFormulario.mensagem : formulario
    payload.anexos = anexos ?? ''

    if(!dadosForm?.condominioSelecionado) {
      payload.contaCondominio = this.visaoAdm ? contaCondominio : Number(conta)
      payload.carteiraCondominio = carteira
    } else {
      payload.contaCondominio = dadosForm?.condominioSelecionado.conta
      payload.carteiraCondominio = dadosForm?.condominioSelecionado.carteira
    }

    return payload
  }

  mensagemAoCadastrar(sucesso: boolean, id?: number) {
    if(sucesso) {
      this.lightboxesService.openOkPopup(
        null, 'Solicitação enviada com sucesso!', 'Concluído'
      )
      .then(() => this.route.navigate(['/minhas-solicitacoes/detalhe', id]));
    } else {
      this.lightboxesService.openOkPopup('Erro ao enviar', 'Por favor, tente novamente cadastrar a solicitação', 'OK')
    }
  }

  getFluxosDeCaixa(obj): Observable<IfluxoDeCaixa[]> {
    const header = new HttpHeaders({'Content-Type': 'application/json'})

    return this.http.post<IfluxoDeCaixa[]>(`${this.hostMalote + this.methodsMalote.getFluxoDeCaixa}`, obj,
    {headers:header});
  }

  private converterBase64ParaBlob(base64Data: string): Blob {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: 'application/octet-stream' });
  }


  downloadFile(conteudo, nome) {
    try {
      const blob = this.converterBase64ParaBlob(conteudo);
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = nome;
      link.click();
    } catch (error) {
      console.error('Error converting Base64 to Blob:', error);
    }
  }
}