import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {
  public message = new BehaviorSubject<string | null>(null);
  private _loading: boolean = false;
  loadingStatus: Subject<any> = new Subject();

  get loading(): boolean {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
    this.loadingStatus.next(value);
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }

  setMessage(message: string | null) {
    this.message.next(message);
  }

  getMessage() {
    return this.message.asObservable();
  }
}