import { Platform } from '@ionic/angular';
import { Component, OnInit, Output, Input, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SessionStorageService } from '../../services/session-storage.service';
import { GerenteContaDTO } from '../../services/DTO/response/GerenteContaDTO';
import { CondominioService } from '../../services/condominio.service';
import { StateService } from '../../services/stateService';
import { Texts } from './texts';
import { HeaderService } from 'src/app/shared/services/header.service';
import { Validations } from '../../utils/validations';
import { LightBoxesService } from '../lightboxes';
import { ActivatedRoute, Router } from '@angular/router';
import { IFaleComApsaDTO } from '../../services/DTO/Request/IFaleComApsa.DTO';
import { Subject, Subscription } from 'rxjs';
import CustomerService from '../../services/customer.service';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CUSTOMERS_ID } from 'src/customers';

@Component({
  selector: 'app-fale-com-a-apsa',
  templateUrl: './fale-com-a-apsa.component.html',
  styleUrls: ['./fale-com-a-apsa.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class FaleComAApsaComponent implements OnInit, AfterViewInit, OnDestroy {
  public formFaleComApsa: UntypedFormGroup;
  public gerenteConta: GerenteContaDTO;
  public textsCustom = Texts;
  public listAssuntos = [];
  public nomeCondominio: string;
  public isSubmitted: boolean = false;
  public isMobile: boolean = this.platform.is('mobile');
  public isDesktop: boolean = this.platform.is('desktop');
  public pessoaLogada = this.stateService.getPessoaLogada();
  public possuiPerfilSindico: boolean = false;
  public subscription: Subscription;
  public admLogada$ = this.customerService.getCustomerObservable()
  public admLogada
  public idAdm = CUSTOMERS_ID
  private unsubscribe$ = new Subject<boolean>()

  @Input() load: boolean;

  constructor(
    private sessionStorageService: SessionStorageService,
    private fb: UntypedFormBuilder,
    private condominioService: CondominioService,
    public stateService: StateService,
    private headerService: HeaderService,
    public lightBoxesService: LightBoxesService,
    public activedRoute: ActivatedRoute,
    private router: Router,
    private platform: Platform,
    private customerService: CustomerService,
  ) {
    activedRoute.params.subscribe(val => {
      this.ngOnInit();
      this.possuiPerfilSindico = this.stateService.possuiPerfilSindico_();
    });
  }

  ngOnInit() {
    // this.listAssuntos = [];

    this.formFaleComApsa = this.fb.group({
      tipoMensagem: ['1', Validators.required],
      mensagem: ['', Validators.required],
      idAssunto: ['', Validators.required],
      contaEmpresa: this.sessionStorageService.get('condominioLogado') ? [JSON.parse(this.sessionStorageService.get('condominioLogado')).contaEmpresa] : undefined,
      idPessoa: [JSON.parse(this.sessionStorageService.get('pessoaLogada')).id],
    });
    this.admLogada$.pipe(takeUntil(this.unsubscribe$)).subscribe(adm => {
      this.admLogada = adm
    })
  }

  ngAfterViewInit(): void {
    this.subscription = CondominioService.trocaCondominio.subscribe(
      condominio => {
        sessionStorage.removeItem('dadosGerente');
        sessionStorage.removeItem('tiposSeguro');

        if(condominio) {
          this.fetchData();
        }
      },
    )

    this.fetchData();
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
    
    this.unsubscribe$.next(true)
    this.unsubscribe$.complete()
  }

  fetchData() {
    this.gerenteConta = null;
    this.listAssuntos = null;

    if (this.stateService.getCondominioLogado()) {
      const dadosGerente = JSON.parse(sessionStorage.getItem('dadosGerente'));
      if(!dadosGerente){
        const contaEmpresa = this.stateService.getCondominioLogado().ContaEmpresa;
  
        this.condominioService.getDadosGerenteConta(contaEmpresa).subscribe(response => {
          sessionStorage.setItem('dadosGerente', JSON.stringify(response));
    
          this.gerenteConta = response;
          this.load = false;
        });  
      } else if(dadosGerente){
        this.gerenteConta = dadosGerente;
        this.load = false;
      }
    } else if (this.stateService.getImovelLogado()) {
      this.gerenteConta = null;
      this.listAssuntos = null;
      this.load = false;
    }
    
    if (!this.listAssuntos) {
      const assuntosStorage = JSON.parse(sessionStorage.getItem('assuntos'));
      if (assuntosStorage) {
        this.listAssuntos = assuntosStorage;
      } 
      else {
        this.headerService.getAssuntos().subscribe(result => {
          this.listAssuntos = result;
          sessionStorage.setItem('assuntos', JSON.stringify(result));
          if (this.listAssuntos && this.listAssuntos[0])
            this.formControls.idAssunto.setValue(this.listAssuntos[0].idAssunto)
        });
      }
    }
  }

  getErrorMessage(input: string) {
    if (this.formControls[input].errors) {
      return this.textsCustom.form.error[input][
        Object.keys(this.formControls[input].errors)[0]
      ];
    }
  }

  getGerenteConta() {
    if (this.stateService.getCondominioLogado()) {
      const contaEmpresa = this.stateService.getCondominioLogado().ContaEmpresa;

      this.condominioService.getDadosGerenteConta(contaEmpresa).subscribe(response => {
        this.gerenteConta = response;
      });
    }
  }

  enviarMensagem() {
    this.isSubmitted = true;
    let condominioLogado = this.stateService.getCondominioLogado();
    let obj = {} as IFaleComApsaDTO;
    
    obj = this.formFaleComApsa.value;
    obj.perfis = condominioLogado.perfisIds;
    obj.contaEmpresa = condominioLogado ? JSON.parse(condominioLogado.contaEmpresa) : null;
    obj.idOrigemDoitt = +this.admLogada?.id;
    obj.destinatario = this.gerenteConta.email;

    if (this.formFaleComApsa.invalid) {
      Validations.markFormGroupTouched(this.formFaleComApsa);
      return;
    }
    this.headerService.enviarMensagem(obj).subscribe(result => {
      this.stateService.openCloseComponent('.Header_Atalho_CentralAtendimento')
      this.lightBoxesService.open(
        'custom-generico',
        { title: '', text: "Solicitação aberta com sucesso!" },
        5000
      );
      if (window.innerWidth < 800)
        this.router.navigate(['/menu']);
    },
      err => {
        this.lightBoxesService.open(
          'custom-generico',
          { title: '', text: "Não foi possível enviar a mensagem" },
          5000
        );
      });
  }

  get formControls() {
    return this.formFaleComApsa.controls;
  }
}
