import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { StateService } from 'src/app/shared/services/stateService';
import { environment } from 'src/environments/environment';
import { ACOMPANHAMENTO_ORCAMENTARIO_MOCK } from './DTO/response/AcompanhamentoOrcamentarioMock';
import { IAcompanhamentoOrcamentarioGeral } from './DTO/response/IAcompanhamentoOrcamentario';

@Injectable({
  providedIn: 'root'
})
export class AcompanhamentoOrcamentarioService {
  private host = environment.apiCondominioDigital.host;
  private methods = environment.apiCondominioDigital.endpoints.methods.acompanhamentoOrcamentario;

  constructor(
    private http: HttpClient,
    public stateService: StateService,
  ) { }

  private getHeaderCondominioDigital() {
    return new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      'Authorization': this.stateService.getTokenApiCondominioDigital()
    });
  }

  getPeriodoDisponivel() {
    const url = this.host + this.methods.getPeriodoDisponivel;
    const headers = this.getHeaderCondominioDigital();

    return this.http.get<string[]>(url, { headers });
  }

  getOrcamento(mesAnos: string) {
    const url = this.host + this.methods.getOrcamento;
    const headers = this.getHeaderCondominioDigital();
    const params = {
      contaCondominio: this.stateService.getCondominioLogado().conta,
      mesAno: mesAnos,
    };

    return this.http.get<IAcompanhamentoOrcamentarioGeral>(url, { params, headers });
    // return of(ACOMPANHAMENTO_ORCAMENTARIO_MOCK);
  }

  getSaldoDevedor() {
    const url = this.host + this.methods.getSaldoDevedor;
    const headers = this.getHeaderCondominioDigital();
    const params = {
      contaCondominio: this.stateService.getCondominioLogado().conta,
    };

    return this.http.get<string>(url, { params, headers });
  }

  getUrlDownload(mesAnos: string) {
    const url = this.host + this.methods.getUrlDownload;
    const usuario = this.stateService.getPessoaLogada();
    const condominio = this.stateService.getCondominioLogado();
    const headers = this.getHeaderCondominioDigital();

    const params = {
      login: usuario.Login,
      agencia: condominio.agencia,
      mesAno: mesAnos,
      contaCondominio: condominio.conta,
    };

    return this.http.get<string>(url, { params, headers });
  }

  getUrlDownloadExcel(mesAnos: string) {
    const url = this.host + this.methods.getUrlDownloadExcel;
    const condominio = this.stateService.getCondominioLogado();
    const headers = this.getHeaderCondominioDigital();

    const params = {
      agencia: condominio.agencia,
      mesAno: mesAnos,
      contaCondominio: condominio.conta,
    };

    return this.http.get<string>(url, { params, headers });
  }
}
