import { Component, Input, Output, EventEmitter, ElementRef, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { StateService } from 'src/app/shared/services/stateService';
import { CategoriasParceiros } from '../../../modules/cash-back/models/parceiros/parceiros.model';

@Component({
  selector: 'app-modal-subcategorias',
  templateUrl: './modal-subcategorias.component.html',
  styleUrls: ['./modal-subcategorias.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalSubcategoriasComponent implements OnDestroy {
  @Input() isOpen: boolean;
  @Output() onClose = new EventEmitter();
  @Input('p-title') title: string;
  
  constructor(private componentRef: ElementRef, public stateService: StateService) {
    document.body.appendChild(this.componentRef.nativeElement)
  
  }
 
  ngOnInit() {
    document.body.appendChild(this.componentRef.nativeElement)
  }

  ngOnDestroy() {
    if(this.componentRef.nativeElement) document.body.removeChild(this.componentRef.nativeElement);
  }

  close(): void {
    this.onClose.emit();
  }

}