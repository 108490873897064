import { OnDestroy, OnInit, Component, Input, Output, ViewChild, ViewEncapsulation, EventEmitter, AfterViewInit } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-slider-select',
  templateUrl: './slider-select.component.html',
  styleUrls: ['./slider-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderSelectComponent implements OnDestroy, OnInit, AfterViewInit {    
  @ViewChild('slider') slider: IonSlides;
  @Output() onSelectOption = new EventEmitter<string[]>();
  @Input('p-default-selection') defaultSelection: string[];
  @Input() options: string[] = [];
  @Input('p-step') step = 1;
  @Input('p-force-one-selection-on-mobile') forceOneSelectionOnMobile: boolean = false;
  @Input('p-show-fast-forward-arrow') showFastForwardArrow: boolean = true;
  @Input('p-show-fast-backward-arrow') showFastBackwardArrow: boolean = true;

  public selectedOptions = new BehaviorSubject(null);
  public subscription: Subscription = null;
  public carouselConfig: any = { 
    slidesPerView: 'auto',
  };
  public oneSelectionMode: boolean = false;
  private requiredSelection: number = null;
  private _defaultSelection: string[];

  constructor() {
  }
  
  ngOnInit() {
    this.selectedOptions.subscribe(options => {
      if(options) {
        this.onSelectOption.emit(options);
      }
    });

    this._defaultSelection = this.defaultSelection;
    this.selectedOptions.next(this._defaultSelection);
    this.requiredSelection = this.defaultSelection.length;
  }

  ngAfterViewInit() {
    this.pickDesign();
    this.slider.slideTo(this.getLastSelectedOptionIndex());
  }

  ngOnDestroy() {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  slideNext() {
    if(this.canSlideNext()) {
      const moveLength = this.getfirstSelectedOptionIndex() + this.step;
      this.slider.slideTo(moveLength);
  
      this.selectedOptions.next(this.options.slice(moveLength, moveLength + this.requiredSelection));
    }
  }

  slidePrevious() {
    if(this.canSlidePrevious()) {
      const moveLength = this.getfirstSelectedOptionIndex() - this.step;
      this.slider.slideTo(moveLength);
  
      this.selectedOptions.next(this.options.slice(moveLength, moveLength + this.requiredSelection));
    }
  }

  slideToStart() {
    if(this.canSlidePrevious()) {
      this.slider.slideTo(0);
      this.selectedOptions.next(this.options.slice(0, this.requiredSelection));
    }
  }

  slideToEnd() {
    if(this.canSlideNext()) {
      this.slider.slideTo(this.options.length-1);
      this.selectedOptions.next(this.options.slice(this.options.length - this.requiredSelection, this.options.length));
    }
  }

  isOptionSelected(option: string): boolean {
    return this.selectedOptions.getValue().includes(option);
  }

  public resetToDefaultSelection() {
    this.selectedOptions.next(this._defaultSelection);
    this.slider.slideTo(this.getLastSelectedOptionIndex());
  }

  public canSlidePrevious() {
    return this.getfirstSelectedOptionIndex() > 0;
  }

  public canSlideNext(): boolean {
    return (this.getLastSelectedOptionIndex() + this.step) < this.options.length;
  }

  private getfirstSelectedOptionIndex() {
    const firstSelectedOption = this.selectedOptions.getValue()[0];
    const firstSelectedOptionIndex = this.options.findIndex(opt => opt === firstSelectedOption);

    return firstSelectedOptionIndex;
  }

  private getLastSelectedOptionIndex() {
    const selectedOptionsLength = this.selectedOptions.getValue().length;
    const lastSelectedOption = this.selectedOptions.getValue()[selectedOptionsLength-1];
    const lastSelectedOptionIndex = this.options.findIndex(opt => opt === lastSelectedOption);

    return lastSelectedOptionIndex;
  }

  private pickDesign() {
    if(this.requiredSelection === 1) {
      this.slider.options['slidesPerView'] = 1;
      this.slider.options['allowTouchMove'] = false;
      this.carouselConfig['slidesPerView'] = 1;
      this.oneSelectionMode = true;
      
      window.setTimeout(() => {
        this.slider.update();
      }, 3000);
    }
  }
}
