import { Platform } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { StateService } from '../../services/stateService';
import { AlertaService } from '../../services/alerta.service';
import { AlertaDTO } from '../../services/DTO/response/AlertaDTO';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss'],
})
export class NotificacoesComponent implements OnInit {
  public notifications: AlertaDTO[] = [];
  public newNotifications: AlertaDTO[] = [];
  public oldNotifications: AlertaDTO[] = [];
  public loading: boolean = true;

  @Input() listNotifications;

  constructor(
    public alertaService: AlertaService,
    public stateService: StateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private platform: Platform,
  ) {
    activatedRoute.params.subscribe(val => {
      // this.fetchNotifications();
    });
  }
  
  ngOnInit() {
    this.fetchNotifications();
  }
  
  ngAfterViewChecked() {
    if(this.listNotifications) {
      this.ngOnInit();
    }
  }

  procuraAtualizacaoNovaVersao(){
    var notificacao = this.stateService.getNotificacaoVersaoApp();
    //procura por atualização de versão
    if(notificacao){
      var alerta = new AlertaDTO();
      alerta.Id = "999";
      alerta.Message = notificacao.mensagem;
      alerta.LinkCondominioDigital = notificacao.rota;
      alerta.NewAlert = this.stateService.possuiNovaVersao() ? true : false;
      alerta.Type = null;
      this.notifications.push(alerta);
    }
  }

  // TODO: Buscar profileCode do CondominioLogado.Profiles[0].profileCodeGT (ainda não existe)
  async fetchNotifications() {
    this.loading = true;
    
    const condominio = this.stateService.getCondominioLogado();
    
    if(this.listNotifications) {
      this.notifications = this.listNotifications;
      this.procuraAtualizacaoNovaVersao();
      this.populateNewAndOldNotifications();
      this.loading = false;
    } 
    else if ((condominio) && (condominio.ehGestaoTotal)) {
      const cpfPessoaLogada = (this.stateService.getPessoaLogada()) ? this.stateService.getPessoaLogada().CpfCnpj : null;
      const profileCode = "1";
      const condominioAgencia = this.stateService.getCondominioLogado().Agencia;
      const condominioConta = this.stateService.getCondominioLogado().ContaEmpresa;

      let origem: string;
      if(this.platform.is('desktop'))
        origem = '1';
      if(this.platform.is('mobile'))
        origem = '2';      

      this.alertaService.getAlertas(origem, cpfPessoaLogada, profileCode, condominioAgencia, condominioConta).subscribe(response => {
        this.notifications = response.Data;
        this.procuraAtualizacaoNovaVersao();
        this.populateNewAndOldNotifications();
        this.loading = false;
      });
    } else{
      this.procuraAtualizacaoNovaVersao();
      this.populateNewAndOldNotifications();
      this.loading = false;
    }
  }

  populateNewAndOldNotifications() {
    this.newNotifications = this.notifications.filter(notification => notification.NewAlert);
    this.oldNotifications = this.notifications.filter(notification => !notification.NewAlert);
  }

  routeTo(page: string) {
    // console.log(page);
    this.router.navigate([page]);
    
    if(page === "/version-logs"){
      localStorage.setItem('ultimaNotificacaoVersaoApp', this.stateService.getNotificacaoVersaoApp().numeroVersao);
    }
  }
}
