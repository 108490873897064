<div class="slider-select-mobile-component">
  <div class="month-selection">
    <button
      class="arrow"
      (click)="previousMonth()"
      [ngClass]="{'__is-disabled': (monthSelected | async) === 0}"
    >
      <i class="fas fa-chevron-left"></i>
    </button>
  
    <ion-slides #slider [options]="sliderOptions">
      <ion-slide *ngFor="let month of listaPeriodoDisponivel">
        <button class="month">{{ month }}</button>
      </ion-slide>
    </ion-slides>
  
    <button
      class="arrow"
      (click)="nextMonth()"
      [ngClass]="{'__is-disabled': (monthSelected | async) === listaPeriodoDisponivel.length - 1}"
    >
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>