import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef, inject } from '@angular/core';

@Directive({
  selector: '[intervaloDeExibicao]'
})
export class ResponsiveDirective {
  private templateRef = inject(TemplateRef)
  private viewContainer = inject(ViewContainerRef)
  private viewRef: EmbeddedViewRef<any>;

  @Input() set intervaloDeExibicao(v: number[]) {
    const [ apartir, ate ] = v
    const larguraAtual = window.innerWidth;
    const intervalo = this.intervalo(apartir, ate, larguraAtual)

    if (intervalo) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
     this.viewContainer.clear();
    }

  }

  private intervalo(apartir: number, ate: number | undefined, largura: number): boolean {
    if (!ate) {
      return largura >= apartir;
    }
    return largura >= apartir && largura <= ate;

  }
}
