import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { LightBoxesService } from '../lightboxes.services';
import { Texts } from './text';

interface IOpenObjectArgument {
  title: string;
  textAreaDefaultValue: string;
  text: string;
}

@Component({
  selector: 'app-text-area-with-confirmation',
  templateUrl: './text-area-with-confirmation.component.html',
  styleUrls: ['./text-area-with-confirmation.component.scss'],
})
export class TextAreaWithConfirmationComponent implements OnInit {
  @Input() id: string;
  @Input() className: string;
  public submitted: boolean = false;

  public textsCustom = Texts;
  private element: any;
  private promisse: Promise<boolean>;
  private resolve;
  public textInputValue = new UntypedFormControl('', Validators.compose([Validators.minLength(20), Validators.required]));

  constructor(
    private service: LightBoxesService,
    private el: ElementRef
  ) {
    this.element = el.nativeElement;
  }

  getErrorMessage(input) {
    if (this.textInputValue.errors) {
      return this.textsCustom.form.error[input][
        Object.keys(this.textInputValue.errors)[0]
      ];
    }
  }

  ngOnInit(): void {
    if (!this.id) {
      return;
    }

    this.moveElementToLastChildOfBody();
    this.closeOnBackgroundClick();
    this.addThisPopupToTheService();
  }

  ngOnDestroy(): void {
    this.element.remove();
  }

  public open(textos: IOpenObjectArgument, time: number = 1000): Promise<boolean> {
    this.element.firstChild.classList.add(this.className);
    this.setInnerHTMLTexts(textos);
    this.textInputValue.setValue(textos.textAreaDefaultValue);

    if (time === 0) return new Promise(resolve => { this.resolve = resolve });

    return new Promise(resolve => {
      setTimeout(() => {
        this.element.firstChild.classList.remove(this.className);
        resolve(false);
      }, time);
    });
  }

  public confirmar() {
    this.submitted = true;

    if(!this.textInputValue.errors) {
      this.resolve(this.textInputValue.value.trim());
      this.submitted = false;
      this.element.firstChild.classList.remove(this.className);
    }
  }

  public close() {
    this.resolve(false);
    this.submitted = false;
    this.element.firstChild.classList.remove(this.className);
  }

  public getErrorClass(): boolean {
    return this.submitted && this.textInputValue.invalid
  }

  private setInnerHTMLTexts(textos: IOpenObjectArgument) {
    const headerTitle = this.element.querySelector('.Popup_Header_Title');
    const defaultMessage = this.element.querySelector('.Popup_Content_MsgDefault');
    
    headerTitle.innerHTML = textos.title;
    defaultMessage.innerHTML = textos.text;
  }

  private moveElementToLastChildOfBody() {
    document.body.appendChild(this.element);
  }

  private closeOnBackgroundClick() {
    this.element.addEventListener("click", el => {
      if (el.target.className === "Lightbox") {
        this.close();
      }
    });
  }

  private addThisPopupToTheService() {
    this.service.add(this);
  }
}
