import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { CUSTOMERS, CUSTOMERS_ID } from 'src/customers';
import { StateService } from './stateService';

type Customer = typeof CUSTOMERS['Apsa'];

@Injectable({ providedIn: 'root' })
export default class CustomerService {
  private customer = new BehaviorSubject<Customer>(null);

  constructor(
    private titleService: Title,
    private platform: Platform,
    public stateService: StateService,
  ) { }

  public initialize() {
    this.setRootHTMLElementCustomerAttributeToWorkWithCSSVariables();
    this.setMobileConfiguration();
    this.setWebConfiguration();
    // this.forceCustomer('Doitt');
  }

  public getCustomer(): Customer {
    return this.customer.getValue();
  }

  public getCustomerObservable() {
    return this.customer.asObservable();
  }

  public forceCustomer(key: string) {
    const customer: Customer = CUSTOMERS[key];

    if(customer) {
      this.applyCustomerConfig(customer);
    }
    else {
      console.error(`forceCustomer(): Customer with key "${key}" not fount in "customers.ts".`);
      // console.log(CUSTOMERS);
    }
  }

  public forceCustomerById(id: CUSTOMERS_ID) {
    const customer = this.findCustomerById(id);

    if(customer) {
      this.applyCustomerConfig(customer);
    }
    else {
      console.error(`forceCustomer(): Customer with id "${id}" not fount in "customers.ts".`);
      // console.log(CUSTOMERS);
    }
  }

  public forceDoittConfigIfMobile() {
    if(this.isMobile())
      window.setTimeout(() => this.forceDoittConfig(), 10);
  }

  public isSameId(id: CUSTOMERS_ID) {
    return this.getCustomer().id === id;
  }

  public isDifferentId(id: CUSTOMERS_ID) {
    return this.getCustomer().id !== id;
  }

  private setRootHTMLElementCustomerAttributeToWorkWithCSSVariables() {
    document.documentElement.setAttribute('customer', window.location.hostname);
  }

  private applyCustomerConfig(customer: Customer) {
    this.titleService.setTitle(customer.webTitle);
    document.documentElement.setAttribute('customer', customer.hosts[0]);
    this.customer.next(customer);
  }

  private setWebConfiguration() {
    if(this.isWeb())
      this.webConfiguration();
  }
  
  private webConfiguration() {
    this.tryToSetCustomerBasedURL();
    this.setWebTitle();
    this.setFavicon();
  }

  private tryToSetCustomerBasedURL() {
    const hostURL = window.location.hostname;
    const customersKeys = Object.keys(CUSTOMERS);
    
    for(let i = 0; i < customersKeys.length; i++) {
      const customer = CUSTOMERS[customersKeys[i]];

      if(customer.hosts.includes(hostURL)) {
        this.customer.next(customer);
        return;
      }
    }

    console.error(`Error: Could not find customer "${hostURL}". This URL must be added in corresponding "hosts" property in 'customers.ts' file. Falling back to Doitt configuration.`);
    this.customer.next(CUSTOMERS.Doitt);
  }

  private setWebTitle() {
    this.titleService.setTitle(this.getCustomer().webTitle);
  }

  private setFavicon() {
    const faviconLinkElement = document.querySelector('link[rel="icon"]');

    faviconLinkElement.setAttribute('href', this.getCustomer().favicon);
  }

  private setMobileConfiguration() {
    if(this.isMobile())
      this.mobileConfiguration();
  }
  
  private mobileConfiguration() {
    if(this.isUserNotLoggedIn())
      this.forceDoittConfigIfMobile();

    const storedCustomerId: CUSTOMERS_ID | null = sessionStorage.getItem('idCliente') as CUSTOMERS_ID;
  
    if(storedCustomerId !== null)
      window.setTimeout(() => this.forceCustomerById(storedCustomerId), 10);
  }
  
  private forceDoittConfig() {
    this.applyCustomerConfig(CUSTOMERS.Doitt);
  }

  private isUserNotLoggedIn() {
    const pessoaLogada = JSON.parse(sessionStorage.getItem('pessoaLogada')) || JSON.parse(sessionStorage.getItem('pessoaLogadaCA'));

    return pessoaLogada === null || pessoaLogada === undefined;
  }

  private isMobile() {
    return this.stateService.platformIsDeviceMobile();
  }

  private isWeb() {
    return (
      this.platform.is('desktop') || this.platform.is('mobileweb')
    );
  }

  private findCustomerById(id: CUSTOMERS_ID): Customer | null {
    const customers: Customer[] = Object.keys(CUSTOMERS).map(key => CUSTOMERS[key]);

    return customers.find((customer: Customer) => customer.id === id) || null;
  }
}