import { CUSTOMERS_ID } from 'src/customers';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { StateService } from '../../../shared/services/stateService';
import { Observable, of } from 'rxjs';
import { IMostUsedFeatures } from './DTO/response/IMostUsedFeatures';
import { IHasImportantUpdate } from './DTO/response/IHasImportanteUpdate';
import { MOCK } from './DTO/response/has-important-update.mock';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import CustomerService from 'src/app/shared/services/customer.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private urlApiHost = environment.apiCondominioDigital.host;
  private methods = environment.apiCondominioDigital.endpoints.methods;
  private customer = this.customerService.getCustomer();
  private idOrigemSite = +this.customer?.id;

  constructor(
    private http: HttpClient,
    public stateService: StateService,
    private constants: ConstantsService,
    private customerService: CustomerService,
  ) { }

  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8'
    });
  }

  private getLoggedInHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.stateService.getTokenApiCondominioDigital()
    });
  }

  public recuperarSenha(login) {
    var endpoint = this.methods.login.recuperarSenha;
    var url = this.urlApiHost + endpoint;
    var parameters = new HttpParams().set("login", login);
    return this.http.put<any>(url, null, { params: parameters, headers: this.getHeader() });

  }

  public recuperarLogin(cpfCnpj: any) {
    var endpoint = this.methods.login['recuperarLogin'];
    var url = this.urlApiHost + endpoint;
    var parameters = new HttpParams().set("cpfCnpj", cpfCnpj);
    return this.http.get<any>(url, { params: parameters, headers: this.getHeader() });
  }

  // 1º Envio o usuário
  public getTelefone(login: any) {
    var endpoint = this.methods.login.getTelefone;
    var url = this.urlApiHost + endpoint;
    var parameters = new HttpParams().set("login", login).set("alteracao", 'true').set('idOrigemSite', this.idOrigemSite);
    return this.http.get<any>(url, { params: parameters, headers: this.getHeader() });
  }

  // 2º Confirmo o número de celular
  public redefinirSenha(login, telefone) {
    var endpoint = this.methods.login.recuperarSenha
    var url = this.urlApiHost + endpoint;
    var body = { "login": login, "celular": telefone, 'idOrigemSite': this.idOrigemSite }
    return this.http.post<any>(url, body);
  }

  public redefinirSenhaNovoNumero(login: string, cpfCnpj: string, telefone: string) {
    var endpoint = this.methods.login.redefinirSenhaNovoNumero
    var url = this.urlApiHost + endpoint;
    const cpfCnpjTratado = cpfCnpj.replace(/\W/g,'').trim();
    const telefoneTratado = telefone.replace(/\W/g,'').trim();
    var body = { "login": login, "cpf_cnpj": cpfCnpjTratado, "celular": telefoneTratado, 'idOrigemSite': this.idOrigemSite }
    return this.http.post<any>(url, body);
  }
  
  verificaDigitalParaLogin(login, uid_device) {
    var endpoint = this.methods.autenticacaoDigital.possuiAutenticacao;
    var url = this.urlApiHost + endpoint;
    var body = { "login_pessoa": login, "uid_autenticacao" : uid_device }
    return this.http.post<any>(url, body, { headers: this.getHeader() });
  }

  logarDigital(login, uid_device, hash){
    var endpoint = this.methods.autenticacaoDigital.login;
    var url = this.urlApiHost + endpoint;
    var body = { "login_pessoa": login, "uid_autenticacao" : uid_device, "hash": hash}

    return this.http.post<any>(url, body, { headers: this.getHeader() });
  }

  getFuncionalidadesFrequentes(): Observable<IMostUsedFeatures[]> {
    const url = this.urlApiHost + this.methods.login.getFuncionalidadesFrequentes;
    const params = this.getFuncionalidadesFrequentesParams();
    const options = { params, headers: this.getLoggedInHeader() }

    return this.http.get<IMostUsedFeatures[]>(url, options);
  }

  getFuncionalidadesFrequentesParams() {
    return new HttpParams()
      .set('idPessoa', this.stateService.getPessoaLogada().Id)
      .set('contaEmpresa', this.stateService.getCondominioLogado().contaEmpresa)
      .set('numeroVersao', this.constants.appVersion);
  }

  enviarEmailConfirmacao() {
    let urlApiHost = environment.apiCondominioDigital.host;
    let methods = environment.apiCondominioDigital.endpoints.methods;

    var endpoint = methods.queroMeCadastrar.enviaEmailConfirmacao;
    var url = urlApiHost + endpoint;
    var body = {"idPessoa": this.stateService.getPessoaLogada().Id, 'idOrigemSite': this.idOrigemSite };

    return this.http.post<any>(url, body, { headers: this.getHeader() });
  }

  getAppHasImportantUpdate(): Observable<IHasImportantUpdate> {
    const url = environment.apiCondominioDigital.host + this.methods.login.getVersaoApp;

    return this.http.get<IHasImportantUpdate>(url, { headers: this.getHeader() });
  }

  getImagensCarrossel(idOrigemSite: CUSTOMERS_ID) {
    const url = environment.apiCondominioDigital.host + this.methods.login.getImagensCarrossel;

    var parametros = new HttpParams()      
    .set('idOrigemSite', idOrigemSite)  

    return this.http.get<any[]>(url, { headers: this.getHeader(), params: parametros });
  }
}
