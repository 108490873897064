import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ds-option-button',
  templateUrl: './option-button.component.html',
  styleUrls: ['./option-button.component.scss'],
})
export class OptionButtonComponent implements OnInit {

  public open:boolean = false
  
  public itemSelecionado: string = "Clique para selecionar"
  
  @Input() opcoes: any[] = []
  @Input() label: string

  @Output() selecionaItem = new EventEmitter()
  constructor() { }

  ngOnInit() {}

  selecionarItem(item?:any){
    if(item !== null && item !== undefined){
      this.itemSelecionado = item;
      this.selecionaItem.emit(item)
    } else{
      this.itemSelecionado = "Clique para selecionar";
      this.itemSelecionado = null;
    }
    this.open = !this.open
     
  }

}
