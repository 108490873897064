<div class="Modal Modal_EspacosReserva" [ngClass]="{ '__is-open': open }">
  <!-- TODO: Adicionar a class "__is-open" para habilitar o modal -->
  <div class="Modal_Close" (click)="close()">
    <i class="fa fa-times"></i>
  </div>
  <div class="Modal_Header">
    {{ (espaco) ? espaco.Name : ""}}
  </div>
  <div class="Modal_Content">

    <div class="Modal_EspacosReserva_Texto">
      {{ (espaco) ? espaco.Description : ""}}
    </div>

    <div class="Modal_EspacosReserva_Fotos" *ngIf="espaco && espaco.Images.length > 0">
        <div class="EspacosReserva_Fotos_Titulo">
          Fotos do Espaço
        </div>
        <div class="EspacosReserva_Fotos_Lista">
          
          <div class="Arrow_Lista_Icon" (click)="prevSlide()" *ngIf="this.platform.is('desktop')">
            <i class="fa fa-chevron-left"></i>
          </div>

          <ion-slides class="Fotos_Lista_Slider" loop="true" pager="true" scrollbar="true" slidesPerView="2">
            <ion-slide class="Fotos_Lista_Item" *ngFor="let item of espaco.Images;">
              <img src="data:image/png;base64,{{item.File}}"/>
            </ion-slide>
          </ion-slides>

          <div class="Arrow_Lista_Icon" (click)="nextSlide()" *ngIf="this.platform.is('desktop')">
            <i class="fa fa-chevron-right"></i>
          </div>
          
        </div>
    </div>

  </div>

</div>
