<div class="Modal__Container__Mensagens">
  <header>
   <h1> Histórico de mensagens <i class="fas fa-history"></i></h1>
    <p>ID: {{mensagens.id}}</p>
  </header>
  <!-- Original -->
  <!-- <main>
    <ng-container *ngFor="let mensagem of historico">
      <section>
        <div class="Label__Content">
          <p class="Label">{{!mensagem.mensagemSolicitante ? nomeAdministradora : mensagens.solicitante}}</p>
          <p class="Label__Date">{{ mensagem.mensagemStatus}}</p>
          <p class="Label__Date">{{mensagem.data}}</p>
        </div>
        <div class="Mensagem">
          {{ mensagem.mensagemAdm|| mensagem.mensagemSolicitante || mensagem.mensagemStatus}}
        </div>
      </section>      
    </ng-container>
  </main> -->

  <main>
    <div class="Historico__Content">
      <!-- Loop com Mensagens:  -->
      <div class="Historico__Mensagem">
        <ng-container *ngFor="let mensagem of historico; let i = index">
          <div class="Historico__Item">
           <div class="Item__Titulo">
              <p class="Item__Data" 
                *ngIf="mensagem.mensagemAdm || mensagem.mensagemSolicitante || mensagem.mensagemStatus"
              >
               {{mensagem.dataAbertura}}
              </p>
              <p>{{mensagem.mensagemStatus}}</p>
           </div>

            <div class="Item__Mensagem">
              <p class="Item__Data" *ngIf="mensagem.mensagemAdm || mensagem.mensagemSolicitante">
                {{mensagem.dataConclusao}}
              </p>

              <div class="Item__Adm" *ngIf="mensagem.mensagemAdm">
                <p>{{mensagens.visaoAdm ? 'Retaguarda' : nomeAdministradora}}</p>
                <p class="Item__Adm__Mensagem">{{mensagem.mensagemAdm}}</p>
              </div>

              <div class="Item__Adm" *ngIf="mensagem.mensagemSolicitante">
                <p>{{ mensagens.solicitante}}</p>
                <p class="Item__Adm__Mensagem">{{mensagem.mensagemSolicitante}}</p>
              </div>
            </div>
          </div>
        </ng-container>    
      </div>
    </div>
  </main>

  <footer>
    <ds-button color="Danger" (click)="closeModal()">Fechar</ds-button>
  </footer>
</div>
<div class="Overlay" (click)="closeModal()"></div>