import { StateService } from 'src/app/shared/services/stateService';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { SaldoContasDTO } from './DTO/response/SaldoContas.DTO';

@Injectable({
  providedIn: 'root'
})
export class SaldoEmContasService {
  private urlApi = environment.apiCondominioDigital.host;
  private methods = environment.apiCondominioDigital.endpoints;

  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.stateService.getTokenApiCondominioDigital()
    });
  }

  constructor(private http: HttpClient, public stateService: StateService) { }
  
  public getListaSaldoConta(){
    const condominio = this.stateService.getCondominioLogado();

    const parametros = new HttpParams()
      .set('contaCondominio', condominio.conta)
    
      return this.http.get<SaldoContasDTO[]>(`${this.urlApi + this.methods.methods.saldoContas.getSaldoContas}`,
      { headers: this.getHeader(), params: parametros })
  }
}
