import { Injectable } from "@angular/core";
import { LightBoxesService } from "../components/lightboxes";
import { CondominioService } from "./condominio.service";
import { StateService } from "./stateService";

@Injectable({ providedIn: 'root'})
export class BannerModalService {
  constructor(
    private condominioService: CondominioService,
    private lightboxesService: LightBoxesService,
    public stateService: StateService,
  ) {

  }

  exibirBannerSelecaoDeCondominio() {
    this.condominioService.getBannerRota('banner-selecao-condominio').subscribe(res => {
      if(res && res.exibirBanner) {
        let exibeModalSessao = sessionStorage.getItem('exibeBannerFaleComAApsa');
        let exibeModalLocal = JSON.parse(localStorage.getItem('exibeBannerFaleComAApsa'));
        let obj = exibeModalLocal.find(item => item.id === this.stateService.getPessoaLogada().Id);
        
        if (!exibeModalSessao && obj.exibeModal) {
          this.lightboxesService.openModalImageLinkButton("image-with-link-button", {
            path: window.innerWidth >= 800 ? res.urlImagemDesktop : res.urlImagemMobile,
            userId: obj.id,
            url: res.exibirLink ? res.link : null,
          }, 0)
        }
      }
    });
  }
}