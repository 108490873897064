import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConstantsService } from '../../../shared/services/constants.service';
import { StateService } from '../../services/stateService';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AlertaService } from '../../services/alerta.service';
import { AccessibilityLevelSwitcher } from '../../services/accessibility.service';
import { ThemeService } from '../../services/theme.service';
import CustomerService from '../../services/customer.service';

@Component({
  selector: 'app-acessibilidade',
  templateUrl: './acessibilidade.component.html',
  styleUrls: ['./acessibilidade.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AcessibilidadeComponent implements OnInit {
  public temaAcessibilidade: number;
  public customer$ = this.customerService.getCustomerObservable();
  public fonteAcessibilidade: any;

  constructor(
    public statusBar: StatusBar,
    public constants: ConstantsService,
    public stateService: StateService,
    public pxSwitcher: AccessibilityLevelSwitcher,
    public themeService: ThemeService,
    public customerService: CustomerService,
  ) { }

  ngOnInit() {
    this.temaAcessibilidade = this.stateService.getNumberTemaAcessibilidade()
  }

  selecionarTema(numeroTema: number) {
    const body = document.getElementsByTagName("BODY")[0];
    if (body) {
      var acessibilidade: any = { fonte: this.stateService.getFonteAcessibilidade(), tema: this.stateService.themeList[numeroTema] }
      sessionStorage.setItem('acessibilidade', JSON.stringify(acessibilidade));
      this.stateService.validarTemaUsuario();
      this.stateService.cadastraAcessibilidade().subscribe();
      this.themeService.setActiveThemeName(this.stateService.themeList[numeroTema]);
      this.ngOnInit();
    }
  }

  selecionarFonte(event) {
    const index: number | any = event.detail.value - 1;

    if(index !== undefined && !Number.isNaN(index)) {
      var acessibilidade: any = { fonte: this.pxSwitcher.getAccessibilityLevelNameByIndex(index), tema: this.stateService.getTemaAcessibilidade() }
      sessionStorage.setItem('acessibilidade', JSON.stringify(acessibilidade));
      this.pxSwitcher.setAccessibilityLevelByIndex(index);
      this.stateService.validarTemaUsuario();
      this.stateService.cadastraAcessibilidade().subscribe();
    }
  }
}
