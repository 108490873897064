<div class="search-input">
  <form
    (ngSubmit)="submit()"
    [formGroup]="form"
  >
    <!-- <pre>{{onlyNumber}}</pre> -->
    <div class="search-input__container">
      <input
        formControlName="searchTerm"
        type="search"
        placeholder="{{ placeholder }}"
        (keyup)="onKey()"
        maxlength="18"
      >

      <div class="search-input__clear-icon">
        <i
          class="fas fa-times"
          (click)="clear()"
          [ngClass]="{'__is-active': isFiltered}"
        ></i>
      </div>
    </div>

    <button
      class="search-input__submit-button"
      [ngClass]="{ 'search-input__submit-button--disabled': form.invalid }"
    >
      <i class="apsa-icon-solid apsa-icon-solid-magnifying-glass" (click)="submit()"></i>
      <span>Buscar</span>
    </button>
  </form>
</div>