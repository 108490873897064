import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';
import { HomeService } from 'src/app/modules/home/services/home.service';
import { LightBoxesService } from 'src/app/shared/components/lightboxes';
import { AlertaService } from 'src/app/shared/services/alerta.service';
import { BannerModalService } from 'src/app/shared/services/bannerModal.service';
import { CondominioService } from 'src/app/shared/services/condominio.service';
import CustomerService from 'src/app/shared/services/customer.service';
import { PessoaLogadaDTO } from 'src/app/shared/services/DTO/Request/PessoaLogada.DTO';
import { CondominioDTO } from 'src/app/shared/services/DTO/response/Condominio.DTO';
import { FuncionalidadeDTO } from 'src/app/shared/services/DTO/response/Funcionalidade.DTO';
import { PerfilDTO } from 'src/app/shared/services/DTO/response/Perfil.DTO';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PerfilDoitt } from 'src/app/shared/services/PerfilDoitt.service';
import { StateService } from 'src/app/shared/services/stateService';

import { menuListFake } from './menuListFake'
import { ConstantsService } from '../../shared/services/constants.service';
import { ModalService } from '../../shared/services/modal.service';

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit, AfterViewChecked {
  @ViewChild("buscaElemento") buscaElemento: ElementRef;
  public isAdmView: boolean;
  public rotaAtual = this.router.url;
  public numeroCondSelecionado: number;
  public numeroDeCondominios: number;
  public filterCondominioMenu: any = { type: 1 };
  public filterProprietarioMenu: any = { type: 2 };
  public filterLocatarioMenu: any = { type: 3 };
  public customer$ = this.customerService.getCustomerObservable();
  public customer = this.customerService.getCustomer();
  public openableMenuItems = {
    reserva: false,
    gestaoDePessoal: false,
    comunicacao: false,
    "o-condominio": false,
    sobre: false,
    informativos: false,
    financeiro: false,
    tutorial: false,
    relatoriosGerenciais: false,
    relatorios: false,
    cashBack: false,
    dashboard: false,
    painelSolicitacoes: false,
  };
  public menuList: Array<any> = [];
  public isMenuAdmOpen: boolean = false;
  private searchSubject: Subject<string> = new Subject();
  public openModalDadosConta: boolean = false;
  public openNotificationModalMobile: boolean = false;
  public openAdesaoModalMobile: boolean;
  public openAdesaoIPTUModalMobile: boolean;
  public openAssinaturaDigitalMobile: boolean;
  public ehGestaoTotal: boolean =
    sessionStorage.getItem("ehGestaoTotal") == "true";
  //public ehContaPool: boolean = (this.stateService.getCondominioLogado()) ? this.stateService.getCondominioLogado().ehContaPool : false;
  public perfilLogadoEhGestorOuSindico: boolean;
  private pessoa: any;
  public urlArray = [];
  public totalAlertas;
  public listNotifications;
  private rotasPorId = [
    {
      value: 287,
      route: "/checklist",
    },
    {
      value: 291,
      route: "/muralGT",
    },
    {
      value: 811,
      route: "/muralGT",
    },
    {
      value: 816,
      route: "/ocorrencia",
    },
    {
      value: 814,
      route: "/muralGT",
    },
    {
      value: 811,
      route: "/enquetes",
    },
    {
      value: 814,
      route: "/mural",
    },
    {
      value: 211,
      route: "/condomino",
    },
    {
      value: 219,
      route: "/cotas",
    },
    {
      value: 220,
      route: "/cotas",
    },
    {
      value: 306,
      route: "/envio-documento",
    },
    {
      value: 285,
      route: "/ocorrenciaGT",
    },
    {
      value: 219,
      route: "/cotas",
    },
    {
      value: 227,
      route: "/funcionario",
    },
    {
      value: 293,
      route: "/colaboradores",
    },
    {
      value: 218,
      route: "/extrato/sindico",
    },
    {
      value: 286,
      route: "/reserva",
    },
    {
      value: 294,
      route: "/reserva",
    },
    {
      value: 304,
      route: "/reserva",
    },
    {
      value: 205,
      route: "/dados-condominio",
    },
    {
      value: 819,
      route: "/cadastro-de-espacos",
    },
    // {
    //   value: 853,
    //   route: '/minhas-solicitacoes'
    // },
    {
      value: 810,
      route: "/documento",
    },
    // {
    //   value: 833,
    //   route: '/relatorio-pagamentos'
    // }
  ];

  public modalAvaliacao: boolean = false;
  public modalAvaliacaoForm: UntypedFormGroup;
  public trocaDeCondominio: number = 0;
  public ehPerfilTeste;
  public condominios;

  constructor(
    private condominioService: CondominioService,
    public stateService: StateService,
    private router: Router,
    private lightBoxesService: LightBoxesService,
    public constant: ConstantsService,
    private activatedRoute: ActivatedRoute,
    private homeService: HomeService,
    private cdr: ChangeDetectorRef,
    private activedRoute: ActivatedRoute,
    private statusBar: StatusBar,
    public modalService: ModalService,
    private customerService: CustomerService,
    public platform: Platform,
    public headerService: HeaderService,
    public alertaService: AlertaService,
    public formbuilder: UntypedFormBuilder,
    private bannerModalService: BannerModalService,
    public perfilDoitt: PerfilDoitt,
    private http: HttpClient
  ) {
    activedRoute.params.subscribe((val) => {
      this.ngOnInit();
      this.condominioService.verificaAdesaoCondominio();
    });
    this.ehPerfilTeste = this.constant.ehPerfilTeste();
    this.condominios = this.ehPerfilTeste ? null : this.stateService.getUserCondominiums();
    this.createForm();
  }

  ngOnInit() {
    this.constant.condominioImovelMostrado =
      this.constant.condominioImovelAtivo;
    this.setAdmView();
    this.modalService.add("app-dados-conta");
    this.modalService.add("modal-avaliar-app");

    this.carregarPagina();
    this.openAdesaoModalMobile =
      this.stateService.platformIsDeviceMobile() &&
      JSON.parse(sessionStorage.getItem("exibirModalAdesaoBoletoDigital"));
    this.openAssinaturaDigitalMobile =
      this.stateService.platformIsDeviceMobile() &&
      JSON.parse(sessionStorage.getItem("exibirModalAssinaturaDigital"));
    this.openAdesaoIPTUModalMobile =
      this.stateService.platformIsDeviceMobile() &&
      JSON.parse(sessionStorage.getItem("exibirModalIPTU"));

    if (this.stateService.visaoCentralAtendimento()) {
      this.listarMenuCentralAtendimento();
      this.numeroDeCondominios = 1;
    } else {
      this.listarMenu().then(
        (menu) => (this.numeroDeCondominios = menu ? menu.length : 0)
      );
    }

    this.defineStatusInicialMenu();
    this.stateService.validarTemaUsuario();
    this.verificaSeTemAcessoArota();
    this.salvaUltimaURLvalida();

    const redirect: any = JSON.parse(localStorage.getItem("redirect"));
    if (redirect) this.abreMenuRedirect(redirect);
  }

  updateImovelMostrado(item) {
    if (item.idMenu != this.constant.condominioImovelMostrado) {
      this.constant.trocaRotaMenu.next(item.idMenu);
      this.constant.condominioImovelMostrado = item.idMenu;
    }
  }

  setAdmView() {
    this.isAdmView = sessionStorage.getItem("visaoADM") === "true";
  }

  salvaUltimaURLvalida() {
    if (this.router.url != "/forbidden") {
      let temPermissao = this.verificaSeTemAcessoArota();
      if (temPermissao) {
        localStorage.setItem("ultimaURLpermitida", this.router.url);
      }
    }
  }

  async abreMenuRedirect(redirect) {
    const menuList = await this.listarMenu();

    for (let menuItem of menuList) {
      if (menuItem.ref.contaEmpresa == redirect.conta) {
        await this.selecionarMenu(menuItem);
        this.router.navigate([redirect.rota]);
        localStorage.removeItem("redirect");
      }
    }
  }

  verificaSeTemAcessoArota() {
    let rotasComValidacao = this.rotasPorId.filter(
      (item) => item.route == this.router.url
    );
    if (rotasComValidacao.length) {
      let temPermissaoArota = rotasComValidacao.some((element) =>
        this.possuiPermissaoAcesso(element.value)
      );
      if (!temPermissaoArota) {
        this.router.navigate(["/forbidden"], {
          queryParams: {
            ultimaURLpermitida: localStorage.getItem("ultimaURLpermitida"),
          },
        });
      }
      return temPermissaoArota;
    }
  }

  // verificaGestaoTotal() {
  //   this.ehGestaoTotal = this.stateService.getCondominioLogado().ehGestaoTotal;
  // }

  verificaContaPool() {
    if (this.stateService.getCondominioLogado()) {
      return this.stateService.getCondominioLogado().ehContaPool;
    }
    return false;
  }

  validaExibicaoContrato() {
    var gerencial = this.stateService.possuiAcessoCentralAtendimento();
    var condominio = this.stateService.getCondominioLogado();
    if (gerencial && condominio && !condominio.ehGestaoTotal) {
      if (
        condominio &&
        (condominio.agencia == "19" || condominio.agencia == "20")
      ) {
        return true;
      } else return false;
    } else if (condominio && !this.ehGestaoTotal) {
      return (
        (condominio.agencia == "19" || condominio.agencia == "20") &&
        (this.stateService.possuiAcessoFuncionalidadeGeral(866) ||
          this.stateService.possuiAcessoFuncionalidadeGeral(289))
      );
    } else
      return this.possuiPermissaoAcesso(866) || this.possuiPermissaoAcesso(289);
  }

  possuiPerfilDoitt(idFuncionalidade): boolean {
    const pessoa: PessoaLogadaDTO = this.stateService.getPessoaLogada();
    return pessoa.perfis.includes(idFuncionalidade);
  }

  getBooleanOcorrenciaOuFaleComSindico() {
    this.homeService
      .verificarPerfilLogado()
      .subscribe((item) => (this.perfilLogadoEhGestorOuSindico = item));
  }

  ngAfterViewChecked() {
    // this.scrollParaOMenuAberto();
    this.getBooleanOcorrenciaOuFaleComSindico();
    this.ehGestaoTotal = this.stateService.getCondominioLogado()
      ? this.stateService.getCondominioLogado().ehGestaoTotal
      : false;
    this.cdr.detectChanges();
  }

  abrirFecharModalDadosConta(event: number) {
    if (event === 1) this.modalService.open("app-dados-conta");
  }

  fecharModalDadosConta(event: number) {
    this.modalService.close("app-dados-conta");
  }

  abriModalNotificacoesMobile() {
    this.openNotificationModalMobile = true;
  }

  exibeModalAdesaoBoletoDigital() {
    this.openAdesaoModalMobile = !this.openAdesaoModalMobile;
  }

  // exibeModalAdesaoIPTU(){
  //   this.openAdesaoIPTUModalMobile = !this.openAdesaoIPTUModalMobile;
  // }

  exibeModalAssinaturaDigital() {
    this.openAssinaturaDigitalMobile = !this.openAssinaturaDigitalMobile;
  }

  buscarImovel(argumentoBusca) {
    this.listarMenu().then((menuList) => {
      this.menuList = menuList.filter(
        (obj) => obj.label.toLowerCase().indexOf(argumentoBusca) > -1
      );
    });
  }

  carregarPagina() {
    this.setBuscarImovelSubject();
    this.validarPessoaLogada();
    if (this.stateService.visaoCentralAtendimento()) {
      this.listarMenuCentralAtendimento();
    } else {
      this.listarMenu();
    }
    this.verificarCondominioLogado();
    this.verificarImovelLogado();
  }

  defineStatusInicialMenu() {
    if (window.innerWidth > 800) {
      this.constant.statusMenu = true;
    } else if (window.innerWidth <= 799) {
      this.constant.statusMenu = false;
    }
  }

  closeMenuMobile() {
    this.stateService.openCloseMenuMobile();
  }

  downloadFile() {
    const fileUrl = "../../../assets/mock/doitt-extrato-exemplo.pdf"
    this.http.get(fileUrl, { responseType: 'blob' })
      .subscribe(response => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'doitt-extrato-exemplo.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
  }

  extrairMenuCondominios(condominios) {
    // console.log("extrair", condominios);

    if (condominios == null && this.constant.ehPerfilTeste()) {
      const condominiosMock = menuListFake.condominiosImoveis.condominios

      const menuCondominioMock = condominiosMock.map(obj => {
        const condominio: any = obj.condominio
        const perfis = obj.perfis
        const funcionalidades = obj.funcionalidades

        const resposta = {
          idMenu: this.getCodigoCondominio(condominio),
          agenciaConta: condominio.agencia + "-" + condominio.contaEmpresa,
          label: condominio.nome,
          type: 1,
          ref: condominio,
          perfis: perfis,
          funcionalidades: funcionalidades,
        }
        return resposta
      })

      return menuCondominioMock
    };

    if(condominios == null) return [];

    let menu = [];
    condominios.forEach((obj) => {
      let condominio = obj.condominio;
      let perfis = obj.perfis;
      let func = obj.funcionalidades;
      menu.push({
        idMenu: this.getCodigoCondominio(condominio),
        agenciaConta: condominio.agencia + "-" + condominio.contaEmpresa,
        label: condominio.nome,
        type: 1,
        ref: condominio,
        perfis: perfis,
        funcionalidades: func,
      });
    });
    return menu;
  }

  extrairMenuCondominioCentralAtendimento(condominio) {
    if (condominio == null) return [];
    let menu = [];
    menu.push({
      idMenu: this.getCodigoCondominio(condominio),
      agenciaConta: condominio.agencia + "-" + condominio.contaEmpresa,
      label: condominio.nome,
      type: 1,
      ref: condominio,
      perfis: null,
      funcionalidades: null,
    });
    return menu;
  }

  isMenuItemOpen(menuItemName: string, ...routes: string[]) {
    return this.openableMenuItems[menuItemName];
  }

  isRoute(...routes: string[]) {
    return routes.includes(this.rotaAtual);
  }

  openMenuItem(menuItemName: string) {
    if (this.openableMenuItems[menuItemName]) {
      this.openableMenuItems[menuItemName] = false;
    } else {
      switch (menuItemName) {
        case "informativos":
          this.closeAllMenuItemsExcept("sobre");
          break;
        case "cashBack":
          this.closeAllMenuItemsExcept("cashBack");
          break;
          // this.router.navigateByUrl("/cashBack/dashboard");
        // case "dashboard":
        //   this.router.navigateByUrl("/cashBack/dashboard");
        //   this.closeAllMenuItemsExcept("dashboard");
        //   break;
        case "reserva":
        case "gestaoDePessoal":
        case "relatoriosGerenciais":
          window.setTimeout(() => this.scrollThisSidebarToBottom(), 400);
          break;
        case "painelSolicitacoes":
          this.closeAllMenuItemsExcept("painelSolicitacoes");
          break;
        default:
          this.closeAllMenuItems();
          break;
        }

      this.openableMenuItems[menuItemName] = true;
    }
    this.openableMenuItems[menuItemName] = true;
  }

  closeAllMenuItems() {
    for (const menuItem in this.openableMenuItems) {
      this.openableMenuItems[menuItem] = false;
    }
  }

  closeAllMenuItemsExcept(...menuItemsToIgnore: string[]) {
    for (const menuItem in this.openableMenuItems) {
      if (!menuItemsToIgnore.includes(menuItem))
        this.openableMenuItems[menuItem] = false;
    }
  }

  extrairMenuImovelCentralAtendimento(imovel) {
    if (imovel == null) return [];
    let menu = [];
    menu.push({
      idMenu: "IMO-" + imovel.codigoImovelPrinipal,
      agenciaConta: null,
      label: imovel.endereco,
      type: imovel.tipoPerfil,
      ref: imovel,
      perfis: null,
      funcionalidades: null,
    });
    return menu;
  }

  extrairMenuProprietario(imoveis) {
    if (imoveis == null) return [];
    let menu = [];
    let tipo = 2;
    imoveis.forEach((obj) => {
      menu.push({
        idMenu: "IMO-" + obj.imovel.codigoImovel,
        label: obj.imovel.endereco,
        agenciaConta: null,
        type: tipo,
        ref: obj.imovel,
      });
    });
    return menu;
  }

  extrairMenuLocatario(imoveis) {
    if (imoveis == null) return [];
    let menu = [];
    let tipo = 3;
    imoveis.forEach((obj) => {
      menu.push({
        idMenu: "IMO-" + obj.imovel.codigoImovelPrinipal,
        label: obj.imovel.enderecoimovel,
        agenciaConta: null,
        type: tipo,
        ref: obj.imovel,
      });
    });
    return menu;
  }

  getCodigoCondominio(condominio: CondominioDTO) {
    //console.log("get", condominio)
    return "CO-" + condominio.contaEmpresa;
  }

  // getPrefixoImovel(tipoImovel) {
  //   if (tipoImovel == 2) return "P";
  //   return "L";
  // }

  // getTipoFromPrefixo(prefixo) {
  //   console.log("pref", prefixo, prefixo == "L")
  //   return (prefixo == "L") ? 3 : 2;
  // }

  listarMenuCentralAtendimento() {
    this.menuList = [];
    var condominioLogado = this.stateService.getCondominioLogado();
    if (condominioLogado) {
      var menuCondominio =
        this.extrairMenuCondominioCentralAtendimento(condominioLogado);
      this.menuList = this.menuList.concat(menuCondominio);
      //console.log("menu", this.menuList);
    }

    var imovelLogado = this.stateService.getImovelLogado();
    if (imovelLogado) {
      var menuImovel = this.extrairMenuImovelCentralAtendimento(imovelLogado);
      this.menuList = this.menuList.concat(menuImovel);
      //console.log(this.menuList);
    }
    return this.menuList;
  }

  listarMenu() {
    return this.condominioService
      .listarCondominiosImoveis(this.pessoa.cpfCnpj)
      .then((item) => {
        if (item) {
          var menuCondominio = this.extrairMenuCondominios(item.condominios);
          var menuProprietario = this.extrairMenuProprietario(
            item.imoveisProprietario
          );
          var menuLocatario = this.extrairMenuLocatario(item.imoveisLocatario);

          this.menuList = [];
          this.menuList = this.menuList.concat(menuCondominio);
          this.menuList = this.menuList.concat(menuProprietario);
          this.menuList = this.menuList.concat(menuLocatario);
          // console.log('list', this.menuList)
          return this.menuList;
        }
      });
  }

  isMenuActive(menuItem) {
    return this.constant.condominioImovelAtivo === menuItem.idMenu;
  }

  searchEvent(buscaElement) {
    var argumento = buscaElement.value.toLowerCase();
    if (argumento.length > 1 && argumento.length <= 2) return;
    this.searchSubject.next(argumento);
  }

  limparFiltroMenu() {
    this.searchSubject.next("");
    this.buscaElemento.nativeElement.value = "";
  }

  exibirModalSalaoImoveis() {
    this.bannerModalService.exibirBannerSelecaoDeCondominio();

    // let exibeModalSessao = sessionStorage.getItem('exibeBannerFaleComAApsa');
    // let exibeModalLocal = JSON.parse(localStorage.getItem('exibeBannerFaleComAApsa'));
    // // let possuiPerfilSindico = this.stateService.possuiPerfilSindico_();
    // let obj = exibeModalLocal.find(item => item.id === this.stateService.getPessoaLogada().Id);

    // if (!exibeModalSessao && obj.exibeModal) {
    //   this.lightBoxesService.openModalImageLinkButton("image-with-link-button", {
    //     path: '../../../assets/img/BannerSalaoDeImoveis.png',
    //     userId: obj.id,
    //     url: 'https://doitt.com.br/salaodeimoveis'
    //   }, 0)
    // }
  }

  irParaRota(rota:string) {
    this.router.navigate([`/${rota}`])
  }

  async selecionarMenu(menuItem): Promise<void> {
    this.stateService.setVeioMenuLateral(false)
    return new Promise(async (resolve) => {
      this.exibirModalSalaoImoveis();
      this.stateService.closeAllComponentsHeader(
        ".Header_Atalho_CentralAtendimento"
      );
      this.stateService.closeAllComponentsHeader(".Header_Atalho_Perfil");
      sessionStorage.removeItem("dadosGerente");
      sessionStorage.removeItem("tiposSeguro");
      this.isMenuAdmOpen = false;

      if (menuItem.idMenu == this.constant.condominioImovelAtivo) {
        this.constant.condominioImovelAtivo = null;
        return;
      }
      switch (menuItem.type) {
        case 1:
          await this.selecionarCondominio(
            menuItem.ref,
            menuItem.perfis,
            menuItem.funcionalidades
          );
          break;
        default:
          this.selecionarImovel(menuItem.ref);
          break;
      }
      this.constant.condominioImovelAtivo = menuItem.idMenu;
      resolve();
    });
  }

  selecionaMenuAdministracao() {
    this.stateService.closeAllComponentsHeader(
      ".Header_Atalho_CentralAtendimento"
    );
    this.stateService.closeAllComponentsHeader(".Header_Atalho_Perfil");
    this.constant.condominioImovelAtivo = null;
    this.isMenuAdmOpen = !this.isMenuAdmOpen;
  }

  selecionarCondominio(
    condSelecionado: CondominioDTO,
    perfis: Array<PerfilDTO>,
    funcionalidades: Array<FuncionalidadeDTO>
  ): Promise<void> {
    return new Promise((resolve) => {
      if (condSelecionado == undefined) {
        resolve();
        return;
      }

      var condominio = <CondominioDTO>condSelecionado;
      this.condominioService
        .logarCondominio(condominio, perfis, funcionalidades)
        .add(() => {
          this.constant.condominioImovelAtivo =
            this.getCodigoCondominio(condominio);
          this.ehGestaoTotal = condominio.ehGestaoTotal;
          CondominioService.trocaCondominio.next(condSelecionado);
          this.condominioService.verificaAdesaoCondominio();
          this.carregarQuantidadeAlertas(
            condominio.agencia,
            condominio.contaEmpresa
          );
          this.router.navigate(["/home"]);
          // this.trocaDeCondominio++
          resolve();
        });
    });
  }

  removerCondominioLogado() {
    if(this.stateService.getCondominioLogado()) sessionStorage.removeItem('condominioLogado')
    this.stateService.setVeioMenuLateral(true)
    // this.isMenuActive({idMenu: 0})
  }

  ehCondominioPrime(): boolean {
    var condominioLogado = this.stateService.getCondominioLogado();
    if (!condominioLogado) return false;
    return condominioLogado.prime === 1;
  }

  selecionarImovel(imovel) {
    if (imovel == undefined) return;
    this.condominioService.logarImovel(imovel);
    if (imovel.codigoImovel == null || imovel.codigoImovel == undefined) {
      this.constant.condominioImovelAtivo =
        "IMO-" + imovel.codigoImovelPrinipal;
    } else {
      this.constant.condominioImovelAtivo = "IMO-" + imovel.codigoImovel;
    }
    CondominioService.trocaCondominio.next(null);
  }

  setBuscarImovelSubject() {
    this.searchSubject.pipe(debounceTime(300)).subscribe((searchTextValue) => {
      this.buscarImovel(searchTextValue);
    });
  }

  validarPessoaLogada() {
    var obj = JSON.parse(sessionStorage.getItem("pessoaLogada"));
    if (obj == "undefined" || obj == undefined) {
      sessionStorage.clear();
      console.log("VEIO LOGIN EXPIRADO 1");
      this.router.navigate(["/"], {
        queryParams: {
          showMsg: true,
          title: "Acesso expirado",
          mensagem: "Faça o login novamente",
        },
      });
      return;
    }
    this.pessoa = obj;
  }

  verificarCondominioLogado() {
    var condominio = this.stateService.getCondominioLogado();
    if (condominio == null || condominio == undefined) return;
    if (
      this.constant.condominioImovelAtivo == null ||
      this.constant.condominioImovelAtivo == undefined
    ) {
      this.constant.condominioImovelAtivo = "CO-" + condominio.contaEmpresa;
    }
    //this.selecionarCondominio(condominio, condominio.perfis);
  }

  verificarImovelLogado() {
    var imovel = this.stateService.getImovelLogado();
    if (imovel == null) return;
    var prefixo = imovel.tipo; //o prefixo do menu é o tipo que retornamos na API.
    //var tipo = this.getTipoFromPrefixo(prefixo);
    if (imovel == null || imovel == undefined) return;
    this.selecionarImovel(imovel);
  }

  zerarMenu() {
    this.constant.condominioImovelAtivo = null;
  }

  possuiPermissaoAcesso(idFuncionalidade: number) {
    return this.stateService.possuiAcessoFuncionalidade(idFuncionalidade);
  }

  possuiAcessoFuncionalidadeGeral(idFuncionalidade: number) {
    return this.stateService.possuiAcessoFuncionalidadeGeral(idFuncionalidade);
  }

  // // função para só liberar para Monica.Brito
  // podeVisualizarLancamentoProvenDescontos(){
  //   var pessoa = this.stateService.getPessoaLogada();
  //   return pessoa && pessoa.Id == "21183"; // só libera p Monica
  // }

  possuiPermissaoAcessoValidacaoCentralAtendimento(idFuncionalidade: number) {
    if (this.stateService.visaoCentralAtendimento())
      return this.stateService.possuiAcessoFuncionalidadeGeral(
        idFuncionalidade
      );
    else return this.stateService.possuiAcessoFuncionalidade(idFuncionalidade);
  }

  scrollParaOMenuAberto() {
    let arrayDeObjetosComClasseIsOpen = document.getElementsByClassName(
      "MenuList_Content_Item __is-open"
    );
    var menuAbertoAtual =
      arrayDeObjetosComClasseIsOpen[arrayDeObjetosComClasseIsOpen.length - 1];
    if (menuAbertoAtual) {
      let posicaoMenuAberto = menuAbertoAtual["offsetTop"];

      let arraySidebarContent =
        document.getElementsByClassName("Sidebar_Content");
      let sidebarAtual = arraySidebarContent[arraySidebarContent.length - 1];

      if (sidebarAtual) {
        let alturaSideBarAtual = sidebarAtual["offsetTop"];
        sidebarAtual.scrollTo(0, posicaoMenuAberto + alturaSideBarAtual);
      }
    }
  }

  handleClickVisiteONossoSite() {
    this.router.navigate(["/seguros"]);
  }

  handleClickComodidades() {
    this.router.navigate(["/descontos-parceiros"]);
  }

  handleClickListaParceiros(){
    this.router.navigate(["/cashBack/lista-parceiros"]);
  }

  handleFornecedoresOptionClick() {
    this.stateService.openExternalLink(
      "https://www.revistasindico.com.br/guia"
    );
  }

  carregarQuantidadeAlertas(agencia, contaEmpresa) {
    if (this.ehGestaoTotal) {
      this.totalAlertas = null;
      this.listNotifications = null;

      var cpfPessoaLogada = this.stateService.getPessoaLogada()
        ? this.stateService.getPessoaLogada().CpfCnpj
        : null;
      var profileCode = "1";
      let origem;

      this.platform.is("desktop")
        ? (origem = "1")
        : this.platform.is("mobile")
        ? (origem = "2")
        : null;

      this.alertaService
        .getTotalAlertas(
          origem,
          cpfPessoaLogada,
          profileCode,
          agencia,
          contaEmpresa
        )
        .subscribe((response) => {
          this.totalAlertas = response;
        });

      this.alertaService
        .getAlertas(origem, cpfPessoaLogada, profileCode, agencia, contaEmpresa)
        .subscribe((response) => {
          this.listNotifications = response.Data;
        });
    }
  }

  private scrollThisSidebarToBottom() {
    const elements = document.querySelectorAll(".Sidebar_Content");
    const el = elements[elements.length - 1];

    el.scrollTo({ top: el.scrollHeight, behavior: "smooth" });
  }

  public returnPlaceHolder(): string {
    return "Envie a sua sugestão ou melhoria aqui...";
  }

  openModalAvaliacao() {
    this.modalAvaliacao = true;
  }

  closeModalAvaliacao() {
    this.modalAvaliacao = false;
  }

  createForm() {
    this.modalAvaliacaoForm = this.formbuilder.group({
      descricao: ["", []],
    });

    this.setValidators();
  }

  enviarAvaliacao() {
    const avaliacao = this.modalAvaliacaoForm.get("descricao").value
    const idOrigemDoitt = +this.customer.id

    this.stateService.registrarReclamacao(avaliacao, idOrigemDoitt).subscribe(
      (item) => {
        this.lightBoxesService.open(
          "custom-generico",
          {
            title: "",
            text: item,
          },
          5000
        );
      },
      (err) => {
        this.lightBoxesService.open(
          "custom-generico",
          {
            title: "Atenção",
            text: err.error.message,
          },
          5000
        );
      }
    );
  }

  setValidators() {
    this.modalAvaliacaoForm
      .get("descricao")
      .setValidators([Validators.required, Validators.minLength(20)]);
    this.modalAvaliacaoForm.get("descricao").updateValueAndValidity();
  }

  handleBibliotecaTreinamentoClick() {
    // this.router.navigate(['/home'], {queryParams: {'verTutorial': true}});
    this.router.navigate(["/biblioteca/treinamentos"]);
  }

  handleBibliotecaModelosDocumentosClick() {
    // this.router.navigate(['/home'], {queryParams: {'verTutorial': true}});
    this.router.navigate(["/biblioteca/modelos-documentos"]);
  }

  openModalAreaRestrita () {
    this.lightBoxesService.open(
      "custom-generico",
      {
        title: "Acesso Restrito",
        text: "Esta área não é disponivel para acessos de teste.",
      },
      5000
    );
  }
}
