import { ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/modules/home/services/home.service';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginService } from 'src/app/modules/login/services/login.service';
import { ColorfulIcon } from '../../utils/ColorfulIcon';
import { StateService } from '../../services/stateService';

@Component({
  selector: 'app-most-used-features',
  templateUrl: './most-used-features.component.html',
  styleUrls: ['./most-used-features.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MostUsedFeaturesComponent implements OnInit {
  @Input('p-design') design: 'default' | 'compact' = 'default';

  public mostUsedFeatures;
  public maisUsadosSession: Array<any>;
  public slideOpts = {
    initialSlide: 0,
    slidesPerView: 'auto',
    pager: true,
    spaceBetween: 8,
  };

  constructor(
    private loginService: LoginService,
    public stateService: StateService,
    private homeService: HomeService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((val) => {
      var condLogado = this.stateService.getCondominioLogado();
      var visaoCentral = this.stateService.visaoCentralAtendimento();
      var perfilFuncionario = this.stateService.possuiPerfilFuncionario();
      this.mostUsedFeatures = [];

      if (condLogado && !visaoCentral && !perfilFuncionario) {
        this.fetchDataFromAPI();
      }
    });
  }

  ngOnInit() {}

  returnNome(obj: any) {
    if (obj.id == 14 || obj.id == 15) {
      if (!this.stateService.possuiPerfilSindico()) return 'Fale com o síndico';
    }

    if (obj.id == 1 && this.stateService.possuiApenasPerfilCondomino()) {
      return '2ª via de boletos';
    }
    return obj.nome;
  }

  fetchDataFromAPI() {
    this.fetchMostUsedFeatures();
  }

  constructIcon(icon: string) {
    return ColorfulIcon.construct(icon);
  }

  async fetchMostUsedFeatures() {
    this.maisUsadosSession = JSON.parse(
      sessionStorage.getItem('atalhosMaisUsados')
    );
    if (!this.maisUsadosSession) {
      this.getFuncionalidades('semSession');
      return;
    } else if (
      this.maisUsadosSession[0].dataAtual != new Date().toDateString()
    ) {
      this.getFuncionalidades('dataAntiga');
      return;
    } else {
      let arrayControleCondominio = [];
      let arrayControlePessoa = [];

      for (let pessoa of this.maisUsadosSession) {
        if ('idPessoa' in pessoa) {
          arrayControlePessoa.push(pessoa.idPessoa);
          if (pessoa.idPessoa == this.stateService.getPessoaLogada().Id) {
            for (let condominio of pessoa.maisUsadosCondominio) {
              // console.log(condominio);
              arrayControleCondominio.push(condominio.condominio);
              if (
                condominio.condominio ==
                this.stateService.getCondominioLogado().contaEmpresa
              ) {
                this.mostUsedFeatures = condominio.maisUsados;
                return;
              } else if (
                arrayControleCondominio.length ==
                pessoa.maisUsadosCondominio.length
              ) {
                this.getFuncionalidades('novoCondominio', pessoa);
                return;
              }
            }
          } else if (
            arrayControlePessoa.length ==
            this.maisUsadosSession.length + 1
          ) {
            this.getFuncionalidades('novaPessoa');
            return;
          }
        }
      }
    }
  }

  getFuncionalidades(tipo: string, pessoa?) {
    this.loginService.getFuncionalidadesFrequentes().subscribe(
      (response) => {
        if (response) {
          if (tipo == 'semSession') {
            this.mostUsedFeatures = response;
            this.createSessionStorage(this.mostUsedFeatures);
          } else if (tipo == 'dataAntiga') {
            // console.log('data');
            sessionStorage.removeItem('atalhosMaisUsados');
            this.mostUsedFeatures = response;
            this.createSessionStorage(this.mostUsedFeatures);
          } else if (tipo == 'novoCondominio') {
            // console.log('novocondominio');
            this.mostUsedFeatures = response;
            const novosCondominios = [
              {
                condominio: this.stateService.getCondominioLogado()
                  .contaEmpresa,
                maisUsados: response,
              },
            ];
            const pessoaAtualizada = {
              idPessoa: this.stateService.getPessoaLogada().Id,
              maisUsadosCondominio: pessoa.maisUsadosCondominio.concat(
                novosCondominios
              ),
            };
            this.maisUsadosSession.splice(
              this.maisUsadosSession.indexOf(pessoa),
              1,
              pessoaAtualizada
            );
            // this.maisUsadosSession.concat(pessoaAtualizada);
            sessionStorage.setItem(
              'atalhosMaisUsados',
              JSON.stringify(this.maisUsadosSession)
            );
            return;
          } else if (tipo == 'novaPessoa') {
            // console.log('novapessoa');
            const condominio = this.stateService.getCondominioLogado()
              .contaEmpresa;
            const arrayCondominio = [
              {
                condominio: condominio,
                maisUsados: response,
              },
            ];
            const novaPessoa = {
              idPessoa: this.stateService.getPessoaLogada().Id,
              maisUsadosCondominio: arrayCondominio,
            };
            this.maisUsadosSession.concat(novaPessoa);
            sessionStorage.setItem(
              'atalhosMaisUsados',
              JSON.stringify(this.maisUsadosSession)
            );
          }
        }
      },
      (err) => {
        this.mostUsedFeatures = null;
      }
    );
  }

  createSessionStorage(response) {
    const condominio = this.stateService.getCondominioLogado().contaEmpresa;
    const maisUsadosCondominio = [
      {
        condominio: condominio,
        maisUsados: response,
      },
    ];
    const arrayMaisUsados = [
      { dataAtual: new Date().toDateString() },
      {
        idPessoa: this.stateService.getPessoaLogada().Id,
        maisUsadosCondominio: maisUsadosCondominio,
      },
    ];
    sessionStorage.setItem(
      'atalhosMaisUsados',
      JSON.stringify(arrayMaisUsados)
    );
  }

  get hasFeatures(): boolean {
    return this.mostUsedFeatures != null && this.mostUsedFeatures.length > 0;
  }
}
