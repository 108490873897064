import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import CustomerService from '../../services/customer.service';
import { CUSTOMERS, CUSTOMERS_ID } from 'src/customers';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PerfilDoitt } from '../../services/PerfilDoitt.service';

@Component({
  selector: 'debug-doitt-customer',
  templateUrl: './debug-doitt-customer.component.html',
  styleUrls: ['./debug-doitt-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugDoittCustomerComponent implements OnInit, OnDestroy {
  public forceDoittDebug: boolean = false;
  public customers: typeof CUSTOMERS.Doitt[] = Object.keys(CUSTOMERS).map(key => CUSTOMERS[key]);
  public formGroup: UntypedFormGroup;
  public subscription: Subscription;
  public subscription2: Subscription;
  public isOpen = true;  
  public EPerfil = this.perfilDoitt.EPerfis;
  public ECostumerID = CUSTOMERS_ID;
  public customer$ = this.customerService.getCustomerObservable();

  constructor(
    private customerService: CustomerService,
    public perfilDoitt: PerfilDoitt,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      customer: null,
      perfilDoitt: this.perfilDoitt.perfil$.value,
    });

    this.subscription = this.formGroup.get('customer').valueChanges.subscribe(nomeApp => {
      this.customerService.forceCustomer(nomeApp);
    });

    this.subscription2 = this.formGroup.get('perfilDoitt').valueChanges.subscribe(perfil => {
      if(perfil) {
        const id = parseInt(perfil);

        this.perfilDoitt.perfil$.next(perfil);
        this.perfilDoitt.isDoitt$.next(id === this.perfilDoitt.EPerfis.DOITT);
        this.perfilDoitt.isDoitter$.next(id === this.perfilDoitt.EPerfis.DOITTER);
        this.perfilDoitt.isParceiro$.next(id === this.perfilDoitt.EPerfis.PARCEIRO);
      }
    });
  }

  ngOnDestroy() {
    if(this.subscription)
      this.subscription.unsubscribe();
    
    if(this.subscription2)
      this.subscription2.unsubscribe();
  }

  toggleVisibility() {
    this.isOpen = !this.isOpen;
  }
}
