import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '../../services/stateService';
import { ColorfulIcon } from '../../utils/ColorfulIcon';

@Component({
  selector: 'app-quick-card',
  templateUrl: './quick-card.component.html',
  styleUrls: ['./quick-card.component.scss'],
})

export class QuickCardComponent {
  @Input() id: any;
  @Input() title: any;
  @Input() icon: string;
  @Input() link: string;
  @Input() compact: boolean = false;

  constructor(
    private router: Router,
    public stateService: StateService
    ) {
  }

  ngOnInit() {
    if(this.stateService.possuiApenasPerfilCondomino() && this.title === 'Cotas') {
      this.title = '2ª Via de Boletos';
    } else if(this.title === 'Cotas') {
      this.title = '2ª Via de Cotas'
    }
  }


  constructIcon() {
    if(this.id == 50) 
    return "<i class='fab fa-whatsapp WhatsappIcon'></i>";
    else
      return ColorfulIcon.construct(this.icon);
  }

  redirect(){
    if(this.id == 50) 
    this.stateService.openExternalLink(this.link);
    else
      this.router.navigate([this.link]);
  }
}
