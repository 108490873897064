<div class="LoadingSplash">
  <div class="LoadingSplash_Content" *ngIf="customer$">
    <img
      class="LoadingSplash_Content_Logo"
      [src]="(customer$ | async).logos.logoSplashScreen"
    />

    <div class="LoadingSplash_Content_Spin">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="LoadingSplash_Content_Msg">
      Aguarde um instante ...
    </div>
  </div>
</div>

<ion-app>
  <ion-router-outlet></ion-router-outlet>
</ion-app>
<app-loading-screen></app-loading-screen>

<debug-doitt-customer *ngIf="!environment.production && isLocalhostDoitt"></debug-doitt-customer>
