import {File} from '@ionic-native/file/ngx';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, AfterViewChecked, Sanitizer, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { ImagemArquivoMaloteDTO } from 'src/app/modules/envio-documento/services/DTO/request/ImagemArquivoMalote.DTO';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { StateService } from 'src/app/shared/services/stateService';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { LightBoxesService } from '../../lightboxes';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Base64 } from '@ionic-native/base64/ngx';
import { of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { ImagemArquivoDTO } from 'src/app/shared/services/DTO/Request/ImagemArquivo.DTO';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import * as moment from 'moment';
import { FileMobile } from 'src/app/shared/services/DTO/response/FileMobile';
import { ChooserService } from 'src/app/shared/plugins/Chooser/chooser.service';
import { IChooser } from 'src/app/shared/plugins/Chooser/IChooser';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Camera, CameraPlugin, CameraResultType, CameraSource, ImageOptions, } from '@capacitor/camera';
import { InputFileMobile } from '../input-file-mobile/input-file-mobile.component';

import { PDFDocument } from 'pdf-lib';
@Component({
  selector: 'app-upload-html5',
  templateUrl: './upload-html5.component.html',
  styleUrls: ['./upload-html5.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RouterModule]
})
export class UploadHtml5Component implements OnInit, AfterViewChecked {
  public uploader: FileUploader = new FileUploader({ url: './', isHTML5: true,  disableMultipart: false });
  private filesAdded: any[] = [];
  avatar: any;
  public reader = new FileReader();
  public form: UntypedFormGroup;
  @Input() listaImagens: ImagemArquivoMaloteDTO[] = [];
  @Input() isFullButton: boolean = true;
  @ViewChild('fileInput') fileInput;
  @Input() mimeTypesFilter: string = undefined;

  @Input() exibeBotaoDocumentosMobile: boolean = false;
  @Input() imagesOnly: boolean = false;
  @Input() docsOnly: boolean = false;
  @Input() descricaoBotao: string;
  @Input() descricaoListaAnexos: string
  @Output() atualizarFormListaImagens: EventEmitter<any> = new EventEmitter();
  @Output() getUrlImagem: EventEmitter<any> = new EventEmitter();
  public valoresAceitosDefault: string = ".xls, .xlsx, .jpg, .jpeg, .png, .doc, .pdf, .docx";
  public valoresAceitosDefaultArray: string[] = ["image/xls", "image/xlsx", "image/jpg", "image/jpeg", "image/png", "file/doc", "file/pdf", "file/docx"];
  public valoresAceitosOutros = ".pdf, .jpg, .jpeg, .png, .btm, .tiff, .tif";
  public valoresImagens = ".jpg, .jpeg, .png, .tiff, .tif";
  public valoresDocumentos = ".pdf, .docx, .pptx, .doc, .ppt";
  public rotaAtual = this.router.url;
  public window: any = window;
  public fileDropped: boolean = false;
  public files: any[] = [];
  public rotasBotaoUploadDocumentos = ['/envio-documento','/envio-pagamentos', '/minhas-solicitacoes/cadastro', '/mural/cadastro', '/muralGT/cadastro', '/mural/edicao/', '/muralGT/edicao']
  public listaCheia: boolean = false;
  public isDesktopPlatform = this.platform.is('desktop') && !this.platform.is('mobileweb');
  constructor(
    private file: File, 
    private activedRoute: ActivatedRoute, 
    private filePicker: IOSFilePicker, 
    private filePath: FilePath, 
    private fileChooser: FileChooser, 
    private backgroundMode: BackgroundMode, 
    private sanitizer: DomSanitizer, 
    private base64: Base64, 
    public platform: Platform, 
    private router: Router, 
    private lightBoxesService: LightBoxesService,
    public constants: ConstantsService, 
    private fb: UntypedFormBuilder, 
    public stateService: StateService, 
    private lightboxesService: LightBoxesService,
    private inputMobile: InputFileMobile,
    @Inject('CameraPluginImpl') private cameraPlugin: CameraPlugin, 
 
    private route: ActivatedRoute,
    @Inject("ChooserPluginImpl") private chooserService: IChooser
  ) {
    activedRoute.params.subscribe(val => {
      this.ngOnInit();
    });

    // this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
    //   this.uploader.queue.map(item =>{
    //     console.log('Arquivo(s) selecionado(s):',item._file);
    //     this.onFileChangeDG(item._file)
        
    //   })
    //   // this.onFileChangeDG(fileItem._file)
    //   // console.log('Arquivo selecionado:', fileItem);
    // };

    this.uploader.onAfterAddingAll = (fileItems: FileItem[]) => {
      if(!this.uploadUnitario())
      fileItems.forEach((fileItem: FileItem) => {        
        // if (!this.filesAdded.includes(fileItem._file)) {
        //   this.filesAdded.push(fileItem._file);
        // }

        this.onFileChangeDG(fileItem._file);
      });
    };

    
  }
  
  ngOnInit() {
    // this.createForm();
  }

  ngAfterViewChecked(): void {
    this.stateService.platformIsDesktopOrMobileWeb() && this.updateInputRender();
  }

  getDescricao(): string {
    return (this.descricaoBotao) ? this.descricaoBotao : "Anexar Imagens";
  }

  getDescricaoListagemArquivos(): string {

    const rota = this.rotaAtual
    const posicao = rota.indexOf('?')
    const rotaListagem = rota.slice(0,posicao)

    if ((this.rotaAtual === '/documento') || (rotaListagem === '/documento/listagem'))  {
      return 'Anexo';
    }
    else{
      return (this.exibeBotaoDocumentosMobile || this.rotasBotaoUploadDocumentos.includes(this.rotaAtual)) ? "Lista de anexos" : "Lista de imagens"
    }
  }

  uploadUnitario(){ 
    
    const rota = this.rotaAtual
    const posicao = rota.indexOf('?')
    const rotaListagem = rota.slice(0,posicao)

    if(((this.rotaAtual === '/documento') || (rotaListagem === '/documento/listagem')) && (this.listaImagens.length > 0)){
             
        this.lightBoxesService.open(
          'custom-generico',
          { title: 'Atenção', text: 'Você só pode anexar um documento' },
          5000
        );
  
        return this.listaCheia = true;
      
    }
    else{
      this.listaCheia = false;
    }
  }
  updateInputRender() {
    if (this.fileInput) {

      let validacaoRotas = this.rotaAtual == '/envio-documento' || this.rotaAtual == '/envio-pagamentos';
      const rota = this.rotaAtual
      const posicao = rota.indexOf('?')
      const rotaListagem = rota.slice(0, posicao)

      let rotasDocumentos = ((this.rotaAtual === '/documento') || (rotaListagem === '/documento/listagem'));
      //aqui personalizo o box de arquivos para filtrar apenas
      if ((this.docsOnly) && (rotasDocumentos)){
        
        this.fileInput.nativeElement.setAttribute('accept', this.valoresDocumentos);
        document.getElementsByClassName('Form_Upload_Input')[0].setAttribute('accept', this.valoresDocumentos);

      }
      
      if (validacaoRotas){
        this.fileInput.nativeElement.setAttribute('accept', this.valoresAceitosOutros);
        document.getElementsByClassName('Form_Upload_Input')[0].setAttribute('accept', this.valoresAceitosOutros);
      }else{

        this.fileInput.nativeElement.setAttribute('accept', this.valoresAceitosDefault);
      }

      if(this.imagesOnly) {
        this.fileInput.nativeElement.setAttribute('accept', this.valoresImagens);
      }

    }

    // if(this.fileInput && this.imagesOnly) {
    //   this.fileInput.nativeElement.setAttribute('accept', this.valoresImagens);
    // }
  }

  public onFileDropped(files: any[]): void {
    this.fileDropped = true;

    for (const file of files) {
      if (file.fileEntry.isFile) {
        const fileEntry = file.fileEntry as FileSystemFileEntry;
        fileEntry.file(item => {
          this.files.push(item);
        });
      }
    }

    files.forEach(file => {
      console.log('file', file)
      // this.onFileChangeDG(file)
    });
  }

  // async onFileChangeDGi(file){
  //   console.log('file', file)
  //   // debugger
  //   let tamanho = file.size;
  //   let tamanhoEspecifico = (this.exibeBotaoDocumentosMobile || this.rotasBotaoUploadDocumentos.includes(this.rotaAtual)) ? 9000000:undefined;

  //   if (this.stateService.verificarTamanhoUpload(tamanho, tamanhoEspecifico)) {
  //     if (file && file.length > 0 || (file && file.size > 0)) {
  //       const ext = file.name.split(".")[file.name.split(".").length - 1] ? file.name.match(/\.([^\.]+)$/)[1] : null;

  //       if (!ext) {
  //         this.lightBoxesService.open(
  //           'custom-generico',
  //           { title: 'Atenção', text: 'O documento enviado não possui extensão, por favor tente anexar um arquivo que contenha uma extensão válida' },
  //           5000
  //         );
  //         return;
  //       }

  //       if(this.imagesOnly && !this.valoresImagens.includes(ext.toLowerCase())) {
  //         this.lightBoxesService.open(
  //           'custom-generico',
  //           { title: 'Atenção', text: 'O arquivo enviado não é uma imagem.' },
  //           5000
  //         );
  //         return;
  //       }

  //       let valido;

  //       if (this.rotaAtual == '/envio-documento' || this.rotaAtual == '/envio-pagamentos') valido = this.valoresAceitosOutros.includes(ext.toLowerCase());
  //       // else if (
  //       //   this.rotaAtual == '/mural/cadastro' ||
  //       //   this.rotaAtual == '/muralGT/cadastro' ||
  //       //   this.rotaAtual.includes('/mural/edicao') ||
  //       //   this.rotaAtual.includes('/muralGT/edicao')
  //       // ) valido = this.valoresImagens.includes(ext.toLowerCase());
  //       else valido = this.valoresAceitosDefault.includes(ext.toLowerCase());

  //       if (valido) {
  //         await this.reader.readAsDataURL(file);
  //         this.reader.onload = () => {

  //           this.avatar = {
  //             filename: file.name,
  //             filetype: file.type,
  //             value: this.reader.result.toString().split(',')[1]
  //           }

  //           var src = this.stateService.getCaminhoImagemExibicao(file.type, this.reader.result.toString().split(',')[1])
  //           this.listaImagens.push(new ImagemArquivoMaloteDTO(this.reader.result.toString().split(',')[1], file.type, file.name, src));
  //           this.atualizarFormListaImagens.emit(this.listaImagens);
  //           //this.form.get('avatar').setValue('');
  //           this.avatar = '';
  //         };
  //       } else {
  //         this.lightBoxesService.open(
  //           'custom-generico',
  //           { title: 'Atenção', text: 'O formato de arquivo informado não é aceito.' },
  //           5000
  //         );
  //       }

  //     }

  //   }
  // }

  async onFileChangeDG(file): Promise<void> {
    try {
      const tamanho = file.size;
      const tamanhoEspecifico = (this.exibeBotaoDocumentosMobile || this.rotasBotaoUploadDocumentos.includes(this.rotaAtual)) ? 9000000 : undefined;
  
      if (this.stateService.verificarTamanhoUpload(tamanho, tamanhoEspecifico)) {
        if (file && file.length > 0 || (file && file.size > 0)) {
          const ext = file.name.split(".")[file.name.split(".").length - 1] ? file.name.match(/\.([^\.]+)$/)[1] : null;
  
          if (!ext) {
            this.lightBoxesService.open(
              'custom-generico',
              { title: 'Atenção', text: 'O documento enviado não possui extensão, por favor tente anexar um arquivo que contenha uma extensão válida' },
              5000
            );
            return;
          }
  
          if (this.imagesOnly && !this.valoresImagens.includes(ext.toLowerCase())) {
            this.lightBoxesService.open(
              'custom-generico',
              { title: 'Atenção', text: 'O arquivo enviado não é uma imagem.' },
              5000
            );
            return
          }
  
          let valido;
  
          if (this.rotaAtual == '/envio-documento' || this.rotaAtual == '/envio-pagamentos') valido = this.valoresAceitosOutros.includes(ext.toLowerCase());
          // else if (
          //   this.rotaAtual == '/mural/cadastro' ||
          //   this.rotaAtual == '/muralGT/cadastro' ||
          //   this.rotaAtual.includes('/mural/edicao') ||
          //   this.rotaAtual.includes('/muralGT/edicao')
          // ) valido = this.valoresImagens.includes(ext.toLowerCase());
          else valido = this.valoresAceitosDefault.includes(ext.toLowerCase());
  
          if (valido) {
            await this.readAndProcessFile(file);
          } else {
            this.lightBoxesService.open(
              'custom-generico',
              { title: 'Atenção', text: 'O formato de arquivo informado não é aceito.' },
              5000
            );
          }
        }
      }
    } catch (error) {
      // Tratamento erros
      console.error(error);
    }
  }
  
  async readAndProcessFile(file): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = () => {
        this.processFile(file, reader.result);
        resolve();
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsDataURL(file);
    });
  }
  
  processFile(file, result): void {
    this.avatar = {
      filename: file.name,
      filetype: file.type,
      value: result.toString().split(',')[1]
    }

    const src = this.stateService.getCaminhoImagemExibicao(file.type, result.toString().split(',')[1]);
    this.listaImagens.push(new ImagemArquivoMaloteDTO(result.toString().split(',')[1], file.type, file.name, src));
    this.atualizarFormListaImagens.emit(this.listaImagens);
    this.avatar = '';
  }

    
  async onFileChange(event) {
    if(this.uploadUnitario()){
      return
    } // linha verificando se está na rota documento para upload de apenas um arquivo
    
    const tamanho = event.target.files[0].size
    const tamanhoEspecifico =
      this.exibeBotaoDocumentosMobile ||
      this.rotasBotaoUploadDocumentos.includes(this.rotaAtual)
        ? 9000000
        : undefined;
    if (this.stateService.verificarTamanhoUpload(tamanho, tamanhoEspecifico)) {
      if (event.target.files && event.target.files.length > 0) {
        let file = event.target.files[0];
        const ext = file.name.split(".")[file.name.split(".").length - 1]
          ? file.name.match(/\.([^\.]+)$/)[1]
          : null;

          if (!ext) {
            this.lightBoxesService.open(
              "custom-generico",
              {
                title: "Atenção",
                text: "O documento enviado não possui extensão, por favor tente anexar um arquivo que contenha uma extensão válida",
              },
              5000
            );
            return;
          }

        if(this.imagesOnly && !this.valoresImagens.includes(ext.toLowerCase())) {
          this.lightBoxesService.open(
            'custom-generico',
            { title: 'Atenção', text: 'O arquivo enviado não é uma imagem.' },
            5000
          );
          return;
        }

        let valido;

        const rota = this.rotaAtual
        const posicao = rota.indexOf('?')
        const rotaListagem = rota.slice(0, posicao)
        const rotasDocumentos = ((this.rotaAtual === '/documento') || (rotaListagem === '/documento/listagem'))
        
        if (rotasDocumentos) {
          valido = this.valoresDocumentos.includes(ext.toLowerCase())

        }
        else if (
          this.rotaAtual == "/envio-documento" ||
          this.rotaAtual == "/envio-pagamentos"
        )
          valido = this.valoresAceitosOutros.includes(ext.toLowerCase());
        // else if (
        //   this.rotaAtual == '/mural/cadastro' ||
        //   this.rotaAtual == '/muralGT/cadastro' ||
        //   this.rotaAtual.includes('/mural/edicao') ||
        //   this.rotaAtual.includes('/muralGT/edicao')
        // ) valido = this.valoresImagens.includes(ext.toLowerCase());
        else valido = this.valoresAceitosDefault.includes(ext.toLowerCase());

        if (valido) {
          this.reader.readAsDataURL(file);
          this.reader.onload = async () => {
            let validarTamanho = true;
            console.log('file', file)
            if(file.type == 'application/pdf' && this.rotaAtual == "/envio-pagamentos"){
              const pdf =  PDFDocument.load(this.reader.result);
              let pageCount = (await pdf).getPages().length;
              console.log('pageCount', pageCount)
              validarTamanho = pageCount > 30 ? false : true
            }

            var src = this.stateService.getCaminhoImagemExibicao(file.type, this.reader.result.toString().split(',')[1])
            
            if(validarTamanho){
              
              this.avatar = {
                filename: file.name,
                filetype: file.type,
                value: this.reader.result.toString().split(",")[1],
              };
  
              // console.log('file result', this.reader.result.toString().split(",")[1])
              
  
              var src = this.stateService.getCaminhoImagemExibicao(
                file.type,
                this.reader.result.toString().split(",")[1]
              );

              
              this.listaImagens.push(new ImagemArquivoMaloteDTO(this.reader.result.toString().split(',')[1], file.type, file.name, src));
              this.atualizarFormListaImagens.emit(this.listaImagens);
              this.avatar = '';
              
            }else{
              this.lightBoxesService.open(
                "custom-generico",
                {
                  title: "Atenção",
                  text: "O arquivo não pode conter mais de 30 páginas.",
                },
                5000
              );
            }
          };

        } else {
          this.lightBoxesService.open(
            'custom-generico',
            { title: 'Atenção', text: 'O formato de arquivo informado não é aceito.' },
            5000
          );
        }
      }
    }
  }

  get formControls() {
    return this.form.controls;
  }

  fileOverBase(event: any): void {
    if (event) {
      // event.preventDefault();
      // event.stopPropagation();
    }
  }
  

  async anexarDocumentosApps() {
    const filters = this.mimeTypesFilter ? [this.mimeTypesFilter] : []

    await this.inputMobile.anexar(filters).then(arquivo => {
      let src = this.stateService.getCaminhoImagemExibicao(
        arquivo.Tipo,
        arquivo.Conteudo
      );
      const fileName = moment().format("DD-MM-YYYY_HH-mm-ss") + ".pdf";
      this.listaImagens.push(
        new ImagemArquivoMaloteDTO(arquivo.Conteudo, arquivo.Tipo, fileName, src)
      );
      this.atualizarFormListaImagens.emit(this.listaImagens);
    });
  }

  async openCamera(type: number) {

    const options: ImageOptions = {
      quality: 50,
      resultType: CameraResultType.DataUrl,
      source: type === 1 ? CameraSource.Camera : CameraSource.Photos
    }

    await Camera.getPhoto(options).then(photo => {
      if(photo) {
        let src = this.stateService.getCaminhoImagemExibicao(
          `image/${photo.format}`,
          photo.dataUrl.split(',')[1]
        );
        const fileName = moment().format("DD-MM-YYYY_HH-mm-ss") + ".jpeg";
        this.listaImagens.push(
          new ImagemArquivoMaloteDTO(photo.dataUrl.split(',')[1], `image/${photo.format}`, fileName, src)
        );
        this.atualizarFormListaImagens.emit(this.listaImagens);
      }
    })
  }

  getSanitizedURL(src) {
    return this.sanitizer.bypassSecurityTrustUrl(src);
  }

  excluirAnexo(obj) {
    var index = this.listaImagens.indexOf(obj);
    this.listaImagens.splice(index, 1);
    this.fileInput.nativeElement.value = null;
    this.atualizarFormListaImagens.emit(this.listaImagens);
    this.listaCheia = false;
 
  }

  limparAnexo() {
    this.listaImagens.splice(0, 1);
    this.atualizarFormListaImagens.emit(this.listaImagens);
    this.listaCheia = false;
  }

  openLinkAnexo(url) {
    if(url) {
      this.stateService.openExternalLink(url)
    } else {
      this.lightboxesService.open(
        'custom-generico',
        { title: 'Atenção', text: 'URL inválida' },
        6000);
    }
  }

  limitChars(str: string) {
    const max = window.innerWidth > 800 ? 15 : 25;

    if(str.length > max) {
      return str.slice(0, max) + '...';
    }
    else {
      return str;
    }
  }
}
