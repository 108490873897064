import { Component, HostListener, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { MinhasSolicitacoesService } from 'src/app/modules/minhas-solicitacoes/services/minhas-solicitacoes.service';

@Component({
  selector: 'modal-resumo-solicitacao',
  templateUrl: './modal-resumo-solicitacao.component.html',
  styleUrls: ['./modal-resumo-solicitacao.component.scss']
})
export class ModalResumoSolicitacaoComponent implements OnInit{
  public resumoFormulario

  constructor(private modalService: ModalService, protected service: MinhasSolicitacoesService) { }

  ngOnInit() {
    this.modalService.modalData$.subscribe(resumo => this.resumoFormulario = resumo)
  }

  closeModal() {
    this.modalService.closeModal()
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeModal()
    }
  }

  enviar() {
    this.modalService.enviarForm()
  }
}
