<label class="FormGroup_Label">{{label}}</label>
<div class="selectArea">
  <div class="selectInput" (click)="open = !open">
    {{ itemSelecionado }}
  </div>
  <div class="optionsArea" [ngClass]="{ __ShowOptions: open }">
    <div class="optionItem" (click)="selecionarItem()"></div>
    <div
      class="optionItem"
      (click)="selecionarItem(opcao)"
      *ngFor="let opcao of opcoes"
    >
      <!-- {{ getOpcaoSelect(opcao) }} -->
      {{ opcao }}
    </div>
  </div>
</div>
<div class="FormGroup_Erro">Este campo é obrigatório</div>