<div
  class="QuickCardComponent"
  [ngClass]="{
    '__is-highlighted': this.id == 50 || this.id === 7,
    '__is-compact': this.compact
  }" 
>
  <label (click)="redirect()">
    <div class="Icon" [innerHTML]='constructIcon()'>
    </div>

    <div class="Title">
      {{ title }}
    </div>
  </label>
</div>