import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { StateService } from './stateService';

@Injectable({
  providedIn: 'root'
})
export class AdesaoDeBoletoDigitalService {
  
  private urlApiHostCondominioDigital = environment.apiCondominioDigital.host;
  private methodsApiCondominioDigital = environment.apiCondominioDigital.endpoints;

  private getHeaderCondominioDigital() {
    return new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      'Authorization': this.stateService.getTokenApiCondominioDigital()
    });
  }

  constructor(private http: HttpClient, public stateService: StateService) { }

  cadastrarEditarAdesao(body){

    return this.http.post(`${this.urlApiHostCondominioDigital + this.methodsApiCondominioDigital.methods.optinsUsuario.cadastrarEditarAdesaoUnidade}`,
      body, {headers: this.getHeaderCondominioDigital()})
  }


}
