import { Platform } from '@ionic/angular';
import { AvaliacaoLojaComponent } from './../avaliacao-loja-app/avaliacao.component';
import { Component, OnInit, ViewEncapsulation, EventEmitter,AfterViewInit, AfterViewChecked, Output, ViewChild, ElementRef, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConstantsService } from '../../services/constants.service';
import { StateService } from '../../services/stateService';
import { ModalService } from '../../services/modal.service';
import { CondominioService } from '../../services/condominio.service';
import { LightBoxesService } from '../lightboxes';
import { HomeService } from 'src/app/modules/home/services/home.service';
import { Device } from '@ionic-native/device/ngx';
import { LoginService } from 'src/app/modules/login/services/login.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../services/user.service';
import { ProfilePictureFacade } from '../../facades/profile-picture.facade';
import CustomerService from '../../services/customer.service';
import { BiometryService } from '../../services/biometry.service';



@Component({
  selector: 'app-lista-dados-conta',
  templateUrl: './lista-dados-conta.component.html',
  styleUrls: ['./lista-dados-conta.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListaDadosContaComponent implements OnInit, OnChanges {

  @Input() trocaDeCondominio;
  @Output() abreModalAdesao = new EventEmitter();
  @Output() abreModalAdesaoIPTU = new EventEmitter();
  @Output() onOpenModalAvaliacao = new EventEmitter();
  @ViewChild('fileInput') fileInputEl: ElementRef;

  public exibirWpp: boolean = false;
  public showFingerprintItem: boolean;
  public isFingerprintEnabled$ = this.BiometryService.isBiometryEnabledByUserAsObservable();
  public customer = this.customerService.getCustomer();
  public customer$ = this.customerService.getCustomerObservable();
  public condominioValidado: boolean

  constructor(
    private router: Router,
    public constant: ConstantsService,
    public stateService: StateService,
    public modalService: ModalService,
    public condominioService: CondominioService,
    public lightboxesService: LightBoxesService,
    private homeService: HomeService,
    private device: Device,
    private loginService: LoginService,
    public avaliacaoLoja: AvaliacaoLojaComponent,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    public profilePictureFacade: ProfilePictureFacade,
    private customerService: CustomerService,
    public platform: Platform,
    private BiometryService: BiometryService,
  ) { }

  async ngOnInit() {
    this.condominioValidado = this.validarCondominio();
    this.definirSeDeveExibirAFuncaoDeBiometriaOuNao();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.trocaDeCondominio.currentValue) {
      this.condominioValidado = this.validarCondominio();
      this.definirSeDeveExibirAFuncaoDeBiometriaOuNao();
    }
  }

  async definirSeDeveExibirAFuncaoDeBiometriaOuNao() {
    this.showFingerprintItem = await this.BiometryService.isBiometryAvaiable();
  }

  enableFingerprintLoginFeature() {
    this.BiometryService.registerFingerprintBiometry();
  }

  disableFingerprintLoginFeature() {
    this.BiometryService.removeFingerprintBiometry();
  }

  validarCondominio(): boolean{
    return this.stateService.hasCondominioSelecionado()
  }

  avaliacaoApp(){
    
    if(this.stateService.platformIsDesktop()){
      this.onOpenModalAvaliacao.emit()
    }else{
      this.avaliacaoLoja.avaliacaoLoja(true)
    }

  }

  exibirOpcaoIPTU(){
    return JSON.parse(sessionStorage.getItem('possuiImoveisIPTU')) === true && !this.stateService.hasCondominioSelecionado() && !this.stateService.visaoCentralAtendimento();
  }

  sair() {
    sessionStorage.clear();
    this.customerService.forceDoittConfigIfMobile();

    this.router.navigate(['/login', { 'exitFromLogout': true }]);
    return;
  }

  redirect() {
    if (this.router.url !== '/descontos-parceiros') {
      this.router.navigate(['/descontos-parceiros']);
    }
    else {
      this.modalService.close('app-dados-conta');
    }
  }

  redirectEditUserData() {
    this.router.navigate(['/usuario/edicao-dados']);
    this.stateService.openCloseComponent('.Header_Atalho_Perfil');
  }
  
  handleWhatsappOptionClick() {
    const customer = this.customerService.getCustomer();

    var nome = this.stateService.getPessoaLogada().Nome;
    this.stateService.openExternalLink(customer.atendimentoViaWhatsApp.url);
  }

  handleFornecedoresOptionClick() {
    this.stateService.openExternalLink('https://www.revistasindico.com.br/guia');
  }

  handleBibliotecaTreinamentoClick(){
    // this.router.navigate(['/home'], {queryParams: {'verTutorial': true}});
    this.router.navigate(['/biblioteca/treinamentos']);
  }

  handleBibliotecaModelosDocumentosClick(){
    this.router.navigate(['/biblioteca/modelos-documentos']);
  }

  handleTutorial(){
    this.router.navigate(['/home'], {queryParams: {'verTutorial': true}});
  }

  permitirEditarDadosUsuario(): boolean {
    if (this.stateService.visaoCentralAtendimentoPessoa()) {
      if (sessionStorage.getItem('pessoaLogadaCA') == sessionStorage.getItem('pessoaLogada')) {
        return false;
      } else {
        return this.stateService.possuiAcessoFuncionalidadeGeral(837);
      }
    } else {
      return true;
    }
  }

  exibeFuncaoAvalieApp() : boolean{
    return this.stateService.platformIsDevice() && this.stateService.hasCondominioSelecionado()
  }

  enviarEmailConfirmacao(){
    this.loginService.enviarEmailConfirmacao().subscribe(item =>{
      this.lightboxesService.open(
        'custom-generico',
        { title: 'E-mail enviado', text: item },
        50000
      );
    }, err=>{
      this.lightboxesService.open(
        'custom-generico',
        { title: 'E-mail enviado', text: err.error.message },
        50000
      );
    });
  }

  redirectAdesaoBoletoDigital() {
      if(this.stateService.platformIsDesktop()) {
        this.router.navigate(['/adesao-de-boleto-digital']);
      }
      else {
        this.abreModalAdesao.emit();
        
        var container = document.querySelectorAll('.Container');
        
        window.setTimeout(function () {
          for (var i = 0; i < container.length; i++) {
            container[i].classList.add('__has-modal')
          }
        }, 290);
      }
    }

  // redirectAdesaoIPTU() {
  //   if(this.stateService.platformIsDesktop()) {
  //     this.router.navigate(['/adesao-iptu']);
  //   }
  //   else {
  //     this.abreModalAdesaoIPTU.emit();
      
  //     var container = document.querySelectorAll('.Container');
      
  //     window.setTimeout(function () {
  //       for (var i = 0; i < container.length; i++) {
  //         container[i].classList.add('__has-modal')
  //       }
  //     }, 290);
  //   }
  // }
    
  routeToRecorrenciaCartaoCredito() {
    this.router.navigate(['/recorrencia-cartao-credito/gerenciar-adesao-recorrencia']);
    this.stateService.openCloseComponent('.Header_Atalho_Perfil');
  }

  platformIsDesktop(): boolean {
    return this.stateService.platformIsDesktop();
  }

  handleTrocarFotoDoPerfilClick() {
    const fileInput = this.fileInputEl.nativeElement as HTMLElement;

    fileInput.click();
  }

  handleTrocarFotoDoPerfilFileChange(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length > 0) {
      const photo = event.target.files[0];
      const fileName = this.getFileName(event.target.value);

      reader.readAsDataURL(photo);
      reader.onload = () => {
        const fileContent = reader.result.toString().split(',')[1];

        this.profilePictureFacade.change(fileName, fileContent);

        (event.target as HTMLInputElement).value = null;
      }
    }
  }

  private getFileName(fileNameGeneratedByBrowser: string) {
    const fileName = fileNameGeneratedByBrowser;
    const nameLength = fileNameGeneratedByBrowser.length;

    if(fileName.includes('fakepath'))
      return fileName.substring(fileName.lastIndexOf("\\") + 1, nameLength);
    else
      return fileName;
  }
}
