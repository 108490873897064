import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { StateService } from "./stateService";

@Injectable({ providedIn: 'root' })
export class PlatformService {
  private isDesktopWebBrowser = new BehaviorSubject<boolean>(false);
  private isMobileDevice = new BehaviorSubject<boolean>(false);

  constructor(
    private stateService: StateService,
    
  ) { }

  public init() {
    this.setIsDesktop(this.stateService.platformIsDesktop());
    this.setIsMobileDevice(this.stateService.platformIsDeviceMobile());
  }

  public get isDesktop$(): Observable<boolean> {
    return this.isDesktopWebBrowser.asObservable();
  }

  public get isDesktop(): boolean {
    return this.isDesktopWebBrowser.getValue();
  }

  public setIsDesktop(value: boolean) {
    this.isDesktopWebBrowser.next(value);
  }

  public get isMobileDevice$(): Observable<boolean> {
    return this.isMobileDevice.asObservable();
  }

  public setIsMobileDevice(value: boolean) {
    this.isMobileDevice.next(value);
  }
}