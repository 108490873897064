<div class="Seguros">
  <div class="Seguros-header">
    <div class="logo-apsa">
      <img
        src="https://hotsiteschatz.hmg.marlin.com.br/assets/img/header/logoCorretora.png"
        [ngClass]="{'__should-apply-white-filter': (themeService.getActiveThemeName() | async) === 'Theme_Dark'}"
      >
    </div>
  
    <p class="header-text">
      Olá {{this.nomeUsuario}}, a Schatz Corretora de Seguros tem mais de 50 anos de tradição e soluções completas para condomínios, imóveis e clientes individuais.
      <br><br>
      Conheça nossos seguros sob medida para o que você precisa!
    </p>
  </div>
  
  <form [formGroup]="form">
    <div class="Atalho_CentralAtendimento_Form" *ngIf="tipos">
      <div class="FormGroup" [ngClass]="setClassError('tipoSeguro')">
        <div class="FormGroup_Label">Tipo de seguro</div>
  
        <div class="FormGroup_Input">
  
          <select name="tipoSeguro" formControlName="tipoSeguro">
            <option [value]="''" disabled>Selecione o tipo</option>
            <option *ngFor="let tipo of tipos" [value]="tipo.id">{{tipo.tipo}}</option>
          </select>
        </div>
  
        <div class="FormGroup_Erro">Este campo é obrigatório</div>
      </div>
  
      <div class="FormGroup" [ngClass]="setClassError('mensagem')">
        <div class="FormGroup_Label">Mensagem</div>
  
        <div class="FormGroup_Input">
          <textarea
            cols="30"
            rows="5"
            name="mensagem"
            formControlName="mensagem"
            placeholder="Escreva sua mensagem aqui"
          ></textarea>
        </div>
  
        <div class="FormGroup_Erro">Este campo é obrigatório</div>
      </div>
  
      <div class="FormGroup">
        <ds-button
          [full]="true"
          (click)="handleEnviarMensagemClick()"
          [inactive]="form.invalid || isSubmitting"
        >
          Enviar mensagem
        </ds-button>
      </div>
    </div>
  </form>
</div>