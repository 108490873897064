<div class="AdesaoDeBoletoDigitalSelecaoDeUnidade">
  <form>
    <div
      class="FormGroupCheckBox __is-all-selection"
      *ngIf="listaCondominios && listaCondominios.length > 1"
    >
      <div class="FormGroupCheckBox_Input">
        <ion-checkbox
          mode="md"
          (click)='aderirDesaderirTodos()'
        ></ion-checkbox>
      </div>

      <label class="FormGroupCheckBox_Label" for="confirmation-checkbox">
        Quero aderir em todas as minhas unidades
      </label>
    </div>

    <div *ngIf="!todasAsUnidadesAceitas">
      <p class="Paragraph __is-bold">
        Selecione quais unidades deseja aderir ao boleto digital.
      </p>

      <ng-container *ngIf="this.stateService.getPerfisUnidades()">
        <div class="condominios-imoveis__titulo">
          Condomínios
        </div>
  
        <div
          *ngFor="let condominio of listaCondominios; let i = index"
          class="CollapseLight"
          [id]="condominio.contaCondominio"
          [ngClass]="{ '__is-open': listaCondominios.length === 1 }"
        >
          <div
            class="Collapse_Header"
            (click)="toggleCollapse(condominio.contaCondominio)"
          >
            <div class="Collapse_Header_Title">
              {{ condominio.nomeCondominio }}
            </div>
  
            <div
              class="Collapse_Header_Icon"
              *ngIf="!condominioSelecionado.includes(condominio.contaCondominio)"
            >
              <i class="fas fa-caret-down"></i>
            </div>
          </div>
  
          <div
            *ngIf="!condominioSelecionado.includes(condominio.contaCondominio)"
            class="Collapse_Content"
          >
            <div
              class="FormGroupCheckBox"
              *ngFor="let unidade of condominio.unidades; let i = index"
            >
              <div
                class="FormGroupCheckBox_Input"
                [id]="unidade.id"
              >
                <ion-checkbox
                  mode="md"
                  [checked]="unidade.unidadeAderida"
                  (ionChange)="aderirDesaderirUnidade(
                    condominio.contaCondominio,
                    unidade.idUnidade,
                    unidade.idSubdivisao
                  )"
                ></ion-checkbox>
              </div>
  
              <label
                class="FormGroupCheckBox_Label"
                [for]="unidade.id"
              >
                {{ unidade.descricaoUnidade }}
              </label>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="this.stateService.tipoPerfilLocatario()">
        <div class="condominios-imoveis">
          <div class="condominios-imoveis__titulo">
            Imóveis
          </div>
  
          <div class="condominios-imoveis__content"
          *ngFor="let imovel of listaImoveis; let i = index"
          [id]="imovel.contrato_Locacao"
          >
            <div class="FormGroupCheckBox">
              <div class="FormGroupCheckBox_Input">
                <!-- <ion-checkbox mode="md"></ion-checkbox> -->
                <ion-checkbox
                mode="md"
                [checked]="imovel.aderido"
                (ionChange)="aderirDesaderirImovel(
                  imovel.contrato_Locacao
                )"
                ></ion-checkbox>
              </div>
    
              <label
                class="FormGroupCheckBox_Label"
              >
                {{ imovel.enderecoImovel }}
              </label>
            </div>
          </div>
        </div>
      </ng-container>
      
    </div>
  </form>
</div>