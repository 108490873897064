<div class="AdesaoDeBoletoDigital">
  <h1 class="title">Olá, {{ nome }}!</h1>

  <p class="Paragraph">
    Para sua comodidade, a {{(customer$ | async).nomeApp}} está oferecendo a adesão ao boleto digital, uma
    maneira mais prática e sustentável de realizar o seu pagamento!
  </p>

  <p class="Paragraph">
    Ao aderir, não enviaremos o boleto impresso para a sua residência.
  </p>

  <adesao-de-boleto-digital-selecao-de-unidades
    [listaCondominios]="listaCondominios"
    [listaImoveis]="listaImoveis"
    (closeModal)='closeModal()'
  >
  </adesao-de-boleto-digital-selecao-de-unidades>
</div>
