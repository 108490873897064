<div class="Modal Modal_AdesaoIPTU" [ngClass]="{ '__is-open': isOpenModalIPTU }" *ngIf="iptu && iptu.imoveisIptu">
  <div class="Modal_Header">
    <div class="Modal_Header_Title">
      IPTU
    </div>
  </div>

  <div class="Modal_Content">
    <div class="Block">
      <div class="Block_Container">
        <h1 class="title">Olá, {{ nome }}!</h1>

        <ng-container *ngIf="!jaCadastrado">
          <p class="Paragraph">
            Você ainda não optou pela forma de pagamento do IPTU referente ao ano {{ iptu.anoVigencia }}
          </p>
    
          <p class="Paragraph">
            A <strong>forma padrão é parcelada</strong> mas você<strong> pode optar pela cota única</strong>. Basta enviar a
            opção escolhida.
          </p>
        </ng-container>

        <div class="adesao-iptu-table-container">
          <div class="adesao-iptu-info __is-bold">
            Veja seus(s) contrados de locação abaixo e informe a forma de pagamento desejada para cada um.
          </div>

          <table class="adesao-iptu-table">
            <thead>
              <tr>
                <th>Imóveis</th>
                <th>Nº do contrato</th>
                <th>Forma de pagamento</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let imovel of iptu.imoveisIptu; let i = index">
                <td>{{ imovel.dscImovel }}</td>
                <td>{{ imovel.numContrato }}</td>

                <td>
                  <ion-radio-group (ionChange)="setStatus($event, i)" class="IonRadio">
                    <div class="FormGroup">
                      <div class="form-group-radio flex-align-center flex-colum">
                        <div class="form-group-radio-option flex-align-center">
                          <!-- [checked]="values[i].formaPagamento === 1" -->
                          <ion-radio slot="formaPagamento[i]" value="1" [disabled]="jaCadastrado" mode="md"></ion-radio>
                          <div class="form-group-radio-label">
                            Parcelada
                          </div>
                        </div>

                        <div class="form-group-radio-option flex-align-center">
                          <!-- [checked]="values[i].formaPagamento === 2" -->
                          <ion-radio slot="formaPagamento[i]" value="2" [disabled]="jaCadastrado" mode="md"></ion-radio>
                          <div class="form-group-radio-label">
                            Cota única
                          </div>
                        </div>
                      </div>
                    </div>
                  </ion-radio-group>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="adesao-iptu-info">
            <ng-container *ngIf="!jaCadastrado">
              Você poderá ver a opção escolhida, a qualquer momnento, na página "IPTU 2022" no menu do usuário, <strong>mas
              não poderá alterá-la pelo site após o envio</strong>, somente através de contato telefônico com a APSA.
            </ng-container>

            <ng-container *ngIf="jaCadastrado">
              Caso deseje alterar a forma de pagamento do IPTU, entre em contato pelo telefone (21) 3233-3090.
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="Modal_Footer">
    <div class="Modal_Footer_ListActions" *ngIf="!jaCadastrado">
      <div class="Footer_ListActions_Item __is-full-width" >
        <ds-button full="true" leftIcon="fas fa-check" (click)="submit()">
          Salvar preferências
        </ds-button>
      </div>

      <div class="Footer_ListActions_Item __is-full-width __is-flex">
        <button (click)="closeModal()" class="RememberLater">
          Lembrar depois
        </button>
      </div>
    </div>
  </div>
</div>