import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CashBackService } from 'src/app/modules/cash-back/services/cash-back.service';
import { PessoaLogadaDTO } from './DTO/Request/PessoaLogada.DTO';
import { StateService } from './stateService';

export enum EPerfilDoitt {
  DOITTER  = 102,
  DOITT = 104,
  PARCEIRO = 103,
};

@Injectable({
  providedIn: 'root'
})
export class PerfilDoitt { // Service criado pada debugar apenas
  public perfil$ = new BehaviorSubject<EPerfilDoitt>(EPerfilDoitt.DOITTER);
  
  public isDoitter$ = new BehaviorSubject<boolean>(false);
  public isDoitt$ = new BehaviorSubject<boolean>(false);
  public isParceiro$ = new BehaviorSubject<boolean>(false);
  public EPerfis = EPerfilDoitt;
  public idParceiro = new BehaviorSubject<number>(null);

  constructor( public stateService: StateService, public cashBackService: CashBackService) {
    // const pessoa: PessoaLogadaDTO = this.stateService.getPessoaLogada();
    const pessoa: PessoaLogadaDTO = sessionStorage.getItem('pessoaLogadaCA') ? JSON.parse(sessionStorage.getItem('pessoaLogadaCA')) : JSON.parse(sessionStorage.getItem('pessoaLogada'));
    
    if(pessoa !== undefined && pessoa !== null){
      this.isDoitt$.next(pessoa.perfis.includes(EPerfilDoitt.DOITT))
      this.isDoitter$.next(pessoa.perfis.includes(EPerfilDoitt.DOITTER))
      this.isParceiro$.next(pessoa.perfis.includes(EPerfilDoitt.PARCEIRO))

      if(this.isParceiro$.value){
        let id = this.cashBackService.getIdParceiro()
        this.idParceiro.next(id)
        
      }
    }
  }

  trocarPerfil(perfil: EPerfilDoitt) {
    this.perfil$.next(perfil);
    this.isDoitter$.next(perfil === EPerfilDoitt.DOITTER);
    this.isDoitt$.next(perfil === EPerfilDoitt.DOITT);
    this.isParceiro$.next(perfil === EPerfilDoitt.PARCEIRO);
  }
}